<template>
  <div :class="componentClassName">
    <m-button-container>
      <slot></slot>
    </m-button-container>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import MButtonContainer from '@/components/molecules/button-container';

export default {
  name: 'm-form-action-buttons',
  components: {
    MButtonContainer,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-form-action-buttons');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
