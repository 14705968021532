<template>
  <t-default-layout ref="defaultLayout" active-nav-id="app-versions">
    <div id="t-app-versions-component" class="t-app-versions">
      <div class="t-app-versions-heading-line">
        <a-page-heading class="t-app-versions-heading-item">アプリバージョン管理</a-page-heading>
        <a-button
          v-if="isSuperUser"
          id="t-app-versions-add-button"
          data-testid="app-version-add-button"
          class="t-app-versions-heading-item"
          variant="icon"
          color="primary"
          icon="plus"
          icon-color="white"
          @click="handleAddAppVersion"
        />
      </div>
      <m-section>
        <div class="t-app-versions-tabgroup">
          <m-action-card title="" icon="">
            <m-data-table
              header-id="t-app-versions-table-header"
              body-id="t-app-versions-table-body"
              id-text="app-version-info-display"
              :data="tableData"
              :border="true"
            >
              <a-data-table-column :width="60" prop="androidAppVersion" label="現行" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.isValidLatest ? '○' : '-' }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="150" prop="androidAppVersion" label="Androidバージョン" align="center">
                <template #default="scope">
                  <a-text class="t-app-versions-record-strong" :center="true">
                    Latest: {{ scope.row.androidLatestVersion }}
                  </a-text>
                  <a-text size="small" :center="true">Min: {{ scope.row.androidMinSupportVersion }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="150" prop="iosAppVersion" label="iosバージョン" align="center">
                <template #default="scope">
                  <a-text class="t-app-versions-record-strong" :center="true">
                    Latest: {{ scope.row.iosLatestVersion }}
                  </a-text>
                  <a-text size="small" :center="true">Min: {{ scope.row.iosMinSupportVersion }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="100" prop="releaseDate" label="リリース日" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.releaseDate }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column prop="description" label="説明" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.description || '-' }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column type="action" :width="160" align="center" class="a-data-table-column--action">
                <template #default="scope">
                  <a-button
                    v-if="isSuperUser"
                    :id="scope.row.documentId + '-editBtn'"
                    :data-testid="'app-version-edit-button-' + scope.index"
                    variant="round"
                    size="small"
                    label="編集"
                    color="grey"
                    icon="edit"
                    icon-color="black"
                    :inline="true"
                    @click="handleAppVersionEdit(scope.row)"
                  />
                  <a-button
                    v-if="isSuperUser"
                    :id="scope.row.documentId + '-deleteBtn'"
                    :data-testid="'app-version-delete-button-' + scope.index"
                    variant="round"
                    size="small"
                    label="削除"
                    color="grey"
                    icon="trash"
                    icon-color="black"
                    :inline="true"
                    @click="handleOpenToDeleteModal(scope.row)"
                  />
                </template>
              </a-data-table-column>
            </m-data-table>
          </m-action-card>
        </div>
      </m-section>
    </div>
    <teleport to="body">
      <o-modal
        id="settingappVersionList_deleteModal"
        :visible="deleteAppVersionModalVisible"
        data-testid="app-version-delete-confirm-modal"
        class="o-modal-remove-app-versions-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        @close="handleCloseToDeleteModal"
      >
        <template #title> バージョン情報削除 </template>
        <div style="border: 1px solid black; width: 80%; margin: 0 auto; border-radius: 5px">
          <a-text text-id="deleteAppVersionInfo_iosLatest" :center="true">
            ios最新: {{ targetData.iosLatestVersion }}
          </a-text>
          <a-text text-id="deleteAppVersionInfo_iosMinSupport" :center="true">
            ios最小サポート: {{ targetData.iosMinSupportVersion }}
          </a-text>
          <a-text text-id="deleteAppVersionInfo_androidLatest" :center="true">
            android最新: {{ targetData.androidLatestVersion }}
          </a-text>
          <a-text text-id="deleteAppVersionInfo_androidMinSupport" :center="true">
            android最小サポート: {{ targetData.androidMinSupportVersion }}
          </a-text>
          <a-text text-id="deleteAppVersionInfo_releaseDate" :center="true">
            公開日: {{ targetData.releaseDate }}
          </a-text>
        </div>
        <a-text :center="true" style="margin-top: 20px"> 削除しますか？ </a-text>
        <template #footer>
          <a-button
            id="removeAppVersionDialogCancelBtn"
            data-testid="app-version-delete-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCloseToDeleteModal"
          />
          <a-button
            id="removeAppVersionDialogOKBtn"
            data-testid="app-version-delete-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleVersionDelete"
          />
        </template>
      </o-modal>
    </teleport>

    <!-- Info, Error Modal -->
    <teleport to="body">
      <o-modal
        id="appVersions_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span id="appVersionsDelete_resultModal_body" v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="resultModalOKBtn"
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import dayjs from '@/common/utils.js';

export default {
  name: 't-app-versions',
  components: {
    TDefaultLayout,
    APageHeading,
  },
  mixins: [customRequest, messageContent],
  data() {
    return {
      documentData: '',
      tableData: [],
      isValidLatestVersion: {},
      deleteAppVersionModalVisible: false,
      targetData: {
        documentId: '',
        iosLatestVersion: '',
        iosMinSupportVersion: '',
        androidLatestVersion: '',
        androidMinSupportVersion: '',
        releaseDate: '',
      },
      resultModalVisible: false,
      resultModalTitle: '',
      resultModalContent: '',
      authInfo: this.$store.state.user.authInfo,
    };
  },
  computed: {
    isSuperUser() {
      return this.authInfo === 'manager';
    },
  },
  created() {
    this.getVersionList();
  },
  methods: {
    displayedTableData() {
      return this.tableData;
    },
    getVersionList: async function () {
      try {
        const res = await this.postRequest('/appVersions/list');
        // 最新かつ、今日の日付より前のものにプロパティをつける。
        res.data.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
        const today = dayjs.tz();
        let onlyOneFlag = true;
        res.data.forEach(appVersion => {
          const versionDate = dayjs.tz(appVersion.releaseDate);
          if (versionDate.isSameOrBefore(today) && onlyOneFlag) {
            appVersion.isValidLatest = true;
            appVersion.isOrangeBg = true;
            this.isValidLatestVersion = appVersion;
            onlyOneFlag = false;
          }
        });

        this.tableData = res.data;
      } catch {
        this.tableData = [];
      }
    },
    handleAddAppVersion() {
      this.$router.push({
        path: '/appVersions/add',
        query: {
          iosMinSupportVersion: this.isValidLatestVersion.iosMinSupportVersion ?? '0.0.0',
          iosLatestVersion: this.isValidLatestVersion.iosLatestVersion ?? '0.0.0',
          androidMinSupportVersion: this.isValidLatestVersion.androidMinSupportVersion ?? '0.0.0',
          androidLatestVersion: this.isValidLatestVersion.androidLatestVersion ?? '0.0.0',
          releaseDate: this.isValidLatestVersion.releaseDate ?? '2020-01-01',
        },
      });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showResultModal(title, message) {
      this.handleCloseToDeleteModal();
      this.resultModalVisible = true;
      this.resultModalTitle = title;
      this.resultModalContent = message;
    },
    handleAppVersionEdit(row) {
      this.$router.push({
        path: '/appVersions/edit',
        query: {
          documentId: row.documentId ?? '',
          iosMinSupportVersion: row.iosMinSupportVersion ?? '0.0.0',
          iosLatestVersion: row.iosLatestVersion ?? '0.0.0',
          androidMinSupportVersion: row.androidMinSupportVersion ?? '0.0.0',
          androidLatestVersion: row.androidLatestVersion ?? '0.0.0',
          releaseDate: row.releaseDate ?? '2020-01-01',
          description: row.description ?? '',
        },
      });
    },

    handleOpenToDeleteModal(row) {
      this.targetData.documentId = row.documentId;
      this.targetData.iosLatestVersion = row.iosLatestVersion;
      this.targetData.iosMinSupportVersion = row.iosMinSupportVersion;
      this.targetData.androidLatestVersion = row.androidLatestVersion;
      this.targetData.androidMinSupportVersion = row.androidMinSupportVersion;
      this.targetData.releaseDate = row.releaseDate;
      this.deleteAppVersionModalVisible = true;
    },
    handleCloseToDeleteModal() {
      this.targetData = {};
      this.deleteAppVersionModalVisible = false;
    },
    async handleVersionDelete() {
      try {
        const formData = {
          documentId: this.targetData.documentId,
        };
        const res = await this.postRequest('/appVersions/delete', formData);
        if (res.status === 'success') {
          this.showResultModal(messageContent.infoModalTitle, 'appVersionを削除しました。');
          this.getVersionList();
        } else {
          throw new Error('削除に失敗しました。');
        }
      } catch (e) {
        this.showResultModal(messageContent.errorModalTitle, e.message);
      }
    },
  },
};
</script>
