import { helpers, required } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { compare } from 'compare-versions';

export const appVersionValidation = {
  create: {
    description: {
      requiredIf: helpers.withMessage(messageContent.hasSpecialCharacter, value => {
        return !messageContent.checkUrlSpecialCharacter(value);
      }),
    },
    releaseDate: {
      required: helpers.withMessage('リリース日を登録してください', required),
    },
    iosLatestVersion: {
      required: helpers.withMessage(messageContent.requireInput('タイトル'), required),
      requiredIf: helpers.withMessage(
        '【iOS】最小サポートバージョンを最新バージョン以下にしてください',
        (value, _, data) => {
          const latest = Object.values(value).join('.');
          const minSupport = Object.values(data.iosMinSupportVersion).join('.');
          return compare(latest, minSupport, '>=');
        }
      ),
    },
    androidLatestVersion: {
      requiredIf: helpers.withMessage(
        '【Android】最小サポートバージョンを最新バージョン以下にしてください',
        (value, _, data) => {
          const latest = Object.values(value).join('.');
          const minSupport = Object.values(data.androidMinSupportVersion).join('.');
          return compare(latest, minSupport, '>=');
        }
      ),
    },
  },
  update: {
    description: {
      requiredIf: helpers.withMessage(messageContent.hasSpecialCharacter, value => {
        return !messageContent.checkUrlSpecialCharacter(value);
      }),
    },
  },
};
