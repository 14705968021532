<template>
  <t-default-layout active-nav-id="app-versions">
    <div class="t-app-versions-edit">
      <a-back-page text="バージョン一覧画面" page="AppVersions" />
      <form
        ref="form"
        class="t-app-versions-edit__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-app-versions-edit__fields">
          <m-section>
            <m-action-card
              id="t-app-versions-edit-section-title-1"
              title="バージョン設定"
              header-border
              no-header-margin
              icon="clock-remove"
            >
              <div class="t-app-versions-edit__fields">
                <m-form-input>
                  <a-text class="t-app-versions-edit__form-label-title">最新iOSバージョン</a-text>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-latest-app-version-major-select"
                      v-model="iosLatestVersion.maj"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-ios-latest-app-version-major-select"
                      :options="majIosLatestVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-latest-app-version-minor-select"
                      v-model="iosLatestVersion.min"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-ios-latest-app-version-minor-select"
                      :options="minIosLatestVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-latest-app-version-patch-select"
                      v-model="iosLatestVersion.pat"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-ios-latest-app-version-patch-select"
                      :options="patIosLatestVersionOptions"
                      :disabled="true"
                    />
                  </div>
                </m-form-input>
                <m-form-input>
                  <a-text class="t-app-versions-edit__form-label-title">最新Androidバージョン</a-text>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-android-latest-app-version-major-select"
                      v-model="androidLatestVersion.maj"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-android-latest-app-version-major-select"
                      :options="majAndroidLatestVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-android-latest-app-version-minor-select"
                      v-model="androidLatestVersion.min"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-android-latest-app-version-minor-select"
                      :options="minAndroidLatestVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-android-latest-app-version-patch-select"
                      v-model="androidLatestVersion.pat"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-android-latest-app-version-patch-select"
                      :options="patAndroidLatestVersionOptions"
                      :disabled="true"
                    />
                  </div>
                </m-form-input>
                <div class="t-app-versions-edit__border"></div>
                <m-form-input>
                  <div class="t-app-versions-edit-info-text-wrapper">
                    <a-text class="t-app-versions-edit__form-label-title">最小サポートiOSバージョン</a-text>
                    <a-text class="t-app-versions-edit__form-label-description">強制アップデートの対象</a-text>
                  </div>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-ios-min-support-app-version-major-select"
                      v-model="iosMinSupportVersion.maj"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-ios-min-support-app-version-major-select"
                      :options="majIosMinSupportVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-ios-min-support-app-version-minor-select"
                      v-model="iosMinSupportVersion.min"
                      class="t-app-versions-edit__version-select"
                      :options="minIosMinSupportVersionOptions"
                      data-testid="edit-ios-min-support-app-version-minor-select"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-ios-min-support-app-version-patch-select"
                      v-model="iosMinSupportVersion.pat"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-ios-min-support-app-version-patch-select"
                      :options="patIosMinSupportVersionOptions"
                      :disabled="true"
                    />
                  </div>
                </m-form-input>
                <m-form-input>
                  <div class="t-app-versions-edit-info-text-wrapper">
                    <a-text class="t-app-versions-edit__form-label-title">最小サポートAndroidバージョン</a-text>
                    <a-text class="t-app-versions-edit__form-label-description">強制アップデートの対象</a-text>
                  </div>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-android-min-support-app-version-major-select"
                      v-model="androidMinSupportVersion.maj"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-android-min-support-app-version-major-select"
                      :options="majAndroidMinSupportVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-android-min-support-app-version-minor-select"
                      v-model="androidMinSupportVersion.min"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-android-min-support-app-version-minor-select"
                      :options="minAndroidMinSupportVersionOptions"
                      :disabled="true"
                    />
                  </div>
                  <span class="t-app-versions-edit__dot">.</span>
                  <div class="t-app-versions-edit__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-edit-android-min-support-app-version-patch-select"
                      v-model="androidMinSupportVersion.pat"
                      class="t-app-versions-edit__version-select"
                      data-testid="edit-android-min-support-app-version-patch-select"
                      :options="patAndroidMinSupportVersionOptions"
                      :disabled="true"
                    />
                  </div>
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card
              id="t-app-versions-edit-section-title-2"
              title="詳細設定"
              header-border
              no-header-margin
              icon="clock-remove"
            >
              <div class="t-app-versions-edit__col-2">
                <div class="t-app-versions-edit__fields">
                  <m-form-input>
                    <a-text class="t-app-versions-edit__form-label-title">このバージョンの説明</a-text>　
                    <a-apply-text-field
                      id="t-app-versions-edit-info-text"
                      v-model="description"
                      data-testid="edit-app-version-description-input"
                      class="t-app-versions-edit__content-input"
                      type="textarea"
                      rows="3"
                      maxlength="1000"
                      maxlength-id="t-app-versions-edit-info-text-maxlength"
                      placeholder="このバージョンの説明を入力(optional)"
                    />
                  </m-form-input>
                  <div class="t-app-versions-edit__row">
                    <a-text id="t-app-versions-edit-release-date__a-text">リリース日</a-text>　
                    <m-form-input id="t-app-versions-edit-release-date__form-input" aria-required="">
                      <a-calendar-field
                        id="release-date"
                        v-model="releaseDate"
                        data-testid="edit-app-version-release-date-input"
                        mode="dateTime"
                        :disabled="true"
                      />
                    </m-form-input>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            data-testid="edit-app-version-submit"
            >変更</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Error Modal -->
    <teleport to="body">
      <o-modal
        id="appVersionsEdit_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span id="appVersionsEdit_resultModal_body"   v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="resultModalOKBtn"
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import AButton from '@/components/atoms/button';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import ASelectField from '@/components/atoms/select-field';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { useVuelidate } from '@vuelidate/core';
import { utils } from '@/common/utils.js';
import { appVersionValidation } from '@/validations/appVersion.js';

export default {
  name: 't-app-versions-edit',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASelectField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, messageContent, utils],
  data() {
    return {
      v$: useVuelidate(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,
      releaseDate: '',
      description: '',
      resultType: '',
      majIosLatestVersionOptions: [],
      minIosLatestVersionOptions: [],
      patIosLatestVersionOptions: [],
      majAndroidLatestVersionOptions: [],
      minAndroidLatestVersionOptions: [],
      patAndroidLatestVersionOptions: [],
      majIosMinSupportVersionOptions: [],
      minIosMinSupportVersionOptions: [],
      patIosMinSupportVersionOptions: [],
      majAndroidMinSupportVersionOptions: [],
      minAndroidMinSupportVersionOptions: [],
      patAndroidMinSupportVersionOptions: [],
      iosLatestVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
      androidLatestVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
      iosMinSupportVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
      androidMinSupportVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
    };
  },
  validations() {
    return appVersionValidation.update;
  },
  created() {
    const authInfo = this.$store.state.user.authInfo;
    // 「マネージャー」でない場合は「PermissionDenied」ページにリダイレクトする。
    if (authInfo !== 'manager') {
      this.$router.push({ name: 'PermissionDenied' });
      return;
    }
  },
  mounted() {
    const convertVersion = version => (version ?? '0.0.0').split('.');
    const createOptions = version => [{ label: version, value: version }];
    const query = this.$route.query;
    // 初期値設定
    [this.iosLatestVersion.maj, this.iosLatestVersion.min, this.iosLatestVersion.pat] = convertVersion(
      query.iosLatestVersion
    );
    [this.androidLatestVersion.maj, this.androidLatestVersion.min, this.androidLatestVersion.pat] = convertVersion(
      query.androidLatestVersion
    );
    [this.iosMinSupportVersion.maj, this.iosMinSupportVersion.min, this.iosMinSupportVersion.pat] = convertVersion(
      query.iosMinSupportVersion
    );
    [this.androidMinSupportVersion.maj, this.androidMinSupportVersion.min, this.androidMinSupportVersion.pat] =
      convertVersion(query.androidMinSupportVersion);
    this.releaseDate = query.releaseDate ?? '2020-01-01';
    this.description = query.description;

    this.majIosLatestVersionOptions = createOptions(this.iosLatestVersion.maj);
    this.minIosLatestVersionOptions = createOptions(this.iosLatestVersion.min);
    this.patIosLatestVersionOptions = createOptions(this.iosLatestVersion.pat);

    this.majIosMinSupportVersionOptions = createOptions(this.iosMinSupportVersion.maj);
    this.minIosMinSupportVersionOptions = createOptions(this.iosMinSupportVersion.min);
    this.patIosMinSupportVersionOptions = createOptions(this.iosMinSupportVersion.pat);

    this.majAndroidLatestVersionOptions = createOptions(this.androidLatestVersion.maj);
    this.minAndroidLatestVersionOptions = createOptions(this.androidLatestVersion.min);
    this.patAndroidLatestVersionOptions = createOptions(this.androidLatestVersion.pat);

    this.majAndroidMinSupportVersionOptions = createOptions(this.androidMinSupportVersion.maj);
    this.minAndroidMinSupportVersionOptions = createOptions(this.androidMinSupportVersion.min);
    this.patAndroidMinSupportVersionOptions = createOptions(this.androidMinSupportVersion.pat);
  },
  methods: {
    async handleFormSubmit() {
      const query = this.$route.query;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showResultModal('バリデーションエラー', errMsg);
        return;
      }
      this.disabledButton = 'disabled';

      const formData = {
        documentId: query.documentId,
        description: this.description,
      };

      try {
        const res = await this.postRequest('/appVersions/edit', formData);
        if (res.status === 'success') {
          this.showResultModal('Info', '編集に成功しました。');
          this.resultType = 'info';
        } else {
          throw new Error('編集に失敗しました。');
        }
      } catch (e) {
        this.resultType = 'error';
        this.showResultModal('Error', e.message);
      } finally {
        this.disabledButton = null;
      }
    },

    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
      if (this.resultType === 'info') this.$router.push({ name: 'AppVersions' });
      this.resultType = '';
    },

    showResultModal(title, message) {
      this.resultModalVisible = true;
      this.resultModalTitle = title;
      this.resultModalContent = message;
    },
  },
};
</script>
