<template>
  <div :class="componentClassName">
    <ul class="m-link-container__list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button/index.vue';

export default {
  name: 'm-link-container',
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-link-container');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
