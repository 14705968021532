<template>
  <div :class="componentClassName">
    <template v-if="variant === 'filter'">
      <div class="m-filter-dialog__header">
        <a-button
          id="all_choice"
          variant="text"
          color="primary"
          size="small"
          :inline="true"
          label="全選択"
          @click="handleSelectAllClick"
        />
        <a-button
          id="clear"
          variant="text"
          color="primary"
          size="small"
          :inline="true"
          label="クリア"
          @click="handleClearClick"
        />
      </div>
      <div v-if="searchable" class="m-filter-dialog__search">
        <a-text-field v-model="term" type="search" placeholder="検索" />
      </div>
      <div class="m-filter-dialog__selection">
        <div class="m-filter-dialog__list">
          <template v-for="item in filteredDataList" :key="item.value">
            <div class="m-filter-dialog__list-item">
              <a-checkbox :id="item.value" v-model="selected" :value="item.value" :label="item.label" />
            </div>
          </template>
        </div>
      </div>
      <div class="m-filter-dialog__footer">
        <a-button
          id="cancel"
          variant="outlined"
          color="primary"
          size="small"
          :inline="true"
          label="キャンセル"
          @click="handleFilterCancelClick"
        />
        <a-button
          id="ok"
          variant="round"
          color="primary"
          size="small"
          :inline="true"
          label="確定"
          @click="handleFilterConfirmClick"
        />
      </div>
    </template>
    <template v-if="variant === 'sort-time'">
      <a-button variant="text" label="古い順に並び替え" :inline="true" @click="handleSortAscending" />
      <a-button variant="text" label="新しい順に並び替え" :inline="true" @click="handleSortDescending" />
    </template>
    <template v-if="variant === 'sort-call'">
      <a-button variant="text" label="短い順に並び替え" :inline="true" @click="handleSortAscending" />
      <a-button variant="text" label="長い順に並び替え" :inline="true" @click="handleSortDescending" />
    </template>
    <template v-if="variant === 'sort-normal'">
      <a-button variant="text" label="昇順に並び替え" :inline="true" @click="handleSortAscending" />
      <a-button variant="text" label="降順に並び替え" :inline="true" @click="handleSortDescending" />
    </template>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ATextField from '@/components/atoms/text-field';
import ACheckbox from '@/components/atoms/checkbox';
import { cloneDeep } from 'lodash';
import Simplebar from 'simplebar';

export default {
  name: 'm-filter-dialog',
  components: {
    AButton,
    ATextField,
    ACheckbox,
  },
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(filter|sort-time|sort-call|sort-normal)/) || value === null;
      },
    },
    class: {
      type: String,
      default: '',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: Array,
      default: new Array(0),
    },
    selectedItems: {
      type: Array,
      default: new Array(0),
    },
    handleFilterCancel: {
      type: Function,
      default: () => {},
    },
    handleFilterConfirm: {
      type: Function,
      default: () => {},
    },
    handleSortAscending: {
      type: Function,
      default: () => {},
    },
    handleSortDescending: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      term: '',
      selected: [],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-filter-dialog', this.variant);
      return `${baseClassName} ${this.class}`.trim();
    },
    filteredDataList() {
      if (this.searchable) {
        return this.dataList.filter(item => {
          const term = this.term.trim();
          return term === '' || (term !== '' && item.label.indexOf(term) !== -1);
        });
      }
      return this.dataList;
    },
  },
  created() {
    for (const item of this.selectedItems) this.selected.push(item);
  },
  mounted() {
    const list = this.$el.querySelector('.m-filter-dialog__list');
    if (list) {
      new Simplebar(list);
    }
  },
  methods: {
    handleSelectAllClick() {
      // select all
      this.selected = this.filteredDataList.map(x => x.value);
    },
    handleClearClick() {
      // clear selection
      this.clearFilterData();
    },
    handleFilterCancelClick() {
      // clear data
      this.handleFilterCancel();
    },
    handleFilterConfirmClick() {
      const payload = cloneDeep(this.selected);
      this.handleFilterConfirm(payload);
    },
    clearFilterData() {
      // clear data
      this.term = '';
      this.selected = [];
    },
  },
};
</script>
