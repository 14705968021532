<template>
  <div :class="componentClassName">
    <a-tab-nav :current-name="currentName" :panes="panes" :on-tab-click="onTabClick" />
    <slot></slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ATabNav from '@/components/atoms/tab-nav';
import ATabPane from '@/components/atoms/tab-pane';

export default {
  name: 'm-tab',
  components: {
    ATabNav,
    // eslint-disable-next-line vue/no-unused-components
    // ATabPane,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    current: {
      type: String,
      default: null,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentName: '',
      panes: [],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-tab');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  watch: {
    current(val) {
      this.currentName = val;
    },
  },
  mounted() {
    if (this.current) {
      this.currentName = this.current;
    }
  },
  methods: {
    addPane(item) {
      this.panes.push(item);
      if (this.currentName === '') {
        this.currentName = this.panes[0].paneName;
      }
    },
    removePane(item) {
      const panes = this.panes;
      const index = panes.indexOf(item);
      if (index !== -1) {
        panes.splice(index, 1);
      }
    },
    onTabClick(index) {
      this.currentName = this.panes[index].paneName;
      this.$emit('change', this.currentName);
    },
  },
};
</script>
