<template>
  <div :class="componentClassName">
    <a-brand-logo variant="horizontal" class="t-error__logo" />
    <div class="t-error__content">
      <div class="t-error__image">
        <img src="@/images/pages/404.svg" />
      </div>
      <a-heading :heading="title" color="primary" size="large" class="t-error__heading" />
      <a-text :center="true" class="t-error__text">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="message" />
      </a-text>
      <a-button
        data-testid="go-home-button"
        variant="outlined"
        color="primary"
        label="ホーム画面へ"
        class="t-error__button"
        @click="handleGoToHome"
      />
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ABrandLogo from '@/components/atoms/brand-logo';
import AButton from '@/components/atoms/button';
import AHeading from '@/components/atoms/heading';
import AText from '@/components/atoms/text';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 't-error',
  components: {
    ABrandLogo,
    AButton,
    AHeading,
    AText,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    //title: {
    //  type: String,
    //  default: '',
    //},
    //message: {
    //  type: String,
    //  default: '',
    //},
  },
  data() {
    return {
      title: messageContent.pageNotFoundTitle,
      message: messageContent.pageNotFound,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-error');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    handleGoToHome() {
      window.location.href = '/';
    },
  },
};
</script>
