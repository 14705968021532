<template>
  <t-default-layout active-nav-id="app-versions">
    <div class="t-app-versions-add">
      <a-back-page text="バージョン一覧画面" page="AppVersions" />
      <form
        ref="form"
        class="t-app-versions-add__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-app-versions-add__fields">
          <m-section>
            <m-action-card
              id="t-app-versions-add-section-title-1"
              title="バージョン設定"
              header-border
              no-header-margin
              icon="clock-remove"
            >
              <div class="t-app-versions-add__fields">
                <m-form-input>
                  <a-text class="t-app-versions-add__form-label-title">最新iOSバージョン</a-text>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-latest-app-version-major-select"
                      v-model="iosLatestVersion.maj"
                      class="t-app-versions-add__version-select"
                      data-testid="add-latest-app-version-major-select"
                      :options="majIosLatestVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-latest-app-version-minor-select"
                      v-model="iosLatestVersion.min"
                      class="t-app-versions-add__version-select"
                      data-testid="add-latest-app-version-minor-select"
                      :options="minPatIosLatestVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-latest-app-version-patch-select"
                      v-model="iosLatestVersion.pat"
                      class="t-app-versions-add__version-select"
                      data-testid="add-latest-app-version-patch-select"
                      :options="minPatIosLatestVersionOptions"
                    />
                  </div>
                </m-form-input>
                <m-form-input>
                  <a-text class="t-app-versions-add__form-label-title">最新Androidバージョン</a-text>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-android-latest-app-version-major-select"
                      v-model="androidLatestVersion.maj"
                      class="t-app-versions-add__version-select"
                      data-testid="add-android-latest-app-version-major-select"
                      :options="majAndroidLatestVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-android-latest-app-version-minor-select"
                      v-model="androidLatestVersion.min"
                      class="t-app-versions-add__version-select"
                      data-testid="add-android-latest-app-version-minor-select"
                      :options="minPatAndroidLatestVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-android-latest-app-version-patch-select"
                      v-model="androidLatestVersion.pat"
                      class="t-app-versions-add__version-select"
                      data-testid="add-android-latest-app-version-patch-select"
                      :options="minPatAndroidLatestVersionOptions"
                    />
                  </div>
                </m-form-input>
                <div class="t-app-versions-add__border"></div>
                <m-form-input>
                  <div class="t-app-versions-add__info-text-wrapper">
                    <a-text class="t-app-versions-add__form-label-title">最小サポートiOSバージョン</a-text>
                    <a-text class="t-app-versions-add__form-label-description">強制アップデートの対象</a-text>
                  </div>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-ios-min-support-app-version-major-select"
                      v-model="iosMinSupportVersion.maj"
                      class="t-app-versions-add__version-select"
                      data-testid="add-ios-min-support-app-version-major-select"
                      :options="majIosMinSupportVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-ios-min-support-app-version-minor-select"
                      v-model="iosMinSupportVersion.min"
                      class="t-app-versions-add__version-select"
                      data-testid="add-ios-min-support-app-version-minor-select"
                      :options="minPatIosMinSupportVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-ios-min-support-app-version-patch-select"
                      v-model="iosMinSupportVersion.pat"
                      class="t-app-versions-add__version-select"
                      data-testid="add-ios-min-support-app-version-patch-select"
                      :options="minPatIosMinSupportVersionOptions"
                    />
                  </div>
                </m-form-input>
                <m-form-input>
                  <div class="t-app-versions-add__info-text-wrapper">
                    <a-text class="t-app-versions-add__form-label-title">最小サポートAndroidバージョン</a-text>
                    <a-text class="t-app-versions-add__form-label-description">強制アップデートの対象</a-text>
                  </div>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-android-min-support-app-version-major-select"
                      v-model="androidMinSupportVersion.maj"
                      class="t-app-versions-add__version-select"
                      data-testid="add-android-min-support-app-version-major-select"
                      :options="majAndroidMinSupportVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-android-min-support-app-version-minor-select"
                      v-model="androidMinSupportVersion.min"
                      class="t-app-versions-add__version-select"
                      data-testid="add-android-min-support-app-version-minor-select"
                      :options="minPatAndroidMinSupportVersionOptions"
                    />
                  </div>
                  <span class="t-app-versions-add__dot">.</span>
                  <div class="t-app-versions-add__version-select-wrapper">
                    <a-select-field
                      id="t-app-versions-add-android-min-support-app-version-patch-select"
                      v-model="androidMinSupportVersion.pat"
                      class="t-app-versions-add__version-select"
                      data-testid="add-android-min-support-app-version-patch-select"
                      :options="minPatAndroidMinSupportVersionOptions"
                    />
                  </div>
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card
              id="t-app-versions-add-section-title-2"
              title="詳細設定"
              header-border
              no-header-margin
              icon="clock-remove"
            >
              <div class="t-app-versions-add__col-2">
                <div class="t-app-versions-add__fields">
                  <m-form-input>
                    <a-text class="t-app-versions-add__form-label-title">このバージョンの説明</a-text>　
                    <a-apply-text-field
                      id="t-app-versions-add-info-text"
                      v-model="description"
                      data-testid="add-app-version-description-input"
                      class="t-app-versions-add__content-input"
                      type="textarea"
                      rows="3"
                      maxlength="1000"
                      maxlength-id="t-app-versions-add-info-text-maxlength"
                      placeholder="このバージョンの説明を入力(optional)"
                    />
                  </m-form-input>
                  <div class="t-app-versions-add__row">
                    <a-text id="t-app-versions-add-release-date__a-text">リリース日</a-text>
                    <m-form-input id="t-app-versions-add-release-date__form-input" aria-required="">
                      <a-calendar-field
                        id="release-date"
                        v-model="releaseDate"
                        data-testid="add-app-version-release-date-input"
                        :min-date="minDate"
                        mode="dateTime"
                      />
                    </m-form-input>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            data-testid="add-app-version-submit"
            >追加</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Error Modal -->
    <teleport to="body">
      <o-modal
        id="appVersionsCreate_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span id="appVersionsCreate_resultModal_body" v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="resultModalOKBtn"
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import AButton from '@/components/atoms/button';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import ASelectField from '@/components/atoms/select-field';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { useVuelidate } from '@vuelidate/core';
import { utils } from '@/common/utils.js';
import { appVersionValidation } from '@/validations/appVersion.js';

export default {
  name: 't-app-versions-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASelectField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, messageContent, utils],

  data() {
    return {
      v$: useVuelidate(),
      minDate: utils.specifyDateTime('2020-11-22T00:00:00Z'),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,
      releaseDate: '',
      description: '',
      resultType: '',
      majIosLatestVersionOptions: [],
      minPatIosLatestVersionOptions: [],
      majAndroidLatestVersionOptions: [],
      minPatAndroidLatestVersionOptions: [],
      majIosMinSupportVersionOptions: [],
      minPatIosMinSupportVersionOptions: [],
      majAndroidMinSupportVersionOptions: [],
      minPatAndroidMinSupportVersionOptions: [],
      iosLatestVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
      androidLatestVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
      iosMinSupportVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
      androidMinSupportVersion: {
        maj: '0',
        min: '0',
        pat: '0',
      },
    };
  },
  validations() {
    return appVersionValidation.create;
  },
  created() {
    const authInfo = this.$store.state.user.authInfo;
    // 「マネージャー」でない場合は「PermissionDenied」ページにリダイレクトする。
    if (authInfo !== 'manager') {
      this.$router.push({ name: 'PermissionDenied' });
      return;
    }
  },
  mounted() {
    const query = this.$route.query;
    const largeNum = (num1, num2) => (Number(num1) > Number(num2) ? Number(num1) : Number(num2));
    // 初期値設定
    [this.iosLatestVersion.maj, this.iosLatestVersion.min, this.iosLatestVersion.pat] = (
      query.iosLatestVersion ?? '0.0.0'
    ).split('.');
    [this.androidLatestVersion.maj, this.androidLatestVersion.min, this.androidLatestVersion.pat] = (
      query.androidLatestVersion ?? '0.0.0'
    ).split('.');
    [this.iosMinSupportVersion.maj, this.iosMinSupportVersion.min, this.iosMinSupportVersion.pat] = (
      query.iosMinSupportVersion ?? '0.0.0'
    ).split('.');
    [this.androidMinSupportVersion.maj, this.androidMinSupportVersion.min, this.androidMinSupportVersion.pat] = (
      query.androidMinSupportVersion ?? '0.0.0'
    ).split('.');
    this.releaseDate = query.releaseDate ?? '2020-01-01';

    // selectボックスの数値設定
    this.majIosLatestVersionOptions = this.settingVersionOptions(this.iosLatestVersion.maj);
    this.minPatIosLatestVersionOptions = this.settingVersionOptions(
      largeNum(this.iosLatestVersion.min, this.iosLatestVersion.pat)
    );
    this.majAndroidLatestVersionOptions = this.settingVersionOptions(this.androidLatestVersion.maj);
    this.minPatAndroidLatestVersionOptions = this.settingVersionOptions(
      largeNum(this.androidLatestVersion.min, this.androidLatestVersion.pat)
    );

    this.majIosMinSupportVersionOptions = this.settingVersionOptions(this.iosMinSupportVersion.maj);
    this.minPatIosMinSupportVersionOptions = this.settingVersionOptions(
      largeNum(this.iosMinSupportVersion.min, this.iosMinSupportVersion.pat)
    );
    this.majAndroidMinSupportVersionOptions = this.settingVersionOptions(this.androidMinSupportVersion.maj);
    this.minPatAndroidMinSupportVersionOptions = this.settingVersionOptions(
      largeNum(this.androidMinSupportVersion.min, this.androidMinSupportVersion.pat)
    );
  },
  methods: {
    settingVersionOptions: currentNumber => {
      return [...Array(Number(currentNumber) + 11)].map((_, index) => ({
        label: index.toString(),
        value: index.toString(),
      })); // 0 ~ currentNumber+10の範囲の数値の配列を返す。
    },
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showResultModal('バリデーションエラー', errMsg);
        return;
      }

      this.disabledButton = 'disabled';
      const iosLatestVersion = Object.values(this.iosLatestVersion).join('.');
      const iosMinSupportVersion = Object.values(this.iosMinSupportVersion).join('.');
      const androidLatestVersion = Object.values(this.androidLatestVersion).join('.');
      const androidMinSupportVersion = Object.values(this.androidMinSupportVersion).join('.');

      try {
        const formData = {
          iosLatestVersion,
          iosMinSupportVersion,
          androidLatestVersion,
          androidMinSupportVersion,
          description: this.description,
          releaseDate: this.releaseDate,
        };
        const res = await this.postRequest('/appVersions/add', formData);
        if (res.status === 'success') {
          this.showResultModal(messageContent.infoModalTitle, '追加に成功しました。');
          this.resultType = 'info';
        } else {
          throw new Error('追加に失敗しました。');
        }
      } catch (e) {
        this.resultType = 'error';
        this.showResultModal(messageContent.errorModalTitle, e.message);
      } finally {
        this.disabledButton = null;
      }
    },

    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
      if (this.resultType === 'info') this.$router.push({ name: 'AppVersions' });
      this.resultType = '';
    },
    showResultModal(title, message) {
      this.resultModalVisible = true;
      this.resultModalTitle = title;
      this.resultModalContent = message;
    },
  },
};
</script>
