<template>
  <div :class="componentClassName">
    <div ref="items" class="m-user-invitation-list__items">
      <template v-for="(item, index) in currentItems" :key="index">
        <m-user-invitation-item
          :ref="`item-${index}`"
          v-model:userID="currentItems[index].userID"
          v-model:authInfo="currentItems[index].authInfo"
          v-model:userName="currentItems[index].userName"
          v-model:departmentInfo="currentItems[index].departmentInfo"
          v-model:docomoID="currentItems[index].docomoID"
          :item-index="index"
          @remove="handleRemoveItem"
          @change="handleItemChange"
        />
      </template>
    </div>
    <div class="m-user-invitation-list__button">
      <a-button
        id="uilAddNewUserBtn"
        data-testid="add-user-form-increase-button"
        variant="fill"
        color="primary"
        size="small"
        label="ユーザー追加"
        @click="handleAddItem"
      />
    </div>
  </div>
  <!-- Info, Erro Modal -->
  <teleport to="body">
    <o-modal
      id="testPush_resultModal"
      :visible="resultModalVisible"
      class="o-modal--confirmation"
      footer-variant="confirmation"
      width="442px"
      :footer-shadow="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <template #title>{{ resultModalTitle }}</template>
      <a-text>
        <!-- eslint-disable -->
        <span v-html="resultModalContent" />
        <!-- eslint-enable -->
      </a-text>
      <template #footer>
        <a-button
          data-testid="error-info-modal-confirm-button"
          variant="block"
          color="primary"
          label="OK"
          size="small"
          @click="handleCloseModal"
        />
      </template>
    </o-modal>
  </teleport>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import MUserInvitationItem from '@/components/molecules/user-invitation-item';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 'm-user-invitation-list',
  components: {
    AButton,
    MUserInvitationItem,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: new Array(0),
    },
    modelValue: {
      type: Array,
      default: null,
    },
    addLimit: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change', 'update:modelValue', 'reachLimit'],
  data() {
    return {
      currentItems: [],
      listAninationInterval: 250, // ms
      removeUserInvitationDialogVisible: false,
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-user-invitation-list');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  watch: {
    items: {
      handler(val) {
        this.setCurrentValue(val);
      },
      deep: true,
    },
    modelValue: {
      handler(val, oldVal) {
        this.setCurrentValue(val);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    setCurrentValue(items) {
      this.currentItems.splice(0);
      if (Array.isArray(items)) {
        items.forEach(item => {
          this.currentItems.push(item);
        });
      }
    },
    handleAddItem() {
      if (this.modelValue.length >= this.addLimit) {
        this.$emit('reachLimit', true);
        this.showInfoModal(messageContent.messengerLimitUser);
        return;
      }
      const vm = this;
      vm.currentItems.push({
        userID: '',
        authInfo: 'writer',
        userName: '',
        departmentInfo: '',
        docomoID: '',
      });
      vm.emitEvents();
      // get last child element
      const itemIndex = vm.currentItems.length - 1;
      vm.$nextTick(() => {
        const item = vm.$refs[`item-${itemIndex}`][0];
        if (item) {
          vm.animateList(true, item.$el).then(() => {
            vm.clearAnimationModifiers();
          });
        }
      });
    },
    handleRemoveItem(index) {
      const vm = this;
      const item = vm.$refs[`item-${index}`][0];
      if (item) {
        vm.animateList(false, item.$el).then(() => {
          vm.currentItems.splice(index, 1);
          vm.emitEvents();
          vm.$nextTick(() => {
            vm.clearAnimationModifiers();
          });
        });
      } else {
        vm.currentItems.splice(index, 1);
        vm.emitEvents();
      }
    },
    animateList(isExpand, element) {
      const vm = this;
      const elHeight = element.scrollHeight;
      return new Promise(resolve => {
        if (isExpand) {
          requestAnimationFrame(function () {
            element.classList.add('animating');
            element.style.maxHeight = 0 + 'px';
            vm.$nextTick(() => {
              requestAnimationFrame(function () {
                element.style.maxHeight = elHeight + 'px';
                element.addEventListener('transitionend', vm.handleTransitionEnd);
                setTimeout(() => {
                  resolve();
                }, vm.listAninationInterval);
              });
            });
          });
        } else {
          const elementTransition = element.style.transition;
          element.style.transition = '';
          requestAnimationFrame(function () {
            element.style.maxHeight = elHeight + 'px';
            element.style.transition = elementTransition;
            element.classList.add('animating');
            vm.$nextTick(() => {
              requestAnimationFrame(function () {
                element.style.maxHeight = 0 + 'px';
                element.addEventListener('transitionend', vm.handleTransitionEnd);
                setTimeout(() => {
                  resolve();
                }, vm.listAninationInterval);
              });
            });
          });
        }
      });
    },
    clearAnimationModifiers() {
      this.currentItems.forEach((item, index) => {
        const el = this.$refs[`item-${index}`][0].$el;
        const elementTransition = el.style.transition;
        el.style.transition = null;
        el.style.maxHeight = null;
      });
    },
    handleTransitionEnd(e) {
      const el = e.target;
      el.classList.remove('animating');
      e.target.removeEventListener('transitionend', this.handleTransitionEnd);
    },
    handleItemChange() {
      this.emitEvents();
    },
    emitEvents() {
      this.$emit('update:modelValue', cloneDeep(this.currentItems));
      this.$emit('change', cloneDeep(this.currentItems));
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
