import { utils } from '@/common/utils.js';
import { helpers, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';

export const commonCastDateValidationRules = {
  // 公開終了日時が公開開始日時より前の場合、エラーモーダルを出す
  validateSetCastEndDateBeforeCastStartDate: (value, vm) => {
    if (!value || !vm.castEndDate || vm.checkStatus === 'draft') return true;
    return value <= vm.castEndDate;
  },

  // 公開日時と公開終了日時が同じ日時に設定した場合
  validateSetSameDateTime: (value, vm) => {
    if (!value || !vm.castEndDate || vm.checkStatus === 'draft') return true;
    return value !== vm.castEndDate;
  },

  // 現在の日時より公開日が過去の場合、エラーモーダルを出す
  // 現在の日時より公開終了日が過去の場合、エラーモーダルを出す
  validateSetCastDateToPastDateTime: (value, vm) => {
    if (!value || vm.checkStatus === 'draft') return true;
    const nowDate = utils.currentDateTime();
    return value >= nowDate;
  },

  // 公開中でない場合、現在の日時より公開日が過去の場合、エラーモーダルを出す
  // data: announcementData || newsAndSeminarData || campaignData
  validateSetCastStartDateToPastDateTimeWithNotBePublished: data => (value, vm) => {
    if (!vm[data].castStartDate || vm[data].checkStatus === 'draft') return true;
    if (!value && vm.hasParentDoc) return true;
    const nowDate = utils.currentDateTime();
    return !(!value && vm[data].castEndDate >= nowDate && vm[data].castStartDate < nowDate);
  },

  // 公開中でない場合、現在の日時より公開終了日が過去の場合、エラーモーダルを出す
  // data: announcementData || newsAndSeminarData || campaignData
  validateSetCastEndDateToPastDateTimeWithNotBePublished: data => (value, vm) => {
    if (!vm[data].castEndDate || vm[data].checkStatus === 'draft') return true;
    const nowDate = utils.currentDateTime();
    return !(!value && vm[data].castEndDate < nowDate);
  },

  // 公開終了している場合、保存できずエラーモーダルを出す
  // data: announcementData || newsAndSeminarData || campaignData
  validateEditAfterPublishing: data => (value, vm) => {
    if (!value || vm[data].checkStatus === 'draft' || vm.currentCheckStatus) return true;
    const nowDate = utils.currentDateTime();
    return value >= nowDate;
  },
};

export const commonCastDateValidations = checkStatus => {
  return {
    create: {
      castStartDate: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('公開日'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        validateSetCastEndDateBeforeCastStartDate: helpers.withMessage(
          messageContent.setCastEndDateBeforeCastStartDate,
          commonCastDateValidationRules.validateSetCastEndDateBeforeCastStartDate
        ),
        validateSetSameDateTime: helpers.withMessage(
          messageContent.setSameDateTime,
          commonCastDateValidationRules.validateSetSameDateTime
        ),
        validateSetPastDateTime: helpers.withMessage(
          messageContent.setCastStartDateToPastDateTime,
          commonCastDateValidationRules.validateSetCastDateToPastDateTime
        ),
      },

      castEndDate: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('公開終了日'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        validateSetPastDateTime: helpers.withMessage(
          messageContent.setCastEndDateToPastDateTime,
          commonCastDateValidationRules.validateSetCastDateToPastDateTime
        ),
      },
    },

    update: {
      castStartDate: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('公開日'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        validateSetCastEndDateBeforeCastStartDate: helpers.withMessage(
          messageContent.setCastEndDateBeforeCastStartDate,
          commonCastDateValidationRules.validateSetCastEndDateBeforeCastStartDate
        ),
        validateSetSameDateTime: helpers.withMessage(
          messageContent.setSameDateTime,
          commonCastDateValidationRules.validateSetSameDateTime
        ),
      },

      castEndDate: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('公開終了日'),
          requiredIf(() => checkStatus !== 'draft')
        ),
      },

      isPublished: data => {
        return {
          validateSetCastStartDateToPastDateTimeWithNotBePublished: helpers.withMessage(
            messageContent.setCastStartDateToPastDateTime,
            commonCastDateValidationRules.validateSetCastStartDateToPastDateTimeWithNotBePublished(data)
          ),
          validateSetCastEndDateToPastDateTimeWithNotBePublished: helpers.withMessage(
            messageContent.setCastEndDateToPastDateTime,
            commonCastDateValidationRules.validateSetCastEndDateToPastDateTimeWithNotBePublished(data)
          ),
        };
      },

      beforeCastEndDate: data => {
        return {
          validateEditAfterPublishing: helpers.withMessage(
            messageContent.editAfterPublishing,
            commonCastDateValidationRules.validateEditAfterPublishing(data)
          ),
        };
      },
    },
  };
};
