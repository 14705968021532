<template>
  <div class="o-transition-type-service">
    <m-form-input id="o-transition-type-service__form-input" class="o-transition-type-service__form-input">
      <a-text id="o-transition-type-service__a-text" class="o-transition-type-service__a-text">リンク先</a-text>
      <a-radio
        id="o-transition-type-service__radio__url"
        v-model="transitionTypeChild"
        class="o-transition-type-service__radio o-transition-type-service__radio__url"
        :disabled="disabled"
        name="transitionType"
        value="url"
        label="外部Webサイト"
        data-testid="add-service-transitionType"
      />
      <a-radio
        id="o-transition-type-service__radio__service"
        v-model="transitionTypeChild"
        class="o-transition-type-service__radio o-transition-type-service__radio__campaign"
        :disabled="disabled"
        name="transitionType"
        value="service"
        label="アプリ内サービス個別説明ページ"
        data-testid="add-service-transitionType"
      />
    </m-form-input>
    <m-form-input v-if="transitionTypeChild === 'url'">
      <a-apply-text-field
        id="o-transition-type-service__contents-url"
        v-model="contentsUrlChild"
        class="o-transition-type-service__contents-url"
        :disabled="disabled"
        data-testid="add-banner-url-input"
        type="text"
        maxlength="500"
        placeholder="URL入力"
      />
    </m-form-input>
    <m-form-input v-if="transitionTypeChild === 'service'">
      <a-select-field
        v-if="updatedServiceFlag"
        id="o-transition-type-service__transition-doc-service"
        v-model="transitionServiceDocChild"
        class="o-transition-type-service__transition-doc-service"
        :disabled="disabled"
        :options="transitionDocServiceOptions"
      />
    </m-form-input>
  </div>
</template>

<script>
import _ from 'lodash';
import MFormInput from '@/components/molecules/form-input';
import ASelectField from '@/components/atoms/select-field';
import AApplyTextField from '@/components/atoms/apply-text-field';
import ARadio from '@/components/atoms/radio';
import { utils } from '@/common/utils.js';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 'o-transition-type-service',
  components: {
    MFormInput,
    ASelectField,
    AApplyTextField,
    ARadio,
  },
  mixins: [customRequest],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    transitionType: {
      type: String,
      default: 'web',
    },
    transitionDoc: {
      type: String,
      default: '',
    },
    contentsUrl: {
      type: String,
      default: '',
    },
  },
  emits: ['update:transitionType', 'update:transitionDoc', 'update:contentsUrl'],
  data() {
    return {
      transitionTypeChild: '',
      transitionServiceDocChild: '',
      contentsUrlChild: '',
      transitionDocServiceOptions: [],
      updatedServiceFlag: false,
    };
  },
  watch: {
    transitionTypeChild(val) {
      this.$emit('update:transitionType', val);
      if (val === 'service') {
        this.$emit('update:transitionDoc', this.transitionServiceDocChild);
      }
    },
    transitionServiceDocChild(val) {
      this.$emit('update:transitionDoc', val);
    },
    transitionInfoDocChild(val) {
      this.$emit('update:transitionDoc', val);
    },
    contentsUrlChild(val) {
      this.$emit('update:contentsUrl', val);
    },
  },
  async mounted() {
    this.transitionTypeChild = this.transitionType;
    if (this.transitionType === 'service') {
      this.transitionServiceDocChild = this.transitionDoc;
    }
    this.contentsUrlChild = this.contentsUrl;
    await this.getServiceData();
  },
  methods: {
    // サービスのデータを取得する
    async getServiceData() {
      const res = await this.postRequest('/services/get-list', { execUserID: this.execUserID });
      if (res.status === 'success') {
        this.transitionDocServiceOptions = this.transformToOptionData(res.data);
        if (this.transitionDoc && !this.transitionDocServiceOptions.some(item => item.value === this.transitionDoc)) {
          const id = this.transitionDoc;
          const doc = await this.postRequest('/services/get', { id });
          if (doc.status === 'success') {
            this.transitionDocServiceOptions = [
              this.getCurrentSelectedItem(id, doc),
              ...this.transitionDocServiceOptions,
            ];
          } else {
            if (res.message) {
              this.showErrorModal(res.message);
            } else {
              this.showErrorModal(messageContent.requireTry('サービスデータ取得'));
            }
          }
        }
        this.updatedServiceFlag = true;
      } else {
        if (res.message) {
          this.showErrorModal(res.message);
        } else {
          this.showErrorModal(messageContent.requireTry('サービスデータ取得'));
        }
      }
    },
    // 現在の選択されたアイテムを取得
    getCurrentSelectedItem(id, doc) {
      if (doc.data && doc.data.data) {
        const data = doc.data.data;
        const publishStatusLabel = utils.getPublishStatusLabel(data);
        return {
          value: id,
          label: `[現在選択][${publishStatusLabel}] ${id} ${data.serviceName1}${data.serviceName2}`,
          data: data,
        };
      } else {
        return {
          value: id,
          label: `[現在選択][削除] ${id}`,
          data: null,
        };
      }
    },
    // サービスのデータをselect-field用optionDataに変換
    transformToOptionData(dataArray) {
      const ret = [];
      for (const id in dataArray) {
        const publishStatus = utils.getPublishStatus(dataArray[id]);
        if (publishStatus === 'ended' || publishStatus === 'unapproved') {
          continue;
        }
        // サービス個別説明ページだけがあるのを取得する
        if (dataArray[id].individualFlag) {
          const publishStatusLabel = publishStatus === 'published' ? '公開中' : '未公開';
          ret.push({
            value: id,
            label: `[${publishStatusLabel}] ${id} ${dataArray[id].serviceName1}${dataArray[id].serviceName2}`,
            data: dataArray[id],
          });
        }
      }
      ret.sort((a, b) => (a.data.castStartDate > b.data.castStartDate ? 1 : -1));
      return ret;
    },
  },
};
</script>
