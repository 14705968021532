<template>
  <div v-show="visible" :id="id" class="o-modal__wrapper" @click.self="handleWrapperClick">
    <div ref="dialog" :class="componentClassName" :style="style">
      <a-modal-header
        v-if="showHeader"
        :id="id"
        :data-testid="dataTestid"
        :icon="titleIcon"
        :icon-color="titleIconColor"
        :with-border="showHeaderBorder"
        :show-close="showClose"
        class="o-modal__header"
        @close="handleClose"
      >
        <slot :id="id" name="title">{{ title }}</slot>
      </a-modal-header>
      <div v-show="rendered" class="o-modal__body"><slot></slot></div>
      <a-modal-footer v-if="$slots.footer" class="o-modal__footer" :variant="footerVariant" :shadow="footerShadow">
        <slot name="footer"></slot>
      </a-modal-footer>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapModifiers } from '@/libs/component';
import Popup from '@/libs/popup';
import AModalHeader from '@/components/atoms/modal-header';
import AModalFooter from '@/components/atoms/modal-footer';
import Simplebar from 'simplebar';

export default {
  name: 'o-modal',
  components: {
    AModalHeader,
    AModalFooter,
  },
  mixins: [Popup],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: null,
    },
    titleIconColor: {
      type: String,
      default: 'orange',
    },
    footerVariant: {
      type: String,
      default: 'dialog',
      validator(value) {
        return value.match(/(primary|dialog|confirmation)/);
      },
    },
    footerShadow: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    modalAppendToBody: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '500px',
    },
    minHeight: {
      type: String,
      default: null,
    },
    fullscreen: Boolean,
    class: {
      type: String,
      default: '',
    },
    top: {
      type: String,
      default: '80px',
    },
    beforeClose: {
      type: Function,
      default: null,
    },
    center: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    dataTestid: {
      type: String,
      default: '',
    },
  },
  emits: ['open', 'close', 'closed', 'update:visible', 'closeModal'],
  data() {
    return {
      closed: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'o-modal',
        this.fullscreen ? 'fullscreen' : '',
        this.center ? 'center' : '',
        this.$slots.footer ? 'with-footer' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    style() {
      const style = {};
      if (!this.fullscreen) {
        // style.marginTop = this.top;
        // style.top = this.top;
        if (this.width) {
          style.width = this.width;
        }
      }
      if (this.minHeight) {
        style.minHeight = this.minHeight;
      }
      return style;
    },
    showHeaderBorder() {
      if (this.$slots.title || this.title) {
        return true;
      }
      return false;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.closed = false;
        this.$emit('open');
        this.$el.addEventListener('scroll', this.updatePopper);
        this.$nextTick(() => {
          this.$refs.dialog.scrollTop = 0;
        });
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
        }
      } else {
        this.$el.removeEventListener('scroll', this.updatePopper);
        if (!this.closed) this.$emit('close');
      }
    },
  },
  mounted() {
    if (this.visible) {
      this.rendered = true;
      this.open();
      if (this.appendToBody) {
        document.body.appendChild(this.$el);
      }
    }
    if (!this.noScroll) {
      const body = this.$el.querySelector('.o-modal__body');
      setTimeout(() => {
        // NOTE: Simplebarが要素のリサイズをモニタリングし、オフセット演算を行っている。
        // appendChildによりDOMに要素が追加されDOMレンダリングが完了したタイミングで
        // Simplebarを適用しないとオフセット演算がループしてしまうためsetTimeoutで遅延させる。
        new Simplebar(body);
      }, 100);
    }
  },
  unmounted() {
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  methods: {
    handleWrapperClick() {
      this.$emit('closeModal');
      if (!this.closeOnClickModal) return;
      this.handleClose();
    },
    handleClose() {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.hide);
      } else {
        this.hide();
      }
    },
    hide(cancel) {
      if (cancel !== false) {
        this.$emit('update:visible', false);
        this.$emit('close');
        this.closed = true;
      }
    },
    afterLeave() {
      this.$emit('closed');
    },
    // popup functions
    open(options) {
      if (!this.rendered) {
        this.rendered = true;
      }

      const props = _.merge({}, this.$props || this, options);

      if (this._closeTimer) {
        clearTimeout(this._closeTimer);
        this._closeTimer = null;
      }
      clearTimeout(this._openTimer);

      const openDelay = Number(props.openDelay);
      if (openDelay > 0) {
        this._openTimer = setTimeout(() => {
          this._openTimer = null;
          this.doOpen(props);
        }, openDelay);
      } else {
        this.doOpen(props);
      }
    },
  },
};
</script>
