/* Automatically generated by './build/bin/build-entry.js' */
import './styles/index.scss';
import 'vue-loading-overlay/dist/vue-loading.css';
import AAlert from './components/atoms/alert/index.js';
import AAvatar from './components/atoms/avatar/index.js';
import ABrandLogo from './components/atoms/brand-logo/index.js';
import AButton from './components/atoms/button/index.js';
import ASplitButton from './components/atoms/split-button/index.js';
import ACalendarField from './components/atoms/calendar-field/index.js';
import ACaptionList from './components/atoms/caption-list/index.js';
import ACheckbox from './components/atoms/checkbox/index.js';
import ADataListItem from './components/atoms/data-list-item/index.js';
import ADataTableBody from './components/atoms/data-table-body/index.js';
import ADataTableBodyServices from './components/atoms/data-table-body-services/index.js';
import ADataTableColumn from './components/atoms/data-table-column/index.js';
import ADataTableHeader from './components/atoms/data-table-header/index.js';
import AErrorBalloon from './components/atoms/error-balloon/index.js';
import AExpiryDateField from './components/atoms/expiry-date-field/index.js';
import AHeading from './components/atoms/heading/index.js';
import AIcon from './components/atoms/icon/index.js';
import AListGroupHeader from './components/atoms/list-group-header/index.js';
import AListItem from './components/atoms/list-item/index.js';
import ALoading from './components/atoms/loading/index.js';
import AModalFooter from './components/atoms/modal-footer/index.js';
import AModalHeader from './components/atoms/modal-header/index.js';
import ANavigationItem from './components/atoms/navigation-item/index.js';
import APageHeading from './components/atoms/page-heading/index.js';
import APagination from './components/atoms/pagination/index.js';
import ARadio from './components/atoms/radio/index.js';
import ASectionDivider from './components/atoms/section-divider/index.js';
import ASelectField from './components/atoms/select-field/index.js';
import ASummaryItemHeader from './components/atoms/summary-item-header/index.js';
import ASwitch from './components/atoms/switch/index.js';
import ATabNav from './components/atoms/tab-nav/index.js';
import ATabPane from './components/atoms/tab-pane/index.js';
import ATag from './components/atoms/tag/index.js';
import ATextField from './components/atoms/text-field/index.js';
import AText from './components/atoms/text/index.js';
import AUomText from './components/atoms/uom-text/index.js';
import AUploadField from './components/atoms/upload-field/index.js';
import MActionCard from './components/molecules/action-card/index.js';
import MApplicationSummary from './components/molecules/application-summary/index.js';
import MButtonContainer from './components/molecules/button-container/index.js';
import MCalendarRangeField from './components/molecules/calendar-range-field/index.js';
import MCallingActionButtonList from './components/molecules/calling-action-button-list/index.js';
import MCardFilter from './components/molecules/card-filter/index.js';
import MCardList from './components/molecules/card-list/index.js';
import MContactSummaryBar from './components/molecules/contact-summary-bar/index.js';
import MCurrentUserStatus from './components/molecules/current-user-status/index.js';
import MDataList from './components/molecules/data-list/index.js';
import MDataTable from './components/molecules/data-table/index.js';
import MDataTableServices from './components/molecules/data-table-services/index.js';
import MFilterDialog from './components/molecules/filter-dialog/index.js';
import MFormActionButtons from './components/molecules/form-action-buttons/index.js';
import MFormInput from './components/molecules/form-input/index.js';
import MLinkContainer from './components/molecules/link-container/index.js';
import MListGroup from './components/molecules/list-group/index.js';
import MNavigation from './components/molecules/navigation/index.js';
import MNumpad from './components/molecules/numpad/index.js';
import MPaymentRadio from './components/molecules/payment-radio/index.js';
import MSection from './components/molecules/section/index.js';
import MTab from './components/molecules/tab/index.js';
import MUserInvitationItem from './components/molecules/user-invitation-item/index.js';
import MUserInvitationList from './components/molecules/user-invitation-list/index.js';
import MUserInvitationRadio from './components/molecules/user-invitation-radio/index.js';
import MUserInvitationUploadRadio from './components/molecules/user-invitation-upload-radio/index.js';
import MUserSelectionTable from './components/molecules/user-selection-table/index.js';
import MRichTextEditor from './components/molecules/rich-text-editor/index.js';
import OGlobalFooter from './components/organisms/global-footer/index.js';
import OGlobalSidebar from './components/organisms/global-sidebar/index.js';
import OModal from './components/organisms/modal/index.js';
import OCsvUploader from './components/organisms/csv-uploader/index.js';
import OCsvHistory from './components/organisms/csv-history/index.js';
import OTransitionTypeSelector from './components/organisms/transition-type-selector/index.js';
import OTransitionTypeService from './components/organisms/transition-type-service/index.js';
import OTestPush from './components/organisms/test-push/index.js';
import OTestPushSelected from './components/organisms/test-push-selected/index.js';
import TDefaultEntry from './components/templates/default-entry/index.js';
import TDefaultLayout from './components/templates/default-layout/index.js';
import TError from './components/templates/error/index.js';
import TLogin from './components/templates/login/index.js';
import TAnnouncementsAdd from './components/templates/announcements-add/index.js';
import TAnnouncementsDetail from './components/templates/announcements-detail/index.js';
import TAnnouncementsEdit from './components/templates/announcements-edit/index.js';
import TCampaignsAdd from './components/templates/campaigns-add/index.js';
import TCampaignsDetail from './components/templates/campaigns-detail/index.js';
import TCampaignsEdit from './components/templates/campaigns-edit/index.js';
import TLoginInvitedUserSetPassword from './components/templates/login-invited-user-set-password/index.js';
import TLoginPasscodeInput from './components/templates/login-passcode-input/index.js';
import TAppVersionAdd from './components/templates/appVersions-add';
import TAppVersionEdit from './components/templates/appVersions-edit';

const components = [
  AAlert,
  AAvatar,
  ABrandLogo,
  AButton,
  ASplitButton,
  ACalendarField,
  ACaptionList,
  ACheckbox,
  ADataListItem,
  ADataTableBody,
  ADataTableBodyServices,
  ADataTableColumn,
  ADataTableHeader,
  AErrorBalloon,
  AExpiryDateField,
  AHeading,
  AIcon,
  AListGroupHeader,
  AListItem,
  ALoading,
  AModalFooter,
  AModalHeader,
  ANavigationItem,
  APageHeading,
  APagination,
  ARadio,
  ASectionDivider,
  ASelectField,
  ASummaryItemHeader,
  ASwitch,
  ATabNav,
  ATabPane,
  ATag,
  ATextField,
  AText,
  AUomText,
  AUploadField,
  MActionCard,
  MApplicationSummary,
  MButtonContainer,
  MCalendarRangeField,
  MCallingActionButtonList,
  MCardFilter,
  MCardList,
  MContactSummaryBar,
  MCurrentUserStatus,
  MDataList,
  MDataTable,
  MDataTableServices,
  MFilterDialog,
  MFormActionButtons,
  MFormInput,
  MLinkContainer,
  MListGroup,
  MNavigation,
  MNumpad,
  MPaymentRadio,
  MSection,
  MTab,
  MUserInvitationItem,
  MUserInvitationList,
  MUserInvitationRadio,
  MUserInvitationUploadRadio,
  MUserSelectionTable,
  MRichTextEditor,
  OGlobalFooter,
  OGlobalSidebar,
  OModal,
  OCsvUploader,
  OCsvHistory,
  OTransitionTypeSelector,
  OTransitionTypeService,
  OTestPush,
  OTestPushSelected,
  TDefaultEntry,
  TDefaultLayout,
  TError,
  TLogin,
  TAnnouncementsAdd,
  TAnnouncementsDetail,
  TAnnouncementsEdit,
  TCampaignsAdd,
  TCampaignsDetail,
  TCampaignsEdit,
  TLoginInvitedUserSetPassword,
  TLoginPasscodeInput,
  TAppVersionAdd,
  TAppVersionEdit,
];

const install = function (app) {
  components.forEach(component => {
    app.component(component.name, component);
  });
};

export default {
  install,
  AAlert,
  AAvatar,
  ABrandLogo,
  AButton,
  ASplitButton,
  ACalendarField,
  ACaptionList,
  ACheckbox,
  ADataListItem,
  ADataTableBody,
  ADataTableBodyServices,
  ADataTableColumn,
  ADataTableHeader,
  AErrorBalloon,
  AExpiryDateField,
  AHeading,
  AIcon,
  AListGroupHeader,
  AListItem,
  ALoading,
  AModalFooter,
  AModalHeader,
  ANavigationItem,
  APageHeading,
  APagination,
  ARadio,
  ASectionDivider,
  ASelectField,
  ASummaryItemHeader,
  ASwitch,
  ATabNav,
  ATabPane,
  ATag,
  ATextField,
  AText,
  AUomText,
  AUploadField,
  MActionCard,
  MApplicationSummary,
  MButtonContainer,
  MCalendarRangeField,
  MCallingActionButtonList,
  MCardFilter,
  MCardList,
  MContactSummaryBar,
  MCurrentUserStatus,
  MDataList,
  MDataTable,
  MDataTableServices,
  MFilterDialog,
  MFormActionButtons,
  MFormInput,
  MLinkContainer,
  MListGroup,
  MNavigation,
  MNumpad,
  MPaymentRadio,
  MSection,
  MTab,
  MUserInvitationItem,
  MUserInvitationList,
  MUserInvitationRadio,
  MUserInvitationUploadRadio,
  MUserSelectionTable,
  MRichTextEditor,
  OGlobalFooter,
  OGlobalSidebar,
  OModal,
  OCsvUploader,
  OCsvHistory,
  OTransitionTypeSelector,
  OTransitionTypeService,
  OTestPush,
  OTestPushSelected,
  TDefaultEntry,
  TDefaultLayout,
  TError,
  TLogin,
  TAnnouncementsAdd,
  TAnnouncementsDetail,
  TAnnouncementsEdit,
  TCampaignsAdd,
  TCampaignsDetail,
  TCampaignsEdit,
  TLoginInvitedUserSetPassword,
  TLoginPasscodeInput,
  TAppVersionAdd,
  TAppVersionEdit,
};
