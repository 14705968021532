<template>
  <t-default-entry sub-heading="Login" heading="認証コード入力" :class="componentClassName">
    <loading :active="isLoading" :is-full-page="true" :height="100" :width="100"></loading>
    <div v-if="errorMessage" class="t-login-passcode-input__alert">
      <a-alert color="error" size="small" class="t-login-passcode-input__alert">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span data-testid="login-error-message-span" v-html="errorMessage"></span>
      </a-alert>
    </div>
    <form class="t-login-passcode-input__form" @submit.prevent="sendPasscode()">
      <div class="t-login-passcode-input__group">
        <m-form-input
          class="t-login-passcode-input__input"
          label="登録メールアドレスへ送信したコードを入力してください"
        >
          <a-text-field
            id="passcode"
            v-model="passcode"
            data-testid="login-auth-code-input"
            type="text"
            name=""
            placeholder="012345"
            maxlength="6"
            @keypress="checkPasscode($e)"
          />
        </m-form-input>
      </div>
      <m-button-container class="t-login-passcode-input__buttons">
        <a-button
          id="submitBtn"
          data-testid="login-auth-code-submit-button"
          variant="block"
          color="primary"
          submit="submit"
          >ログイン</a-button
        >
      </m-button-container>
    </form>
  </t-default-entry>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AAlert from '@/components/atoms/alert';
import AButton from '@/components/atoms/button';
import ATextField from '@/components/atoms/text-field';
import MButtonContainer from '@/components/molecules/button-container';
import MFormInput from '@/components/molecules/form-input';
import TDefaultEntry from '@/components/templates/default-entry';
import { showMsgBoxMixin } from '@/common/showMsgBox.js';
import { mapActions } from 'vuex';
import { customValidate } from '@/common/customValidate.js';
import { messageContent } from '@/common/messageContent.js';
import { customRequest } from '@/common/customRequest.js';
import Loading from 'vue-loading-overlay';

export default {
  name: 't-login',
  components: {
    AAlert,
    AButton,
    ATextField,
    MButtonContainer,
    MFormInput,
    TDefaultEntry,
    Loading,
  },
  mixins: [showMsgBoxMixin, customValidate, customRequest],
  props: {},
  data: function () {
    return {
      passcode: '',
      userID: '',
      csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      isLoading: false,
      errorMessage: '',
      isSalesforce: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-login-passcode-input', this.error ? 'error' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created: function () {
    this.userID = this.$route.params.userID;
    if (!this.userID) this.$router.push({ path: '/login' });
  },
  methods: {
    ...mapActions(['updateLoginInfo']),
    checkPasscode(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      // ６桁の数字で入力できる
      if (charCode < 48 || charCode > 57 || this.passcode.length === 6) {
        return evt.preventDefault();
      } else {
        return true;
      }
    },
    sendPasscode() {
      this.errorMessage = '';
      if (this.passcode.length !== 6 || !this.numberOnly(this.passcode)) {
        this.errorMessage = messageContent.invalidPasscode;
        return;
      }
      this.postRequest('/login/verifyTwoFactorAuthCode', {
        twoFactorAuthCode: this.passcode.trim(),
        userID: this.userID,
      }).then(res => {
        if (res.status === 'success') {
          if (res.data.tempPwdFlag) {
            // 仮パスワードを変更する
            this.$router.push({
              name: 'LoginInvitedUserSetPassword',
            });
          } else {
            window.location.href = '/users';
          }
        } else if (res.status === 'wrongPasscode') {
          this.errorMessage = messageContent.passcodeWrong;
        } else {
          // statusはinputCountLimited又はpasscodeExpired又はfailedの場合
          const routeName = 'login';
          this.$router.push({ name: routeName, params: { errorMessage: messageContent.passcodeAuthFailed } });
        }
      });
    },
  },
};
</script>
