import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';

import Login from '@/components/templates/login/index.vue';
import LoginInvitedUserSetPassword from '@/components/templates/login-invited-user-set-password/index.vue';
import Error from '@/components/templates/error/index.vue';
import PermissionDenied from '@/components/templates/permission-denied/index.vue';
import Index from '@/components/templates/index/index.vue';
import Announcements from '@/components/templates/announcements/index.vue';
import AnnouncementsAdd from '@/components/templates/announcements-add/index.vue';
import AnnouncementsDetail from '@/components/templates/announcements-detail/index.vue';
import AnnouncementsEdit from '@/components/templates/announcements-edit/index.vue';
import Users from '@/components/templates/users/index.vue';
import UsersAdd from '@/components/templates/users-add/index.vue';
import UsersEdit from '@/components/templates/users-edit/index.vue';
import Campaigns from '@/components/templates/campaigns/index.vue';
import CampaignsAdd from '@/components/templates/campaigns-add/index.vue';
import CampaignsDetail from '@/components/templates/campaigns-detail/index.vue';
import CampaignsEdit from '@/components/templates/campaigns-edit/index.vue';
import NewsAndSeminars from '@/components/templates/news-and-seminars/index.vue';
import NewsAndSeminarsAdd from '@/components/templates/news-and-seminars-add/index.vue';
import NewsAndSeminarsDetail from '@/components/templates/news-and-seminars-detail/index.vue';
import NewsAndSeminarsEdit from '@/components/templates/news-and-seminars-edit/index.vue';
import HomeSubBanner from '@/components/templates/home-sub-banner/index.vue';
import HomeSubBannerAdd from '@/components/templates/home-sub-banner-add/index.vue';
import HomeSubBannerDetail from '@/components/templates/home-sub-banner-detail/index.vue';
import HomeSubBannerEdit from '@/components/templates/home-sub-banner-edit/index.vue';
import OnlineShopBanners from '@/components/templates/online-shop-banners/index.vue';
import OnlineShopBannersAdd from '@/components/templates/online-shop-banners-add/index.vue';
import OnlineShopBannersDetail from '@/components/templates/online-shop-banners-detail/index.vue';
import OnlineShopBannersEdit from '@/components/templates/online-shop-banners-edit/index.vue';
import Banners from '@/components/templates/banners/index.vue';
import BannersAdd from '@/components/templates/banners-add/index.vue';
import BannersDetail from '@/components/templates/banners-detail/index.vue';
import BannersEdit from '@/components/templates/banners-edit/index.vue';
import LoginPasscodeInput from '@/components/templates/login-passcode-input/index.vue';
import AppVersions from '@/components/templates/appVersions/index.vue';
import AppVersionAdd from '@/components/templates/appVersions-add';
import AppVersionEdit from '@/components/templates/appVersions-edit';
import AppReviewSettings from '@/components/templates/app-review-settings/index.vue';
import AppReviewSettingsAdd from '@/components/templates/app-review-settings-add/index.vue';
import AppReviewSettingsEdit from '@/components/templates/app-review-settings-edit/index.vue';
import Services from '@/components/templates/services/index.vue';
import ServicesAdd from '@/components/templates/services-add/index.vue';
import ServicesDetail from '@/components/templates/services-detail/index.vue';
import ServicesEdit from '@/components/templates/services-edit/index.vue';
import OnlineShopService from '@/components/templates/online-shop-service/index.vue';
import OnlineShopServiceAdd from '@/components/templates/online-shop-service-add/index.vue';
import OnlineShopServiceDetail from '@/components/templates/online-shop-service-detail/index.vue';
import OnlineShopServiceEdit from '@/components/templates/online-shop-service-edit/index.vue';
import OnlineShopSupport from '@/components/templates/online-shop-support/index.vue';
import OnlineShopSupportAdd from '@/components/templates/online-shop-support-add/index.vue';
import OnlineShopSupportDetail from '@/components/templates/online-shop-support-detail/index.vue';
import OnlineShopSupportEdit from '@/components/templates/online-shop-support-edit/index.vue';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    redirect: Users,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/announcements',
        name: 'Announcements',
        component: Announcements,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/announcements/add',
        name: 'AnnouncementsAdd',
        component: AnnouncementsAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/announcements/detail',
        name: 'AnnouncementsDetail',
        component: AnnouncementsDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/announcements/edit',
        name: 'AnnouncementsEdit',
        component: AnnouncementsEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/campaigns',
        name: 'Campaigns',
        component: Campaigns,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/campaigns/add',
        name: 'CampaignsAdd',
        component: CampaignsAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/campaigns/detail',
        name: 'CampaignsDetail',
        component: CampaignsDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/campaigns/edit',
        name: 'CampaignsEdit',
        component: CampaignsEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/users/add',
        name: 'UsersAdd',
        component: UsersAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/users/edit',
        name: 'UsersEdit',
        component: UsersEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/news-and-seminars',
        name: 'NewsAndSeminars',
        component: NewsAndSeminars,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/news-and-seminars/add',
        name: 'NewsAndSeminarsAdd',
        component: NewsAndSeminarsAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/news-and-seminars/detail',
        name: 'NewsAndSeminarsDetail',
        component: NewsAndSeminarsDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/news-and-seminars/edit',
        name: 'NewsAndSeminarsEdit',
        component: NewsAndSeminarsEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home-sub-banner',
        name: 'HomeSubBanner',
        component: HomeSubBanner,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home-sub-banner/add',
        name: 'HomeSubBannerAdd',
        component: HomeSubBannerAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home-sub-banner/detail',
        name: 'HomeSubBannerDetail',
        component: HomeSubBannerDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home-sub-banner/edit',
        name: 'HomeSubBannerEdit',
        component: HomeSubBannerEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/banners',
        name: 'Banners',
        component: Banners,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/banners/detail',
        name: 'BannersDetail',
        component: BannersDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/banners/edit',
        name: 'BannersEdit',
        component: BannersEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/banners/add',
        name: 'BannersAdd',
        component: BannersAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/banners/detail',
        name: 'BannersDetail',
        component: BannersDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-banners',
        name: 'OnlineShopBanners',
        component: OnlineShopBanners,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-banners/detail',
        name: 'OnlineShopBannersDetail',
        component: OnlineShopBannersDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-banners/edit',
        name: 'OnlineShopBannersEdit',
        component: OnlineShopBannersEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-banners/add',
        name: 'OnlineShopBannersAdd',
        component: OnlineShopBannersAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-service',
        name: 'OnlineShopService',
        component: OnlineShopService,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-service/detail',
        name: 'OnlineShopServiceDetail',
        component: OnlineShopServiceDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-service/edit',
        name: 'OnlineShopServiceEdit',
        component: OnlineShopServiceEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-service/add',
        name: 'OnlineShopServiceAdd',
        component: OnlineShopServiceAdd,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: '/online-shop-support',
        name: 'OnlineShopSupport',
        component: OnlineShopSupport,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-support/detail',
        name: 'OnlineShopSupportDetail',
        component: OnlineShopSupportDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-support/edit',
        name: 'OnlineShopSupportEdit',
        component: OnlineShopSupportEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/online-shop-support/add',
        name: 'OnlineShopSupportAdd',
        component: OnlineShopSupportAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/appVersions',
        name: 'AppVersions',
        component: AppVersions,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/appVersions/add',
        name: 'AppVersionsAdd',
        component: AppVersionAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/appVersions/edit',
        name: 'AppVersionsEdit',
        component: AppVersionEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/app-review-settings',
        name: 'AppReviewSettings',
        component: AppReviewSettings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/app-review-settings/add',
        name: 'AppReviewSettingsAdd',
        component: AppReviewSettingsAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/app-review-settings/edit',
        name: 'AppReviewSettingsEdit',
        component: AppReviewSettingsEdit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/services',
        name: 'Services',
        component: Services,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/services/add',
        name: 'ServicesAdd',
        component: ServicesAdd,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/services/detail',
        name: 'ServicesDetail',
        component: ServicesDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/services/edit',
        name: 'ServicesEdit',
        component: ServicesEdit,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login-passcode-input',
    name: 'LoginPasscodeInput',
    component: LoginPasscodeInput,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/resettemppassword',
    name: 'LoginInvitedUserSetPassword',
    component: LoginInvitedUserSetPassword,
    //ログイン画面だけから遷移可能
    beforeEnter: (to, from, next) => {
      if (from.name !== 'login' && from.name !== 'LoginPasscodeInput' && from.name !== 'LoginInvitedUserSetPassword') {
        return next({ name: 'login' });
      }
      return next();
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/permission-denied',
    name: 'PermissionDenied',
    component: PermissionDenied,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: Error,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '',
    name: '',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Error,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //call get /login function every time route changed to check login status
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    to.name !== 'login' &&
    to.name !== 'LoginInvitedUserSetPassword'
  ) {
    const _csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch('/login/authenticate', {
      method: 'POST',
      body: JSON.stringify({ _csrf: _csrf }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 401 || response.status === 403) {
          if (from !== '/login') {
            store.dispatch('updateLoginInfo', {
              authInfo: '',
              userName: '',
              userID: '',
              departmentInfo: '',
            });
            window.location.href = '/login';
          }
        }
        if (!response.ok) {
          throw new Error('/login/authenticate response was not ok.');
        } else {
          return response.json();
        }
      })
      .then(e => {
        if (e.session) {
          if (e.csrfToken) document.querySelector('meta[name="csrf-token"]').setAttribute('content', e.csrfToken);

          if (!store.state.user.userID) {
            store.dispatch('updateLoginInfo', e.data);
          }
          // permission check
          //const routeName = to.name;
          // if (PERMISSIONS_FRONTEND[routeName]) {
          //   if (!PERMISSIONS_FRONTEND[routeName][e.data.authInfo]) {
          //     // permission denied
          //     return next({ name: 'PermissionDenied' });
          //   }
          // }
          next();
        } else {
          if (e.status === 'csrfExpired' || e.status === 'expired') {
            if (from !== '/login') {
              store.dispatch('updateLoginInfo', {
                authInfo: '',
                userName: '',
                userID: '',
                departmentInfo: '',
              });
            }
            window.location.href = '/login';
          }
        }
      })
      .catch(err => {
        console.error(err);
        next({ name: 'login' });
      });
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
