<template>
  <div class="o-test-push-selected">
    <div>
      <m-form-action-buttons>
        <a-split-button
          id="test-push"
          data-testid="test-push-button"
          :details="[
            {
              type: 'test-push',
              label: 'テスト配信',
              description: 'CMSログインユーザーのdocomoIDでログインされているDアプリに対してPUSH通知を行います',
            },
            {
              type: 'test-segment-push',
              label: 'テスト特定配信',
              description: 'CSVに記述されているアカウント識別子に対してPUSH配信を行います',
            },
          ]"
          :click="handleNavigateTestForSegment"
          :change="handleTestTypeChange"
        />
      </m-form-action-buttons>
    </div>
    <div v-if="showCsvSpace" class="o-test-push-selected__csv-info-area">
      <a-text>CSVアップロード履歴</a-text>
      <o-csv-history :id="id" type="campaign" :csvlist="csvlist" />
      <a-text>CSVアップロード</a-text>
      <a-upload-field
        id="o-csv-uploader__a-upload-field"
        v-model="pushTargetListFileInfo.file"
        name="pushTargetListFileInfo"
        class="o-csv-uploader__a-upload-field"
        label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
        accept="image/png, .jfif, image/jpg, image/jpeg, text/csv, text/plain"
        @change="handleUploadFileChangeCsv(pushTargetListFileInfo)"
        @showErrorModal="showErrorModal"
      />
    </div>
    <!-- Info, Error Modal -->
    <teleport to="body">
      <o-modal
        id="testPush_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </div>
</template>

<script>
import _ from 'lodash';
import AText from '@/components/atoms/text';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import ASplitButton from '@/components/atoms/split-button';
import OModal from '@/components/organisms/modal';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import AUploadField from '@/components/atoms/upload-field';
import { utils } from '@/common/utils.js';

export default {
  name: 'o-test-push-selected',
  components: {
    MFormActionButtons,
    // AButton,
    ASplitButton,
    OModal,
    AText,
    AUploadField,
  },
  mixins: [customRequest, messageContent],
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'announcement',
    },
    csvlist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      showCsvSpace: false,
      pushTargetListFileInfo: {
        file: null,
        filename: null,
        binary: null,
        size: null,
      },
      isTestSegmentSuccess: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.type === 'banners' ? 'テストポップアップ配信' : 'テスト配信';
    },
  },
  methods: {
    testPushPath() {
      if (this.type === 'campaign') {
        return '/campaigns/test-push';
      } else if (this.type === 'banners') {
        return '/banners/test-push';
      }
      return '/announcements/test-push';
    },
    handleNavigateTest() {
      this.postRequest(this.testPushPath(), { id: this.$props.id })
        .then(e => {
          if (e.status === 'failed') {
            if (e.result === 'docomoID not found') {
              this.showErrorModal(messageContent.errorDelivery);
            }
          } else {
            if (this.type === 'banners') {
              this.showInfoModal(messageContent.successPopupDelivery);
            } else {
              this.showInfoModal(messageContent.successDelivery);
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleNavigateTestForSegment(testType) {
      // テスト特定配信
      if (testType === 'test-segment-push') {
        const formData = {
          id: this.id,
          pushTargetListFile: this.pushTargetListFileInfo.binary ?? '',
          pushTargetListFileSize: this.pushTargetListFileInfo.size ?? 0,
          pushTargetListFileName: this.pushTargetListFileInfo.filename ?? '',
          pushTargetListFileType: 'accountid',
        };
        if (this.type === 'banners') {
          this.showErrorModal('テスト特定配信はバナーには対応していません。');
        }
        this.postRequest(
          this.type === 'campaign' ? '/campaigns/test-segment-push' : '/announcements/test-segment-push',
          formData
        )
          .then(res => {
            if (res.status === 'success') {
              this.isTestSegmentSuccess = true;
              this.showInfoModal(messageContent.successTestSegmentDelivery);
            } else {
              if (res.message) {
                this.showErrorModal(res.message);
              } else {
                this.showErrorModal(messageContent.requireTry('テスト特定配信'));
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        this.handleNavigateTest();
      }
    },
    handleTestTypeChange(type) {
      if (type === 'test-segment-push') {
        this.showCsvSpace = true;
      } else {
        this.showCsvSpace = false;
      }
    },
    handleUploadFileChangeCsv(fileObj) {
      const self = this;
      const reader = new FileReader();
      let tempFile = null; // ファイルを格納する一時変数
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          fileObj.binary = content;
          const errorArr = [];
          const typeStr = 'アカウント識別子';

          // CSVファイルかどうかを確認する
          if (tempFile && tempFile.name.endsWith('.csv')) {
            const csvContent = atob(content);
            const lines = csvContent.split('\n');
            let errorBom = false;
            let errorCsvLength = false;
            let errorCsvCharacters = false;

            if (lines.length > 0 && utils.containsBOM(lines[0])) {
              errorBom = true;
            } else {
              for (let i = 0; i < lines.length; i++) {
                const line = lines[i].replace(/[\r\n\x00-\x1F\x7F-\x9F]/g, '');
                if (line.length !== 24) {
                  errorCsvLength = true;
                }
                if (utils.containsNonLargeAlphanumeric(line)) {
                  errorCsvCharacters = true;
                }
              }
            }

            if (errorBom) {
              errorArr.push(messageContent.bomMessengerError);
            } else {
              if (errorCsvLength) {
                errorArr.push(messageContent.errorCsvLength(typeStr));
              }
              if (errorCsvCharacters) {
                errorArr.push(messageContent.errorCsvCharacters(typeStr));
              }
            }
          } else {
            errorArr.push(messageContent.errorNotFileCsv);
          }
          // エラー処理
          if (errorArr.length > 0) {
            self.showErrorModal(errorArr.join('<br>'));
            self.clearFile(fileObj);
          }
        },
        false
      );
      if (fileObj.file[0]) {
        tempFile = fileObj.file[0];
        reader.readAsDataURL(fileObj.file[0]);
        fileObj.filename = tempFile.name;
        fileObj.size = tempFile.size;
      } else {
        self.clearFile(fileObj);
      }
    },
    clearFile(fileObj) {
      fileObj.file = null;
      fileObj.filename = null;
      fileObj.binary = null;
      fileObj.size = null;
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
      // テスト特定配信の成功時のみDBからCSVデータ取得したいのでリロード
      this.isTestSegmentSuccess && this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
