<template>
  <div :class="componentClassName">
    <ul class="m-contact-summary-bar__list">
      <li v-for="(item, index) in contactList" :id="item.key" :key="index" class="m-contact-summary-bar__item">
        <a-button
          :id="'btn-' + item.key"
          variant="text"
          color="primary"
          :inline="true"
          :label="item.label"
          :disabled="!item.active"
          @click="handleItemClick(item.key)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';

export default {
  name: 'm-contact-summary-bar',
  components: {
    AButton,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    contactList: {
      type: Array,
      default: new Array(0),
    },
  },
  emits: ['click'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-contact-summary-bar');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    handleItemClick(key) {
      this.$emit('click', key);
    },
  },
};
</script>
