<template>
  <div :class="componentClassName">
    <header v-if="title" class="m-card-list__header">
      <a-icon v-if="icon" :icon="icon" color="orange" />
      <h3 class="m-card-list__header-title">{{ title }}</h3>
    </header>
    <div v-if="$slots.filter" class="m-card-list__filter">
      <slot name="filter"></slot>
    </div>
    <ul class="m-card-list__items">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon';
import Simplebar from 'simplebar';

export default {
  name: 'm-card-list',
  components: {
    AIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      scroller: null,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-card-list');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  mounted() {
    this.scroller = new Simplebar(this.$el.querySelector('.m-card-list__items'));
  },
  methods: {
    scrollToId(id) {
      const scrollEl = this.scroller.getScrollElement();
      const target = scrollEl.querySelector(`#${id}`);
      if (target) {
        const pos = target.offsetTop;
        scrollEl.scrollTo({ top: pos, left: 0, behavior: 'smooth' });
      }
    },
  },
};
</script>
