<script lang="jsx">
/* eslint-disable react/react-in-jsx-scope */
import { mapModifiers } from '@/libs/component';
import ACheckbox from '@/components/atoms/checkbox';
import { h } from 'vue';

export default {
  name: 'a-data-table-body-services',
  components: {
    ACheckbox,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: new Array(0),
    },
    data: {
      type: Array,
      default: new Array(0),
    },
    table: {
      type: Object,
      required: true,
    },
    isHistoryListTable: {
      type: Boolean,
      default: false,
    },
    idText: {
      type: String,
      default: '',
    },
  },
  emits: ['rowClick', 'drop', 'positionUpdate'],
  data() {
    return {
      row: null,
      curentsData: {},
      newData: {},
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-data-table-body');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    getCellClass(rowIndex, columnIndex, row, column) {
      const classes = [column.id, `a-data-table-column--${column.type}`, column.align, column.nowrap, column.class];

      const cellClassName = this.table.cellClassName;
      if (typeof cellClassName === 'string') {
        classes.push(cellClassName);
      } else if (typeof cellClassName === 'function') {
        classes.push(
          cellClassName.call(null, {
            rowIndex,
            columnIndex,
            row,
            column,
          })
        );
      }

      return classes.join(' ');
    },
    getRowClass(isGreyBg, isOrangeBg) {
      const classes = [];
      if (isGreyBg) classes.push('m-data-table--row-grey');
      if (isOrangeBg) classes.push('m-data-table--row-orange');
      return classes;
    },
    rowClick(row, e) {
      if (this.isHistoryListTable) {
        this.$emit('rowClick', { row: row });
      }
    },
    startDrag(event, row) {
      this.row = event.target;
      this.curentsData = row;
    },
    dragOver(event) {
      event.preventDefault();
    },
    drop(event, row) {
      event.preventDefault();
      // ドロップイベントを含む要素を取得します。
      const targetElement = event.currentTarget;
      // 要素が行であるかどうかを確認する
      if (!targetElement.tagName || targetElement.tagName.toLowerCase() !== 'tr') {
        // そうでない場合は、最も近い行である親要素を検索します。
        const closestRow = targetElement.closest('tr');
        if (closestRow) {
          closestRow.parentNode.insertBefore(this.row, closestRow.nextSibling);
        }
      } else {
        // 行の場合は直接位置変換を行う
        const currentRow = targetElement;
        const tbody = currentRow.parentNode;
        const children = Array.from(tbody.children);
        const indexToInsert = children.findIndex(child => child === currentRow);
        const currentIndex = children.findIndex(child => child === this.row);

        // インデックスが無効かどうかを確認し、位置をボディの先頭または末尾に移動します。
        if (indexToInsert === -1) {
          // 本体の先頭に挿入
          tbody.insertBefore(this.row, tbody.firstChild);
        } else {
          // 該当する場合のみ行を前に移動します
          if (currentIndex > indexToInsert) {
            currentRow.before(this.row);
          } else if (currentIndex < indexToInsert) {
            currentRow.after(this.row);
          }
        }
      }

      this.$emit('positionUpdate', {
        newData: this.curentsData,
        oldData: row,
      });
    },
  },
  render() {
    const self = this;
    return (
      <table className={this.componentClassName}>
        <colgroup>
          {this.columns.map(column => {
            return <col name={column.id} key={column.id} />;
          })}
        </colgroup>
        <tbody>
          {this.data.map((row, index) => [
            <tr
              id={'table-row-' + index}
              data-testid={this.idText + '-' + index}
              className={this.getRowClass(row.isGreyBg, row.isOrangeBg)}
              key={index}
              draggable={true}
              // eslint-disable-next-line react/no-unknown-property
              onDragstart={e => this.startDrag(e, row)}
              // eslint-disable-next-line react/no-unknown-property
              onDragover={this.dragOver}
              onDrop={e => this.drop(e, row)}
              onClick={e => this.rowClick(row, e)}
            >
              {this.columns.map((column, cellIndex) => {
                return (
                  <td
                    className={this.getCellClass(index, cellIndex, row, column)}
                    key={cellIndex}
                    id={'row-' + index + '-col-' + cellIndex}
                    data-testid={this.idText + '-' + index + '-col-' + cellIndex}
                  >
                    {column.renderCell.call(this, {
                      row,
                      column,
                      index,
                      isGreyBg: row.isGreyBg,
                      isOrangeBg: row.isOrangeBg,
                    })}
                  </td>
                );
              })}
            </tr>,
          ])}
        </tbody>
      </table>
    );
  },
};
</script>
