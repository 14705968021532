<template>
  <label :class="componentClassName">
    <input
      v-bind="inputAttrs"
      :id="id"
      v-model="currentValue"
      :data-testid="dataTestid"
      type="radio"
      class="m-news-and-seminar__input"
      :name="name"
      :value="value"
      :checked="checked"
      @click="handleClick"
      @change="handleChange"
    />
    <div class="m-news-and-seminar__body">
      <div :id="'m-news-and-seminar-radio_' + id" class="m-news-and-seminar__indicator"></div>
      <div class="m-news-and-seminar__label">
        <!-- eslint-disable -->
        <span v-if="htmlLabel" v-html="htmlLabel"></span>
        <!-- eslint-enable -->
        <span v-else>{{ label }}</span>
      </div>
      <div v-if="sublabel" className="m-news-and-seminar__sublabel">
        <a-caption-list>
          <li>{{ sublabel }}</li>
        </a-caption-list>
      </div>
    </div>
  </label>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ACaptionList from '@/components/atoms/caption-list';

export default {
  name: 'm-news-and-seminar',
  components: {
    ACaptionList,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|button)/);
      },
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    htmlLabel: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    sublabel: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
    dataTestid: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'change', 'update:modelValue'],
  data() {
    return {
      isChecked: this.checked || false,
      currentValue: null,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-news-and-seminar',
        this.sublabel ? 'sublabel' : '',
        this.disabled ? 'disabled' : '',
        this.isChecked ? 'checked' : '',
        this.variant
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { checked, placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    modelValue(val, oldValue) {
      this.setCurrentValue(val);
    },
  },
  mounted() {
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    handleClick(event) {
      const value = event.target.value;
      this.setCurrentValue(value);
      this.$emit('click', event);
    },
    handleChange(event) {
      this.setCurrentValue(event.target.value);
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', event);
    },
    setCurrentValue(val) {
      this.currentValue = val;
    },
  },
};
</script>
