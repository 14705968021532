<template>
  <div :class="componentClassName">
    <date-picker
      ref="datepicker"
      v-model="dateData"
      locale="ja"
      :model-config="modelConfig"
      :popover="{ placement: 'bottom', visibility: 'focus' }"
      :inline="true"
      :masks="masks"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      :mode="mode"
      :hide-time-header="true"
      :is24hr="is24hr"
    >
      <template #header-left-button>
        <div class="a-calendar-field__nav-button a-calendar-field__nav-button--prev">
          <div class="a-calendar-field__nav-button-icon">
            <a-icon icon="chevron-left" color="white" />
          </div>
          <div v-if="navTooltipPrevious" class="a-calendar-field__nav-button-tooltip">
            <svg width="8.666" height="16" viewBox="0 0 8.666 16">
              <path
                d="M7.265.8a1,1,0,0,1,1.47,0l5.716,6.192a1,1,0,0,1-.735,1.678H2.284a1,1,0,0,1-.735-1.678Z"
                transform="translate(8.666) rotate(90)"
                fill="#4d4d4d"
              />
            </svg>
            {{ navTooltipPreviousMessage }}
          </div>
        </div>
      </template>
      <template #header-right-button>
        <div class="a-calendar-field__nav-button a-calendar-field__nav-button--next">
          <div class="a-calendar-field__nav-button-icon">
            <a-icon icon="chevron-right" color="white" />
          </div>
          <div v-if="navTooltipNext" class="a-calendar-field__nav-button-tooltip">
            <svg width="8.666" height="16" viewBox="0 0 8.666 16">
              <path
                d="M7.265.8a1,1,0,0,1,1.47,0l5.716,6.192a1,1,0,0,1-.735,1.678H2.284a1,1,0,0,1-.735-1.678Z"
                transform="translate(0 16) rotate(-90)"
                fill="#4d4d4d"
              />
            </svg>
            {{ navTooltipNextMessage }}
          </div>
        </div>
      </template>
      <template #default="{ inputEvents }">
        <div class="a-calendar-field__field">
          <div class="a-calendar-field__icon">
            <a-icon icon="calendar-outline" color="grey" />
          </div>
          <input
            :id="id"
            ref="input"
            :data-testid="testIdText + '-day-select'"
            class="a-calendar-field__input"
            :placeholder="datePlaceholder"
            :value="dateValue"
            :disabled="disabled"
            v-on="inputEvents"
            @change="e => handleChangeDate(e)"
            @keydown.enter.prevent
          />
          <input
            :id="id + '-time'"
            ref="timeInput"
            :data-testid="testIdText + '-time-select'"
            class="a-calendar-field__input"
            :placeholder="timePlaceholder"
            :value="timeValue"
            :disabled="disabled"
            v-on="inputEvents"
            @change="e => handleChangeTime(e)"
            @keydown.enter.prevent
          />
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import { DatePicker } from 'v-calendar';
import AIcon from '@/components/atoms/icon';
import Cleave from 'cleave.js';
import * as dateFns from 'date-fns';

export default {
  name: 'a-calendar-field',
  components: {
    AIcon,
    DatePicker,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    disabledDates: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
    datePlaceholder: {
      type: String,
      default: '',
    },
    timePlaceholder: {
      type: String,
      default: '',
    },
    navTooltipPrevious: {
      type: Boolean,
      default: false,
    },
    navTooltipNext: {
      type: Boolean,
      default: false,
    },
    navTooltipPreviousMessage: {
      type: String,
      default: null,
    },
    navTooltipNextMessage: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    is24hr: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: 'date',
    },
    testIdText: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: this.mode !== 'dateTime' ? 'YYYY / MM / DD' : 'YYYY / MM / DD HH:mm',
        //mask: 'YYYY/MM/DDHH:mm',
      },
      masks: {
        title: 'YYYY年 MMM',
        input: 'YYYY / MM / DD',
      },
      dateData: null,
      date: null,
      time: null,
      inputMask: null,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-calendar-field');
      return `${baseClassName} ${this.class}`.trim();
    },
    dateDataValue() {
      return this.dateData;
    },
    dateValue() {
      return this.date;
    },
    timeValue() {
      return this.time;
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    dateData(val, oldVal) {
      const output = this.toISO(val);
      this.$emit('update:modelValue', output);
      this.$emit('change', output);
    },
    modelValue(val, oldVal) {
      this.dateData = this.toFormatted(val);
      this.date = this.toFormattedForDate(val);
      this.time = this.toFormattedForTime(val);
    },
  },
  mounted() {
    if (this.$refs.input) {
      new Cleave(this.$refs.input, {
        date: true,
        delimiter: ' / ',
        datePattern: ['Y', 'm', 'd'],
      });
    }
    if (this.$refs.timeInput) {
      new Cleave(this.$refs.timeInput, {
        time: true,
        timePattern: ['h', 'm'],
        timeFormat: this.is24hr ? '24' : '12',
      });
    }
    if (this.modelValue) {
      this.dateData = this.toFormatted(this.modelValue);
      this.date = this.toFormattedForDate(this.modelValue);
      this.time = this.toFormattedForTime(this.modelValue);
    }
  },
  methods: {
    handleChangeTime(event) {
      const timeValue = event.target.value;
      if (!timeValue) {
        this.time = '00:00';
        if (!this.date) {
          this.dateData = null;
        } else {
          this.dateData = this.date + '00:00';
        }
        return;
      }
      if (!this.date) {
        this.date = null;
        this.time = null;
        this.dateData = null;
        return;
      }
      const time = this.toFormattedForTime(timeValue);
      this.time = time;
      this.dateData = this.date + time;
    },
    handleChangeDate(event) {
      const dateValue = event.target.value;
      if (!dateValue) {
        this.date = null;
        this.time = null;
        this.dateData = null;
        return;
      }
      const date = this.toFormattedForDate(dateValue);
      if (!this.time) {
        this.dateData = date + '00:00';
      } else {
        this.dateData = date + this.time;
      }
    },
    toISO(dateText) {
      const mask = this.mode !== 'dateTime' ? 'yyyy/MM/dd' : 'yyyy/MM/ddHH:mm';
      const format = this.mode !== 'dateTime' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm';
      if (dateText) {
        return dateFns.format(dateFns.parse(dateText.replace(/\s/gi, ''), mask, new Date()), format);
      }
      return dateText;
    },
    toFormatted(dateText) {
      const mask = this.mode !== 'dateTime' ? 'yyyy / MM / dd' : 'yyyy / MM / dd HH:mm';
      if (dateText && dateText.indexOf('-') !== -1) {
        // convert formating
        return dateFns.format(dateFns.parseISO(dateText), mask);
      }
      return dateText;
    },
    toFormattedForDate(dateText) {
      const mask = 'yyyy / MM / dd';
      if (dateText && dateText.indexOf('-') !== -1) {
        // convert formating
        return dateFns.format(dateFns.parseISO(dateText), mask);
      }
      return dateText;
    },
    toFormattedForTime(dateText) {
      const mask = 'HH:mm';
      if (dateText && dateText.indexOf('-') !== -1) {
        // convert formating
        return dateFns.format(dateFns.parseISO(dateText), mask);
      }
      return dateText;
    },
  },
};
</script>
