<template>
  <div :class="componentClassName">
    <p>{{ text }}</p>
    <slot></slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-summary-item-header',
  props: {
    text: {
      type: String,
      default: null,
    },
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-summary-item-header');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
