<template>
  <div :class="componentClassName">
    <div v-if="shouldShowIcon" class="a-text-field__icon">
      <a-icon icon="search" color="grey" />
    </div>
    <input
      v-if="componentType === 'input'"
      :id="id"
      v-model="currentValue"
      :data-testid="dataTestid"
      class="a-text-field__input"
      v-bind="attrs"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    />
    <textarea
      v-if="componentType === 'textarea'"
      :id="id"
      v-model="currentValue"
      :data-testid="dataTestid"
      class="a-text-field__input"
      v-bind="attrs"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    ></textarea>
    <a-error-balloon v-if="error" :message="error" />
    <button
      v-if="type === 'password' && !disabled && showToggle"
      :data-testid="dataTestid + '-view-toggle'"
      type="button"
      class="a-text-field__toggle-password"
      @click="togglePassword"
    >
      <a-icon :icon="showPasswordIcon" color="grey" />
    </button>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AErrorBalloon from '@/components/atoms/error-balloon/index.vue';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'a-text-field',
  components: {
    AErrorBalloon,
    AIcon,
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return value.match(/(null|text|password|tel|email|number|search|textarea)/);
      },
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    class: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    short: {
      type: Boolean,
      default: false,
    },
    showToggle: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataTestid: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'focus', 'blur', 'change', 'update:modelValue'],
  data() {
    return {
      currentValue: '',
      isShowingPassword: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-text-field',
        this.type,
        this.error ? 'invalid' : '',
        this.short ? 'short' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    componentType() {
      if (this.type === 'textarea') {
        return 'textarea';
      }
      return 'input';
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
    attrs() {
      const attrs = { ...this.inputAttrs };
      if (this.componentType === 'input') {
        if (this.isShowingPassword) {
          attrs.type = 'text';
        } else {
          attrs.type = this.type;
        }
        attrs.value = this.currentValue;
        attrs.pattern = this.pattern;
        attrs.maxlength = this.maxlength;
        attrs.id = this.id;
      }
      attrs.name = this.name;
      attrs.placeholder = this.placeholder;
      attrs.autocomplete = this.autocomplete;
      return attrs;
    },
    shouldShowIcon() {
      return ['search'].indexOf(this.type) !== -1;
    },
    showPasswordIcon() {
      return this.isShowingPassword ? 'eye-slash' : 'eye';
    },
  },
  watch: {
    value(val, oldValue) {
      this.setCurrentValue(val);
    },
    modelValue(val, oldValue) {
      this.setCurrentValue(val);
    },
  },
  mounted() {
    if (this.value) {
      this.setCurrentValue(this.value);
    }
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    handleInput(event) {
      const value = event.target.value;
      this.setCurrentValue(value);
      this.$emit('input', event);
      this.$emit('update:modelValue', this.currentValue);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleChange(event) {
      this.setCurrentValue(event.target.value);
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', event);
    },
    setCurrentValue(val) {
      this.currentValue = val;
    },
    togglePassword() {
      this.isShowingPassword = !this.isShowingPassword;
    },
  },
};
</script>
