<template>
  <div :class="componentClassName">
    <div class="o-global-sidebar__scroll">
      <div class="o-global-sidebar__logo">
        <span class="o-global-sidebar__logo-env">{{ envMode == 'production' ? '本番環境' : '開発環境' }}</span>
        <a-brand-logo variant="horizontal" />
      </div>
      <div class="o-global-sidebar__user-status">
        <m-current-user-status
          v-if="currentUserInfo"
          :user-name="currentUserInfo.userName"
          :department-info="currentUserInfo.departmentInfo"
          :edit-profile-url="currentUserInfo.edit_profile_url"
          :logout-url="currentUserInfo.logout_url"
          :auth-info="currentUserInfo.auth_info"
          :handle-logout="handleLogout"
        />
      </div>
      <div class="o-global-sidebar__navigation">
        <m-navigation>
          <template v-for="item in navigationItems" :key="item.nav_id">
            <a-navigation-item
              :nav-id="item.nav_id"
              :active="isNavActive(item)"
              :icon="item.icon"
              :active-icon="item.active_icon"
              :icon-size="item.icon_size"
              :label="item.label"
              :has-submenu="item.has_submenu"
              :expand="item.has_submenu && isSubNavActive(item)"
              @click="handleClick(item.route_name)"
            >
              <a-navigation-item
                v-for="subItem in item.items"
                :key="subItem.nav_id"
                :nav-id="subItem.nav_id"
                :active="isNavActive(subItem)"
                :icon="subItem.icon"
                :label="subItem.label"
                @click="handleClick(subItem.route_name)"
              />
            </a-navigation-item>
          </template>
        </m-navigation>
      </div>
    </div>
    <teleport to="body">
      <o-modal
        id="global_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal-remove-phone-number-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="confirmResult"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ABrandLogo from '@/components/atoms/brand-logo';
import ANavigationItem from '@/components/atoms/navigation-item';
import AButton from '@/components/atoms/button';
import AText from '@/components/atoms/text';
import OModal from '@/components/organisms/modal';
import MCurrentUserStatus from '@/components/molecules/current-user-status';
import MNavigation from '@/components/molecules/navigation';
import Simplebar from 'simplebar';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 'o-global-sidebar',
  components: {
    ABrandLogo,
    ANavigationItem,
    AButton,
    AText,
    MCurrentUserStatus,
    MNavigation,
    OModal,
  },
  mixins: [customRequest],
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|primary)/);
      },
    },
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    currentUserInfo: {
      type: Object,
      default: null,
    },
    navigationItems: {
      type: Array,
      default: new Array(0),
    },
    currentActiveItemId: {
      type: String,
      default: '',
    },
    handleOnlineStatusChange: {
      type: Function,
      default: () => {},
    },
    handleLogout: {
      type: Function,
      default: () => {},
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isAuthenticated: false,
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      envMode: process.env.NODE_ENV,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('o-global-sidebar', this.variant);
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    this.isAuthenticated = this.$route.params.isAuthenticated;
  },
  mounted() {
    new Simplebar(this.$el.querySelector('.o-global-sidebar__scroll'));
  },
  methods: {
    isNavActive(navItem) {
      let active = false;
      if (navItem.nav_id === this.currentActiveItemId) {
        active = true;
      } else {
        // check child active
        active = this.isSubNavActive(navItem);
      }
      return active;
    },
    isSubNavActive(navItem) {
      let active = false;
      if (navItem.items) {
        navItem.items.forEach(subNavItem => {
          if (subNavItem.nav_id === this.currentActiveItemId) {
            active = true;
          }
        });
      }
      return active;
    },
    handleClick: function (route_name) {
      //if (!route_name || route_name === this.$route.name) return;
      //if (this.isAuthenticated) {
      this.isAuthenticated = false;
      this.$router.push({ name: route_name });
      //} else {
      //this.$router.push({ name: route_name, params: { isAuthenticated: false } });
      // this.$router.push({ name: route_name, params: { isAuthenticated: false } });
      //}
      //} else {
      //  console.warn('The menu is temporarily disabled due to waiting for authentication!');
      //}
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
