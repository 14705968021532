<template>
  <div :class="componentClassName">
    <div class="m-calendar-range-field__calendar">
      <a-calendar-field
        id="start_date"
        v-model="startDate"
        class="m-calendar-range-field__calendar-start"
        :min-date="minDate"
        :max-date="startMaxDate"
        :disabled-dates="disabledDates"
        :placeholder="startDatePlaceholder"
        :nav-tooltip-previous="startDateNavTooltipPrevious"
        :nav-tooltip-previous-message="startDateNavTooltipPreviousMessage"
        :nav-tooltip-next="startDateNavTooltipNext"
        :nav-tooltip-next-message="startDateNavTooltipNextMessage"
        @change="handleChange"
      />
    </div>
    <div class="m-calendar-range-field__separator">-</div>
    <div class="m-calendar-range-field__calendar">
      <a-calendar-field
        id="end_date"
        v-model="endDate"
        class="m-calendar-range-field__calendar-end"
        :min-date="endMinDate"
        :max-date="maxDate"
        :disabled-dates="disabledDates"
        :placeholder="endDatePlaceholder"
        :nav-tooltip-previous="endDateNavTooltipPrevious"
        :nav-tooltip-previous-message="endDateNavTooltipPreviousMessage"
        :nav-tooltip-next="endDateNavTooltipNext"
        :nav-tooltip-next-message="endDateNavTooltipNextMessage"
        @change="handleChange"
      />
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ACalendarField from '@/components/atoms/calendar-field';
import * as dateFns from 'date-fns';

export default {
  name: 'm-calendar-range-field',
  components: {
    ACalendarField,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    disabledDates: {
      type: Array,
      default: null,
    },
    startDatePlaceholder: {
      type: String,
      default: '開始日',
    },
    endDatePlaceholder: {
      type: String,
      default: '終了日',
    },
    startDateNavTooltipPrevious: {
      type: Boolean,
      default: false,
    },
    startDateNavTooltipNext: {
      type: Boolean,
      default: false,
    },
    startDateNavTooltipPreviousMessage: {
      type: String,
      default: null,
    },
    startDateNavTooltipNextMessage: {
      type: String,
      default: null,
    },
    endDateNavTooltipPrevious: {
      type: Boolean,
      default: false,
    },
    endDateNavTooltipNext: {
      type: Boolean,
      default: false,
    },
    endDateNavTooltipPreviousMessage: {
      type: String,
      default: null,
    },
    endDateNavTooltipNextMessage: {
      type: String,
      default: null,
    },
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-calendar-range-field');
      return `${baseClassName} ${this.class}`.trim();
    },
    startMaxDate() {
      const endDate = this.toDateObject(this.endDate);
      const maxDate = this.maxDate;
      if (dateFns.differenceInCalendarDays(maxDate, endDate) > 0 || maxDate === null) {
        return endDate;
      }
      return maxDate;
    },
    endMinDate() {
      const startDate = this.toDateObject(this.startDate);
      const minDate = this.minDate;
      if (dateFns.differenceInCalendarDays(minDate, startDate) < 0 || minDate === null) {
        return startDate;
      }
      return minDate;
    },
  },
  watch: {
    modelValue: {
      handler(val) {
        this.setCurrentValue(val);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    toDateObject(dateText) {
      const date = Date.parse(dateText);
      if (date) {
        return new Date(date);
      }
      return null;
    },
    setCurrentValue(val) {
      const { start, end } = val;
      this.startDate = start;
      this.endDate = end;
    },
    handleChange() {
      const payload = {
        start: this.startDate,
        end: this.endDate,
      };
      this.$emit('update:modelValue', payload);
      this.$emit('change', payload);
    },
  },
};
</script>
