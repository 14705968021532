/**
 * 共通関数はこのファイルに定義する
 */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIMEZONE } from '@/libs/constants';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault(DEFAULT_TIMEZONE);
dayjs.extend(isSameOrBefore);
export default dayjs;

export const utils = {
  methods: {
    /**
     * Collapse the given text
     * @param {STRING} text     target text
     * @param {INTEGER} length  the length of the text which be collapsed
     * @returns STRING
     */
    collapseText: function (text, length) {
      if (!text || text.length < length) return text;
      return text.substring(0, 7) + '・・・';
    },
    numberOnly: function (number) {
      return /^[0-9]*$/.test(number);
    },
    isPhoneNumber: function (phoneNumber) {
      return this.numberOnly(phoneNumber) && (phoneNumber.length === 10 || phoneNumber.length === 11);
    },
    convertDateToString: function (date) {
      const convertedDate = `${date.getFullYear().toString().padStart(4, '0')}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      return convertedDate;
    },
  },

  currentDateTime: function () {
    const datetime = dayjs().tz().format('YYYY-MM-DD HH:mm');
    return datetime;
  },

  // e.g. date = new Date()
  specifyDateTime: function (date = undefined) {
    const datetime = dayjs(date).tz().toDate();
    return datetime;
  },

  setMetaDescription: route => {
    function setMetaDescriptionReal(descreptionText) {
      if (document.querySelector("meta[name='description']")) {
        document.querySelector("meta[name='description']").setAttribute('content', descreptionText);
      }
    }
    if (route.meta.desc) {
      setMetaDescriptionReal(route.meta.desc);
    } else {
      setMetaDescriptionReal('');
    }
  },
  setMetaDescription2: route => {
    function setMetaDescriptionReal(descreptionText) {
      if (document.querySelector("meta[name='description']")) {
        document.querySelector("meta[name='description']").setAttribute('content', descreptionText);
      }
    }
    if (route.meta.desc2) {
      setMetaDescriptionReal(route.meta.desc2);
    } else {
      setMetaDescriptionReal('');
    }
  },

  latestCastStartDateLatestUpdateDateSortOrderRule(a, b) {
    // 同じ時間の場合、更新日が新しいものをトップ側に表示
    if (a.castStartDate === b.castStartDate) {
      if (a.updateDate === b.updateDate) return 0;
      if (!a.updateDate) return -1;
      if (!b.updateDate) return 1;
      return a.updateDate < b.updateDate ? 1 : -1;
    }

    // 公開日が選択させていないの場合は一番上に表示
    if (!a.castStartDate) return -1;
    if (!b.castStartDate) return 1;

    // 公開日順で並ぶ
    return a.castStartDate < b.castStartDate ? 1 : -1;
  },

  publishedImportantLatestCastStartDateSortOrderRule(a, b) {
    // 同じ時間の場合、更新日が新しいものをトップ側に表示
    if (a.importantFlag === b.importantFlag) {
      return utils.latestCastStartDateLatestUpdateDateSortOrderRule(a, b);
    }

    if (a.importantFlag) return -1;
    if (b.importantFlag) return 1;

    return 0;
  },

  unpublishedCastStartDateLatestUpdateDateHasParenDocSortOrderRule(a, b) {
    if (a.parentDocId && b.parentDocId) {
      return utils.latestCastStartDateLatestUpdateDateSortOrderRule(a, b);
    }

    if (!a.parentDocId && !b.parentDocId) {
      return utils.latestCastStartDateLatestUpdateDateSortOrderRule(a, b);
    }

    if (a.parentDocId) return -1;
    if (b.parentDocId) return 1;

    return 0;
  },

  // バイナリデータからファイルをダウンロードする処理
  downloadFile(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.download = filename;
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  },

  tableDataServicePublishedSort(tableDataPublished) {
    // 発行日の昇順に並べ替えます
    tableDataPublished.sort((a, b) => {
      if (a.publishedDate < b.publishedDate) {
        return -1; // a は b より前に公開され、a は b の前に配置されます
      } else if (a.publishedDate > b.publishedDate) {
        return 1; // a は b の後に来て、b は a の前に置かれます
      } else {
        // 2 つの記事の発行日が同じ場合は、番号順に並べ替えます。
        return a.position - b.position;
      }
    });

    return tableDataPublished;
  },

  tableDataPublishedSort: (tableDataPublished, number) => {
    // 公開中については優先で公開日順で並ぶ
    tableDataPublished.sort(utils.publishedImportantLatestCastStartDateSortOrderRule);

    let countImportantFlags = 0;
    tableDataPublished.map(item => {
      if (item.importantFlag) {
        countImportantFlags++;
      }
    });

    if (countImportantFlags >= number) {
      // 優先number件で公開日順
      const firstNLatestCastStartDateImportantItems = tableDataPublished.slice(0, number);
      // (number+1)件目以降の優先記事＆非優先記事は公開日順で混ぜて並ぶ
      let restItems = tableDataPublished.slice(number);
      restItems = restItems.sort(utils.latestCastStartDateLatestUpdateDateSortOrderRule);

      tableDataPublished = firstNLatestCastStartDateImportantItems.concat(restItems);
    }

    return tableDataPublished;
  },

  tableDataUnpublishedSort: tableDataUnpublished => {
    tableDataUnpublished.sort(utils.unpublishedCastStartDateLatestUpdateDateHasParenDocSortOrderRule);
    return tableDataUnpublished;
  },

  // banner / newsAndSeminars / info / campaign のデータから公開状態を返す
  // @return 未承認: unapproved, 未公開: unpublished, 公開中: published, 公開終了: ended
  getPublishStatus: data => {
    const castStartDate = data.castStartDate;
    const castEndDate = data.castEndDate;
    const checkStatus = data.checkStatus;
    const nowDate = dayjs().tz().format('YYYY-MM-DD HH:mm');
    if (checkStatus !== 'approved') {
      return 'unapproved';
    } else if (castStartDate <= nowDate && nowDate <= castEndDate) {
      return 'published';
    } else if (castEndDate < nowDate) {
      return 'ended';
    } else {
      return 'unpublished';
    }
  },

  getPublishStatusLabel: data => {
    const castStartDate = data.castStartDate;
    const castEndDate = data.castEndDate;
    const checkStatus = data.checkStatus;
    const nowDate = dayjs().tz().format('YYYY-MM-DD HH:mm');
    if (checkStatus !== 'approved') {
      if (checkStatus === 'checked') {
        return 'チェック済み';
      } else {
        return '下書き';
      }
    } else if (castStartDate <= nowDate && nowDate <= castEndDate) {
      return '公開中';
    } else if (castEndDate < nowDate) {
      return '公開終了';
    } else {
      return '未公開';
    }
  },

  // アルファベット・数字以外が含まれているかどうかをチェックする
  // 含まれる場合：true
  containsNonAlphanumeric: input => {
    const alphanumericRegex = /^[A-Za-z0-9]+$/;
    return !alphanumericRegex.test(input);
  },
  // アルファベット(大文字)・数字以外が含まれているかどうかをチェックする
  // 含まれる場合：true
  containsNonLargeAlphanumeric: input => {
    const alphanumericRegex = /^[A-Z0-9]+$/;
    return !alphanumericRegex.test(input);
  },

  containsBOM: input => {
    // さまざまなテキストエンコーディングの BOM 定義の配列
    const bomSignatures = [
      [0xef, 0xbb, 0xbf], // UTF-8
      [0xfe, 0xff], // UTF-16 BE
      [0xff, 0xfe], // UTF-16 LE
      [0x00, 0x00, 0xfe, 0xff], // UTF-32 BE
      [0xff, 0xfe, 0x00, 0x00], // UTF-32 LE
    ];
    const bytes = Array.from(input, c => c.charCodeAt(0));
    // BOM 定義をループし、一致するかどうかを確認します。
    for (const bom of bomSignatures) {
      const match = bom.every((byte, index) => byte === bytes[index]);
      if (match) {
        return true;
      }
    }
    return false;
  },
};
