<template>
  <div id="m-modal">
    <div id="m-modal-content" v-click-outside="handleModalClose" class="m-modal">
      <span
        v-if="!isEnded && !isPublished"
        id="m-modal-edit"
        data-testid="edit-content-button"
        class="m-modal__choice m-modal-edit"
        @click="handleAnnounceEdit(documentid)"
      >
        <a-icon icon="edit"></a-icon>　編集
      </span>
      <span
        v-if="isPublished"
        id="m-modal-edit-return-unpublished"
        data-testid="edit-return-unpublished-content-button"
        class="m-modal__choice m-modal-edit"
        @click="handleAnnounceEditReturnUnpublished()"
      >
        <a-icon icon="edit"></a-icon>　未公開に戻す
      </span>
      <span
        v-if="isPublished"
        id="m-modal-edit-keep-published"
        data-testid="edit-keep-published-content-button"
        class="m-modal__choice m-modal-edit"
        @click="handleAnnounceEditKeepPublished()"
      >
        <a-icon icon="edit"></a-icon>　公開のまま編集
      </span>
      <span
        id="m-modal-delete"
        data-testid="delete-content-button"
        class="m-modal__choice"
        @click="handleAnnounceConfirmDelete()"
      >
        <a-icon icon="trash"></a-icon>　削除
      </span>
    </div>
    <div id="m-modal-overlay"></div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'modal',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    documentid: {
      type: String,
      default: '',
    },
    isEnded: {
      type: Boolean,
      default: false,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalVisible: true,
    };
  },
  methods: {
    handleAnnounceConfirmDelete(documentid) {
      this.$emit('handle-confirm-delete', documentid);
    },
    handleAnnounceEdit(documentid) {
      this.$emit('handle-edit', documentid);
    },
    handleAnnounceEditReturnUnpublished(documentid) {
      this.$emit('handle-edit-return-unpublished', documentid);
    },
    handleAnnounceEditKeepPublished(documentid) {
      this.$emit('handle-edit-keep-published', documentid);
    },
    handleModalClose() {
      this.$emit('handle-modal-close');
    },
  },
};
</script>
