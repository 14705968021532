<template>
  <t-default-layout ref="defaultLayout" active-nav-id="app-review-settings">
    <div id="t-app-review-settings-component" class="t-app-review-settings">
      <div class="t-app-review-settings-heading-line">
        <a-page-heading class="t-app-review-settings-heading-item">アプリレビュー設定</a-page-heading>
        <a-button
          v-if="isSuperUser"
          id="t-app-review-settings-add-button"
          data-testid="app-review-setting-add-button"
          class="t-app-review-settings-heading-item"
          variant="icon"
          color="primary"
          icon="plus"
          icon-color="white"
          @click="handleAdd"
        />
      </div>
      <m-section>
        <div class="t-app-review-settings-tabgroup">
          <m-action-card title="" icon="">
            <m-data-table
              header-id="app-review-settings-table-header"
              body-id="app-review-settings-table-body"
              id-text="app-review-setting-info-display"
              :data="displayedTableData()"
              :border="true"
            >
              <a-data-table-column prop="showStartDate" label="表示開始日時" align="left">
                <template #default="scope">
                  <a-text>
                    <span style="font-size: 15px; white-space: pre">
                      {{ formatText(scope.row.showStartDate) }}
                    </span>
                  </a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column type="action" :width="160" align="right" class="a-data-table-column--action">
                <template #default="scope">
                  <a-button
                    v-show="isSuperUser && isFuture(scope.row)"
                    :id="scope.row.documentId + '-editBtn'"
                    :data-testid="'app-review-setting-edit-button-' + scope.index"
                    variant="round"
                    size="small"
                    label="編集"
                    color="grey"
                    icon="edit"
                    icon-color="black"
                    :inline="true"
                    @click="handleEdit(scope.row)"
                  />
                  <a-button
                    v-if="isSuperUser"
                    :id="scope.row.documentId + '-deleteBtn'"
                    :data-testid="'app-review-setting-delete-button-' + scope.index"
                    variant="round"
                    size="small"
                    label="削除"
                    color="grey"
                    icon="trash"
                    icon-color="black"
                    :inline="true"
                    @click="handleConfirmDelete(scope.row)"
                  />
                </template>
              </a-data-table-column>
            </m-data-table>
          </m-action-card>
        </div>
      </m-section>
    </div>
    <teleport to="body">
      <o-modal
        id="t-app-review-settings-remove-modal"
        :visible="modalVisible"
        data-testid="app-review-setting-delete-confirm-modal"
        class="o-modal-remove-app-review-settings-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        @close="handleCloseModal"
      >
        <template #title>{{ modalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span id="appReviewSettingsDelete_resultModal_body" v-html="modalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="removeAppReviewSettingDialogCancelBtn"
            data-testid="app-review-setting-delete-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCloseModal"
          />
          <a-button
            id="removeAppAppReviewSettingDialogOKBtn"
            data-testid="app-review-setting-delete-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleDelete(targetDocId)"
          />
        </template>
      </o-modal>
    </teleport>

    <!-- Info, Error Modal -->
    <teleport to="body">
      <o-modal
        id="appReviewSettings_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="resultModalOKBtn"
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { utils } from '@/common/utils.js';

export default {
  name: 't-app-review-settings',
  components: {
    TDefaultLayout,
    APageHeading,
  },
  mixins: [customRequest, messageContent],
  data() {
    return {
      tableData: [],
      targetDocId: '',
      modalVisible: false,
      modalTitle: '',
      modalContent: '',
      execUserID: this.$store.state.user.userID,
      authInfo: this.$store.state.user.authInfo,
      resultModalVisible: false,
      resultModalTitle: '',
      resultModalContent: '',
    };
  },
  computed: {
    isSuperUser() {
      return this.authInfo === 'manager';
    },
  },
  created() {
    this.getAppReviewSettingList();
  },
  methods: {
    displayedTableData() {
      return this.tableData;
    },
    isFuture(row) {
      const nowDate = utils.currentDateTime();
      return row.showStartDate > nowDate;
    },
    formatText(data) {
      if (!data) return '';
      const arr = data.split(' ');
      const date = arr[0].split('-');
      return `${date[0]}/${date[1]}/${date[2]}    ${arr[1]}`;
    },
    getAppReviewSettingList: function () {
      const data = {
        execUserID: this.execUserID,
      };
      this.postRequest('/app-review-settings/get-list', data).then(e => {
        for (const k in e.data) {
          const item = {
            documentID: k,
            showStartDate: e.data[k].showStartDate,
          };
          this.tableData.push(item);
        }
        this.tableData.sort((a, b) => {
          if (a.showStartDate > b.showStartDate) {
            return -1;
          }
          if (a.showStartDate < b.showStartDate) {
            return 1;
          }
          return 0;
        });
      });
    },
    handleAdd() {
      this.$router.push({
        path: '/app-review-settings/add',
        query: { execUserID: this.execUserID },
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: '/app-review-settings/edit',
        query: { id: row.documentID },
      });
    },
    handleCloseModal() {
      this.targetDocId = '';
      this.modalVisible = false;
    },
    showResultModal(title, message) {
      this.handleCloseModal();
      this.modalVisible = true;
      this.resultModalTitle = title;
      this.resultModalContent = message;
    },
    handleConfirmDelete(row) {
      this.targetDocId = row.documentID;
      this.modalVisible = true;
      this.modalTitle = 'アプリレビュー設定削除';
      this.modalContent = messageContent.comfirmDelete(row.showStartDate);
    },
    handleDelete(documentID) {
      this.handleCloseModal();
      const formData = {
        execUserID: this.execUserID,
        documentID: documentID,
      };
      this.postRequest('/app-review-settings/delete', formData).then(e => {
        this.$router.push({ name: 'AppReviewSettings' }).then(() => {
          this.$router.go();
        });
      });
    },
  },
};
</script>
