<template>
  <label :id="`${id}-label`" :class="componentClassName">
    <input
      :id="`${id}-input`"
      ref="input"
      v-model="checkboxModel"
      :data-testid="dataTestid"
      class="a-switch__input"
      type="checkbox"
      :name="name"
      :value="value"
      v-bind="inputAttrs"
      @click="handleClick"
      @change="handleChange"
    />
    <span v-if="label || $slots.default" class="a-switch__label">
      <slot>{{ label }}</slot>
    </span>
    <span :id="`switch-${id}-${label}`" class="a-switch__body"></span>
  </label>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-switch',
  props: {
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Boolean, Array],
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    dataTestid: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'change', 'update:modelValue'],
  data() {
    return {
      checkboxModel: false,
      isChecked: this.checked || false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-switch', this.disabled ? 'disabled' : '', this.isChecked ? 'checked' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { checked, placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    value(val, oldValue) {
      this.setCurrentValue(val);
    },
    checked(val, oldValue) {
      this.isChecked = val;
      if (this.modelValue === null) {
        this.checkboxModel = val;
      }
    },
    modelValue(val, oldValue) {
      this.checkboxModel = val;
    },
    checkboxModel(val, oldValue) {
      if (!Array.isArray(this.modelValue)) {
        this.isChecked = this.checkboxModel;
      }
    },
  },
  mounted() {
    if (this.modelValue !== null) {
      this.checkboxModel = this.modelValue;
    } else if (this.checked !== null) {
      this.checkboxModel = this.checked;
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
    handleChange(event) {
      // check model is array or not
      this.$emit('update:modelValue', this.checkboxModel);
      this.$emit('change', event);
    },
  },
};
</script>
