<template>
  <div :class="componentClassName" :data-testid="'add-user-form-' + itemIndex">
    <div class="m-user-invitation-item__header">
      <div class="m-user-invitation-item__header-title">
        <a-icon icon="user-outline" color="orange" />
        {{ title }}
      </div>
      <div class="m-user-invitation-item__header-action">
        <a-button
          :id="'deleteBtn' + itemIndex"
          :data-testid="'add-user-delete-button-' + itemIndex"
          :disabled="disabled ? 'disabled' : null"
          variant="round"
          color="grey"
          size="small"
          :inline="true"
          label="削除"
          icon="trash"
          icon-color="black"
          @click="handleRemoveClick"
        />
      </div>
    </div>
    <div class="m-user-invitation-item__fields">
      <m-form-input label="メールアドレス（ユーザーIDになります）">
        <a-text-field
          :id="'userIDInput' + itemIndex"
          v-model="currentUserID"
          :data-testid="'add-user-id-input-' + itemIndex"
          class="m-user-invitation-item__fields-email-input"
          :name="'userID-' + itemIndex"
          type="email"
          placeholder="xxxx@kaisha.co.jp"
          :disabled="disabled"
          @input="handleUserIDChange"
          @change="handleUserIDChange"
        />
        <a-select-field
          :id="'authInfoSelect' + itemIndex"
          v-model="currentAuthInfo"
          :data-testid="'add-user-auth-select-' + itemIndex"
          class="m-user-invitation-item__fields-role-select"
          :name="'authInfo-' + itemIndex"
          :disabled="disabled"
          :options="selectableAuthInfo"
          @change="handleAuthInfoChange"
        />
      </m-form-input>
      <m-form-input label="名前（必須）">
        <a-text-field
          :id="'userNameInput' + itemIndex"
          v-model="currentUsername"
          :data-testid="'add-user-name-input-' + itemIndex"
          class="m-user-invitation-item__fields-name-input"
          :name="'userName-' + itemIndex"
          type="text"
          placeholder="山田 太郎"
          :disabled="disabled"
          @input="handleUsernameChange"
          @change="handleUsernameChange"
        />
      </m-form-input>
      <m-form-input label="部署（任意）">
        <a-text-field
          :id="'departmentInfoInput' + itemIndex"
          v-model="currentDepartmentInfo"
          :data-testid="'add-user-department-input-' + itemIndex"
          class="m-user-invitation-item__fields-department-input"
          :name="'departmentInfo-' + itemIndex"
          type="text"
          placeholder="マーケティング"
          :disabled="disabled"
          @input="handleDepartmentInfoChange"
          @change="handleDepartmentInfoChange"
        />
      </m-form-input>
      <m-form-input label="ビジネスdアカウントのid（任意）">
        <a-text-field
          :id="'docomoIDInput' + itemIndex"
          v-model="currentDocomoID"
          :data-testid="'add-user-docomoid-input-' + itemIndex"
          class="m-user-invitation-item__fields-docomoid-input"
          :name="'docomoID-' + itemIndex"
          type="text"
          placeholder="xxxx@xxxx.xxx"
          :disabled="disabled"
          @input="handleDocomoIDChange"
          @change="handleDocomoIDChange"
        />
      </m-form-input>
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon';
import AButton from '@/components/atoms/button';
import ATextField from '@/components/atoms/text-field';
import ASelectField from '@/components/atoms/select-field';
import MFormInput from '@/components/molecules/form-input';

export default {
  name: 'm-user-invitation-item',
  components: {
    AIcon,
    AButton,
    ATextField,
    ASelectField,
    MFormInput,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    itemIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userID: {
      type: String,
      default: '',
      required: true,
    },
    authInfo: {
      type: String,
      default: 'writer',
    },
    userName: {
      type: String,
      default: '',
    },
    departmentInfo: {
      type: String,
      default: '',
    },
    docomoID: {
      type: String,
      default: '',
    },
  },
  emits: [
    'remove',
    'change',
    'update:userID',
    'update:authInfo',
    'update:userName',
    'update:departmentInfo',
    'update:docomoID',
  ],
  data() {
    return {
      currentUserID: '',
      currentAuthInfo: 'writer',
      currentUsername: '',
      currentDepartmentInfo: '',
      currentDocomoID: '',
      selectableAuthInfo: [],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-user-invitation-item', this.disabled ? 'disabled' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
    title() {
      return `ユーザー${this.itemIndex + 1}`;
    },
  },
  watch: {
    userID(val, oldValue) {
      this.setValue('currentUserID', val);
    },
    authInfo(val, oldValue) {
      this.setValue('currentAuthInfo', val);
    },
    userName(val, oldValue) {
      this.setValue('currentUsername', val);
    },
    departmentInfo(val, oldValue) {
      this.setValue('currentDepartmentInfo', val);
    },
    docomoID(val, oldValue) {
      this.setValue('currentDocomoID', val);
    },
  },
  mounted() {
    if (this.userID) {
      this.setValue('currentUserID', this.userID);
    }
    if (this.authInfo) {
      this.setValue('currentAuthInfo', this.authInfo);
    }
    if (this.userName) {
      this.setValue('currentUsername', this.userName);
    }
    if (this.departmentInfo) {
      this.setValue('currentDepartmentInfo', this.departmentInfo);
    }
    if (this.docomoID) {
      this.setValue('currentDocomoID', this.docomoID);
    }
  },
  created() {
    this.selectableAuthInfo = [
      { value: 'operator', label: '運用者権限ユーザー' },
      { value: 'manager', label: 'スーパーユーザー' },
      { value: 'writer', label: '作業者権限ユーザー' },
    ];
  },
  methods: {
    handleRemoveClick(event) {
      this.$emit('remove', this.itemIndex);
    },
    handleUserIDChange(event) {
      this.handleInputChange('userID', event);
    },
    handleAuthInfoChange(event) {
      this.handleInputChange('authInfo', event);
    },
    handleUsernameChange(event) {
      this.handleInputChange('userName', event);
    },
    handleDepartmentInfoChange(event) {
      this.handleInputChange('departmentInfo', event);
    },
    handleDocomoIDChange(event) {
      this.handleInputChange('docomoID', event);
    },
    // eslint-disable-next-line require-await
    async handleInputChange(field, event) {
      if (event) {
        const dataName = `current${field[0].toUpperCase()}${field.substring(1)}`;
        this.setValue(dataName, event.target ? event.target.value : event);
        this.$emit(`update:${field}`, this[dataName]);
        this.$emit('change', event);
      }
    },
    setValue(field, val) {
      this[field] = val;
    },
  },
};
</script>
