<template>
  <div class="o-csv-history">
    <m-data-table
      ref="dataTable"
      empty-text="アップロードされたCSVはありません"
      :fit="true"
      :data="csvlist.slice(0, 10)"
      :border="true"
    >
      <a-data-table-column :width="160" prop="status" label="状態" align="center">
        <template #default="scope">
          <a-text size="small" :center="true">{{
            dbStatus2ViewStatusForCsv(scope.row.pushedFlag, scope.row.testPushFlag, scope.index)
          }}</a-text>
        </template>
      </a-data-table-column>
      <a-data-table-column :width="180" prop="filename" label="ファイル名" align="center">
        <template #default="scope">
          <a-text size="small" :center="true">{{ scope.row.filename ?? '-' }}</a-text>
        </template>
      </a-data-table-column>
      <a-data-table-column :width="90" prop="type" label="リストの種類" align="center">
        <template #default="scope">
          <a-text size="small" :center="true">{{ csvTypeToLabelMap[scope.row.type] ?? 'アカウント識別子' }}</a-text>
        </template>
      </a-data-table-column>
      <a-data-table-column :width="80" prop="size" label="容量" align="center">
        <template #default="scope">
          <a-text size="small" :center="true">{{ dbSize2ViewSizeForCsv(scope.row.size) }}</a-text>
        </template>
      </a-data-table-column>
      <a-data-table-column :width="120" prop="updateDate" label="アップロード日時" align="center">
        <template #default="scope">
          <a-text size="small" :center="true">{{ scope.row.updateDate ?? '-' }}</a-text>
        </template>
      </a-data-table-column>
      <a-data-table-column :width="70" prop="download" label="ダウンロード" align="center">
        <template #default="scope">
          <button class="o-csv-history" type="button" @click.stop="handleCsvDownload(scope.row)">
            <a-icon icon="download" color="black" />
          </button>
        </template>
      </a-data-table-column>
    </m-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import MDataTable from '@/components/molecules/data-table';
import ADataTableColumn from '@/components/atoms/data-table-column';
import { customRequest } from '@/common/customRequest.js';
import { utils } from '@/common/utils.js';

export default {
  name: 'o-csv-history',
  components: {
    MDataTable,
    ADataTableColumn,
  },
  mixins: [customRequest],
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'announcement',
    },
    csvlist: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      csvTypeToLabelMap: {
        accountid: 'アカウント識別子',
        pseudoid: 'pseudo_id',
      },
    };
  },
  methods: {
    dbStatus2ViewStatusForCsv(pushedFlag, testPushFlag, idx) {
      if (pushedFlag) {
        return testPushFlag ? 'プッシュ通知済（テスト特定配信用）' : 'プッシュ通知済';
      } else {
        if (idx === 0) {
          return testPushFlag ? 'プッシュ通知予定（テスト特定配信用）' : 'プッシュ通知予定（Limited時）';
        } else {
          return testPushFlag ? '未使用（テスト特定配信用）' : '未使用';
        }
      }
    },
    dbSize2ViewSizeForCsv(dbSize) {
      if (typeof dbSize !== 'number') return '-';
      const kb = Math.floor(dbSize / 1024);
      if (kb < 1) return `${dbSize}B`;
      const mb = Math.floor(dbSize / 1024 / 1024);
      if (mb < 1) return `${kb}KB`;
      return `${mb}MB`;
    },
    downloadPath() {
      if (this.type === 'campaign') {
        return '/campaigns/download-csv';
      }
      return '/announcements/download-csv';
    },
    base64ImageToBlob(str) {
      // decode base64
      const imageContent = window.atob(str);

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      const buffer = new ArrayBuffer(imageContent.length);
      const view = new Uint8Array(buffer);
      // fill the view, using the decoded base64
      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
      }
      // convert ArrayBuffer to Blob
      const blob = new Blob([buffer], { type: 'text/csv' });
      return blob;
    },
    handleCsvDownload(csvInfo) {
      this.postRequest(this.downloadPath(), { id: this.$props.id, csvId: csvInfo.id })
        .then(e => {
          if (e.status === 'failed' || typeof e.data !== 'string') {
            return;
          }
          const blob = this.base64ImageToBlob(e.data);
          utils.downloadFile(blob, csvInfo.filename);
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>
