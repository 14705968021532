<template>
  <div class="o-csv-uploader">
    <a-select-field
      id="o-csv-uploader__type-select"
      v-model="pushTargetListFileTypeChild"
      class="o-csv-uploader__type-select"
      :options="pushTargetListFileTypeOptions"
    />
    <a-upload-field
      id="o-csv-uploader__a-upload-field"
      v-model="pushTargetListFileChild.file"
      name="pushTargetListFileChild"
      class="o-csv-uploader__a-upload-field"
      label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
      accept="image/png, .jfif, image/jpg, image/jpeg, text/csv, text/plain"
      @change="handleUploadFileChangeCsv(pushTargetListFileChild)"
      @showErrorModal="showErrorModal"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ASelectField from '@/components/atoms/select-field';
import AUploadField from '@/components/atoms/upload-field';
import { messageContent } from '@/common/messageContent.js';
import { utils } from '@/common/utils.js';

export default {
  name: 'o-csv-uploader',
  components: {
    ASelectField,
    AUploadField,
  },
  mixins: [],
  props: {
    pushTargetListFile: {
      type: Object,
      required: true,
    },
    pushTargetListFileType: {
      type: String,
      required: true,
    },
    errorFunction: {
      type: Function,
      default: undefined,
    },
  },
  emits: ['update:pushTargetListFile', 'update:pushTargetListFileType'],
  data() {
    return {
      pushTargetListFileChild: {
        file: null,
        filename: null,
        binary: null,
        size: null,
      },
      pushTargetListFileTypeChild: 'accountid',
      pushTargetListFileTypeOptions: [
        { value: 'accountid', label: 'アカウント識別子リストをアップロード' },
        { value: 'pseudoid', label: 'pseudo_idリストをアップロード' },
      ],
      // ファイルタイプの名称定義
      msgOfTypeMap: {
        accountid: 'アカウント識別子',
        pseudoid: 'pseudo_id',
      },
      // ファイルタイプ毎の桁数定義
      numberOfTypeMap: {
        accountid: 24,
        pseudoid: 32,
      },
    };
  },
  watch: {
    pushTargetListFileChild: {
      handler: function (val) {
        this.$emit('update:pushTargetListFile', val);
      },
      deep: true,
    },
    pushTargetListFileTypeChild(val) {
      this.$emit('update:pushTargetListFileType', val);
      this.clearFile(this.pushTargetListFileChild);
    },
  },
  mounted() {
    this.pushTargetListFileChild = this.pushTargetListFile;
    this.pushTargetListFileTypeChild = this.pushTargetListFileType;
  },
  methods: {
    handleUploadFileChangeCsv(fileObj) {
      const vm = this;
      const reader = new FileReader();
      let tempFile = null; // ファイルを格納する一時変数
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          fileObj.binary = content;
          const errorArr = [];
          const typeStr = vm.msgOfTypeMap[vm.pushTargetListFileTypeChild];

          // CSVファイルかどうかを確認する
          if (tempFile && tempFile.name.endsWith('.csv')) {
            const csvContent = atob(content);
            const lines = csvContent.split('\n');
            let errorBom = false;
            let errorCsvLength = false;
            let errorCsvCharacters = false;

            if (lines.length > 0 && utils.containsBOM(lines[0])) {
              errorBom = true;
            } else {
              // 各行をループして、正確に24文字であるかどうかを確認します
              for (let i = 0; i < lines.length; i++) {
                const line = lines[i].replace(/[\r\n\x00-\x1F\x7F-\x9F]/g, '');
                if (line.length !== vm.numberOfTypeMap[vm.pushTargetListFileTypeChild]) {
                  errorCsvLength = true;
                }
                if (
                  (vm.pushTargetListFileTypeChild === 'accountid' && utils.containsNonLargeAlphanumeric(line)) ||
                  (vm.pushTargetListFileTypeChild === 'pseudoid' && utils.containsNonAlphanumeric(line))
                ) {
                  errorCsvCharacters = true;
                }
              }
            }

            if (errorBom) {
              errorArr.push(messageContent.bomMessengerError);
            } else {
              if (errorCsvLength) {
                errorArr.push(messageContent.errorCsvLength(typeStr));
              }
              if (errorCsvCharacters) {
                errorArr.push(messageContent.errorCsvCharacters(typeStr));
              }
            }
          } else {
            errorArr.push(messageContent.errorNotFileCsv);
          }
          // エラー処理
          if (errorArr.length > 0) {
            vm.showErrorModal(errorArr.join('<br>'));
            vm.clearFile(fileObj);
          }
        },
        false
      );
      if (fileObj.file[0]) {
        tempFile = fileObj.file[0];
        reader.readAsDataURL(fileObj.file[0]);
        fileObj.filename = tempFile.name;
        fileObj.size = tempFile.size;
      } else {
        // clear file content
        fileObj.file = null;
        fileObj.filename = null;
        fileObj.binary = null;
        fileObj.size = null;
      }
    },
    clearFile(fileObj) {
      fileObj.file = null;
      fileObj.filename = null;
      fileObj.binary = null;
      fileObj.size = null;
    },
    showErrorModal(message) {
      if (this.errorFunction) {
        this.errorFunction(message);
      }
    },
  },
};
</script>
