export const NAVIGATION_ITEMS = [
  {
    nav_id: 'homes',
    icon: 'home',
    active_icon: 'home',
    label: 'ホーム画面コンテンツ',
    has_submenu: true,
    items: [
      {
        nav_id: 'news-and-seminars',
        label: '記事・セミナー（ホームバナー）',
        route_name: 'NewsAndSeminars',
      },
      {
        nav_id: 'home-sub-banner',
        label: '小バナー（ホーム）',
        route_name: 'HomeSubBanner',
      },
      {
        nav_id: 'banners',
        label: 'キャンペーン・トクする情報',
        route_name: 'Banners',
      },
    ],
  },
  {
    nav_id: 'notices',
    icon: 'notice',
    active_icon: 'notice',
    label: 'お知らせコンテンツ',
    has_submenu: true,
    items: [
      {
        nav_id: 'campaigns',
        label: '記事・セミナー（一覧）',
        route_name: 'Campaigns',
      },
      {
        nav_id: 'announcements',
        label: 'お知らせ',
        route_name: 'Announcements',
      },
    ],
  },
  {
    nav_id: 'contact',
    icon: 'list-outline',
    active_icon: 'list',
    label: 'フッターコンテンツ',
    has_submenu: true,
    items: [
      {
        nav_id: 'services',
        label: 'サービス',
        route_name: 'Services',
      },
      {
        nav_id: 'online-shop-service',
        label: 'ショップ（人気のサービス）',
        route_name: 'OnlineShopService',
      },
      {
        nav_id: 'online-shop-support',
        label: 'ショップ（お悩み・課題）',
        route_name: 'OnlineShopSupport',
      },
      {
        nav_id: 'online-shop-banners',
        label: 'ショップ（バナー）',
        route_name: 'OnlineShopBanners',
      },
    ],
  },
  {
    nav_id: 'settings',
    icon: 'gear-outline',
    active_icon: 'gear',
    label: '設定',
    has_submenu: true,
    items: [
      {
        nav_id: 'app-review-settings',
        label: 'アプリレビュー設定',
        route_name: 'AppReviewSettings',
      },
      {
        nav_id: 'app-versions',
        label: 'アプリバージョン管理',
        route_name: 'AppVersions',
      },
      {
        nav_id: 'users',
        label: 'ユーザー管理',
        route_name: 'Users',
      },
    ],
  },
];

export const PERMISSIONS_FRONTEND = {
  // オペレーター追加・削除
  userAdd: {
    supervisor: true,
    manager: true,
    operator: true,
    writer: true,
  },
};

export const DEFAULT_TIMEZONE = 'Asia/Tokyo';

export const MAX_USER = 150;

// 単位はMB
export const Limit_UPLOAD_FILE_SIZE = 10;
