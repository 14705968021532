<template>
  <section :class="componentClassName">
    <a-heading :id="id" tag="h1" variant="border">
      <slot>{{ title }}</slot>
    </a-heading>
  </section>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AHeading from '@/components/atoms/heading';

export default {
  name: 'a-page-heading',
  components: {
    AHeading,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'pageHeadingTitle',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-page-heading');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
