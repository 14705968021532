<template>
  <div :class="componentClassName">
    <a-button
      id="prev"
      variant="icon"
      icon="chevron-left"
      :icon-color="currentIndex === 1 ? 'grey' : 'orange'"
      :class="getNavClassName('prev')"
      :disabled="currentIndex === 1"
      @click="handleNavClick('prev')"
    />
    <ul class="a-pagination__pages">
      <template v-for="(item, index) in pageIndexes" :key="index">
        <li :class="getPageClassName(item)" @click="handlePageClick(item)">
          <span :id="'pagination_' + item" class="a-pagination__page-index">{{ item }}</span>
        </li>
      </template>
    </ul>
    <a-button
      id="next"
      variant="icon"
      icon="chevron-right"
      :icon-color="currentIndex === length ? 'grey' : 'orange'"
      :class="getNavClassName('next')"
      :disabled="currentIndex === length"
      @click="handleNavClick('next')"
    />
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';

export default {
  name: 'a-pagination',
  components: {
    AButton,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    length: {
      type: Number,
      default: 0,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ['page:selected'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-pagination');
      return `${baseClassName} ${this.class}`.trim();
    },
    pageIndexes() {
      const length = this.length;
      const slotLength = 9;

      if (length <= slotLength) {
        return Array.from({ length }, (_val, idx) => idx + 1);
      }

      const arr = Array(slotLength);
      const ellipsis = '...';

      // first 2 elements
      arr[0] = 1;
      arr[1] = this.currentIndex - 3 <= 1 ? 2 : ellipsis;

      // last 2 elements
      arr[arr.length - 2] = this.currentIndex + 3 >= length ? length - 1 : ellipsis;
      arr[arr.length - 1] = length;

      // middle elements
      const maxBound = slotLength - 2;
      for (let i = 2; i < maxBound; i++) {
        if (arr[i - 1] !== ellipsis) {
          arr[i] = arr[i - 1] + 1;
        } else {
          if (arr[arr.length - 2] !== ellipsis) {
            arr[i] = length - maxBound - 1;
          } else {
            arr[i] = this.currentIndex - 2;
          }
        }
      }
      return arr;
    },
  },
  methods: {
    getNavClassName(type) {
      const isDisabled =
        (type === 'prev' && this.currentIndex === 1) || (type === 'next' && this.currentIndex === this.length);
      return mapModifiers('a-pagination__action', type, isDisabled ? 'disabled' : '');
    },
    getPageClassName(item) {
      return mapModifiers(
        'a-pagination__page-item',
        this.currentIndex === item ? 'active' : '',
        item === '...' ? 'ellipsis' : ''
      );
    },
    handleNavClick(type) {
      let newIndex = -1;
      if (type === 'prev') {
        newIndex = this.currentIndex - 1;
        if (newIndex < 1) {
          newIndex = 1;
        }
      } else {
        newIndex = this.currentIndex + 1;
        if (newIndex > this.length) {
          newIndex = this.length;
        }
      }
      this.$emit('page:selected', newIndex);
    },
    handlePageClick(page) {
      if (page !== '...') {
        this.$emit('page:selected', page);
      }
    },
  },
};
</script>
