<template>
  <div :class="componentClassName">
    <component :is="componentSubtitleTagName" v-if="subheading" class="a-heading__subtitle">
      {{ subheading }}
    </component>
    <component :is="componentTagName" :id="id" :data-testid="dataTestid" class="a-heading__title">
      <slot>{{ heading }}</slot>
    </component>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-heading',
  props: {
    heading: {
      type: String,
      default: null,
    },
    subheading: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
    subheadingTag: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|border|center)/);
      },
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|primary)/);
      },
    },
    size: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|small|large)/);
      },
    },
    id: {
      type: String,
      default: 'headingTitle',
    },
    dataTestid: {
      type: String,
      default: null,
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-heading', this.variant, this.color, this.size);
      return `${baseClassName}`.trim();
    },
    componentTagName() {
      const tagName = this.tag ? this.tag : 'h2';
      return tagName;
    },
    componentSubtitleTagName() {
      const level = this.tag ? parseInt(this.tag.substring(1)) : undefined;
      if (this.subheadingTag) {
        return this.subheadingTag;
      } else {
        const subheadingTagName = level < 6 ? 'h' + (level + 1).toString() : 'span';
        return subheadingTagName;
      }
    },
  },
};
</script>
