<template>
  <span class="a-uom-text">
    {{ price }}
    <span v-if="unit" class="a-uom-text__unit">{{ unit }}</span>
  </span>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-uom-text',
  props: {
    price: {
      type: String,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
  },
};
</script>
