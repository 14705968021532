<template>
  <t-default-layout active-nav-id="online-shop-service">
    <div :class="componentClassName">
      <a-back-page :id="id" :text="backPageName()" :page="backPage()" :show="show" />
      <form
        ref="form"
        class="t-online-shop-service-edit__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-online-shop-service-edit__fields">
          <m-section>
            <m-action-card
              :id="idName[1]"
              test-id-text="edit-online-shop-service"
              title="詳細設定"
              :document-id="id"
              header-border
              no-header-margin
              icon="clock"
            >
              <div class="t-online-shop-service-edit__fields">
                <div id="t-online-shop-service-edit-uploading-file" class="t-online-shop-service-edit__row">
                  <div class="t-online-shop-service-edit__col-1">
                    <div class="t-online-shop-service-edit__fields">
                      <m-form-input>
                        <a-upload-field
                          id="upload-image"
                          v-model="onlineShopServiceData.imageFile.file"
                          test-id-text="edit-online-shop-service-image"
                          name="imageFile"
                          class="t-online-shop-service-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange('imageFile', onlineShopServiceData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-online-shop-service-edit__col-2">
                    <div class="t-online-shop-service-edit__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="onlineShopServiceData.title"
                          data-testid="edit-online-shop-service-title-input"
                          type="text"
                          maxlength="50"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
                <div class="t-online-shop-service-add__row">
                  <a-text id="t-online-shop-service-add-importantFlag__a-text"> 外部Webサイト</a-text>
                  <m-form-input>
                    <a-apply-text-field
                      id="t-online-shop-service-add__contents-url"
                      v-model="onlineShopServiceData.contentsURL"
                      class="t-online-shop-service-add__contents-url"
                      :disabled="disabled"
                      data-testid="t-online-shop-service-add-url-input"
                      type="text"
                      maxlength="500"
                      placeholder="URL入力"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-online-shop-service-edit__fields">
                <div id="t-online-shop-service-edit-cast-start-date-day__row" class="t-online-shop-service-edit__row">
                  <a-text id="t-online-shop-service-edit-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-online-shop-service-edit-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="onlineShopServiceData.castStartDate"
                      test-id-text="edit-online-shop-service-publish-start"
                      :min-date="minDate"
                      date-placeholder="公開日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      :disabled="hasParentDoc"
                    />
                  </m-form-input>
                </div>
                <div class="t-online-shop-service-edit__row">
                  <a-text id="t-online-shop-service-edit-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-online-shop-service-edit-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="onlineShopServiceData.castEndDate"
                      test-id-text="edit-online-shop-service-publish-end"
                      :min-date="minDate"
                      date-placeholder="公開終了日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-online-shop-service-edit__fields__pushtype">
                <m-form-input id="t-online-shop-service-edit__fields__pushtype">
                  <a-select-field
                    id="t-online-shop-service-edit__fields__pushtype__a-select-field"
                    v-model="onlineShopServiceData.checkStatus"
                    data-testid="detail-online-shop-service-check-status-select"
                    :options="checkStatusOptions"
                    :v-options="visibleCheckStatusOptions"
                  />
                </m-form-input>
              </div>
              <!-- 情報を表示するために m-data-table を追加します 承認フロー -->
              <m-data-table
                :empty-text="dataTableEmp"
                :fit="true"
                :data="onlineShopServiceData.checkHistories"
                id-text="online-shop-service-check-histories-info-display"
              >
                <a-data-table-column
                  prop="publishedDocumentId"
                  :min-width="120"
                  :label="'公開ドキュメントID：<br>' + docId"
                  align="center"
                >
                  <template #default="scope">
                    <a-text size="medium" :center="true">公開{{ scope.index + 1 }}回目</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="draft" :min-width="100" label="下書き" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.draft.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.draft.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="checked" :min-width="100" label="チェック済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.checked.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.checked.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="approved" :min-width="100" label="承認済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.approved.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.approved.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
              </m-data-table>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="edit-online-shop-service-save-button"
            variant="block"
            color="primary"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>

    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="online-shop-serviceCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { onlineShopServiceValidation } from '@/validations/onlineShopService.js';
import { cloneDeep } from 'lodash';

export default {
  name: 't-online-shop-service-edit',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    AUploadField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      id: this.$route.query.id,
      source: this.$route.query.source,
      show: this.$route.query.show ?? '1',
      idName: [],
      maxPosition: 0,
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      hasParentDoc: false,
      docId: (() => {
        const id = this.$route.query.id;
        if (id.includes('_')) {
          const lastIndex = id.lastIndexOf('_');
          return id.slice(0, lastIndex);
        }
        return id;
      })(),

      // コンテンツデータ
      onlineShopServiceData: {
        title: '', // タイトル
        imageURL: '', //
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        position: 0, // 位置表示
        castStartDate: '', // 公開開始日
        castEndDate: '', // 公開終了日
        deleteDate: '', // 削除予定日
        draftFlag: false, // 下書き
        checkStatus: 'draft', // チェックステータス
        contentsURL: '',
        checkHistories: [
          {
            draft: {
              userName: '',
              updateDate: '',
            },
            checked: {
              userName: '',
              updateDate: '',
            },
            approved: {
              userName: '',
              updateDate: '',
            },
          },
        ],
      },
      isPublished: false, // 公開中かどうかのフラグ
      beforeCastEndDate: '', // 変更前の公開終了日時
      currentCheckStatus: 'draft', // DBに現在のcheckStatus
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
      visibleCheckStatusOptions: [],
    };
  },

  validations() {
    return onlineShopServiceValidation.update(this.onlineShopServiceData.checkStatus);
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-online-shop-service-edit');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 1; i <= 4; i++) {
      this.idName[i] = 'h1-online-shop-service-edit-' + i;
    }
    this.handleGetOnlineShopServiceInfo();
    this.getPositionOnlineShopServiceList();
  },
  mounted() {},
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.onlineShopServiceData);
      //  URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.contentsURL = encodeURI(payload.contentsURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      const id = this.id;
      if (id) {
        if (this.onlineShopServiceData.castEndDate) {
          const castEndDate = new Date(this.onlineShopServiceData.castEndDate);
          const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
          this.deleteDate = this.convertDateToString(deleteDate);
        }
        const formDataTmp = {
          id: id ?? '',
          title: payload.title ?? '',
          position: this.maxPosition ?? 0,
          imageFile: this.onlineShopServiceData.imageFile.binary ?? '',
          castStartDate: this.onlineShopServiceData.castStartDate ?? '',
          castEndDate: this.onlineShopServiceData.castEndDate ?? '',
          deleteDate: this.deleteDate ?? '',
          draftFlag: this.onlineShopServiceData.draftFlag ?? false,
          checkStatus: this.onlineShopServiceData.checkStatus ?? 'draft',
          imageURL: this.onlineShopServiceData.imageURL ?? '',
          contentsURL: encodeURI(this.onlineShopServiceData.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
          checkHistories: this.onlineShopServiceData.checkHistories ?? [],
        };
        await this.postRequest('/online-shop-service/edit', formDataTmp).then(res => {
          if (res.status === 'success') {
            this.$router.push({ name: 'OnlineShopService', query: { show: this.show } });
          } else {
            if (res.message) {
              this.showErrorModal(res.message);
            } else {
              this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス更新'));
            }
          }
        });
      } else {
        this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス更新'));
      }
    },
    handleUploadFileChange(type, file) {
      const reader = new FileReader();
      const data = this.onlineShopServiceData[type];

      reader.addEventListener(
        'load',
        () => {
          const [info, content] = reader.result.split(',');
          data.binary = content;
        },
        false
      );

      if (file[0]) {
        reader.readAsDataURL(file[0]);
        data.filename = file[0].name;
        data.size = file[0].size;
        this.agree = type === 'imageFile' && data.filename !== null;
      } else {
        // Clear file content
        data.file = null;
        data.filename = null;
        data.binary = null;
        data.size = null;
        if (type === 'imageFile') {
          this.agree = false;
          this.onlineShopServiceData.imageURL = '';
        }
      }
    },
    getPositionOnlineShopServiceList: function () {
      const data = {
        execUserID: this.execUserID,
      };
      return this.postRequest('/online-shop-service/get-list', data).then(response => {
        let maxPosition = 0;
        const nowDate = utils.currentDateTime();
        for (const k in response.data) {
          const deleteDate = response.data[k].deleteDate;
          const checkStatus = response.data[k].checkStatus;
          if (checkStatus === 'approved' && deleteDate > nowDate) {
            maxPosition = Math.max(maxPosition, response.data[k].position);
          }
        }
        maxPosition++;
        this.maxPosition = maxPosition;
        return maxPosition;
      });
    },
    handleGetOnlineShopServiceInfo() {
      const nowDate = utils.currentDateTime();
      const id = this.$route.query.id;
      if (id) {
        this.postRequest('/online-shop-service/get', { id })
          .then(e => {
            if (e.status === 'success' && e.data) {
              const data = e.data;
              const authInfo = this.$store.state.user.authInfo;
              if ((authInfo === 'writer' && data.checkStatus === 'draft') || authInfo !== 'writer') {
                this.onlineShopServiceData.title = data?.title || '';
                this.onlineShopServiceData.imageURL = data?.imageURL || '';
                (this.onlineShopServiceData.imageFile = {
                  file: e.data.imageURL ? e.data.imageURL : null,
                }),
                  (this.onlineShopServiceData.castStartDate = data?.castStartDate || '');
                this.onlineShopServiceData.castEndDate = data?.castEndDate || '';
                this.onlineShopServiceData.contentsURL = decodeURI(data?.contentsURL) || '';
                // 変更前の公開終了日時を保存
                this.beforeCastEndDate = data?.castEndDate || '';
                // 公開中の投稿か確認
                if (
                  data &&
                  data.checkStatus === 'approved' &&
                  data.castStartDate &&
                  data.castEndDate &&
                  data.castStartDate <= nowDate &&
                  nowDate <= data.castEndDate
                ) {
                  this.isPublished = true;
                  this.minDate = '';
                }

                if (this.isPublished) {
                  this.$router.push({ name: 'OnlineShopService' });
                  return;
                }

                this.hasParentDoc = data?.parentDocId ? true : false;

                // DBに現在のcheckStatusを保存
                this.currentCheckStatus = data?.checkStatus;
                // 現在のcheckStatusの値しだいでvisibleCheckStatusOptionsを変更する
                this.visibleCheckStatusOptions = this.selectVisibleCheckStatusOptions(data?.checkStatus || '');
                // DBから取得したcheckHistoriesが空配列のときは更新せず、初期値のcampaignData.checkHistoriesを用いる
                if (data?.checkHistories && data?.checkHistories.length > 0) {
                  this.onlineShopServiceData.checkHistories = data?.checkHistories;
                }
              } else {
                this.$router.push({ name: 'PermissionDenied' });
                return;
              }
            } else {
              this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス) 取得'));
            }
          })
          .catch(error => {
            console.error(error);
            this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス) 取得'));
          });
      }
    },
    handleCancelClick() {
      const id = this.id;
      const source = this.source;
      if (id && source === 'detail') {
        return this.$router.push({ name: 'OnlineShopServiceDetail', query: { id: id, show: this.show } });
      } else {
        return this.$router.push({ name: 'OnlineShopService', query: { show: this.show } });
      }
    },
    selectVisibleCheckStatusOptions(checkStatus) {
      if (checkStatus === 'draft') {
        return [
          { value: 'draft', label: '下書き' },
          { value: 'checked', label: 'チェック済み' },
        ];
      } else if (checkStatus === 'checked') {
        return [
          { value: 'draft', label: '下書き' },
          { value: 'approved', label: '承認済み' },
        ];
      } else if (checkStatus === 'approved') {
        return [{ value: 'draft', label: '下書き' }];
      }
      // イレギュラーは下書き相当
      return [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
      ];
    },
    backPageName() {
      const source = this.source;
      if (source === 'detail') {
        return '詳細画面';
      } else {
        return '一覧画面';
      }
    },
    backPage() {
      const source = this.source;
      if (source === 'detail') {
        return 'OnlineShopServiceDetail';
      } else {
        return 'OnlineShopService';
      }
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
