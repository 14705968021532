<template>
  <t-default-entry
    v-show="finishCheckAuthen"
    sub-heading="Login"
    heading="初回ログイン"
    :navigation-items="navigationItems"
    :class="componentClassName"
  >
    <loading :active="isLoading" :is-full-page="true" :height="100" :width="100"></loading>
    <div v-if="error" class="t-login-invited-user-set-password__alert">
      <a-alert color="error" size="small">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span data-testid="login-error-message-span" v-html="errorMessage"></span>
      </a-alert>
    </div>
    <form class="t-login-invited-user-set-password__form" @submit.prevent="handleFormSubmit">
      <div class="t-login-invited-user-set-password__group">
        <div class="t-login-invited-user-set-password__instruction">
          <a-heading tag="h4">パスワード変更</a-heading>
          <a-text>新しいパスワードを設定してください</a-text>
        </div>
        <m-form-input class="t-login__input" label="ユーザーID">
          <a-text-field
            id="userid"
            v-model="formData.userID"
            data-testid="invited-user-id-input"
            type="email"
            name=""
            placeholder="dummy@gmail.com"
          />
        </m-form-input>
        <m-form-input class="t-login-invited-user-set-password__input" label="初期パスワード">
          <a-text-field
            id="old-password"
            v-model="formData.old_password"
            data-testid="invited-user-old-password-input"
            type="password"
            placeholder="初期パスワード"
          />
        </m-form-input>
        <m-form-input class="t-login-invited-user-set-password__input" label="新しいパスワード">
          <a-text-field
            id="password"
            v-model="formData.password"
            data-testid="invited-user-new-password-first-input"
            type="password"
            placeholder="新しいパスワード"
          />
        </m-form-input>
        <m-form-input class="t-login-invited-user-set-password__input" label="新しいパスワード(確認)">
          <a-text-field
            id="password-conf"
            v-model="formData.passwordConfirm"
            data-testid="invited-user-new-password-second-input"
            type="password"
            placeholder="新しいパスワード"
          />
        </m-form-input>
        <div class="t-login-invited-user-set-password__password-condition">
          <a-text :text="passwordCondition" size="small" />
        </div>
      </div>
      <m-button-container class="t-login-invited-user-set-password__buttons">
        <a-button id="next" data-testid="invited-user-go-next-button" variant="block" submit="submit" color="primary"
          >次へ</a-button
        >
      </m-button-container>
    </form>
    <teleport to="body">
      <o-modal
        id="loginInvitedUserSetPassword_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal-login-invited-user-set-password-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-entry>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AAlert from '@/components/atoms/alert';
import AButton from '@/components/atoms/button';
import ATextField from '@/components/atoms/text-field';
import MButtonContainer from '@/components/molecules/button-container';
import MFormInput from '@/components/molecules/form-input';
import TDefaultEntry from '@/components/templates/default-entry';
import AHeading from '@/components/atoms/heading';
import AText from '@/components/atoms/text';
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';
import { customRequest } from '@/common/customRequest.js';
import { customValidate } from '@/common/customValidate.js';
import { messageContent } from '@/common/messageContent.js';
import Loading from 'vue-loading-overlay';
import { useVuelidate } from '@vuelidate/core';
import { loginValidation } from '@/validations/login.js';

export default {
  name: 't-login-invited-user-set-password',
  components: {
    AAlert,
    AButton,
    ATextField,
    MButtonContainer,
    MFormInput,
    TDefaultEntry,
    AHeading,
    AText,
    Loading,
  },
  mixins: [customValidate, customRequest],
  props: {
    class: {
      type: String,
      default: '',
    },
    navigationItems: {
      type: Array,
      default: new Array(0),
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      formData: {
        userID: '',
        old_password: '',
        password: '',
        passwordConfirm: '',
      },
      error: false,
      errorMessage: '',
      authFlag: false,
      passwordCondition: messageContent.passwordCondition,

      // modal
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,

      finishCheckAuthen: false,
      isLoading: false,
    };
  },

  validations() {
    return loginValidation.loginInvitedUserSetPassword;
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-login-invited-user-set-password');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created: function () {
    this.finishCheckAuthen = true;
    this.postRequest('/login/sessionCheck')
      .then(res => {
        if (res.session) {
          if (res.tempPwdFlag) {
            this.postRequest('/login/logout').then(data => {
              if (data.status === 'success') {
                window.location.href = '/login';
              } else {
                this.showErrorModal(messageContent.logoutOtherError);
              }
            });
          } else {
            this.$router.push({ name: 'Users' });
          }
        } else {
          this.finishCheckAuthen = true;
        }
      })
      .catch(err => {
        console.error(err);
        this.finishCheckAuthen = true;
        this.setError(messageContent.requireTry('パスワード変更'));
      });
  },
  methods: {
    ...mapActions(['updateLoginInfo']),
    async handleFormSubmit(event) {
      event.preventDefault();
      if (await this.validateForm()) {
        const payload = cloneDeep(this.formData);
        this.handleSubmitForm(payload);
      }
    },
    async validateForm() {
      this.resetError();

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.setError(errMsg);
        return false;
      }

      return true;
    },
    resetError() {
      this.error = false;
      this.errorMessage = '';
    },
    setError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    handleSubmitForm: async function (payload) {
      this.isLoading = true;
      // メールリンク認証が成功した場合のみパスワード変更を行う
      const formData = {
        password: payload.old_password,
        newPassword: payload.password,
        userID: payload.userID,
      };
      await this.postRequest('/login/changePassword', formData)
        .then(res => {
          if (res.session || res.status === 'success') {
            this.$router.push({ name: 'Users' });
          } else {
            if (res.status === 'resetPassword') {
              //仮パスワードの場合
              this.$router.push({ path: '/resettemppassword' });
            } else if (res.status === 'loginFailed') {
              this.isLoading = false;
              this.errorMessage = res.msg;
              this.setError(this.errorMessage);
            } else {
              this.isLoading = false;
              this.errorMessage = messageContent.loginOtherError;
              this.setError(this.errorMessage);
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.isLoading = false;
          this.errorMessage = messageContent.loginOtherError;
          this.error = true;
        });
      // handleCheckAuthCodeで画面遷移済みのため、if文の条件がtrueとならない場合は処理無し
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
