export const customRequest = {
  data() {
    return {
      baseURL: '',
    };
  },
  methods: {
    postRequest: function (path, data, header) {
      const defaultHeader = {
        'Content-Type': 'application/json',
      };
      if (header) {
        for (const [key, value] of Object.entries(header)) {
          defaultHeader[key] = header[key];
        }
      }
      if (!data) data = {};
      data._csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      if (!data._csrf) {
        throw new Error('CSRF Token Error');
      }
      //Browser will automatically set header later. Config header just avoid it become application/json when upload file
      if (defaultHeader['Content-Type'] === 'multipart/form-data') delete defaultHeader['Content-Type'];
      return fetch(`${this.baseURL}${path}`, {
        method: 'POST',
        body: defaultHeader['Content-Type'] === 'application/json' ? JSON.stringify(data) : data,
        headers: defaultHeader,
      }).then(async response => {
        if (response.status === 401 || response.status === 403) {
          //session expired
          if (this.$route.path !== '/login') {
            return (window.location.href = '/login');
          }
        }
        if (response.status === 404) {
          throw 404;
        }
        if (!response.ok) {
          throw new Error(path + ' response was not ok.');
        } else {
          const resJson = await response.json();
          if (resJson.session === false || resJson.status === 'expired') {
            if (this.$route.path !== '/login' && this.$route.path !== '/resettemppassword') {
              return (window.location.href = '/login');
            }
          }

          replaceEscapeCharacterInObj(resJson.data);

          if (resJson.message) {
            let errMsg = '';
            if (typeof resJson.message === 'string') {
              errMsg = resJson.message;
            } else {
              resJson.message.map(item => (errMsg += item.msg + '<br>'));
            }
            resJson.message = errMsg;
          }

          return resJson;
        }
      });
    },
  },
};

// Replace escape character values with actual values in object recursively
const replaceEscapeCharacterInObj = obj => {
  if (typeof obj === 'object') {
    for (const keys in obj) {
      if (typeof obj[keys] === 'object') {
        replaceEscapeCharacterInObj(obj[keys]);
      } else if (Array.isArray(obj[keys])) {
        obj[keys].forEach(function (item, index) {
          obj[keys][index] = replaceSpecialCharacter(obj[keys][index]);
        });
      } else {
        obj[keys] = replaceSpecialCharacter(obj[keys]);
      }
    }
  }
};

const replaceSpecialCharacter = string => {
  if (typeof string !== 'string') return string;
  return string
    .replaceAll('%%lt%%', '<')
    .replaceAll('%%gt%%', '>')
    .replaceAll('%%eq%%', '=')
    .replaceAll('%%q%%', '"')
    .replaceAll('%%n%%', '\n')
    .replaceAll('%%r%%', '\r')
    .replaceAll('%%t%%', '\t');
};
