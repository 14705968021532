<template>
  <div :class="componentClassName">
    <slot></slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'm-navigation',
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-navigation');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
