<template>
  <t-default-layout active-nav-id="online-shop-service">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="OnlineShopService" />
      <form
        ref="form"
        class="t-online-shop-service-add__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-online-shop-service-add__fields">
          <m-section>
            <m-action-card :id="idName[1]" title="詳細設定" header-border no-header-margin icon="clock">
              <div class="t-online-shop-service-add__fields">
                <div id="t-online-shop-service-add-uploading-file" class="t-online-shop-service-add__row">
                  <div class="t-online-shop-service-add__col-1">
                    <div class="t-online-shop-service-add__fields">
                      <m-form-input>
                        <a-upload-field
                          id="upload-image"
                          v-model="onlineShopServiceData.imageFile.file"
                          test-id-text="add-online-shop-service-image"
                          name="imageFile"
                          class="t-online-shop-service-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange(onlineShopServiceData.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-online-shop-service-add__col-2">
                    <div class="t-online-shop-service-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="onlineShopServiceData.title"
                          data-testid="add-online-shop-service-title-input"
                          type="text"
                          maxlength="50"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
                <div class="t-online-shop-service-add__row">
                  <a-text id="t-online-shop-service-add-importantFlag__a-text"> 外部Webサイト</a-text>
                  <m-form-input>
                    <a-apply-text-field
                      id="t-online-shop-service-add__contents-url"
                      v-model="onlineShopServiceData.contentsURL"
                      class="t-online-shop-service-add__contents-url"
                      :disabled="disabled"
                      data-testid="t-online-shop-service-add-url-input"
                      type="text"
                      maxlength="500"
                      placeholder="URL入力"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-online-shop-service-add__fields">
                <div id="t-online-shop-service-add-cast-start-date-day__row" class="t-online-shop-service-add__row">
                  <a-text id="t-online-shop-service-add-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-online-shop-service-add-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="onlineShopServiceData.castStartDate"
                      test-id-text="add-online-shop-service-publish-start"
                      :min-date="minDate"
                      date-placeholder="公開日"
                      time-placeholder="00:00"
                      mode="dateTime"
                    />
                  </m-form-input>
                </div>
                <div class="t-online-shop-service-add__row">
                  <a-text id="t-online-shop-service-add-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-online-shop-service-add-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="onlineShopServiceData.castEndDate"
                      test-id-text="add-online-shop-service-publish-end"
                      :min-date="minDate"
                      date-placeholder="公開終了日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-online-shop-service-add__fields__pushtype">
                <m-form-input id="t-online-shop-service-add__fields__pushtype">
                  <a-select-field
                    id="t-online-shop-service-add__fields__pushtype__a-select-field"
                    v-model="onlineShopServiceData.checkStatus"
                    data-testid="add-online-shop-service-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="add-online-shop-service-save-button"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="online-shop-bannersCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { cloneDeep } from 'lodash';

export default {
  name: 't-online-shop-service-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    AUploadField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,

      // コンテンツデータ
      onlineShopServiceData: {
        title: '', // タイトル
        imageURL: '', // 画像の公開URL
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        position: 0, // 順序
        castStartDate: '', // 公開開始日
        castEndDate: '', // 公開終了日
        deleteDate: '', // 削除予定日
        draftFlag: true, // 下書き
        checkStatus: 'draft', // 作成時のチェックステータスは下書き
        contentsURL: '', // 遷移先URL
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
    };
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-online-shop-service-add');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 1; i <= 4; i++) {
      this.idName[i] = 'h1-online-shop-service-add-' + i;
    }
  },
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.onlineShopServiceData);
      //  URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.contentsURL = encodeURI(payload.contentsURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      if (this.onlineShopServiceData.castEndDate) {
        const castEndDate = new Date(this.onlineShopServiceData.castEndDate);
        const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
        this.deleteDate = this.convertDateToString(deleteDate);
      }
      const formDataTmp = {
        title: payload.title ?? '',
        imageFile: this.onlineShopServiceData.imageFile.binary ?? '',
        position: this.onlineShopServiceData.position ?? 0,
        castStartDate: this.onlineShopServiceData.castStartDate ?? '',
        castEndDate: this.onlineShopServiceData.castEndDate ?? '',
        deleteDate: this.deleteDate ?? '',
        draftFlag: this.onlineShopServiceData.draftFlag ?? false,
        checkStatus: this.onlineShopServiceData.checkStatus ?? '',
        imageURL: this.onlineShopServiceData.imageURL ?? '',
        contentsURL: encodeURI(this.onlineShopServiceData.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/online-shop-service/add', formDataTmp).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'OnlineShopService' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス）作成'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleUploadFileChange(fileObj) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          fileObj.binary = content;
        },
        false
      );
      if (fileObj.file[0]) {
        reader.readAsDataURL(fileObj.file[0]);
        fileObj.filename = fileObj.file[0].name;
        fileObj.size = fileObj.file[0].size;
      } else {
        // clear file content
        fileObj.file = null;
        fileObj.filename = null;
        fileObj.binary = null;
        fileObj.size = null;
      }
    },
    handleChange() {},
    handleCancelClick() {
      this.$router.push({ name: 'OnlineShopService' });
    },

    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
