<template>
  <div :class="componentClassName">
    <div id="userStatus" data-testid="user-status-open-button" class="m-current-user-status__body" @click="handleClick">
      <div class="m-current-user-status__avatar">
        <a-avatar :active="online" />
      </div>
      <div class="m-current-user-status__user-info">
        <div
          id="currentUserStatus-user-name"
          data-testid="current-user-name-display"
          class="m-current-user-status__user-name"
        >
          {{ currentUserName }}
        </div>
        <div data-testid="current-user-department-display" class="m-current-user-status__status">
          {{ currentDepartmentInfo }}
        </div>
      </div>
      <div class="m-current-user-status__caret">
        <a-icon :icon="iconShape" :color="iconColor" />
      </div>
    </div>
    <div class="m-current-user-status__option-container">
      <ul class="m-current-user-status__options">
        <li class="m-current-user-status__logout">
          <a-button id="logout" data-testid="logout-button" type="text" @click="logout">ログアウト</a-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AAvatar from '@/components/atoms/avatar';
import AButton from '@/components/atoms/button';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'm-current-user-status',
  components: {
    AAvatar,
    AButton,
    AIcon,
  },
  props: {
    online: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      required: true,
    },
    editProfileUrl: {
      type: String,
      default: '#',
    },
    logoutUrl: {
      type: String,
      default: '#',
    },
    departmentInfo: {
      type: String,
      default: '',
    },
    handleLogout: {
      type: Function,
      default: () => {},
    },
  },
  emits: [],
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-current-user-status',
        'online',
        'during-business-hour',
        this.opened ? 'opened' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    indicatorClassName() {
      return mapModifiers('m-current-user-status__indicator', this.online ? 'active' : 'inactive');
    },
    switchIndicatorClassName() {
      return mapModifiers('m-current-user-status__indicator', this.online ? 'inactive' : 'active');
    },
    iconShape() {
      return this.opened ? 'caret-up' : 'caret-down';
    },
    iconColor() {
      return 'white';
    },
    currentUserName() {
      return this.userName?.replace('_', ' ');
    },
    currentDepartmentInfo() {
      return this.departmentInfo?.replace('_', ' ');
    },
  },
  watch: {
    businessHour(val, oldVal) {
      this.businessHourStatus = val;
    },
  },
  methods: {
    handleClick() {
      this.opened = !this.opened;
    },
    logout: function () {
      this.handleLogout();
    },
  },
};
</script>
