import { helpers, required } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { utils } from '@/common/utils.js';

export const appReviewSettingValidation = {
  data: {
    showStartDate: {
      required: helpers.withMessage(messageContent.requireInput('表示開始日時'), required),
      validateSetPastDateTime: helpers.withMessage(messageContent.setShowStartDateToPastDateTime, value => {
        if (!value) return true;
        const nowDate = utils.currentDateTime();
        return value > nowDate;
      }),
    },
  },
};
