<template>
  <div :id="selectFieldId" :data-testid="dataTestid" :class="componentClassName">
    <div :id="vueSelectID" class="a-select-field__select">
      <vue-select
        v-if="options"
        :id="id"
        v-model="currentItem"
        label-by="label"
        value-by="value"
        search-placeholder=""
        :options="options"
        :visible-options="visibleOptions"
        :min="1"
        :maxlength="maxlength"
        :track-by="option => option.value"
        :placeholder="placeholder"
        :searchable="searchable"
        :close-on-select="true"
        :clear-on-select="true"
        :clear-search-on-blur="clearSearchOnBlur"
        :disabled="disabled"
        :tabindex="tabindex"
        :replace-instance-uid="id"
        :open-direction="openDirection"
        @keydown.space.prevent="handleKeySpace"
        @keydown.down="handleOpened"
        @keydown.up="handleOpened"
        @focus="handleOpened"
        @blur="handleClosed"
        @search:input="handleSearchInput"
        @search:focus="handleSearchFocus"
        @search:blur="handleSearchBlur"
        @update:model-value="handleModelValueUpdated"
      />
    </div>
    <div v-if="showArrow" class="a-select-field__icon-container">
      <a-icon icon="caret-down" :color="iconColor" />
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon/index.vue';
import VueSelect from 'vue-next-select';
import 'vue-next-select/dist/index.css';
import SimpleBar from 'simplebar';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'a-select-field',
  components: {
    AIcon,
    VueSelect,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String, null],
      default: '',
    },
    modelValue: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    vOptions: {
      type: Array,
      default: null,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    tabopen: {
      type: Number,
      default: null,
    },
    customErrorMessage: {
      type: String,
      default: '',
    },
    clearSearchOnBlur: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: 'bottom',
    },
    dataTestid: {
      type: String,
      default: '',
    },
  },
  emits: ['selected', 'change', 'update:modelValue', 'search'],
  data() {
    return {
      currentItem: null,
      currentValue: '',
      isOpened: false,
      tabindex: 0,
      searchInput: '',
      selectFieldId: 'selectField-' + this.id,
      currentEvent: null,
      inputBox: null,
      vueSelectID: `vue-select-${uuidv4()}`,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-select-field',
        this.disabled ? 'disabled' : '',
        this.isOpened ? 'opened' : '',
        this.currentValue ? 'has-value' : '',
        this.showArrow ? '' : 'no-arrow',
        this.searchable ? 'searchable' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    iconColor() {
      return this.disabled ? 'black' : 'orange';
    },
    visibleOptions() {
      if (this.vOptions) {
        return this.vOptions;
      }
      if (this.searchable) {
        const re = new RegExp(this.searchInput.replace(/[.*+?^${}()|[]\];/gi, '$&'));
        return this.options.filter(option => re.test(option.label));
      }
      return this.options;
    },
  },
  watch: {
    value(val, oldVal) {
      this.setCurrentValue(val);
    },
    modelValue: {
      handler(val, oldVal) {
        this.setCurrentValue(val);
      },
      deep: true,
    },
    visibleOptions(val) {
      if (this.searchInput && !val.length && this.vueSelectID) {
        this.inputBox = document.querySelector(`#${this.vueSelectID} .vue-input input`);
        this.inputBox.setCustomValidity(this.customErrorMessage);
        this.inputBox.reportValidity();
        this.hideDropdown();
      }
      if (val.length && this.inputBox) {
        this.inputBox.setCustomValidity('');
        this.inputBox.reportValidity();
        this.inputBox = null;
        this.showDropdown();
      }
    },
  },
  mounted() {
    if (this.tabopen === null) {
      if (!this.searchable) this.tabindex = -1;
    } else {
      this.tabindex = this.tabopen;
    }

    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
    // attach data-simplebar to vue-dropdown
    const dd = this.$el.querySelector('.vue-dropdown');
    if (dd) {
      new SimpleBar(dd);
    }
  },
  methods: {
    setCurrentValue(val) {
      this.currentItem = val;
      this.currentValue = val;
    },
    handleOpened(event) {
      this.isOpened = true;
    },
    handleKeySpace(event) {
      if (this.isOpened) {
        const target = event.target;
        const attr = thisEle
          ? target.getAttribute('aria-activedescendant')
          : target.closest('.vue-select').getAttribute('aria-activedescendant');
        const thisEle = document.getElementById(attr);
        if (thisEle) thisEle.click();
        this.isOpened = false;
      } else this.isOpened = true;
    },
    handleClosed(event) {
      this.isOpened = false;
    },
    handleSearchInput(event) {
      this.currentEvent = event;
      this.searchInput = event.target.value;
      this.$emit('search', this.searchInput);
    },
    handleSearchFocus() {
      this.searchInput = '';
      this.currentEvent = null;
      this.showDropdown();
    },
    handleSearchBlur() {
      if (this.clearSearchOnBlur && this.currentEvent) {
        // Clear search value
        this.currentEvent.target.value = '';
        if (this.inputBox) {
          this.inputBox.setCustomValidity('');
          this.inputBox.reportValidity();
        }
      }
      // check if searchInput is exists in options
      for (let i = 0; i < this.options.length; i += 1) {
        const item = this.options[i];
        if (item) {
          if (item.value === this.searchInput) {
            this.setCurrentValue(item.value);
            this.emitEvent();
            break;
          }
        }
      }
      this.hideDropdown();
    },
    emitEvent() {
      this.$emit('update:modelValue', cloneDeep(this.currentValue));
      this.$emit('change', cloneDeep(this.currentValue));
    },
    handleModelValueUpdated() {
      this.currentValue = this.currentItem;
      this.emitEvent();
      this.handleClosed();
      this.hideDropdown();
    },
    hideDropdown() {
      if (!this.searchable) return;
      const element = document.querySelector(`#${this.vueSelectID} .vue-dropdown`);
      if (element) element.style.display = 'none';
    },
    showDropdown() {
      if (!this.searchable) return;
      const element = document.querySelector(`#${this.vueSelectID} .vue-dropdown`);
      if (element && element.style.display === 'none') element.style.display = 'unset';
    },
  },
};
</script>
