<template>
  <t-default-layout ref="defaultLayout" active-nav-id="online-shop-support">
    <div id="t-online-shop-support-component" :class="componentClassName">
      <div class="t-online-shop-support-heading-line">
        <a-page-heading class="t-online-shop-support-heading-item">ショップ（お悩み・課題）</a-page-heading>
        <a-button
          id="t-online-shop-support-add-button"
          data-testid="online-shop-support-button"
          class="t-online-shop-support-heading-item"
          variant="icon"
          color="primary"
          icon="plus"
          icon-color="white"
          @click="handleOnlineShopSupportAdd"
        />
      </div>
      <m-section>
        <div class="t-online-shop-support-tabgroup">
          <ul class="t-online-shop-support-tabnav">
            <li
              data-testid="unpublish-online-shop-support-display-button"
              :class="{ selected: show == '1' }"
              @click="select('1')"
            >
              未公開
            </li>
            <li
              data-testid="open-online-shop-support-display-button"
              :class="{ selected: show == '2' }"
              @click="select('2')"
            >
              公開中
            </li>
            <li
              data-testid="end-online-shop-support-display-button"
              :class="{ selected: show == '3' }"
              @click="select('3')"
            >
              公開終了
            </li>
          </ul>
          <m-action-card title="" icon="">
            <m-data-table-services
              ref="dataTable"
              id-text="online-shop-support-info-display"
              :empty-text="dataTableEmptyText"
              :fit="true"
              :data="displayedTableData()"
              :border="true"
              @rowClick="handleRowClick($event.row.documentID)"
              @positionUpdate="handlePositionUpdate($event)"
            >
              <a-data-table-column prop="id" label="タイトル" align="center">
                <template #default="scope">
                  <div v-if="scope.row.imageURL">
                    <img
                      :src="scope.row.imageURL"
                      alt="Logo"
                      class="t-online-shop-support-record-logo"
                      width="70"
                      height="70"
                      :draggable="false"
                    />
                  </div>
                  <div v-else>
                    <a-icon
                      class="t-online-shop-support-record-logo"
                      size="70px"
                      icon="dummy-picture"
                      color="white"
                    ></a-icon>
                  </div>
                  <div class="t-online-shop-support-record-text-column">
                    <a-text
                      class="t-online-shop-support-record-title"
                      :data-testid="'online-shop-support-title-display-' + scope.index"
                      size="medium"
                      weight="bold"
                      >{{ scope.row.title ? scope.row.title : '　　' }}</a-text
                    >
                    <a-text
                      class="t-online-shop-support-record-info"
                      :data-testid="'online-shop-support-type-draft-author-update-display-' + scope.index"
                    >
                      <span>
                        {{ scope.row.checkStatus ? checkStatus2ViewStatus(scope.row.checkStatus) + '：' : '　　' }}
                        {{
                          scope.row.checkHistories && scope.row.checkHistories.length > 0
                            ? scope.row.checkHistories[scope.row.checkHistories.length - 1][
                                assignIrregularCheckStatus(scope.row.checkStatus)
                              ].userName + '　　'
                            : '　　'
                        }}
                        更新日：{{ scope.row.updateDate }}
                      </span></a-text
                    >
                    <a-text
                      v-show="scope.row.childrenDocIdsStr || scope.row.parentDocId"
                      class="t-online-shop-support-record-info"
                      weight="bold"
                    >
                      <span style="font-weight: bold">
                        {{ scope.row.childrenDocIdsStr ? `編集先：${scope.row.childrenDocIdsStr}` : '' }}
                        {{ scope.row.parentDocId ? `編集元：${scope.row.parentDocId}` : '' }}
                      </span>
                    </a-text>
                  </div>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="125" prop="documentID" label="ドキュメントID" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.documentID }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="125" prop="timestamp" label="公開日" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{
                    scope.row.castStartDate ? scope.row.castStartDate : '-'
                  }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="125" prop="timestamp" label="公開終了日" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.castEndDate ? scope.row.castEndDate : '-' }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="75">
                <template #default="scope">
                  <button
                    class="t-online-shop-support-three-dot-leader"
                    :data-testid="'online-shop-support-edit-delete-show-' + scope.index"
                    type="button"
                    @click.stop="showModal($event, scope.row)"
                  >
                    <span class="t-online-shop-support-dot"></span>
                  </button>
                </template>
              </a-data-table-column>
            </m-data-table-services>
          </m-action-card>
        </div>
      </m-section>
    </div>
    <m-modal
      v-show="modalVisible"
      :documentid="selectedDocumentID"
      :is-ended="checkStatus !== 'approved' ? false : isEnded"
      :is-published="checkStatus !== 'approved' ? false : isPublished"
      @handle-confirm-delete="handleConfirmOnlineShopSupportDelete(selectedDocumentID, selectedTitle)"
      @handle-edit="handleOnlineShopSupportEdit(selectedDocumentID)"
      @handle-edit-return-unpublished="handleEditReturnUnpublished(selectedDocumentID)"
      @handle-edit-keep-published="handleEditKeepPublished(selectedDocumentID)"
      @handle-modal-close="handleModalClose()"
    ></m-modal>
    <!-- Info Modal -->
    <teleport to="body">
      <o-modal
        id="updatePosition_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        @closeModal="getOnlineShopSupportList()"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
        <span v-html="resultModalContent" />
        <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="t-online-shop-support-remove-modal-close-button"
            data-testid="online-shop-support-delete-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="getOnlineShopSupportList()"
          />
          <a-button
            id="t-online-shop-support-delete-button"
            data-testid="online-shop-support-delete-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="positionUpdate(eventData)"
          />
        </template>
      </o-modal>
    </teleport>
    <teleport to="body">
      <o-modal
        id="t-online-shop-support-remove-modal"
        v-model:visible="confirmModalVisible"
        class="t-online-shop-support-remove-modal"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ modalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="modalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="t-online-shop-support-remove-modal-close-button"
            data-testid="online-shop-support-delete-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleModalClose"
          />
          <a-button
            id="t-online-shop-support-delete-button"
            data-testid="online-shop-support-delete-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleOnlineShopSupportDelete(selectedDocumentID)"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>
<script>
import { mapModifiers } from '@/libs/component';
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import MModal from '@/components/molecules/modal';
import OModal from '@/components/organisms/modal';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import AIcon from '@/components/atoms/icon';
import { utils } from '@/common/utils.js';

export default {
  name: 't-online-shop-support',
  components: {
    TDefaultLayout,
    APageHeading,
    MModal,
    OModal,
    AIcon,
  },
  mixins: [customRequest, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  emits: [],
  data() {
    return {
      documentData: '',
      tableData: [],
      eventData: {},
      tableDataUnpublished: [],
      tableDataPublished: [],
      tableDataEnded: [],
      show: '',
      execUserID: this.$store.state.user.userID,
      modalVisible: false,
      confirmModalVisible: false,
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      modalTitle: '',
      modalContent: '',
      selectedDocumentID: '',
      selectedCategory: '',
      selectedTitle: '',
      nowDate: '',
      draftFlag: false,
      isEnded: false,
      isPublished: false,
      dragging: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-online-shop-support');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  watch: {},
  created() {
    this.getOnlineShopSupportList();
    this.show = this.$route.query.show ?? '1';
  },
  async mounted() {},
  methods: {
    handlePositionUpdate(event) {
      this.eventData = event;
      if (this.displayedTableData() === this.tableDataPublished) {
        this.showInfoModal(messageContent.updatePosition(this.eventData.newData.title));
      } else {
        this.getOnlineShopSupportList();
      }
    },
    select: function (num) {
      this.show = num;
    },
    getOnlineShopSupportList: function () {
      this.tableDataEnded = [];
      this.tableDataPublished = [];
      this.tableDataUnpublished = [];
      const data = {
        execUserID: this.execUserID,
      };
      this.postRequest('/online-shop-support/get-list', data).then(e => {
        // 取得したお知らせを3つの配列に振分け
        for (const k in e.data) {
          const fileInfo = {
            documentID: k,
            title: e.data[k].title,
            castStartDate: e.data[k].castStartDate,
            castEndDate: e.data[k].castEndDate,
            draftFlag: e.data[k].draftFlag,
            createUserName: e.data[k].createUserName,
            updateDate: e.data[k].updateDate,
            position: e.data[k].position,
            imageURL: e.data[k].imageURL,
            checkStatus: e.data[k].checkStatus,
            checkHistories: e.data[k].checkHistories,
          };
          const castStartDate = e.data[k].castStartDate;
          const castEndDate = e.data[k].castEndDate;
          this.nowDate = utils.currentDateTime();
          if (castStartDate <= this.nowDate && this.nowDate <= castEndDate && e.data[k].checkStatus === 'approved') {
            fileInfo.childrenDocIds = e.data[k].childrenDocIds ? Object.values(e.data[k].childrenDocIds) : [];
            fileInfo.parentDocId = e.data[k].parentDocId ? e.data[k].parentDocId : '';
            if (fileInfo.childrenDocIds.length > 0) {
              let str = fileInfo.childrenDocIds[0];
              for (let i = 1; i < fileInfo.childrenDocIds.length; i++) {
                str += `, ${fileInfo.childrenDocIds[i]}`;
              }
              fileInfo.childrenDocIdsStr = str;
            }
            // 下書き中ではなく、公開期間内の場合
            this.tableDataPublished.push(fileInfo);
          } else if (castEndDate < this.nowDate && e.data[k].checkStatus === 'approved') {
            // 公開期限後の場合
            this.tableDataEnded.push(fileInfo);
          } else {
            fileInfo.parentDocId = e.data[k].parentDocId ? e.data[k].parentDocId : '';
            // 下書き中、または公開期限前の場合
            this.tableDataUnpublished.push(fileInfo);
          }
        }

        this.tableDataUnpublished = utils.tableDataUnpublishedSort(this.tableDataUnpublished);
        this.tableDataEnded.sort(utils.latestCastStartDateLatestUpdateDateSortOrderRule);
        this.tableDataPublished = utils.tableDataServicePublishedSort(this.tableDataPublished, 10);
        // 10件を超えてコンテンツは背景色を白地ではなくグレーで表示する
        let count = 0;
        for (const item of this.tableDataPublished) {
          count++;
          if (item.childrenDocIds.length > 0) {
            item.isOrangeBg = true;
          }
          if (count > 5) item.isGreyBg = true;

          if (count > 5 && item.childrenDocIds.length > 0) {
            item.isOrangeBg = false;
          }
        }
        for (const item of this.tableDataUnpublished) {
          if (item.parentDocId) {
            item.isOrangeBg = true;
          }
        }
      });
    },
    displayedTableData() {
      if (this.show === '1') {
        this.tableData = this.tableDataUnpublished;
      } else if (this.show === '2') {
        this.tableData = this.tableDataPublished;
      } else if (this.show === '3') {
        this.tableData = this.tableDataEnded;
      }
      return this.tableData;
    },
    handleOnlineShopSupportAdd() {
      this.$router.push({
        path: '/online-shop-support/add',
        query: { execUserID: this.execUserID },
      });
    },
    showModal(e, row) {
      const authInfo = this.$store.state.user.authInfo;
      const shouldShowModal = this.shouldShowModal(authInfo, row);

      if (shouldShowModal) {
        this.selectedDocumentID = row.documentID;
        this.selectedTitle = row.title;
        this.isEnded = row.castEndDate < this.nowDate;
        this.isPublished = !row.parentDocId && row.castStartDate <= this.nowDate && this.nowDate <= row.castEndDate;
        this.checkStatus = row.checkStatus;

        const elem = document.getElementById('m-modal-content');
        const topPosition = e.pageY - window.pageYOffset;

        if (window.innerHeight > (topPosition - window.pageYOffset) * 2) {
          elem.style.top = topPosition + 'px';
        } else {
          elem.style.top = topPosition - elem.clientHeight + 'px';
        }

        this.modalVisible = true;
      }
    },

    shouldShowModal(authInfo, row) {
      return (authInfo === 'writer' && row.checkStatus === 'draft') || authInfo !== 'writer';
    },
    handleConfirmOnlineShopSupportDelete(documentid, title) {
      this.modalVisible = false;
      this.confirmModalVisible = true;
      this.modalTitle = 'Info';
      this.selectedDocumentID = documentid;
      this.selectedTitle = title;
      this.modalContent = messageContent.comfirmDelete(title);
    },
    handleOnlineShopSupportDelete(documentid) {
      this.handleModalClose();
      const data = {
        execUserID: this.execUserID,
        documentID: documentid,
      };
      this.postRequest('/online-shop-support/delete', data).then(e => {
        this.$router.push({ name: 'OnlineShopSupport', query: { show: this.show } }).then(() => {
          this.$router.go();
        });
      });
    },
    handleOnlineShopSupportEdit(documentid) {
      this.$router.push({
        path: '/online-shop-support/edit',
        query: { id: documentid, source: 'list', show: this.show },
      });
    },
    handleEditReturnUnpublished(documentid) {
      const data = {
        id: documentid,
      };
      this.postRequest('/online-shop-support/edit-return-unpublished', data).then(e => {
        this.$router.push({ name: 'OnlineShopSupport' }).then(() => {
          this.$router.go();
        });
      });
    },
    handleEditKeepPublished(documentid) {
      const data = {
        id: documentid,
      };
      this.postRequest('/online-shop-support/edit-keep-published', data).then(e => {
        this.$router.push({ name: 'OnlineShopSupport' }).then(() => {
          this.$router.go();
        });
      });
    },
    handleRowClick(documentid) {
      this.$router.push({
        path: '/online-shop-support/detail',
        query: { id: documentid, show: this.show },
      });
    },
    positionUpdate(data) {
      if (this.displayedTableData() === this.tableDataPublished) {
        const formData = {
          sourceDocumentID: data.newData.documentID,
          sourcePosition: data.newData.position,
          destinationDocumentID: data.oldData.documentID,
          destinationPosition: data.oldData.position,
          listSupport: this.tableDataPublished.map(item => ({ documentID: item.documentID, position: item.position })),
        };
        this.postRequest('/online-shop-support/update-position', formData).then(res => {
          if (res.status === 'success') {
            this.getOnlineShopSupportList();
            this.eventData = {};
          } else {
            if (res.message) {
              this.showErrorModal(res.message);
            } else {
              this.showErrorModal(messageContent.requireTry('ショップ（お悩み・課題）位置更新'));
            }
            this.eventData = {};
          }
        });
      } else {
        this.getOnlineShopSupportList();
        this.eventData = {};
      }
    },
    handleModalClose() {
      this.modalVisible = false;
      this.confirmModalVisible = false;
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
      this.selectedDocumentID = '';
      this.selectedTitle = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
    checkStatus2ViewStatus(checkStatus) {
      const statusMap = {
        approved: '承認済み',
        checked: 'チェック済み',
        draft: '下書き',
      };
      return statusMap[checkStatus] || '';
    },
    assignIrregularCheckStatus(checkStatus) {
      const validStatuses = ['draft', 'checked', 'approved'];
      return validStatuses.includes(checkStatus) ? checkStatus : 'draft';
    },
  },
};
</script>
