<template>
  <div v-if="message" :class="componentClassName">
    <div class="a-error-balloon__message">{{ message }}</div>
    <svg
      class="a-error-balloon__caret-container"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
    >
      <path class="a-error-balloon__caret" d="M8,0l8,10H0Z" transform="translate(16 11) rotate(180)" fill="#eb4c2f" />
    </svg>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-error-balloon',
  props: {
    message: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-error-balloon');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
