<template>
  <div :class="componentClassName">
    <slot>{{ label }}</slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-tag',
  props: {
    color: {
      type: String,
      default: 'orange',
      validator(value) {
        return value.match(/(orange|grey|red)/);
      },
    },
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-tag',
        this.color ? `color-${this.color}` : '',
        this.outline ? 'outlined' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
