<script lang="jsx">
/* eslint-disable react/react-in-jsx-scope */
import { mapModifiers } from '@/libs/component';
import TableLayoutObserver from '@/libs/table-layout-observer';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'a-data-table-header',
  components: {
    AIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: new Array(0),
    },
  },
  data() {
    return {
      showFilter: {},
      filterActivated: {},
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-data-table-header');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    headerRowClass(index) {
      const column = this.columns[index];
      if (column) {
        const { id, type, align, filterable, class: className } = column;
        const isFiltered = this.showFilter[index] || this.filterActivated[index];
        const headerClassName = mapModifiers(
          'a-data-table-header__column',
          type,
          filterable ? 'filter' : '',
          isFiltered ? 'filtered' : ''
        );
        return `${headerClassName} ${align} ${id} ${className}`.trim();
      }
      return '';
    },
    handleShowFilter(index) {
      if (typeof this.showFilter[index] === 'undefined') {
        this.showFilter[index] = false;
      }
      this.showFilter[index] = !this.showFilter[index];
    },
    hideFilter(index) {
      this.showFilter[index] = false;
    },
    handleActivateFilter(index) {
      if (typeof this.filterActivated[index] === 'undefined') {
        this.filterActivated[index] = false;
      }
      this.filterActivated[index] = !this.filterActivated[index];
    },
    activateFilter(index) {
      this.filterActivated[index] = true;
    },
    deactivateFilter(index) {
      this.filterActivated[index] = false;
    },
  },
  render() {
    const self = this;
    return (
      <table className={self.componentClassName} cellSpacing="0" cellPadding="0" border="0">
        <colgroup>
          {this.columns.map(column => {
            return <col name={column.id} key={column.id} />;
          })}
        </colgroup>
        <thead>
          <tr className="a-data-table-header__row">
            {this.columns.map((column, cellIndex) => {
              return (
                <th className={this.headerRowClass(cellIndex)} key={cellIndex}>
                  {column.renderHeader.call(this, {
                    column,
                    index: cellIndex,
                    handleShowFilter: self.handleShowFilter,
                  })}
                </th>
              );
            })}
          </tr>
        </thead>
      </table>
    );
  },
};
</script>
