<template>
  <div v-show="active" :id="'a-tab-pane-' + paneName" :class="componentClassName">
    <slot></slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-tab-pane',
  props: {
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
    label: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-tab-pane');
      return `${baseClassName} ${this.class}`.trim();
    },
    active() {
      let currentName = '';
      if (this.$parent && this.$parent.currentName) {
        currentName = this.$parent.currentName;
      }
      return currentName === (this.name || this.index);
    },
    paneName() {
      return this.name || this.index;
    },
  },
  mounted() {
    if (this.$parent && this.$parent.addPane) {
      this.$parent.addPane(this);
    }
  },
  unmounted() {
    if (this.$parent && this.$parent.removePane) {
      this.$parent.removePane(this);
    }
  },
};
</script>
