<template>
  <div :class="componentClassName">
    <div class="o-global-footer__links">
      <slot></slot>
    </div>
    <div class="o-global-footer__copy">Copyright&copy; NTT Communications Corporation All Rights Reserved.</div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'o-global-footer',
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('o-global-footer');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
