<template>
  <div :class="componentClassName">
    <div class="m-user-selection-table__header">
      <div class="m-user-selection-table__header-title">{{ label }}</div>

      <div v-if="showUserCount" class="m-user-selection-table__header-status">
        <span class="m-user-selection-table__header-status-count">{{ selectedUsers.length }}</span
        >人選択中 / {{ userList.length }}人
      </div>
    </div>
    <div class="m-user-selection-table__table">
      <m-data-table
        variant="row"
        :empty-text="noDataMessage"
        :fit="true"
        :data="userList"
        :show-header="false"
        :selected-rows="selectedUsers"
        :with-selection="true"
        @selection-change="handleSelectionChange"
      >
        <a-data-table-column type="selection" :width="50" align="center" />
        <a-data-table-column prop="userID" :nowrap="true" :width="200" align="left" />
        <a-data-table-column prop="userName" :nowrap="true" :min-width="100" align="left" />
        <a-data-table-column prop="departmentInfo" :nowrap="true" :min-width="100" align="left" />
      </m-data-table>
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import MDataTable from '@/components/molecules/data-table';
import ADataTableColumn from '@/components/atoms/data-table-column';
import { cloneDeep } from 'lodash';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 'm-user-selection-table',
  components: {
    MDataTable,
    ADataTableColumn,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'ユーザー選択',
    },
    userList: {
      type: Array,
      default: new Array(0),
    },
    modelValue: {
      type: Array,
      default: new Array(0),
    },
    compact: {
      type: Boolean,
      default: false,
    },
    showUserCount: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      selectedUsers: [],
      noDataMessage: messageContent.noData,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-user-selection-table', this.compact ? 'compact' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  watch: {
    modelValue: {
      handler(val) {
        this.setCurrentValue(val);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    handleSelectionChange(selectedList) {
      this.setCurrentValue(selectedList);
      this.$emit('update:modelValue', cloneDeep(this.selectedUsers));
      this.$emit('change', cloneDeep(this.selectedUsers));
    },
    setCurrentValue(val) {
      // update selected users list
      this.selectedUsers.splice(0);
      if (val) {
        val.forEach(index => {
          this.selectedUsers.push(index);
        });
      }
    },
  },
};
</script>
