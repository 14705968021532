<template>
  <component
    :is="buttonType"
    :href="href"
    :target="target"
    :type="buttonSubmitType"
    :disabled="disabled"
    :class="componentClassName"
    @click="handleClick"
  >
    <a-icon v-if="icon" :icon="icon" :color="iconColor" :size="iconSize" />
    <slot v-if="variant !== 'icon'">{{ label }}</slot>
  </component>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon, { ICON_SHAPES, ICON_COLORS } from '@/components/atoms/icon/index.vue';

export default {
  name: 'a-button',
  components: {
    AIcon,
  },
  props: {
    /**
     * Button variant
     * `outlined`
     */
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|outlined|outlined-round|fill|block|round|numpad|text|icon)/);
      },
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|primary|primary-green|red|grey|white|primary-red|green)/);
      },
    },
    /**
     * The size of the button. Defaults to medium.
     * `small, medium, large`
     */
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return value.match(/(small|medium|large)/);
      },
    },
    /**
     * When setting the button’s type to a link, use this option to give a href.
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * When setting the button’s type to a link, use this option to give a target.
     */
    target: {
      type: String,
      default: null,
    },
    /**
     * Set the button’s type to “submit”.
     */
    submit: {
      type: String,
      default: 'button',
      validator(value) {
        return value.match(/(button|submit)/);
      },
    },
    icon: {
      type: String,
      default: null,
      validator(value) {
        if (!value) return true;
        return ICON_SHAPES.indexOf(value) !== -1;
      },
    },
    iconColor: {
      type: String,
      default: null,
      validator(value) {
        if (!value) return true;
        return ICON_COLORS.indexOf(value) !== -1 || value === null;
      },
    },
    iconSize: {
      type: String,
      default: null,
      validator(value) {
        return (
          value === null ||
          (value.match(/[0-9]+(rem|em|px)/g) !== null && value.match(/[0-9]+(rem|em|px)/g).length === 1)
        );
      },
    },
    disabled: {
      type: [String, Boolean],
      default: null,
    },
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    float: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/null|focus|hover|active/);
      },
    },
    underline: {
      type: Boolean,
      default: null,
    },
    inline: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['click'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-button',
        this.variant,
        this.color ? `color-${this.color}` : null,
        this.disabled ? 'disabled' : null,
        this.size,
        this.state,
        this.float ? 'float' : null,
        this.href ? 'link' : null,
        this.underline ? 'underline' : null,
        this.inline ? 'inline' : null
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    buttonType() {
      return this.href ? 'a' : 'button';
    },
    buttonSubmitType() {
      return this.href ? null : this.submit;
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>
