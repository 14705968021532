import { helpers, maxLength, required } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';

const commonCreateUpdateValidations = {
  userName: {
    required: helpers.withMessage(messageContent.requireInput('名前'), required),
    hasSymbolCharacterInString: helpers.withMessage(messageContent.requireNotSymbolCharacters('名前'), value => {
      if (!value) return true;
      return messageContent.hasSymbolCharacterInString(value);
    }),
    maxLength: helpers.withMessage(messageContent.requireUnderMaximumNumberOfCharacters('名前', 41), maxLength(41)),
    underBarCheck: helpers.withMessage(messageContent.requireNotIncludeUndebar('名前'), value => {
      if (!value) return true;
      return messageContent.fullnameUnderbarCheck(value, '名前').status;
    }),
    onlySpaceCheck: helpers.withMessage(messageContent.requireNotOnlySpace('名前'), value => {
      if (!value) return true;
      return messageContent.onlySpaceCheck(value, '名前').status;
    }),
  },
  departmentInfo: {
    hasSymbolCharacterInString: helpers.withMessage(messageContent.requireNotSymbolCharacters('部署名'), value => {
      if (!value) return true;
      return messageContent.hasSymbolCharacterInString(value);
    }),
    maxLength: helpers.withMessage(messageContent.requireUnderMaximumNumberOfCharacters('部署名', 20), maxLength(20)),
    onlySpaceCheck: helpers.withMessage(messageContent.requireNotOnlySpace('部署'), value => {
      if (!value) return true;
      return messageContent.onlySpaceCheck(value, '部署').status;
    }),
  },
  docomoID: {
    onlySpaceCheck: helpers.withMessage(messageContent.requireNotOnlySpace('ビジネスdアカウントのid'), value => {
      if (!value) return true;
      return messageContent.onlySpaceCheck(value, 'ビジネスdアカウントのid').status;
    }),
  },
  authInfo: {
    accepted: helpers.withMessage(messageContent.requireInput('システム権限'), value =>
      ['manager', 'operator', 'writer'].includes(value)
    ),
  },
};

export const userValidation = {
  create: {
    userList: {
      $each: helpers.forEach({
        userID: {
          addressCheck: helpers.withMessage(messageContent.requireInput('メールアドレス'), value =>
            messageContent.addressCheck(value)
          ),
          maxLength: helpers.withMessage(
            messageContent.requireUnderMaximumNumberOfCharacters('メールアドレス', 50),
            maxLength(50)
          ),
        },
        userName: commonCreateUpdateValidations.userName,
        departmentInfo: commonCreateUpdateValidations.departmentInfo,
        docomoID: commonCreateUpdateValidations.docomoID,
        authInfo: commonCreateUpdateValidations.authInfo,
      }),
    },
  },

  update: {
    userData: {
      userName: commonCreateUpdateValidations.userName,
      departmentInfo: commonCreateUpdateValidations.departmentInfo,
      docomoID: commonCreateUpdateValidations.docomoID,
      authInfo: commonCreateUpdateValidations.authInfo,
    },
  },
};
