<template>
  <div :id="id" :class="componentClassName">
    <a-list-group-header :label="label" />
    <slot></slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AListGroupHeader from '@/components/atoms/list-group-header';

export default {
  name: 'm-list-group',
  components: {
    AListGroupHeader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-list-group');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
