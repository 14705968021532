import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { commonCastDateValidations } from '@/common/commonValidations.js';

const commonCreateUpdateValidations = checkStatus => {
  return {
    homeSubBannerData: {
      checkStatus: {
        accepted: helpers.withMessage(messageContent.requireSelect('チェックステータス'), value =>
          ['draft', 'checked', 'approved'].includes(value)
        ),
      },
      dAccountLoginFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('ビジネスｄアカウントログイン'),
          value => value === true || value === false
        ),
      },
      webviewFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('Webビュー'),
          value => value === true || value === false
        ),
      },
      title: {
        required: helpers.withMessage(messageContent.requireInput('バナー名称'), required),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('バナー名称', 30),
          maxLength(30)
        ),
      },
      iosContentsURL: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('iosのURL'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('iosのURL', 500),
          maxLength(500)
        ),
      },
      androidContentsURL: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('androidのURL'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('androidのURL', 500),
          maxLength(500)
        ),
      },
      imageFile: {
        binary: {
          requiredIf: helpers.withMessage(
            messageContent.requireUpload('画像'),
            requiredIf((value, vm) => {
              if (checkStatus === 'draft') return false;
              return !value && !vm.file;
            })
          ),
        },
      },
    },
  };
};

export const homeSubBannerValidation = {
  create: checkStatus => {
    const obj = commonCreateUpdateValidations(checkStatus);

    obj.homeSubBannerData.castStartDate = commonCastDateValidations(checkStatus).create.castStartDate;
    obj.homeSubBannerData.castEndDate = commonCastDateValidations(checkStatus).create.castEndDate;

    return obj;
  },

  update: checkStatus => {
    const obj = commonCreateUpdateValidations(checkStatus);

    obj.homeSubBannerData.castStartDate = commonCastDateValidations(checkStatus).update.castStartDate;
    obj.homeSubBannerData.castEndDate = commonCastDateValidations(checkStatus).update.castEndDate;
    obj.isPublished = commonCastDateValidations(checkStatus).update.isPublished('homeSubBannerData');
    obj.beforeCastEndDate = commonCastDateValidations(checkStatus).update.beforeCastEndDate('homeSubBannerData');

    return obj;
  },
};
