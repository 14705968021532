import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { commonCastDateValidations } from '@/common/commonValidations.js';

const commonCreateUpdateValidations = (checkStatus, bubbleFlag, supportInfo) => {
  return {
    servicesData: {
      checkStatus: {
        accepted: helpers.withMessage(messageContent.requireSelect('チェックステータス'), value =>
          ['draft', 'checked', 'approved'].includes(value)
        ),
      },
      homeBubbleFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('ホーム画面NEW吹き出し設定'),
          value => value === true || value === false
        ),
      },
      bubbleFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('吹き出し設定'),
          value => value === true || value === false
        ),
      },
      serviceName1: {
        required: helpers.withMessage(messageContent.requireInput('サービス名1'), required),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('サービス名1', 10),
          maxLength(10)
        ),
      },
      serviceName2: {
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('サービス名2', 10),
          maxLength(10)
        ),
      },
      serviceOverview: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('サービス概要'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('サービス概要', 30),
          maxLength(30)
        ),
      },
      bubbleComment: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('吹き出しコメント'),
          requiredIf(() => checkStatus !== 'draft' && bubbleFlag)
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('吹き出しコメント', 10),
          maxLength(10)
        ),
      },
      contentsURL: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('URL'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('URL', 500),
          maxLength(500)
        ),
      },
      webviewFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('Webビュー'),
          value => value === true || value === false
        ),
      },
      imageFile: {
        binary: {
          requiredIf: helpers.withMessage(
            messageContent.requireUpload('画像'),
            requiredIf((value, vm) => {
              if (checkStatus === 'draft') return false;
              return !value && !vm.file;
            })
          ),
        },
      },
      supportInfo:
        supportInfo.length > 0
          ? supportInfo.map((info, index) => ({
              mainCategoryName: {
                requiredIf: helpers.withMessage(
                  messageContent.requireInput(`大カテゴリ名 ${index + 1}`),
                  requiredIf(() => checkStatus !== 'draft')
                ),
                maxlength: helpers.withMessage(
                  messageContent.requireUnderMaximumNumberOfCharacters(`大カテゴリ名 ${index + 1}`, 50),
                  maxLength(50)
                ),
              },
              subCategories: info.subCategories.map((sub, subIndex) => ({
                name: {
                  requiredIf: helpers.withMessage(
                    messageContent.requireInput(`大カテゴリ名${index + 1}の小カテゴリ名 ${subIndex + 1}`),
                    requiredIf(() => checkStatus !== 'draft')
                  ),
                  maxlength: helpers.withMessage(
                    messageContent.requireUnderMaximumNumberOfCharacters(
                      `大カテゴリ名${index + 1}の小カテゴリ名${subIndex + 1}`,
                      50
                    ),
                    maxLength(50)
                  ),
                },
                description: {
                  maxlength: helpers.withMessage(
                    messageContent.requireUnderMaximumNumberOfCharacters('補足説明', 50),
                    maxLength(50)
                  ),
                },
                contactType: {
                  accepted: helpers.withMessage(messageContent.requireSelect('お問い合わせ種類'), value =>
                    ['link', 'button'].includes(value)
                  ),
                },
                contactInfo: sub.contactInfo.map((contact, contactIndex) => ({
                  method: {
                    accepted: helpers.withMessage(messageContent.requireSelect('お問い合わせ方法'), value =>
                      ['link', 'form', 'shop', 'mail', 'phone'].includes(value)
                    ),
                  },
                  target: {
                    requiredIf: helpers.withMessage(
                      contact.method === 'link'
                        ? messageContent.requireInput(`小カテゴリ名${subIndex + 1}のURL`)
                        : messageContent.requireInput(`小カテゴリ名${subIndex + 1}のメールアドレスor電話番号orURL `),
                      requiredIf(() => checkStatus !== 'draft')
                    ),
                    maxlength: helpers.withMessage(
                      messageContent.requireUnderMaximumNumberOfCharacters('メールアドレスor電話番号orURL', 500),
                      maxLength(500)
                    ),
                  },
                })),
              })),
            }))
          : [],
    },
  };
};

export const servicesValidation = {
  create: (checkStatus, bubbleFlag, supportInfo) => {
    const obj = commonCreateUpdateValidations(checkStatus, bubbleFlag, supportInfo);

    obj.servicesData.castStartDate = commonCastDateValidations(checkStatus).create.castStartDate;
    obj.servicesData.castEndDate = commonCastDateValidations(checkStatus).create.castEndDate;

    return obj;
  },

  update: (checkStatus, bubbleFlag, supportInfo) => {
    const obj = commonCreateUpdateValidations(checkStatus, bubbleFlag, supportInfo);

    obj.servicesData.castStartDate = commonCastDateValidations(checkStatus).update.castStartDate;
    obj.servicesData.castEndDate = commonCastDateValidations(checkStatus).update.castEndDate;
    obj.isPublished = commonCastDateValidations(checkStatus).update.isPublished('servicesData');
    obj.beforeCastEndDate = commonCastDateValidations(checkStatus).update.beforeCastEndDate('servicesData');

    return obj;
  },
};
