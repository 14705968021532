<template>
  <div v-if="$slots.default" :class="componentClassName">
    <m-button-container variant="horizontal">
      <slot></slot>
    </m-button-container>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import MButtonContainer from '@/components/molecules/button-container';

export default {
  name: 'a-modal-footer',
  components: {
    MButtonContainer,
  },
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|primary|confirmation|dialog)/);
      },
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-modal-footer', this.variant, this.shadow ? 'shadow' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
