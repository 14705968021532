export const messageContent = {
  errorDelivery: 'ユーザー管理からidを登録してください',
  successDelivery: `
  テスト配信を実施しました。　最大数分程度お待ちください。<br>
  届かない場合は以下を確認してください。<br>
  ・アプリ上で明示的同意に同意されたこと<br>
  ・アプリにビジネスdアカウントでログインされていること`,
  successTestSegmentDelivery: `
  テスト特定配信を実施しました。　最大数分程度お待ちください。<br>
  届かない場合は以下を確認してください。<br>
  ・アプリ上で明示的同意に同意されたこと<br>
  ・アプリにビジネスdアカウントでログインされていること`,
  successPopupDelivery: `
  ポップアップ配信を実施しました。　最大数分程度お待ちください。<br>
  届かない場合は以下を確認してください。<br>
  ・アプリにビジネスdアカウントでログインされていること`,
  hasSpecialCharacter: `「&<>"'」を入力しないでください。`,
  urlSpecialCharater: `URLに「<>"'」を入力しないでください`,
  hasSpecialCharacterForLog: `「&<>」を入力しないでください。`,
  infoModalTitle: `Info`,
  errorModalTitle: `エラー`,
  confirmModalTitle: `Confirm`,
  setCastStartDateToPastDateTime: `公開日は、現在の日時より過去の日時は設定できません。`,
  setShowStartDateToPastDateTime: `表示開始日時は、現在の日時より過去の日時は設定できません。`,
  setCastEndDateToPastDateTime: `公開終了日は、現在の日時より過去の日時は設定できません。`,
  setCastEndDateBeforeCastStartDate: `公開終了日は、公開日より前の日時を設定できません。`,
  setSameDateTime: `公開日と公開終了日に同じ日時を設定できません。`,
  uploaFileSizeLimit: `10MB`,
  loginOtherError: 'ログイン中にエラーが発生しました。ページをリロードした後再度実行してください。',
  editAfterPublishing: `公開終了後に編集はできません。`,
  invalidPasscode: '6桁の数字のみを入力してください。',
  passcodeWrong: 'ログインできません。<br>※メールに記載の認証コードをご確認ください。',
  passcodeAuthFailed: '二要素認証に失敗しました。<br>再度IDパスワードでログインしてください。',
  passwordEmailed: '新しいパスワードをメール送信しました。',
  canNotResetPassword: 'パスワードリセットできませんでした。時間を空けて再度実行してください。',
  passwordNotSame: '新しいパスワードと新しいパスワード(確認)が異なります。',
  permissionDenied: `ご利用アカウントではこちらのページは権限がありません。`,
  permissionDeniedTrialTitle: 'トライアルでは',
  messengerLimitUser: 'ユーザー数の上限になりました。ユーザー追加が出来ません。',
  bomMessengerError: 'BOM無しのファイルをアップロードしてください',
  updatePosition: function (item) {
    return item + ' の順番を変更してよろしいでしょうか。';
  },
  errorCsvLength: function (item) {
    return item + 'の桁数を確認してください。';
  },
  errorCsvCharacters: function (item) {
    return '半角英数字（A-Z0-9）ではない' + item + 'があります';
  },
  errorNotFileCsv: 'CSVファイルをアップロードしてください。',
  requirePermission: function (item) {
    return item + '機能は利用いただけません';
  },
  requireAdd: function (item) {
    return item + 'を追加してください。';
  },
  requireInput: function (item) {
    return item + 'を入力してください。';
  },
  notifyUserAdd: function () {
    return 'ユーザーを追加しますか？';
  },
  hasSymbolCharacterInString: function (string) {
    return /^([ぁ-んァ-ヶーA-Za-zＡ-Ｚａ-ｚ0-9０-９ 　\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu.test(
      string
    );
  },
  addressCheck: address => {
    /* eslint-disable */
    return /^([a-zA-Z0-9])+([a-zA-Z0-9\._\+-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/.test(address);
  },
  hasSpecialCharacterInObject: function (obj) {
    /* eslint-disable */
    for (const [key, value] of Object.entries(obj)) {
      if (/^(?=.*[&<>'"]).*$/.test(value)) {
        return true;
      }
    }
    return false;
  },
  requireNotSymbolCharacters: item => {
    return item + 'に記号文字を入力しないでください。';
  },
  requireNotIncludeUndebar: item => {
    return item + 'にアンダーバーを入力しないでください。';
  },
  requireNotOnlySpace: item => {
    return item + 'にスペースだけを入力しないでください。';
  },
  requireUnderMaximumNumberOfCharacters: (item, num) => {
    return item + 'の最大文字数は' + num + '文字です。';
  },
  emailLengthCheck: function (content) {
    const params = {
      target: 'メールアドレス',
      maxlength: 50, // 半角、ローマ字、漢字も１文字としてカウント
      content,
    };
    return this.maxlengthCheck(params);
  },
  maxlengthCheck: function (params) {
    if (!params.content) {
      return { status: true };
    }
    const message = `${params.target}の最大文字数は${params.maxlength}文字です。`;
    if (params.content.length > params.maxlength) {
      return { status: false, message };
    } else {
      return { status: true };
    }
  },
  departmentNameLengthCheck: function (content) {
    const params = {
      target: '部署名',
      maxlength: 20, // 半角、ローマ字、漢字も１文字としてカウント
      content,
    };
    return this.maxlengthCheck(params);
  },
  fullnameUnderbarCheck: function (fullname, head = '氏名') {
    if (fullname && fullname.includes('_')) {
      return { status: false, message: `${head}は${messageContent.requireNotIncludeUndebar('氏名')}` };
    }
    return { status: true };
  },
  onlySpaceCheck: function (content, target) {
    if (content && content.trim() === '') {
      return {
        status: false,
        message: `${target}は${messageContent.requireNotOnlySpace(target)}`,
      };
    }
    return { status: true };
  },
  passwordPatternCheck: password => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]{12,40}$/.test(password);
  },
  passwordCheck: item => {
    return (
      item + 'は12文字〜40文字で、大文字アルファベット、小文字アルファベット、数字をすべて１つ以上含ませてください。'
    );
  },
  reachUserCountLimit: function (item) {
    return '追加できるユーザー数の上限値（' + item + '）を超過するため、これ以上のユーザー追加を行うことはできません';
  },
  notifyUserDelete: function (userID) {
    return userID + 'を削除しますか？';
  },
  requireTry: function (item) {
    return item + '中にエラーが発生しました。時間を空けて再度実行してください。';
  },
  userInfoUpdateFailed: function () {
    return '設定を変更できませんでした。時間を空けて再度実行してください。';
  },
  userDeleted: function () {
    return 'ユーザーを削除しました。';
  },
  existItem: function (item) {
    return item + 'は既に存在しています。';
  },
  userAdded: function () {
    return 'ユーザーを追加しました。';
  },
  comfirmDelete: function (title) {
    return title + 'を削除してよろしいでしょうか？';
  },
  reachFileSizeLimit: function (fileName, fileSize) {
    return `${fileName}のファイルサイズは${fileSize}のためアップロードできません。<br>
    アップロードできるファイルサイズは${this.uploaFileSizeLimit}までです。`;
  },
  requireSelect: item => {
    return item + 'を選択してください。';
  },
  requireUpload: item => {
    return item + 'を添付してください。';
  },

  checkHasSpecialCharacterInObj: obj => {
    let status = true;

    if (typeof obj === 'object') {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (!messageContent.checkHasSpecialCharacterInObj(obj[key])) {
                    status = false;
                    break;
                }
            } else if (Array.isArray(obj[key])) {
                for (const item of obj[key]) {
                    if (typeof item === 'object' && item.hasOwnProperty('target') && key !== 'target') {
                        if (typeof item.target === 'string' && messageContent.checkHasSpecialCharacter(messageContent.replaceCharacter(item.target))) {
                            status = false;
                            break;
                        }
                    } else if (typeof item === 'string' && messageContent.checkHasSpecialCharacter(messageContent.replaceCharacter(item)) && key !== 'contentsURL' && key !== 'androidContentsURL' && key !== 'iosContentsURL' && key !== 'appURL' && key !== 'customerURL') {
                        status = false;
                        break;
                    }
                }
            } else {
                if (typeof obj[key] === 'string' && key !== 'contentsURL' && key !== 'target' && key !== 'androidContentsURL' && key !== 'iosContentsURL' && key !== 'appURL' && key !== 'customerURL' && messageContent.checkHasSpecialCharacter(messageContent.replaceCharacter(obj[key]))) {
                    status = false;
                    break;
                }
            }
        }
    }

    return status;
  },
  //特殊文字をチェックする
  checkHasSpecialCharacter: value => {
    return /^(?=.*[&<>'"]).*$/.test(value);
  },
  replaceCharacter: string => {
    if (typeof string !== 'string') return string;
    return string.replaceAll('\n', '').replaceAll('\r', '').replaceAll('\t', '');
  },
  /**
   *  特殊文字をチェックする
   * @returns
   */
  checkUrlSpecialCharacter: value => {
    return /^(?=.*[<>'"]).*$/.test(value);
  },
};
