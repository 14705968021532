const { messageContent } = require('./messageContent');
export const showMsgBoxMixin = {
  methods: {
    showErrorMsgBox: function (content) {
      this.$bvModal.msgBoxOk(content, {
        title: messageContent.errorModalTitle,
      });
    },
    showInfoMsgBox: function (content) {
      this.$bvModal.msgBoxOk(content, {
        title: messageContent.infoModalTitle,
      });
    },
  },
};
