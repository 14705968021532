const { messageContent } = require('./messageContent');

export const customValidate = {
  methods: {
    addressCheck: function (address) {
      /* eslint-disable */
      return address.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._\+-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/);
    },
    hasSpecialCharacterInObject: function (obj) {
      /* eslint-disable */
      for (const [key, value] of Object.entries(obj)) {
        if (/^(?=.*[&<>'"]).*$/.test(value)) {
          return true;
        }
      }
      return false;
    },
    departmentNameLengthCheck: function (content) {
      const params = {
        target: '部署名',
        maxlength: 20, // 半角、ローマ字、漢字も１文字としてカウント
        content,
      };
      return this.maxlengthCheck(params);
    },
    maxlengthCheck: function (params) {
      if (!params.content) {
        return { status: true };
      }
      const message = `${params.target}の最大文字数は${params.maxlength}文字です。`;
      if (params.content.length > params.maxlength) {
        return { status: false, message };
      } else {
        return { status: true };
      }
    },
    fullnameUnderbarCheck: function (fullname, head = '氏名') {
      if (fullname && fullname.includes('_')) {
        return { status: false, message: `${head}は${messageContent.fullnameNotIncludeUndebar}` };
      }
      return { status: true };
    },
    onlySpaceCheck: function (content, target) {
      if (content && content.trim() === '') {
        return {
          status: false,
          message: `${target}は${messageContent.onlySpaceError}`,
        };
      }
      return { status: true };
    },
    numberOnly: function (number) {
      return /^[0-9]*$/.test(number);
    },
    passwordPatternCheck: function (password) {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]{12,40}$/.test(password);
    },
    passwordCheck: function (content, target = 'パスワード') {
      if (!content) {
        return {
          status: false,
          message: `${target}を入力してください。`,
        };
      }
      if (!this.passwordPatternCheck(content)) {
        const message = `${target}は12文字〜40文字で、大文字アルファベット、小文字アルファベット、数字をすべて１つ以上含ませてください。`;
        return { status: false, message };
      } else {
        return { status: true };
      }
    },
  },
};
