<template>
  <div :id="id" :class="componentClassName">
    <button class="a-list-item__button" type="button" @click="handleClick">
      <div class="a-list-item__button-bg"></div>
      <i v-if="hasIndicator" class="a-list-item__indicator" :style="indicatorStyle"></i>
      <div v-if="icon" class="a-list-item__icon">
        <a-icon :icon="icon" :color="iconColor" />
      </div>
      <div class="a-list-item__content">
        <div class="a-list-item__label" :style="labelStyle">
          <slot>{{ label }}</slot>
        </div>
        <div class="a-list-item__sublabel">
          {{ sublabel }}
        </div>
      </div>
      <div v-if="important" class="a-list-item__important">
        <a-tag :outline="true">重要</a-tag>
      </div>
      <div v-if="star" class="a-list-item__star">
        <a-icon icon="star" color="yellow" />
      </div>
    </button>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon';
import ATag from '@/components/atoms/tag';

export default {
  name: 'a-list-item',
  components: {
    AIcon,
    ATag,
  },
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|alphanumeric)/);
      },
    },
    size: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|small)/);
      },
    },
    class: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    hasIndicator: {
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      type: String,
      default: '#ee3848',
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'black',
    },
    important: {
      type: Boolean,
      default: false,
    },
    star: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-list-item', this.variant, this.size);
      return `${baseClassName} ${this.class}`.trim();
    },
    labelStyle() {
      return {
        color: this.labelColor,
      };
    },
    indicatorStyle() {
      return {
        backgroundColor: this.indicatorColor,
      };
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
