<template>
  <t-default-layout active-nav-id="campaigns">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="Campaigns" />
      <form ref="form" class="t-campaigns-add__form" enctype="multipart/form-data" @submit.prevent="handleFormSubmit">
        <div class="t-campaigns-add__fields">
          <m-section>
            <m-action-card :id="idName[0]" title="記事・セミナー登録" header-border no-header-margin icon="clock">
              <div class="t-campaigns-add__fields__pushtype">
                <m-form-input id="t-campaigns-add-info-importantFlag__form-input">
                  <a-text id="t-campaigns-add__fields__a-text-top_1">●</a-text>
                  <a-text id="t-campaigns-add-info-importantFlag__a-text">PICKUP</a-text>
                  <a-switch
                    id="t-campaigns-add-importantFlag"
                    v-model="campaignData.importantFlag"
                    data-testid="add-campaign-important-toggle-button"
                    @change="checkDefaultCategory"
                  />
                </m-form-input>
                <m-form-input id="t-campaigns-add__fields__pushtype_1">
                  <a-text id="t-campaigns-add__fields__a-text-top_2">●</a-text>
                  <a-text id="t-campaigns-add__fields__pushtype__a-text">通知ステータス</a-text>
                  <a-select-field
                    id="t-campaigns-add__fields__pushtype__a-select-field_1"
                    v-model="pushStatus"
                    :options="pushStatusOptions"
                  />
                </m-form-input>
              </div>
              <div v-if="pushStatus !== 'none'" class="t-campaigns-add__fields__pushcategory">
                <m-form-input id="t-campaigns-add-info-pushcategory__form-input">
                  <a-text id="t-campaigns-add__fields__pushcategory__a-text-top">●</a-text>
                  <a-text id="t-campaigns-add__fields__pushcategory__a-text">プッシュ通知カテゴリ</a-text>
                  <div id="t-campaigns-add__fields__pushcategory__a-apply-text-field">
                    <a-apply-text-field
                      id="t-campaigns-add__fields__pushcategory__a-apply-text-field-id"
                      v-model="campaignData.pushCategory"
                      data-testid="add-campaigns-pushcategory"
                      type="text"
                      maxlength="10"
                      placeholder="テキストを入力"
                    />
                  </div>
                </m-form-input>
              </div>
              <div
                v-if="pushStatus === 'limited'"
                id="t-campaigns-add__fields__upload-file"
                class="t-campaigns-add__fields__upload-file"
              >
                <a-text id="t-campaigns-add__fields__a-text-top_3">●</a-text>
                <a-text id="t-campaigns-add__fields__upload-file__a-text">CSVアップロード</a-text>
                <o-csv-uploader
                  v-model:pushTargetListFile="campaignData.pushTargetListFile"
                  v-model:pushTargetListFileType="campaignData.pushTargetListFileType"
                  :error-function="showErrorModal"
                />
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[1]" title="内容サマリ" header-border no-header-margin icon="clock">
              <div class="t-campaigns-add__fields">
                <div id="t-campaigns-add-uploading-file" class="t-campaigns-add__row">
                  <div class="t-campaigns-add__col-1">
                    <div class="t-campaigns-add__fields">
                      <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                      <!-- <m-form-input>
                        <a-upload-field
                          id="t-campaigns-add-upload-image"
                          v-model="campaignData.imageFile.file"
                          name="imageFile"
                          class="t-campaigns-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                          @change="handleUploadFileChangeImg(campaignData.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input> -->
                      <m-form-input>
                        <a-upload-field
                          id="t-campaigns-add-upload-image"
                          v-model="campaignData.imageFile.file"
                          test-id-text="add-campaign-summary-image"
                          name="imageFile"
                          class="t-campaigns-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChangeImg(campaignData.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-campaigns-add__col-2">
                    <div class="t-campaigns-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="campaignData.title"
                          data-testid="add-campaign-summary-title-input"
                          type="text"
                          maxlength="16"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-apply-text-field
                          v-model="campaignData.text"
                          data-testid="add-campaign-summary-content-input"
                          class="t-campaigns-add__content-input"
                          type="textarea"
                          rows="2"
                          maxlength="35"
                          placeholder="テキスト入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[2]" title="詳細ページ内容" header-border no-header-margin icon="clock-remove">
              <div class="t-campaigns-add__fields">
                <div id="t-campaigns-add-info-uploading-file" class="t-campaigns-add__row">
                  <div class="t-campaigns-add__col-1">
                    <div class="t-campaigns-add__fields">
                      <m-form-input>
                        <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                        <!-- <a-upload-field
                          id="t-campaigns-add-info-upload-image"
                          v-model="campaignData.detailInfo.imageFile.file"
                          class="t-campaigns-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                          @change="handleUploadFileChangeImg(campaignData.detailInfo.imageFile)"
                          @showErrorModal="showErrorModal"
                        /> -->
                        <a-upload-field
                          id="t-campaigns-add-info-upload-image"
                          v-model="campaignData.detailInfo.imageFile.file"
                          test-id-text="add-campaign-detail-image"
                          class="t-campaigns-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChangeImg(campaignData.detailInfo.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-campaigns-add__col-2">
                    <div class="t-campaigns-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="t-campaigns-add-info-title"
                          v-model="campaignData.detailInfo.title"
                          data-testid="add-campaign-detail-title-input"
                          type="text"
                          maxlength="50"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                      <m-rich-text-editor
                        :html-content="campaignData.detailInfo.htmlContent"
                        max-length="800"
                        @getHtmlContent="campaignData.detailInfo.htmlContent = $event"
                        @getText="campaignData.detailInfo.text = $event"
                      />
                    </div>
                  </div>
                </div>
                <o-transition-type-service
                  v-model:transitionType="campaignData.detailInfo.transitionType"
                  v-model:transitionDoc="campaignData.detailInfo.transitionDoc"
                  v-model:contentsUrl="campaignData.detailInfo.contentsURL"
                />
                <div v-if="campaignData.detailInfo.transitionType === 'url'" class="t-campaigns-add__fields">
                  <m-form-input id="t-campaigns-add-info-webviewFlag__form-input">
                    <a-text id="t-campaigns-add-info-webviewFlag__a-text">Webビュー</a-text>　　
                    <a-switch
                      id="t-campaigns-add-webviewFlag"
                      v-model="campaignData.detailInfo.webviewFlag"
                      data-testid="add-campaigns-detail-webview-toggle-button"
                    />
                    <a-text id="t-campaigns-add-info-webview-note__a-text"
                      >※Webビュー設定ONの場合、URLにはダイナミックリンクを登録しないでください。</a-text
                    >
                  </m-form-input>
                </div>
                <div v-if="campaignData.detailInfo.transitionType === 'url'" class="t-campaigns-add__fields">
                  <m-form-input id="t-campaigns-add-info-webviewFlag__form-input">
                    <a-text id="t-campaigns-add-info-webviewFlag__a-text">ビジネスｄアカウントログイン</a-text>　　
                    <a-switch
                      id="t-campaigns-add-webviewFlag"
                      v-model="campaignData.detailInfo.dAccountLoginFlag"
                      data-testid="add-campaigns-detail-webview-toggle-button"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-campaigns-add__fields">
                <div id="t-campaigns-add-cast-start-date-day__row" class="t-campaigns-add__row">
                  <a-text id="t-campaigns-add-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-campaigns-add-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="campaignData.castStartDate"
                      test-id-text="add-campaign-publish-start"
                      :min-date="minDate"
                      mode="dateTime"
                    />
                  </m-form-input>
                </div>
                <div class="t-campaigns-add__row">
                  <a-text id="t-campaigns-add-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-campaigns-add-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="campaignData.castEndDate"
                      test-id-text="add-campaign-publish-end"
                      :min-date="minDate"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-campaigns-add__fields__pushtype">
                <m-form-input id="t-campaigns-add__fields__pushtype_2">
                  <a-select-field
                    id="t-campaigns-add__fields__pushtype__a-select-field_2"
                    v-model="campaignData.checkStatus"
                    data-testid="add-campaign-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="add-campaign-save-button"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="campaignsCreate_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import MFormInput from '@/components/molecules/form-input';
import ASelectField from '@/components/atoms/select-field';
import OCsvUploader from '@/components/organisms/csv-uploader/index.vue';
import OModal from '@/components/organisms/modal';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import OTransitionTypeService from '@/components/organisms/transition-type-service';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { campaignValidation } from '@/validations/campaign.js';
import { cloneDeep } from 'lodash';
import MRichTextEditor from '@/components/molecules/rich-text-editor';
import sanitizeHtml from 'sanitize-html';

export default {
  name: 't-campaigns-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASwitch,
    MFormInput,
    ASelectField,
    OCsvUploader,
    OModal,
    AUploadField,
    ACalendarField,
    AApplyTextField,
    MRichTextEditor,
    OTransitionTypeService,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,

      //お知らせデータ
      campaignData: {
        pushFlag: false, //push 通知フラグ
        text: '', //お知らせ内容
        title: '', //お知らせタイトル
        imageURL: '', //
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        importantFlag: false, //PICKUPフラグ
        castStartDate: '', //公開開始日
        castEndDate: '', //公開終了日
        deleteDate: '', //削除予定日
        draftFlag: true, //下書き
        checkStatus: 'draft', //作成時のチェックステータスは下書き
        detailInfo: {
          //お知らせ詳細
          text: '',
          title: '',
          htmlContent: '',
          contentsURL: '',
          webviewFlag: false, //Webビューフラグ
          contentsFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          imageURL: '',
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          transitionType: 'url', //遷移先種別
          transitionDoc: '', //遷移先ドキュメントID
          dAccountLoginFlag: false, //ビジネスｄアカウントログイン
        },
        pushType: 'ALL',
        pushTargetListFileType: 'accountid',
        pushTargetListFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        pushCategory: 'NEWS',
      },
      pushStatus: 'none',
      pushStatusOptions: [
        { value: 'all', label: '全体プッシュ通知' },
        { value: 'limited', label: '特定プッシュ通知' },
        { value: 'none', label: 'プッシュ通知なし' },
      ],
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
    };
  },

  validations() {
    return campaignValidation.create(this.campaignData.checkStatus);
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-campaigns-add');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 0; i <= 4; i++) {
      this.idName[i] = 'h1-campaigns-add-' + i;
    }
  },
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.campaignData);
      // URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.detailInfo.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.detailInfo.contentsURL = encodeURI(payload.detailInfo.contentsURL);

      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');
      payload.text = payload.text.replace(/=/g, '%%eq%%');
      payload.detailInfo.title = payload.detailInfo.title.replace(/=/g, '%%eq%%');
      payload.detailInfo.text = payload.detailInfo.text.replace(/=/g, '%%eq%%');

      // Note: htmlコンテンツは不要なタグの削除
      payload.detailInfo.htmlContent = sanitizeHtml(payload.detailInfo.htmlContent, {
        allowedTags: ['p', 'u', 'strong', 'span'],
        allowedAttributes: {
          '*': ['style'],
        },
        allowedStyles: {
          '*': {
            // Match HEX and RGB
            color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
          },
        },
      });
      // Note: 詳細記事はHTMLタグやstyle定義のエスケープ処理を実施
      payload.detailInfo.htmlContent = payload.detailInfo.htmlContent
        .replace(/</g, '%%lt%%')
        .replace(/>/g, '%%gt%%')
        .replace(/=/g, '%%eq%%')
        .replace(/"/g, '%%q%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      if (this.campaignData.castEndDate) {
        const castEndDate = new Date(this.campaignData.castEndDate);
        const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
        this.deleteDate = this.convertDateToString(deleteDate);
      }

      // 特定プッシュ通知以外はファイルクリア
      if (this.pushStatus !== 'limited') {
        this.campaignData.pushTargetListFile.filename = null;
        this.campaignData.pushTargetListFile.binary = null;
        this.campaignData.pushTargetListFile.size = null;
      }

      // 通知ステータスの状態を反映
      switch (this.pushStatus) {
        case 'all': {
          this.campaignData.pushFlag = true;
          this.campaignData.pushType = 'ALL';
          break;
        }
        case 'limited': {
          this.campaignData.pushFlag = true;
          this.campaignData.pushType = 'Limited';
          break;
        }
        case 'none': {
          this.campaignData.pushFlag = false;
          this.campaignData.pushType = 'ALL';
          break;
        }
        default: {
          this.campaignData.pushFlag = false;
          this.campaignData.pushType = 'ALL';
        }
      }
      const formDataTmp = {
        text: payload.text ?? '',
        title: payload.title ?? '',
        imageFile: this.campaignData.imageFile.binary ?? '',
        pushFlag: this.campaignData.pushFlag ?? false,
        importantFlag: this.campaignData.importantFlag ?? false,
        castStartDate: this.campaignData.castStartDate ?? '',
        castEndDate: this.campaignData.castEndDate ?? '',
        deleteDate: this.deleteDate ?? '',
        draftFlag: this.campaignData.draftFlag ?? false,
        checkStatus: this.campaignData.checkStatus ?? '',
        userList: '',
        imageURL: this.campaignData.imageURL ?? '',
        pushDoneCount: 0,
        detailInfo: {
          text: payload.detailInfo.text ?? '',
          title: payload.detailInfo.title ?? '',
          htmlContent: payload.detailInfo.htmlContent ?? '',
          imageFile: this.campaignData.detailInfo.imageFile.binary ?? '',
          imageURL: this.campaignData.detailInfo.imageURL ?? '',
          contentsURL: encodeURI(this.campaignData.detailInfo.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
          webviewFlag: this.campaignData.detailInfo.webviewFlag ?? false,
          transitionType: this.campaignData.detailInfo.transitionType ?? 'url',
          transitionDoc: this.campaignData.detailInfo.transitionDoc ?? '',
          dAccountLoginFlag: this.campaignData.detailInfo.dAccountLoginFlag ?? false,
        },
        pushType: this.campaignData.pushType ?? 'ALL',
        pushTargetListFile: this.campaignData.pushTargetListFile.binary ?? '',
        pushTargetListFileSize: this.campaignData.pushTargetListFile.size ?? 0,
        pushTargetListFileName: this.campaignData.pushTargetListFile.filename ?? '',
        pushTargetListFileType: this.campaignData.pushTargetListFileType ?? 'accountid',
        pushCategory: this.campaignData.pushCategory ?? '',
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/campaigns/add', formDataTmp).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Campaigns' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('お知らせ作成'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleUploadFileChangeImg(fileObj) {
      const vm = this;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          fileObj.binary = content;
        },
        false
      );
      if (fileObj.file[0]) {
        reader.readAsDataURL(fileObj.file[0]);
        fileObj.filename = fileObj.file[0].name;
        fileObj.size = fileObj.file[0].size;
        if (fileObj.filename !== null) {
          vm.agree = true;
        }
      } else {
        // clear file content
        fileObj.file = null;
        fileObj.filename = null;
        fileObj.binary = null;
        fileObj.size = null;
        vm.agree = false;
      }
    },
    handleChange() {},
    handleCancelClick() {
      this.$router.push({ name: 'Campaigns' });
    },

    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
    checkDefaultCategory() {
      if (this.campaignData.importantFlag && this.campaignData.pushCategory === 'NEWS') {
        this.campaignData.pushCategory = 'PICKUP';
      } else if (!this.campaignData.importantFlag && this.campaignData.pushCategory === 'PICKUP') {
        this.campaignData.pushCategory = 'NEWS';
      }
    },
  },
};
</script>
