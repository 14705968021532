<template>
  <div :class="componentClassName">
    <div :id="`${id}-header-title`" class="a-modal-header__title">
      <div v-if="icon" class="a-modal-header__icon">
        <a-icon :icon="icon" :color="iconColor" />
      </div>
      <slot>{{ title }}</slot>
    </div>
    <div v-if="showClose" class="a-modal-header__close-button">
      <a-button
        :id="`${id}-modal-close`"
        :data-testid="`${dataTestid}-close-button`"
        variant="icon"
        icon="close"
        icon-color="orange"
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'a-modal-header',
  components: {
    AButton,
    AIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'orange',
    },
    withBorder: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
    dataTestid: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-modal-header', this.withBorder ? 'border' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    handleClose(event) {
      this.$emit('close', event);
    },
  },
};
</script>
