<template>
  <div class="o-test-push">
    <m-form-action-buttons>
      <a-button
        id="test-push"
        data-testid="test-push-button"
        variant="block"
        color="primary-red"
        @click="handleNavigateTest"
        >{{ buttonLabel }}</a-button
      >
    </m-form-action-buttons>
  </div>
  <!-- Info, Erro Modal -->
  <teleport to="body">
    <o-modal
      id="testPush_resultModal"
      :visible="resultModalVisible"
      class="o-modal--confirmation"
      footer-variant="confirmation"
      width="442px"
      :footer-shadow="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <template #title>{{ resultModalTitle }}</template>
      <a-text>
        <!-- eslint-disable -->
        <span v-html="resultModalContent" />
        <!-- eslint-enable -->
      </a-text>
      <template #footer>
        <a-button
          data-testid="error-info-modal-confirm-button"
          variant="block"
          color="primary"
          label="OK"
          size="small"
          @click="handleCloseModal"
        />
      </template>
    </o-modal>
  </teleport>
</template>

<script>
import _ from 'lodash';
import AText from '@/components/atoms/text';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import AButton from '@/components/atoms/button';
import OModal from '@/components/organisms/modal';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 'o-test-push',
  components: {
    MFormActionButtons,
    AButton,
    OModal,
    AText,
  },
  mixins: [customRequest, messageContent],
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'announcement',
    },
  },
  data() {
    return {
      errMsg: '',
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      showCsvSpace: false,
      pushTargetListFileInfo: {
        file: null,
        filename: null,
        binary: null,
        size: null,
      },
    };
  },
  computed: {
    buttonLabel() {
      return this.type === 'banners' ? 'テストポップアップ配信' : 'テスト配信';
    },
  },
  methods: {
    testPushPath() {
      if (this.type === 'campaign') {
        return '/campaigns/test-push';
      } else if (this.type === 'banners') {
        return '/banners/test-push';
      }
      return '/announcements/test-push';
    },
    handleNavigateTest() {
      this.postRequest(this.testPushPath(), { id: this.$props.id })
        .then(e => {
          if (e.status === 'failed') {
            if (e.result === 'docomoID not found') {
              this.showErrorModal(messageContent.errorDelivery);
            }
          } else {
            if (this.type === 'banners') {
              this.showInfoModal(messageContent.successPopupDelivery);
            } else {
              this.showInfoModal(messageContent.successDelivery);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
