<template>
  <div class="m-rich-text-editor-flexbox">
    <div :class="countStatus">{{ internalPlainText.length }} / {{ maxLength }}</div>
    <div id="m-rich-text-editor" :class="borderStatus">
      <quill-editor
        ref="myEditor"
        v-model:content="internalHtmlContent"
        :read-only="readOnly"
        theme="snow"
        content-type="html"
        toolbar="#my-toolbar"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template #toolbar>
          <div id="my-toolbar">
            <button class="ql-bold"></button>
            <button class="ql-underline"></button>
            <select class="ql-color"></select>
          </div>
        </template>
      </quill-editor>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

export default {
  name: 'm-rich-text-editor',
  components: {
    QuillEditor,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    plainText: {
      type: String,
      default: '',
    },
    maxLength: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['getHtmlContent', 'getText'],
  data() {
    return {
      editor: null,
      internalPlainText: this.plainText,
      internalHtmlContent: this.htmlContent,
      focusStatus: 'blur',
      overStatus: 'normal',
      countStatus: 'm-rich-text-editor-count__normal',
      borderStatus: 'm-rich-text-editor__blur',
    };
  },
  watch: {
    htmlContent(newHtmlContent) {
      // propsの変更を検知
      this.internalHtmlContent = newHtmlContent;
    },
    internalHtmlContent(newValue) {
      console.log('watch internalHtmlContent');
      // プレインテキストを取得
      if (this.editor) {
        let newPlainText = this.editor.getText();
        newPlainText = newPlainText.slice(0, newPlainText.length - 1);
        if (newValue.length > 0 && newPlainText.length === 0) {
          // 通信で取得したケース。htmlContentからプレーンテキストを取得して反映
          newPlainText = newValue.replaceAll('</p><p>', '\n</p><p>').replace(/<.+?>/g, '');
        }
        if (newPlainText.length > this.maxLength) {
          this.overStatus = 'over';
          this.borderStatus = 'm-rich-text-editor__over';
        } else {
          this.overStatus = 'normal';
          this.borderStatus = 'm-rich-text-editor__' + this.focusStatus;
        }
        this.countStatus = 'm-rich-text-editor-count__' + this.overStatus;
        this.internalPlainText = newPlainText;
        // エディターの編集を検知
        this.$emit('getText', newPlainText);
        this.$emit('getHtmlContent', newValue);
      }
    },
  },
  mounted() {
    this.editor = this.$refs.myEditor;
    this.addKeydownListener();
  },
  methods: {
    onFocus: function (editor) {
      this.focusStatus = 'focus';
      this.borderStatus =
        this.overStatus === 'over' ? 'm-rich-text-editor__over' : 'm-rich-text-editor__' + this.focusStatus;
    },
    onBlur: function (editor) {
      this.focusStatus = 'blur';
      this.borderStatus =
        this.overStatus === 'over' ? 'm-rich-text-editor__over' : 'm-rich-text-editor__' + this.focusStatus;
    },
    // タブ文字を禁止にする
    addKeydownListener() {
      const quillEditor = this.$refs.myEditor;
      const quill = quillEditor.getQuill();
      if (quill) {
        const keyboard = quill.getModule('keyboard');
        if (keyboard) {
          keyboard.bindings[9] = [];
          keyboard.addBinding({
            key: 9,
            handler: function (range, context) {
              return false;
            },
          });
        }
      }
    },
  },
};
</script>
