<template>
  <i :class="componentClassName" :style="{ fontSize: size }"></i>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export const ICON_SHAPES = [
  'audio-play',
  'audio-pause',
  'audio-backward',
  'audio-forward',
  'bell',
  'bell-outline',
  'building',
  'calendar-setting',
  'calendar-outline',
  'calendar',
  'caret-down',
  'caret-right',
  'caret-up',
  'check',
  'check-large',
  'chevron-left',
  'chevron-right',
  'clock-remove',
  'clock',
  'close',
  'code',
  'credit-card',
  'download',
  'edit',
  'eye',
  'eye-slash',
  'file',
  'file-outline',
  'filter',
  'fullscreen',
  'gear',
  'gear-outline',
  'help',
  'help-outline',
  'home-outline',
  'home',
  'list-outline',
  'list',
  'lock',
  'logout-outline',
  'minus',
  'minimize',
  'numpad',
  'play',
  'plus',
  'register',
  'search',
  'sms-phone',
  'sms-setting',
  'sound',
  'star',
  'star-outline',
  'upload',
  'user-operator',
  'user-outline',
  'user-setting',
  'user',
  'trash',
  'text',
  'recording-play',
  'outbound-outline',
  'outbound',
  'dummy-picture',
];

export const ICON_COLORS = ['black', 'orange', 'green', 'white', 'grey', 'red', 'yellow'];

export default {
  name: 'a-icon',
  props: {
    icon: {
      type: String,
      required: true,
      validator(value) {
        return ICON_SHAPES.indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return ICON_COLORS.indexOf(value) !== -1 || value === null;
      },
    },
    size: {
      type: String,
      default: null,
      validator(value) {
        return (
          value === null ||
          (value.match(/[0-9]+(rem|em|px)/g) !== null && value.match(/[0-9]+(rem|em|px)/g).length === 1)
        );
      },
    },
  },
  computed: {
    componentClassName() {
      return mapModifiers('a-icon', this.icon, this.color);
    },
  },
};
</script>
