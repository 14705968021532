<template>
  <t-default-layout active-nav-id="users-add">
    <div :class="componentClassName">
      <a-page-heading>ユーザー管理</a-page-heading>
      <m-section v-if="isScreenReady" variant="fullpage">
        <m-action-card title="ユーザー追加" icon="user" :header-border="true" :narrow-body="true">
          <form ref="form" class="t-users-add__form" @submit.prevent="handleFormSubmit">
            <a-text size="small"> ユーザー数の上限は{{ maxUser }}人までです。 </a-text>
            <div class="t-users-add__fields">
              <m-user-invitation-radio
                v-model="userInvitationMode"
                v-model:user-list="userList"
                :add-limit="addLimit"
                name="userInvitation"
                value="list"
              />
            </div>
            <m-form-action-buttons>
              <a-button
                id="saveBtn"
                data-testid="add-user-form-save-button"
                variant="block"
                color="primary"
                :disabled="!flagSaveButtonEnable"
                @click="handleFormSubmit(e)"
                >保存</a-button
              >
              <a-button
                id="cancelBtn"
                data-testid="add-user-form-cancel-button"
                variant="outlined"
                color="primary"
                @click="handleCancelClick"
                >キャンセル</a-button
              >
            </m-form-action-buttons>
          </form>
        </m-action-card>
      </m-section>
    </div>

    <teleport to="body">
      <o-modal
        id="userAdd_comfirmModal"
        :visible="confirmUserInvitationDialogVisible"
        data-testid="user-add-confirm-modal"
        class="o-modal-remove-users-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        @close="handleCancelUserInvitation"
      >
        <template #title> ユーザー追加 </template>
        <a-text :center="true">
          <!-- eslint-disable -->
          <span data-testid="user-add-confirm-message-spans" v-html="addUserInvitationConfirmationMessage" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="cancel"
            data-testid="user-add-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCancelUserInvitation"
          />
          <a-button
            id="confirm"
            data-testid="user-add-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleConfirmUserInvitation"
          />
        </template>
      </o-modal>
    </teleport>

    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="userAdd_resultModal"
        :visible="resultModalVisible"
        class="o-modal-remove-phone-number-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span data-testid="user-add-result-content-span" v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="confirmResult"
            data-testid="user-add-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import AButton from '@/components/atoms/button';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import AText from '@/components/atoms/text';
import MUserInvitationRadio from '@/components/molecules/user-invitation-radio';
import OModal from '@/components/organisms/modal';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import { PERMISSIONS_FRONTEND, MAX_USER } from '@/libs/constants';
import { useVuelidate } from '@vuelidate/core';
import { userValidation } from '@/validations/user.js';

export default {
  name: 't-users-add',
  components: {
    TDefaultLayout,
    APageHeading,
    MSection,
    MActionCard,
    AText,
    AButton,
    MUserInvitationRadio,
    MFormActionButtons,
    OModal,
  },
  mixins: [customRequest, customValidate, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      userInvitationMode: 'list',
      userList: [],
      uploadFile: [],
      confirmUserInvitationDialogVisible: false,
      resultModalVisible: false,
      resultModalTitle: '',
      resultModalContent: '',
      addUserInvitationConfirmationMessage: '',
      userListUrl: '',
      templateDownloadUrl: '',
      authInfo: this.$store.state.user.authInfo,
      flagSaveButtonEnable: true, //#1892
      addLimit: 0, // 追加可能のユーザー数
      isScreenReady: false,
      execUserID: this.$store.state.user.userID,
    };
  },

  validations() {
    return userValidation.create;
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-users-add');
      return `${baseClassName} ${this.class}`.trim();
    },
    maxUser() {
      return MAX_USER;
    },
    canAddUser() {
      if (this.authInfo === 'operator') {
        return false;
      } else {
        return PERMISSIONS_FRONTEND['userAdd'][this.authInfo];
      }
    },
  },
  created() {
    // 作業者として新規ユーザー画面に移動すると４０３ページに遷移する
    if (this.authInfo === 'writer') {
      this.$router.push({ name: 'PermissionDenied' });
      return;
    }
    this.calculateUserAddLimit();
  },
  mounted() {
    this.flagSaveButtonEnable = true;
    if (!this.canAddUser) {
      this.$router.push({ path: '/permission-denied' });
    }
  },
  methods: {
    calculateUserAddLimit() {
      this.postRequest('/users/get-id-list')
        .then(res => {
          const idList = res.allUserIds;
          if (!idList) throw new Error('Fetch user list failed');
          const userCount = Object.keys(idList).length;
          this.addLimit = this.maxUser - userCount;
          this.isScreenReady = true;
        })
        .catch(error => {
          console.error(error);
          this.showErrorModal(messageContent.requireTry('検索'));
        });
    },
    handleSaveClick() {
      // get form data
      this.$refs.form.submit();
    },
    async handleFormSubmit() {
      let isFormCorrect = await this.v$.$validate();

      if (this.userList.length <= 0) return this.showErrorModal(messageContent.requireAdd('ユーザー'));

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.userList.$each.$message.map((item, index) => {
          if (item.length > 0) {
            errMsg += 'ユーザー' + (index + 1) + ':' + '<br>';
            item.map(msg => (errMsg += msg + '<br>'));
            errMsg += '<br>';
          }
        });
        return this.showErrorModal(errMsg);
      }

      this.userList.forEach(user => {
        if (this.hasSpecialCharacterInObject(user)) {
          isFormCorrect = false;
          return this.showErrorModal(messageContent.hasSpecialCharacter);
        }
        // Duplicate check
        let duplicateCount = -1;
        for (const urs of this.userList) {
          if (urs.userID === user.userID) duplicateCount++;
        }
        if (duplicateCount > 0) {
          isFormCorrect = false;
          return this.showErrorModal(`${user.userID}が重複しています。`);
        }
        return '';
      });

      if (!isFormCorrect) return '';
      this.confirmUserInvitationDialogVisible = true;
      this.addUserInvitationConfirmationMessage = messageContent.notifyUserAdd();
      return '';
    },
    handleConfirmUserInvitation() {
      this.confirmUserInvitationDialogVisible = false;
      const data = this.buildPayloadData();
      this.handleUserInvitationAdd(data);
    },
    handleCancelUserInvitation() {
      this.confirmUserInvitationDialogVisible = false;
    },
    buildPayloadData() {
      return {
        mode: this.userInvitationMode,
        data: this.userInvitationMode === 'list' ? this.userList : this.uploadFile,
      };
    },
    handleUserInvitationAdd(data) {
      const formDataTmp = {
        execUserID: this.execUserID,
        userList: this.userList,
      };
      this.flagSaveButtonEnable = false;
      this.postRequest('/users/create', formDataTmp)
        .then(e => {
          if (e.status === 'validationError') {
            this.showErrorModal(e.msg);
            this.flagSaveButtonEnable = true;
            return;
          } else if (e.status === 'failed') {
            this.showErrorModal(e.message);
            this.flagSaveButtonEnable = true;
            return;
          } else if (e.status === 'exceedMaxUser') {
            this.showErrorModal(this.maxUser(this.maxUser));
            this.flagSaveButtonEnable = true;
            return;
          } else if (e.status === 'success') {
            this.showInfoModal(messageContent.userAdded());
          } else if (e.status === 'userExist') {
            this.showErrorModal(messageContent.existItem(e.data.userID));
            this.flagSaveButtonEnable = true;
            return;
          } else {
            this.showErrorModal(messageContent.requireTry('追加'));
            this.flagSaveButtonEnable = true;
            return;
          }
          this.flagSaveButtonEnable = true;
          this.calculateUserAddLimit(); // 追加可能のユーザー数を再計算
        })
        .catch(error => {
          console.error(error);
          this.showErrorModal(messageContent.requireTry('追加'));
          this.flagSaveButtonEnable = true;
        });
      return '';
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
    handleCancelClick() {
      this.$router.push('/users');
    },
    reachLimit(val) {
      if (val) {
        const item = `${this.maxUser}ID`;
        this.showErrorModal(messageContent.reachUserCountLimit(item));
      }
    },
  },
};
</script>
