import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { commonCastDateValidations } from '@/common/commonValidations.js';

const commonCreateUpdateValidations = checkStatus => {
  return {
    onlineShopServiceData: {
      checkStatus: {
        accepted: helpers.withMessage(messageContent.requireSelect('チェックステータス'), value =>
          ['draft', 'checked', 'approved'].includes(value)
        ),
      },
      title: {
        required: helpers.withMessage(messageContent.requireInput('タイトル'), required),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('タイトル', 50),
          maxLength(50)
        ),
      },
      contentsURL: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('URL'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('URL', 500),
          maxLength(500)
        ),
      },
      imageFile: {
        binary: {
          requiredIf: helpers.withMessage(
            messageContent.requireUpload('画像'),
            requiredIf((value, vm) => {
              if (checkStatus === 'draft') return false;
              return !value && !vm.file;
            })
          ),
        },
      },
    },
  };
};

export const onlineShopServiceValidation = {
  create: checkStatus => {
    const obj = commonCreateUpdateValidations(checkStatus);

    obj.onlineShopServiceData.castStartDate = commonCastDateValidations(checkStatus).create.castStartDate;
    obj.onlineShopServiceData.castEndDate = commonCastDateValidations(checkStatus).create.castEndDate;

    return obj;
  },

  update: checkStatus => {
    const obj = commonCreateUpdateValidations(checkStatus);

    obj.onlineShopServiceData.castStartDate = commonCastDateValidations(checkStatus).update.castStartDate;
    obj.onlineShopServiceData.castEndDate = commonCastDateValidations(checkStatus).update.castEndDate;
    obj.isPublished = commonCastDateValidations(checkStatus).update.isPublished('onlineShopServiceData');
    obj.beforeCastEndDate = commonCastDateValidations(checkStatus).update.beforeCastEndDate('onlineShopServiceData');

    return obj;
  },
};
