<template>
  <label :class="componentClassName">
    <input
      v-model="currentValue"
      type="radio"
      class="m-payment-radio__input"
      :name="name"
      :value="value"
      :checked="isChecked"
      v-bind="inputAttrs"
      @click="handleClick"
      @change="handleChange"
    />
    <div class="m-payment-radio__body">
      <div class="m-payment-radio__label-container">
        <div class="m-payment-radio__indicator"></div>
        <div class="m-payment-radio__label">
          {{ label }}
        </div>
        <div v-if="sublabel" className="m-payment-radio__sublabel">
          <a-caption-list>
            <li>{{ sublabel }}</li>
          </a-caption-list>
        </div>
      </div>
      <div v-if="isChecked" class="m-payment-radio__field">
        <template v-if="type === 'credit-card'">
          <m-form-input label="クレジットカード番号">
            <a-text-field
              v-model="currentCardNumber"
              class="m-payment-radio__card-number-input"
              type="text"
              pattern="^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$"
              placeholder="000012345678"
              @change="handleCardNumberChange"
            />
            <template #note>
              <a-caption-list>
                <li>ハイフンなしで、数字のみ入力してください</li>
              </a-caption-list>
            </template>
          </m-form-input>
          <m-form-input label="カードの有効期限">
            <a-expiry-date-field
              v-model="currentCardExpiry"
              class="m-payment-radio__card-expiry-input"
              @change="handleCardExpiryChange"
            />
            <template #note>
              <a-caption-list>
                <li>月2桁、西暦下2桁で入力してください</li>
              </a-caption-list>
            </template>
          </m-form-input>
          <m-form-input label="カード名義人">
            <a-text-field
              v-model="currentCardHolder"
              class="m-payment-radio__card-holder-input"
              type="text"
              pattern="[a-zA-Z]*"
              placeholder="TAROYAMADA"
              @change="handleCardHolderChange"
            />
            <template #note>
              <a-caption-list>
                <li>クレジットカードに記載されているお名前をアルファベットで入力してください</li>
              </a-caption-list>
            </template>
          </m-form-input>
          <m-form-input label="セキュリティコード" :col="4">
            <a-text-field
              v-model="currentCardCvv"
              :short="true"
              type="tel"
              placeholder="000"
              class="m-payment-radio__card-cvv-input"
              maxlength="4"
              @change="handleCardCvvChange"
            />
            <template #note>
              <a-caption-list>
                <li>カード裏面の3または4桁の数字を入力してください</li>
              </a-caption-list>
            </template>
          </m-form-input>
        </template>
        <template v-if="type === 'invoice'">
          <m-form-input label="宛名">
            <a-text-field
              v-model="currentInvoiceAddress"
              class="m-payment-radio__invoice-address-input"
              type="text"
              placeholder="山田太郎"
              :disabled="currentInvoiceSameAddress"
              @change="handleInvoiceAddressChange"
            />
          </m-form-input>
          <m-form-input label="電話番号">
            <a-text-field
              v-model="currentInvoicePhone"
              class="m-payment-radio__invoice-phone-input"
              type="text"
              placeholder="0512341234"
              :disabled="currentInvoiceSameAddress"
              @change="handleInvoicePhoneChange"
            />
          </m-form-input>
          <m-form-input label="住所">
            <a-text-field
              v-model="currentInvoiceStreet"
              class="m-payment-radio__invoice-street-input"
              type="text"
              placeholder="入力してください"
              :disabled="currentInvoiceSameAddress"
              @change="handleInvoiceStreetChange"
            />
          </m-form-input>
          <m-form-input>
            <a-checkbox
              v-model="currentInvoiceSameAddress"
              class="m-payment-radio__invoice-same-address-input"
              label="契約情報と同じ"
              value="1"
              @change="handleInvoiceSameAddressChange"
            />
          </m-form-input>
        </template>
      </div>
    </div>
  </label>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import MFormInput from '@/components/molecules/form-input';
import ATextField from '@/components/atoms/text-field';
import ACaptionList from '@/components/atoms/caption-list';
import ACheckbox from '@/components/atoms/checkbox';
import AExpiryDateField from '@/components/atoms/expiry-date-field';

export default {
  name: 'm-payment-radio',
  components: {
    MFormInput,
    ATextField,
    ACaptionList,
    ACheckbox,
    AExpiryDateField,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    sublabel: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'credit-card',
      validator(value) {
        return value.match(/null|credit-card|invoice/);
      },
    },
    modelValue: {
      type: String,
      default: null,
    },
    // credit card v-model
    cardNumber: {
      type: String,
      default: null,
    },
    cardExpiry: {
      type: String,
      default: null,
    },
    cardHolder: {
      type: String,
      default: null,
    },
    cardCvv: {
      type: String,
      default: null,
    },
    // invoice v-model
    invoiceStreet: {
      type: String,
      default: null,
    },
    invoicePhone: {
      type: String,
      default: null,
    },
    invoiceAddress: {
      type: String,
      default: null,
    },
    invoiceSameAddress: {
      type: Boolean,
      default: null,
    },
  },
  emits: [
    'click',
    'change',
    'update:modelValue',
    'update:cardNumber',
    'update:cardExpiry',
    'update:cardHolder',
    'update:cardCvv',
    'update:invoiceAddress',
    'update:invoicePhone',
    'update:invoiceStreet',
    'update:invoiceSameAddress',
  ],
  data() {
    return {
      isChecked: this.checked || false,
      currentValue: '',
      currentCardNumber: '',
      currentCardExpiry: '',
      currentCardHolder: '',
      currentCardCvv: '',
      currentInvoiceAddress: '',
      currentInvoicePhone: '',
      currentInvoiceStreet: '',
      currentInvoiceSameAddress: true,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-payment-radio',
        this.sublabel ? 'sublabel' : '',
        this.disabled ? 'disabled' : '',
        this.isChecked ? 'checked' : '',
        this.type
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { checked, placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    modelValue(val, oldValue) {
      this.setCurrentValue(val);
    },
    checked(val) {
      this.isChecked = val;
    },
    cardNumber(val, oldValue) {
      this.setValue('currentCardNumber', val);
    },
    cardExpiry(val, oldValue) {
      this.setValue('currentCardExpiry', val);
    },
    cardHolder(val, oldValue) {
      this.setValue('currentCardHolder', val);
    },
    cardCvv(val, oldValue) {
      this.setValue('currentCardCvv', val);
    },
    invoiceAddress(val, oldValue) {
      this.setValue('currentInvoiceAddress', val);
    },
    invoicePhone(val, oldValue) {
      this.setValue('currentInvoicePhone', val);
    },
    invoiceStreet(val, oldValue) {
      this.setValue('currentInvoiceStreet', val);
    },
    invoiceSameAddress(val, oldValue) {
      this.setValue('currentInvoiceSameAddress', val);
    },
  },
  mounted() {
    if (this.checked) {
      this.isChecked = this.checked;
    }
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
    if (this.cardNumber) {
      this.setValue('currentCardNumber', this.cardNumber);
    }
    if (this.cardExpiry) {
      this.setValue('currentCardExpiry', this.cardExpiry);
    }
    if (this.cardHolder) {
      this.setValue('currentCardHolder', this.cardHolder);
    }
    if (this.cardCvv) {
      this.setValue('currentCardCvv', this.cardCvv);
    }
    if (this.invoiceAddress) {
      this.setValue('currentInvoiceAddress', this.invoiceAddress);
    }
    if (this.invoicePhone) {
      this.setValue('currentInvoicePhone', this.invoicePhone);
    }
    if (this.invoiceStreet) {
      this.setValue('currentInvoiceStreet', this.invoiceStreet);
    }
    if (this.invoiceSameAddress) {
      this.setValue('currentInvoiceSameAddress', this.invoiceSameAddress);
    }
  },
  methods: {
    handleClick(event) {
      const value = event.target.value;
      this.setCurrentValue(value);
      this.$emit('click', event);
    },
    handleChange(event) {
      this.setCurrentValue(event.target.value);
      this.isChecked = event.target.checked;
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', event);
    },
    handleCardNumberChange(event) {
      this.handleInputChange('card', 'cardNumber', event);
    },
    handleCardExpiryChange(event) {
      this.handleInputChange('card', 'cardExpiry', event);
    },
    handleCardHolderChange(event) {
      this.handleInputChange('card', 'cardHolder', event);
    },
    handleCardCvvChange(event) {
      this.handleInputChange('card', 'cardCvv', event);
    },
    handleInvoiceAddressChange(event) {
      this.handleInputChange('invoice', 'invoiceAddress', event);
    },
    handleInvoicePhoneChange(event) {
      this.handleInputChange('invoice', 'invoicePhone', event);
    },
    handleInvoiceStreetChange(event) {
      this.handleInputChange('invoice', 'invoiceStreet', event);
    },
    handleInvoiceSameAddressChange(event) {
      this.currentInvoiceSameAddress = event.target.checked;
      if (this.currentInvoiceSameAddress) {
        // clear
        this.currentInvoiceAddress = '';
        this.currentInvoicePhone = '';
        this.currentInvoiceStreet = '';
        this.$emit(`update:invoiceAddress`, this.currentInvoiceAddress);
        this.$emit(`update:invoicePhone`, this.currentInvoicePhone);
        this.$emit(`update:invoiceStreet`, this.currentInvoiceAStreet);
      }
      this.$emit(`update:invoiceSameAddress`, this.currentInvoiceSameAddress);
      this.$emit('change', event);
    },
    handleInputChange(type, field, event) {
      const dataName = field.replace(type, `current${type[0].toUpperCase()}${type.substring(1)}`);
      this.setValue(dataName, event.target ? event.target.value : event);
      this.$emit(`update:${field}`, this[dataName]);
      this.$emit('change', event);
    },
    setCurrentValue(val) {
      this.currentValue = val;
      this.isChecked = this.currentValue === this.value;
    },
    setValue(field, val) {
      this[field] = val;
    },
  },
};
</script>
