<template>
  <div :class="componentClassName">
    <div class="t-default-layout__nav">
      <o-global-sidebar
        :key="sidebarKey"
        :navigation-items="navigationItems"
        :current-active-item-id="activeNavId"
        :current-user-info="currentUserInfo"
        :handle-online-status-change="handleOnlineStatusChange"
        :handle-logout="handleLogout"
        :user-name="userName"
      ></o-global-sidebar>
    </div>
    <div class="t-default-layout__main">
      <slot></slot>
    </div>

    <teleport to="body">
      <o-modal
        id="DefaultLayout_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal-remove-phone-number-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="close-modal-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import AText from '@/components/atoms/text';
import OModal from '@/components/organisms/modal';
import OGlobalSidebar from '@/components/organisms/global-sidebar';
import { NAVIGATION_ITEMS } from '@/libs/constants';
import { cloneDeep } from 'lodash';
import { messageContent } from '@/common/messageContent.js';
import { customRequest } from '@/common/customRequest.js';

export default {
  name: 't-default-layout',
  components: {
    AButton,
    AText,
    OModal,
    OGlobalSidebar,
  },
  mixins: [messageContent, customRequest],
  props: {
    class: {
      type: String,
      default: '',
    },
    activeNavId: {
      type: String,
      default: '',
    },
    handleOnlineStatusChange: {
      type: Function,
      default: () => {},
    },
    userName: {
      type: String,
      default: '',
    },
    userID: {
      type: String,
      default: '',
    },
    memo: {
      type: String,
      default: '',
    },
    currentUserInfo: {
      type: Object,
      default: null,
      //default: {},
    },
  },
  emits: ['update-client-list', 'update-calling-modal-fullscreen', 'update-memo-empty-status', 'sync-memo'],
  data() {
    return {
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      voiceToTextOn: false,
      navigationItems: [],
      sidebarKey: 0,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-default-layout');
      return `${baseClassName} ${this.class}`.trim();
    },
    // isManager() {
    //   return this.currentUserInfo?.auth_info === 'manager';
    // },
    // isOperator() {
    //   return this.currentUserInfo?.auth_info === 'operator';
    // },
    // isSoOperator() {
    //   return this.currentUserInfo?.auth_info === 'soOperator';
    // },
    // isComOperator() {
    //   return this.isComUser && this.isOperator;
    // },
    // isComSoOperator() {
    //   return this.isComUser && this.isSoOperator;
    // },
    // isGeneralOperator() {
    //   return this.isGeneralUser && this.isOperator;
    // },
  },
  watch: {
    voiceToTextOn(val, old) {
      this.navigationItems = this.getNavigationItems();
      // サイドバーを再描画
      this.sidebarKey++;
    },
  },
  created() {
    this.navigationItems = this.getNavigationItems();
  },
  methods: {
    getNavigationItems() {
      const navigationItems = cloneDeep(NAVIGATION_ITEMS);
      // if (this.isGeneralUser) {
      // const navigationItemsIndex = navigationItems.findIndex(({ nav_id }) => nav_id === 'settings');
      // navigationItems[navigationItemsIndex].items = navigationItems[navigationItemsIndex].items.filter(
      //   item =>
      //     item.nav_id !== 'settings-request' &&
      //     // item.nav_id !== 'price-history-list-mgmt' &&
      //     item.nav_id !== 'number-mgmt' &&
      //     item.nav_id !== 'number-mgmt-fdnd' // #3112
      // );
      // }

      // メニュー表示判別処理
      for (let i = 0; i < navigationItems.length; i++) {
        // 設定
        if (navigationItems[i].nav_id === 'settings') {
          navigationItems[i] = this.processSettingNavItems(navigationItems[i]);
        }
      }

      return navigationItems;
    },
    processSettingNavItems(settings) {
      // for (let j = 0; j < settings.items.length; j++) {}
      return settings;
    },
    async handleLogout() {
      const self = this;
      await this.postRequest('/login/logout').then(data => {
        if (data.status === 'success') {
          window.location.href = '/login';
        } else {
          this.showErrorModal(messageContent.requireTry('ログアウト'));
        }
      });
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
  },
};
</script>
