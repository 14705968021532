<template>
  <div :class="componentClassName">
    <div v-if="!customHeader" class="m-action-card__header">
      <div class="m-action-card__header-title">
        <a-icon v-if="icon" :icon="icon" color="orange" />
        <a-heading :id="id" :heading="title" tag="h4" size="small" />
        <div v-if="help" class="m-action-card__header-title-help">
          <a-button :inline="true" @click="handleHelpClick">
            <a-icon icon="help-outline" color="black" />
          </a-button>
        </div>
      </div>
      <div class="m-action-card__header-actions">
        <slot name="action"></slot>
      </div>
      <div v-if="documentId" class="m-action-card__header-document-id">
        <a-heading
          tag="h4"
          :heading="`ドキュメントID: ${documentId}`"
          size="small"
          :data-testid="testIdText + '-document-id-display'"
        />
      </div>
    </div>
    <div v-if="customHeader" class="m-action-card__custom-header">
      <div class="m-action-card__custom-header-title">
        <div class="m-action-card__custom-header-title-icon"><a-icon v-if="icon" :icon="icon" color="orange" /></div>
        <div class="m-action-card__custom-header-title-custom"><slot name="customTitle"></slot></div>
      </div>
    </div>
    <div class="m-action-card__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon';
import AHeading from '@/components/atoms/heading';
import AButton from '@/components/atoms/button';

export default {
  name: 'm-action-card',
  components: {
    AIcon,
    AHeading,
    AButton,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    customHeader: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    help: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    headerBorder: {
      type: Boolean,
      default: false,
    },
    noHeaderMargin: {
      type: Boolean,
      default: false,
    },
    narrowBody: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'actionCardHeadingTitle',
    },
    documentId: {
      type: String,
      default: '',
    },
    testIdText: {
      type: String,
      default: '',
    },
  },
  emits: ['help:click'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-action-card',
        this.border ? 'bordered' : '',
        this.headerBorder ? 'header-bordered' : '',
        this.noHeaderMargin ? 'no-header-margin' : '',
        this.narrowBody ? 'narrow-body' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    handleHelpClick(e) {
      this.$emit('help:click', e);
    },
  },
};
</script>
