<template>
  <tr :class="componentClassName">
    <td :class="valueColumnClassName('label')">
      {{ label }}
      <div v-if="help" class="a-data-list-item__col--label-help">
        <a-button :inline="true" @click="handleHelpClick">
          <a-icon icon="help-outline" color="black" />
        </a-button>
      </div>
    </td>
    <td :class="valueColumnClassName('value')">
      <div class="a-data-list-item__col-content">
        <slot>{{ content }}</slot>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-data-list-item',
  props: {
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    help: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    verticalAlign: {
      type: String,
      default: 'middle',
      validator(value) {
        return value.match(/(top|middle|bottom)/);
      },
    },
    align: {
      type: String,
      default: 'left',
      validator(value) {
        return value.match(/(left|center|right)/);
      },
    },
    paragraph: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['help:click'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-data-list-item', `valign-${this.verticalAlign}`);
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    valueColumnClassName(type) {
      return mapModifiers(
        'a-data-list-item__col',
        type,
        this.align ? `align-${this.align}` : '',
        this.verticalAlign ? `valign-${this.verticalAlign}` : '',
        this.paragraph ? 'paragraph' : ''
      );
    },
    handleHelpClick(e) {
      this.$emit('help:click', e);
    },
  },
};
</script>
