<template>
  <div :class="componentClassName">
    <h4 v-if="title" class="a-alert__title">{{ title }}</h4>
    <slot></slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AText from '@/components/atoms/text/index.vue';

export default {
  name: 'a-alert',
  props: {
    class: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return value.match(/(medium|small)/);
      },
    },
    title: {
      type: String,
      default: null,
    },
    titleSize: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|large)/);
      },
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|primary|error)/);
      },
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-alert',
        this.size,
        this.color ? `color-${this.color}` : '',
        this.titleSize ? `size-${this.titleSize}` : '',
        this.$slots.default ? '' : 'no-children'
      );
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
