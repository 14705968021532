<template>
  <section :class="componentClassName">
    <slot></slot>
  </section>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'm-section',
  props: {
    class: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(fullpage)/) || value === null;
      },
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-section', this.variant);
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
