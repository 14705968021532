<template>
  <li :id="navId" :class="componentClassName">
    <div v-if="hasSubmenu" class="a-navigation-item__caret">
      <a-icon :icon="caretShape" :color="caretColor" />
    </div>
    <a-button
      :id="'navigationItems' + navId"
      :data-testid="navId + '-page-navigation'"
      variant="text"
      :icon="active ? activeIcon : icon"
      :icon-size="iconSize"
      :icon-color="iconColor"
      :href="href"
      :label="label"
      :color="fontColor"
      @click="handleClick"
      @mouseover="handleMouseOver"
      @mouseout="handleMouseOut"
    />
    <ul v-if="hasSubmenu" class="a-navigation-item__items">
      <slot></slot>
    </ul>
  </li>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'a-navigation-item',
  components: {
    AButton,
    AIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    navId: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    activeIcon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: null,
      validator(value) {
        return (
          value === null ||
          (value.match(/[0-9]+(rem|em|px)/g) !== null && value.match(/[0-9]+(rem|em|px)/g).length === 1)
        );
      },
    },
    label: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: null,
    },
    expand: {
      type: Boolean,
      default: null,
    },
    hasSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      collapsed: true,
      hover: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-navigation-item',
        this.active ? 'active' : '',
        this.icon ? 'with-icon' : '',
        this.hasSubmenu ? 'with-submenu' : '',
        this.hasSubmenu ? `child-${this.childCount}` : '',
        this.hasSubmenu && this.collapsed ? 'collapsed' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    iconColor() {
      return this.active ? 'orange' : 'black';
    },
    fontColor() {
      return this.active ? 'primary' : null;
    },
    caretShape() {
      return this.collapsed ? 'caret-right' : 'caret-down';
    },
    caretColor() {
      return this.active || this.hover ? 'orange' : 'black';
    },
    childCount() {
      if (this.$slots.default) {
        const defaultSlot = this.$slots.default();
        if (defaultSlot.length > 0) {
          const firstEl = defaultSlot[0];
          if (firstEl.type.toString().toLowerCase().indexOf('symbol') !== -1) {
            return firstEl.children.length;
          }
          return defaultSlot.length;
        }
      }
      return 0;
    },
  },
  watch: {
    expand(val, oldVal) {
      this.collapsed = !val;
    },
  },
  mounted() {
    if (this.expand) {
      this.collapsed = !this.expand;
    }
  },
  methods: {
    handleClick(e) {
      if (this.hasSubmenu) {
        this.setCollapsed(!this.collapsed);
      }
      this.$emit('click', e);
    },
    handleMouseOver(e) {
      this.hover = true;
    },
    handleMouseOut(e) {
      this.hover = false;
    },
    setCollapsed(val) {
      this.collapsed = val;
    },
  },
};
</script>
