<template>
  <div :class="componentClassName">
    <div ref="hiddenColumns" class="m-data-table-services__hidden-columns"><slot></slot></div>
    <div v-if="showHeader" ref="headerWrapper" class="m-data-table__header">
      <a-data-table-header
        ref="tableHeader"
        :columns="columns"
        :style="{
          width: layout.bodyWidth ? layout.bodyWidth + 'px' : '',
        }"
      />
    </div>
    <div ref="bodyWrapper" class="m-data-table-services__body" :style="[bodyHeight]">
      <div v-if="isLoading" class="m-data-table-services__loading">
        <a-loading />
      </div>
      <a-data-table-body-services
        v-if="!isLoading"
        :table="currentTable"
        :columns="columns"
        :data="data"
        :style="{
          width: layout.bodyWidth ? layout.bodyWidth + 'px' : '',
        }"
        :is-history-list-table="true"
        :id-text="idText"
        @rowClick="rowClick($event.row)"
        @positionUpdate="positionUpdate"
      />
      <div
        v-if="(!data || data.length === 0) && !isLoading"
        ref="emptyBlock"
        class="m-data-table-services__body-empty"
        :style="{
          width: layout.bodyWidth ? layout.bodyWidth + 'px' : '',
        }"
      >
        <slot name="empty">
          <a-text :center="true">{{ emptyText }}</a-text>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AText from '@/components/atoms/text';
import ALoading from '@/components/atoms/loading';
import ADataTableHeader from '@/components/atoms/data-table-header';
import ADataTableBodyServices from '@/components/atoms/data-table-body-services';
import TableLayoutObserver from '@/libs/table-layout-observer';
import TableLayout from './table-layout';
import mitt from 'mitt';
import { addResizeListener, removeResizeListener } from '@/libs/resize-event';

let tableIdSeed = 1;

export default {
  name: 'm-data-table-services',
  components: {
    AText,
    ALoading,
    ADataTableHeader,
    ADataTableBodyServices,
  },
  mixins: [TableLayoutObserver],
  props: {
    class: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: new Array(0),
    },
    emptyText: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'normal',
      validator(value) {
        return value.match(/(normal|row)/);
      },
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 80,
    },
    height: {
      type: Number,
      default: undefined,
    },
    maxHeight: {
      type: Number,
      default: undefined,
    },
    fit: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    cellClassName: {
      type: String,
      default: undefined,
    },
    selectedRows: {
      type: Array,
      default: new Array(0),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    withSelection: {
      type: Boolean,
      default: false,
    },
    isHistoryListTable: {
      type: Boolean,
      default: false,
    },
    idText: {
      type: String,
      default: '',
    },
  },
  emits: ['selection-change', 'rowClick', 'positionUpdate'],
  data() {
    const layout = new TableLayout({
      // store,
      table: this,
      fit: this.fit,
      showHeader: this.showHeader,
    });
    return {
      layout,
      tableId: tableIdSeed,
      columnIdSeed: 1,
      emitter: null,
      columns: [],
      resizeState: {
        width: null,
        height: null,
      },
      currentSelectedRows: [],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-data-table-services',
        this.variant,
        this.border ? 'bordered' : '',
        !this.showHeader ? 'no-header' : '',
        this.isLoading ? 'is-loading' : '',
        this.withSelection ? 'with-selection' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    bodyHeight() {
      if (this.height) {
        return {
          height: this.layout.bodyHeight ? this.layout.bodyHeight + 'px' : '',
        };
      } else if (this.maxHeight) {
        return {
          'max-height':
            (this.showHeader
              ? this.maxHeight - this.layout.headerHeight - this.layout.footerHeight
              : this.maxHeight - this.layout.footerHeight) + 'px',
        };
      }
      return {};
    },
    currentTable() {
      return this;
    },
  },
  watch: {
    selectedRows: {
      handler(val) {
        this.currentSelectedRows.splice(0);
        if (Array.isArray(val)) {
          val.forEach(index => {
            this.currentSelectedRows.push(index);
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.selectedRows) {
      this.currentSelectedRows.splice(0);
      this.selectedRows.forEach(index => {
        this.currentSelectedRows.push(index);
      });
    }

    this.bindEvents();
    this.doLayout();

    this.resizeState = {
      width: this.$el.offsetWidth,
      height: this.$el.offsetHeight,
    };
  },
  created() {
    this.tableId = `m-table_${tableIdSeed}`;
    tableIdSeed += 1;
  },
  beforeMount() {
    this.emitter = mitt();
    this.emitter.on('column:inserted', this.handleColumnInserted);
    this.emitter.on('row:selected', this.handleRowSelected);
  },
  beforeUnmount() {
    if (this.emitter) {
      this.emitter.off('column:inserted', this.handleColumnInserted);
      this.emitter.on('row:selected', this.handleRowSelected);
    }
  },
  unmounted() {
    if (this.resizeListener) removeResizeListener(this.$el, this.resizeListener);
  },
  methods: {
    handleColumnInserted({ columnConfig, columnIndex }) {
      if (typeof index !== 'undefined') {
        this.columns.splice(columnIndex, 0, columnConfig);
      } else {
        this.columns.push(columnConfig);
      }
      this.doLayout();
    },
    handleRowSelected({ rowSelected, rowIndex }) {
      const currentRowSelectedIndex = this.currentSelectedRows.indexOf(rowIndex);
      // update selected index
      if (rowSelected) {
        if (currentRowSelectedIndex === -1) {
          this.currentSelectedRows.push(rowIndex);
        }
      } else {
        if (currentRowSelectedIndex !== -1) {
          this.currentSelectedRows.splice(currentRowSelectedIndex, 1);
        }
      }
      this.$emit('selection-change', this.currentSelectedRows);
    },
    bindEvents() {
      const { headerWrapper, footerWrapper, bodyWrapper } = this.$refs;
      const refs = this.$refs;
      const vm = this;

      bodyWrapper.addEventListener('scroll', function () {
        if (headerWrapper) headerWrapper.scrollLeft = this.scrollLeft;
        if (footerWrapper) footerWrapper.scrollLeft = this.scrollLeft;
        if (refs.fixedBodyWrapper) refs.fixedBodyWrapper.scrollTop = this.scrollTop;
        if (refs.rightFixedBodyWrapper) refs.rightFixedBodyWrapper.scrollTop = this.scrollTop;
        const maxScrollLeftPosition = this.scrollWidth - this.offsetWidth - 1;
        const scrollLeft = this.scrollLeft;
        if (scrollLeft >= maxScrollLeftPosition) {
          vm.scrollPosition = 'right';
        } else if (scrollLeft === 0) {
          vm.scrollPosition = 'left';
        } else {
          vm.scrollPosition = 'middle';
        }
      });

      if (this.fit) {
        addResizeListener(this.$el, this.resizeListener);
      }
    },
    resizeListener() {
      let shouldUpdateLayout = false;
      const el = this.$el;
      const { width: oldWidth, height: oldHeight } = this.resizeState;

      const width = el.offsetWidth;
      if (oldWidth !== width) {
        shouldUpdateLayout = true;
      }

      const height = el.offsetHeight;
      if ((this.height || this.shouldUpdateHeight) && oldHeight !== height) {
        shouldUpdateLayout = true;
      }

      if (shouldUpdateLayout) {
        this.resizeState.width = width;
        this.resizeState.height = height;
        this.doLayout();
      }
    },
    doLayout() {
      this.layout.updateColumnsWidth();
      if (this.shouldUpdateHeight) {
        this.layout.updateElsHeight();
      }
    },
    rowClick(row) {
      this.$emit('rowClick', { row: row });
    },
    positionUpdate(data) {
      this.$emit('positionUpdate', data);
    },
  },
};
</script>
