<template>
  <div :class="componentClassName" />
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-section-divider',
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-section-divider');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
