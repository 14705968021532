<script lang="jsx">
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { computed, getCurrentInstance, onMounted, onUnmounted, toRefs, ref } from 'vue';
import { mapModifiers } from '@/libs/component';
import { assign, cloneDeep } from 'lodash';
import mitt from 'mitt';

let columnIdSeed = 1;
const defaults = {
  default: {
    order: '',
  },
  selection: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: '',
    className: 'a-data-table-column--selection',
  },
  index: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: '',
  },
  action: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: '',
  },
};

const getDefaultColumn = (type, options) => {
  const column = {};

  assign(column, defaults[type || 'default']);

  for (const name in options) {
    // eslint-disable-next-line no-prototype-builtins
    if (options.hasOwnProperty(name)) {
      const value = options[name];
      if (typeof value !== 'undefined') {
        column[name] = value;
      }
    }
  }

  if (!column.minWidth) {
    column.minWidth = 80;
  }

  column.realWidth = typeof column.width === 'undefined' ? column.minWidth : column.width;

  return column;
};

export default {
  name: 'a-data-table-column',
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return value.match(/default|index|selection|action/);
      },
    },
    label: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
    minWidth: {
      type: [Number, String],
      default: undefined,
    },
    align: {
      type: String,
      default: 'left',
      validator(value) {
        return value.match(/left|center|right/);
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance();
    const parent = instance.parent;

    const currentSelectedRows = computed(() => parent.data.currentSelectedRows);

    const defaultRenderCell = function (data) {
      const { column, row, index, isGreyBg, isOrangeBg } = data;
      const className =
        isGreyBg || isOrangeBg ? 'm-data-table--cell-grey a-data-table-body__cell' : 'a-data-table-body__cell';
      if (context.slots.default) {
        return <div className={className}>{context.slots.default(data)}</div>;
      } else {
        return <div className={className}>{row[column.prop]}</div>;
      }
    };

    const defaultRenderHeader = function (data) {
      const { column, index, handleShowFilter } = data;
      if (column.filterable && context.slots.filter) {
        return (
          <div className="a-data-table-header__cell">
            <div id={column.prop} className="a-data-table-header__cell-content" onClick={() => handleShowFilter(index)}>
              <span v-html={column.label}></span>
              <a-icon icon="filter" color="orange" />
            </div>
            <div className="a-data-table-header__filter-body">{context.slots.filter(data)}</div>
          </div>
        );
      } else if (column.isButton && context.slots.button) {
        return <div>{context.slots.button()}</div>;
      } else {
        return <div className="a-data-table-header__cell" v-html={column.label}></div>;
      }
    };

    const onSelectionChange = (e, index) => {
      parent.data.emitter.emit('row:selected', { rowSelected: e.target.checked, rowIndex: index });
    };

    const selectionRenderCell = function (data) {
      const { column, row, index } = data;
      const cbxID = `data-table-column-cbx${data.index}`;
      data.isChecked = parent.props.selectedRows.indexOf(index) !== -1;
      return (
        <div className="a-data-table-body__cell">
          <a-checkbox
            id={cbxID}
            v-model={data.isChecked}
            onChange={e => {
              onSelectionChange(e, index);
            }}
          />
        </div>
      );
    };

    const columnId = `${parent.data.tableId}_column_${columnIdSeed}`;
    columnIdSeed += 1;

    const column = getDefaultColumn(props.type, {
      id: columnId,
      class: props.class,
      label: props.label,
      prop: props.prop,
      width: props.width,
      minWidth: props.minWidth,
      index: props.index,
      type: props.type,
      align: props.align ? 'a-data-table-column--align-' + props.align : null,
      nowrap: props.nowrap ? 'a-data-table-column--nowrap' : null,
      isChecked: false,
      filterable: props.filterable,
      isButton: props.isButton,
    });

    column.renderHeader = defaultRenderHeader;
    if (props.type === 'selection') {
      column.renderCell = selectionRenderCell;
    } else {
      column.renderCell = defaultRenderCell;
    }

    const columnConfig = cloneDeep(column);

    const componentClassName = computed(() => {
      const baseClassName = mapModifiers('m-data-table-column');
      return `${baseClassName} ${props.class || ''}`.trim();
    });

    onMounted(() => {
      const children = parent.refs.hiddenColumns ? parent.refs.hiddenColumns.children : null;
      const el = instance.vnode.el;
      let columnIndex = -1;
      if (Array.isArray(children)) {
        children.forEach((cel, index) => {
          if (cel === el) {
            columnIndex = index;
          }
        });
      }
      if (parent.data.emitter) {
        parent.data.emitter.emit('column:inserted', { columnConfig, columnIndex });
      }
    });

    return {
      componentClassName,
      column,
    };
  },
  render() {
    return (
      <div className={this.componentClassName}>
        {this.$slots.defaults ? this.$slots.defaults() : null}
        {this.$slots.filter ? this.$slots.filter() : null}
        {this.$slots.button ? this.$slots.button() : null}
      </div>
    );
  },
};
</script>
