<template>
  <div :class="componentClassName">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      enable-background="new 0 0 48 48"
      xml:space="preserve"
    >
      <path
        d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C47.9851203,37.2486657 37.2486657,47.9851203 24,48 L24,48 Z M24,5.539 C16.532636,5.53859554 9.80035075,10.0365692 6.94252744,16.9354365 C4.08470413,23.8343039 5.66417094,31.7753817 10.9443946,37.0556054 C16.2246183,42.3358291 24.1656961,43.9152959 31.0645635,41.0574726 C37.9634308,38.1996493 42.4614045,31.467364 42.461,24 C42.4499782,13.8086782 34.1913212,5.54957384 24,5.538 L24,5.539 Z"
        fill="#FCC884"
        opacity="0.4"
      ></path>
      <path
        d="M42.462,23.999 C42.4504262,13.8076788 34.1913218,5.54902181 24,5.538 L24,0 C37.2488939,0.0143296952 47.9856703,10.7511061 48,24 L42.462,23.999 Z"
        fill="#F8923E"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 24 24"
          to="360 24 24"
          dur="0.5s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-loading',
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-loading');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
