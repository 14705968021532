<template>
  <t-default-layout active-nav-id="news-and-seminars">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="NewsAndSeminars" />
      <form
        ref="form"
        class="t-news-and-seminars-add__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-news-and-seminars-add__fields">
          <m-section>
            <m-action-card :id="idName[1]" title="詳細設定" header-border no-header-margin icon="clock">
              <div class="t-news-and-seminars-add__fields">
                <div class="t-news-and-seminars-add__row">
                  <m-form-input id="t-news-and-seminars-add-importantFlag__form-input">
                    <a-text id="t-news-and-seminars-add-importantFlag__a-text">優先</a-text>
                    <a-switch
                      id="t-news-and-seminars-add-importantFlag"
                      v-model="newsAndSeminarData.importantFlag"
                      data-testid="add-news-and-seminar-priority-toggle-button"
                    />
                  </m-form-input>
                </div>
                <o-transition-type-selector
                  v-model:transitionType="newsAndSeminarData.transitionType"
                  v-model:transitionDoc="newsAndSeminarData.transitionDoc"
                  v-model:contentsUrl="newsAndSeminarData.contentsURL"
                />
                <div id="t-news-and-seminars-add-radio" class="t-news-and-seminars-add__row">
                  <m-form-input>
                    <m-news-and-seminar
                      id="radio-news"
                      v-model="newsAndSeminarData.type"
                      data-testid="add-news-and-seminar-select-news"
                      name="flag"
                      value="news"
                      label="ニュース"
                    />
                    <m-news-and-seminar
                      id="radio-seminar"
                      v-model="newsAndSeminarData.type"
                      data-testid="add-news-and-seminar-select-seminar"
                      name="flag"
                      value="seminar"
                      label="セミナー"
                    />
                  </m-form-input>
                </div>
                <div id="t-news-and-seminars-add-uploading-file" class="t-news-and-seminars-add__row">
                  <div class="t-news-and-seminars-add__col-1">
                    <div class="t-news-and-seminars-add__fields">
                      <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                      <!-- <m-form-input>
                        <a-upload-field
                          id="upload-image"
                          v-model="newsAndSeminarData.imageFile.file"
                          name="imageFile"
                          class="t-news-and-seminars-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                          @change="handleUploadFileChange('imageFile', newsAndSeminarData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input> -->
                      <m-form-input>
                        <a-upload-field
                          id="upload-image"
                          v-model="newsAndSeminarData.imageFile.file"
                          test-id-text="add-news-and-seminar-image"
                          name="imageFile"
                          class="t-news-and-seminars-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange('imageFile', newsAndSeminarData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-news-and-seminars-add__col-2">
                    <div class="t-news-and-seminars-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="newsAndSeminarData.title"
                          data-testid="add-news-and-seminar-title-input"
                          type="text"
                          maxlength="30"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-news-and-seminars-add__fields">
                <div id="t-news-and-seminars-add-cast-start-date-day__row" class="t-news-and-seminars-add__row">
                  <a-text id="t-news-and-seminars-add-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-news-and-seminars-add-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="newsAndSeminarData.castStartDate"
                      test-id-text="add-news-and-seminar-publish-start"
                      :min-date="minDate"
                      date-placeholder="公開日"
                      time-placeholder="00:00"
                      mode="dateTime"
                    />
                  </m-form-input>
                </div>
                <div class="t-news-and-seminars-add__row">
                  <a-text id="t-news-and-seminars-add-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-news-and-seminars-add-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="newsAndSeminarData.castEndDate"
                      test-id-text="add-news-and-seminar-publish-end"
                      :min-date="minDate"
                      date-placeholder="公開終了日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-news-and-seminars-add__fields__pushtype">
                <m-form-input id="t-news-and-seminars-add__fields__pushtype">
                  <a-select-field
                    id="t-news-and-seminars-add__fields__pushtype__a-select-field"
                    v-model="newsAndSeminarData.checkStatus"
                    data-testid="add-news-and-seminar-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="add-news-and-seminar-save-button"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="news-and-seminarsCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import MNewsAndSeminar from '@/components/molecules/news-and-seminar-radio';
import OTransitionTypeSelector from '@/components/organisms/transition-type-selector';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { newsAndSeminarValidation } from '@/validations/newsAndSeminar.js';
import { cloneDeep } from 'lodash';

export default {
  name: 't-news-and-seminars-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASwitch,
    AUploadField,
    ACalendarField,
    MNewsAndSeminar,
    AApplyTextField,
    OTransitionTypeSelector,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,

      //おすすめの記事・セミナーデータ
      newsAndSeminarData: {
        title: '', //おすすめの記事・セミナータイトル
        imageURL: '', //画像の公開URL
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        importantFlag: false, //優先フラグ
        castStartDate: '', //公開開始日
        castEndDate: '', //公開終了日
        deleteDate: '', //削除予定日
        draftFlag: true, //下書き
        checkStatus: 'draft', //作成時のチェックステータスは下書き
        type: 'news', //タイプ
        contentsURL: '', //遷移先URL
        transitionType: 'url', //遷移先種別
        transitionDoc: '', //遷移先ドキュメントID
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
    };
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-news-and-seminars-add');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 1; i <= 4; i++) {
      this.idName[i] = 'h1-news-and-seminars-add-' + i;
    }
  },
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.newsAndSeminarData);
      //  URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.contentsURL = encodeURI(payload.contentsURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      if (this.newsAndSeminarData.castEndDate) {
        const castEndDate = new Date(this.newsAndSeminarData.castEndDate);
        const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
        this.deleteDate = this.convertDateToString(deleteDate);
      }
      const formDataTmp = {
        title: payload.title ?? '',
        imageFile: this.newsAndSeminarData.imageFile.binary ?? '',
        importantFlag: this.newsAndSeminarData.importantFlag ?? false,
        castStartDate: this.newsAndSeminarData.castStartDate ?? '',
        castEndDate: this.newsAndSeminarData.castEndDate ?? '',
        deleteDate: this.deleteDate ?? '',
        draftFlag: this.newsAndSeminarData.draftFlag ?? false,
        checkStatus: this.newsAndSeminarData.checkStatus ?? '',
        imageURL: this.newsAndSeminarData.imageURL ?? '',
        type: this.newsAndSeminarData.type ?? '',
        contentsURL: encodeURI(this.newsAndSeminarData.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
        transitionType: this.newsAndSeminarData.transitionType ?? 'url',
        transitionDoc: this.newsAndSeminarData.transitionDoc ?? '',
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/news-and-seminars/add', formDataTmp).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'NewsAndSeminars' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('おすすめの記事・セミナー作成'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleUploadFileChange(type, file) {
      const vm = this;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          vm.newsAndSeminarData[type].binary = content;
        },
        false
      );

      if (file[0]) {
        reader.readAsDataURL(file[0]);
        vm.newsAndSeminarData[type].filename = file[0].name;
        vm.newsAndSeminarData[type].size = file[0].size;

        if (type === 'imageFile' && vm.newsAndSeminarData[type].filename !== null) {
          vm.agree = true;
        }
      } else {
        // clear file content
        vm.newsAndSeminarData[type].file = null;
        vm.newsAndSeminarData[type].filename = null;
        vm.newsAndSeminarData[type].binary = null;
        vm.newsAndSeminarData[type].size = null;

        if (type === 'imageFile' && vm.newsAndSeminarData[type].filename === null) {
          vm.agree = false;
        }
      }
    },
    handleChange() {},
    handleCancelClick() {
      this.$router.push({ name: 'NewsAndSeminars' });
    },

    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
