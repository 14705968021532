<template>
  <t-default-layout active-nav-id="services">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="Services" :show="show" />
      <form ref="form" class="t-services-detail__form" enctype="multipart/form-data" @submit.prevent="handleFormSubmit">
        <div class="t-services-detail__fields">
          <m-section>
            <m-action-card
              :id="idName[1]"
              test-id-text="detail-services"
              title="詳細設定"
              :document-id="id"
              header-border
              no-header-margin
              icon="clock"
            >
              <div class="t-services-detail__fields">
                <div class="t-services-detail__row">
                  <div class="t-services-detail__form-col-1">
                    <div class="t-services-detail__fields">
                      <m-form-input id="t-services-detail-homeBubbleFlag__form-input">
                        <a-text id="t-services-detail-homeBubbleFlag__a-text">ホーム画面吹き出し</a-text>
                        <a-switch
                          id="t-services-detail-homeBubbleFlag"
                          v-model="servicesData.homeBubbleFlag"
                          data-testid="detail-services-priority-toggle-button"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-services-detail__form-col-2">
                    <div class="t-services-detail__bubble">
                      <a-apply-text-field
                        id="t-services-detail-homeBubbleComment"
                        v-model="servicesData.homeBubbleComment"
                        data-testid="detail-services-homeBubble-input"
                        type="text"
                        maxlength="6"
                        placeholder="ホーム画面の吹き出し入力"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div id="t-services-detail-uploading-file" class="t-services-detail__row">
                  <div class="t-services-detail__col-1">
                    <div class="t-services-detail__fields">
                      <m-form-input>
                        <a-upload-field
                          id="t-services-detail-upload-image"
                          v-model="servicesData.imageFile.file"
                          test-id-text="detail-services-image"
                          name="imageFile"
                          class="t-services-detail-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          :disabled="true"
                          @change="handleUploadFileChange('imageFile', servicesData.imageFile.file)"
                        />
                      </m-form-input>
                      <m-form-input id="t-services-add-info-bubbleFlag__form-input">
                        <a-text id="t-services-add-info-bubbleFlag__a-text">吹き出し</a-text>　　
                        <a-switch
                          id="t-services-add-bubbleFlag"
                          v-model="servicesData.bubbleFlag"
                          data-testid="add-bubbleFlag-detail-bubbleFlag-toggle-button"
                          :disabled="true"
                        />
                      </m-form-input>
                      <m-form-input id="t-services-add-info-serviceOverview__form-input">
                        <a-text id="t-services-add-info-serviceOverview__a-text">サービス概要</a-text>
                      </m-form-input>
                      <m-form-input id="t-services-add-info-contentsURL__form-input">
                        <a-text id="t-services-add-info-contentsURL__a-text">Webビュー</a-text>　　
                        <a-switch
                          id="t-services-add-webviewFlag"
                          v-model="servicesData.webviewFlag"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-services-add__col-2">
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="serviceName1"
                          v-model="servicesData.serviceName1"
                          data-testid="add-services-serviceName1-input"
                          type="text"
                          maxlength="10"
                          placeholder="サービス名1入力"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                    <m-form-input>
                      <a-apply-text-field
                        id="serviceName2"
                        v-model="servicesData.serviceName2"
                        data-testid="add-services-serviceName2-input"
                        type="text"
                        maxlength="10"
                        placeholder="サービス名2入力"
                        :disabled="true"
                      />
                    </m-form-input>
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="bubbleComment"
                          v-model="servicesData.bubbleComment"
                          data-testid="add-services-bubbleComment-input"
                          type="text"
                          maxlength="10"
                          placeholder="吹き出しコメント入力"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="serviceOverview"
                          v-model="servicesData.serviceOverview"
                          data-testid="add-services-serviceOverview-input"
                          type="text"
                          maxlength="30"
                          placeholder="サービス概要入力"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                    <m-form-input>
                      <a-apply-text-field
                        id="t-services-add__contents-url"
                        v-model="servicesData.contentsURL"
                        class="t-services-add__contents-url"
                        :disabled="true"
                        data-testid="t-services-add-url-input"
                        type="text"
                        maxlength="500"
                        placeholder="遷移先URL入力"
                      />
                    </m-form-input>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card
              :id="idName[2]"
              title="サービス概要ページ"
              header-border
              no-header-margin
              icon="calendar-setting"
            >
              <div class="t-services-detail__fields">
                <div class="t-services-detail__row">
                  <a-text class="t-individual-level1__a-text">概要ページ</a-text>　
                  <m-form-input :id="'t-individual-radio'">
                    <a-radio
                      :id="'t-individual-true'"
                      v-model="servicesData.individualFlagStr"
                      value="need"
                      label="要"
                      disabled="disabled"
                      @change="handleIndividualFlag(true)"
                    />
                    <a-radio
                      :id="'t-individual-false'"
                      v-model="servicesData.individualFlagStr"
                      value="noneed"
                      label="不要"
                      disabled="disabled"
                      @change="handleIndividualFlag(false)"
                    />
                  </m-form-input>
                </div>
                <div v-if="servicesData.individualFlag">
                  <!-- サービスイメージ画像 -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">サービスイメージ画像</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-upload-field
                          v-model="servicesData.individualInfo.imageFile.file"
                          test-id-text="add-services-image"
                          name="imageFile"
                          class="t-services-detail-uploading-file"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          disabled="disabled"
                          @change="handleUploadFileChange('imageFile', servicesData.individualInfo.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- サービス概要 -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">サービス概要</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          id="description"
                          v-model="servicesData.individualInfo.description"
                          data-testid="add-services-description-input"
                          class="t-banners-add__content-input"
                          type="textarea"
                          rows="4"
                          maxlength="90"
                          placeholder="サービス説明"
                          disabled="disabled"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- 料金説明 -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">料金説明</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          id="priceDescription"
                          v-model="servicesData.individualInfo.priceDescription"
                          data-testid="add-services-priceDescription-input"
                          type="textarea"
                          rows="4"
                          maxlength="90"
                          placeholder="料金説明"
                          disabled="disabled"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- 申込ページURL -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">申込ページURL</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-2">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">Webビュー</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.appWebviewFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          disabled="disabled"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ログイン要求</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.appLoginFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          disabled="disabled"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          v-model="servicesData.individualInfo.appURL"
                          data-testid="add-services-serviceOverview-input"
                          type="text"
                          maxlength="500"
                          placeholder="遷移先URL"
                          disabled="disabled"
                        />
                      </m-form-input>
                      <div v-if="servicesData.individualInfo.appLoginFlag">
                        <m-form-input>
                          <a-text class="t-individual-level1__a-text">アプリ申し込みページ連携</a-text>　　
                          <a-switch
                            v-model="servicesData.individualInfo.appCollaborationFlag"
                            class="t-individual-switch"
                            data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                            disabled="disabled"
                          />
                        </m-form-input>
                      </div>
                    </div>
                  </div>
                  <!-- 利用者向けURL -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ご利用中の方向けURL</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-2">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">Webビュー</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.customerWebviewFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          disabled="disabled"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">直接アクセス設定</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.customerAccessFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          disabled="disabled"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          v-model="servicesData.individualInfo.customerURL"
                          data-testid="add-services-serviceOverview-input"
                          type="text"
                          maxlength="500"
                          placeholder="遷移先URL"
                          disabled="disabled"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- サービスの特徴 -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">サービスの特徴</a-text>
                      </m-form-input>
                    </div>
                  </div>
                  <div
                    v-for="(feature, index) in servicesData.individualInfo.features"
                    :key="index"
                    class="t-services-detail__fields"
                  >
                    <div class="t-services-detail__row">
                      <div class="t-services-detail__col-features-1">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text">特徴{{ index + 1 }}</a-text>
                        </m-form-input>
                      </div>
                      <div class="t-services-detail__col-features-2">
                        <m-form-input>
                          <a-upload-field
                            v-model="feature.imageFile.file"
                            name="imageFile"
                            class="t-services-detail-uploading-file"
                            label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                            accept="image/png, image/jpg, image/jpeg"
                            disabled="disabled"
                            @change="handleUploadFileChangeImg(feature.imageFile)"
                            @showErrorModal="showErrorModal"
                          />
                        </m-form-input>
                      </div>
                      <div class="t-services-detail__col-features-3">
                        <!--  子カテゴリ削除ボタン-->
                        <a-button
                          v-if="index === servicesData.individualInfo.features.length - 1 && index > 0"
                          id="deleteBtn"
                          class="t-services-delete-feature"
                          variant="round"
                          size="small"
                          label="削除"
                          color="grey"
                          icon="trash"
                          icon-color="black"
                          :inline="true"
                          disabled="disabled"
                          @click="handleDeleteFeature(index)"
                        />
                        <m-form-input v-if="index === 0">
                          <a-button
                            data-testid="services-add-features-button"
                            class="t-individual-add-button"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            disabled="disabled"
                            @click="handleAddFeature"
                          />
                          <span class="t-individual-add-button-red-text">特徴追加ボタン</span>
                        </m-form-input>
                        <div class="t-services-detail__fields">
                          <m-form-input>
                            <a-apply-text-field
                              id="t-service-add-feature-description{{ index }}"
                              v-model="feature.description"
                              data-testid="add-services-feature{{ index }}-input"
                              type="textarea"
                              rows="3"
                              maxlength="40"
                              placeholder="特徴説明"
                              disabled="disabled"
                            />
                          </m-form-input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ご利用までの流れ -->
                  <!-- 左設置タブ -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ご利用までの流れ<br />左設置タブ</a-text>
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level2__a-text">左タブ名</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-2">
                      <m-form-input>
                        <a-apply-text-field
                          id="t-services-detail-tab1-title"
                          v-model="servicesData.individualInfo.tabs[0].title"
                          data-testid="add-services--title-input"
                          type="text"
                          maxlength="10"
                          placeholder="左タブ名称"
                          disabled="disabled"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-text class="t-individual-level2__a-text"></a-text>
                      </m-form-input>
                    </div>
                  </div>
                  <div v-for="(item, index) in servicesData.individualInfo.tabs[0].flow" :key="index">
                    <div class="t-services-detail__row t-services-detail__fields">
                      <div class="t-services-detail__col-features-1">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text">左タブ流れ{{ index + 1 }}</a-text>
                        </m-form-input>
                      </div>
                      <div class="t-services-detail__col-features-2">
                        <m-form-input>
                          <a-upload-field
                            v-model="item.imageFile.file"
                            test-id-text="add-services-image"
                            name="imageFile"
                            class="t-services-detail-uploading-file"
                            label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                            accept="image/png, image/jpg, image/jpeg"
                            disabled="true"
                            @change="handleUploadFileChangeImg(item.imageFile)"
                            @showErrorModal="showErrorModal"
                          />
                        </m-form-input>
                      </div>
                      <div class="t-services-detail__col-features-3">
                        <!--  子カテゴリ削除ボタン-->
                        <a-button
                          v-if="index === servicesData.individualInfo.tabs[0].flow.length - 1 && index > 0"
                          id="deleteBtn"
                          class="t-services-delete-flow"
                          data-testid="t-support-delete-button"
                          variant="round"
                          size="small"
                          label="削除"
                          color="grey"
                          icon="trash"
                          icon-color="black"
                          :inline="true"
                          disabled="disabled"
                          @click="handleDeleteFlow(0, index)"
                        />
                        <m-form-input v-if="index === 0">
                          <a-button
                            id="t-services-detail-tab1-flow-button"
                            data-testid="services-add-features-button"
                            class="t-individual-add-button"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            disabled="disabled"
                            @click="handleAddFlow(0)"
                          />
                          <span class="t-individual-add-button-red-text">左タブ流れ追加ボタン</span>
                        </m-form-input>
                        <div class="t-services-detail__fields">
                          <m-form-input>
                            <a-apply-text-field
                              id="t-service-add-tab1-flow{{ index }}-description"
                              v-model="item.description"
                              data-testid="add-services-tab1-flow{{ index }}-description-input"
                              type="textarea"
                              rows="3"
                              maxlength="40"
                              placeholder="流れ説明"
                              disabled="disabled"
                            />
                          </m-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 右タブ設置 -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ご利用までの流れ<br />右設置タブ</a-text>
                      </m-form-input>
                    </div>

                    <div class="t-services-detail__col-features-3">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">右タブ設置</a-text>　　
                        <a-switch
                          id="t-services-detail-rightTabFlag"
                          v-model="servicesData.individualInfo.rightTabFlag"
                          class="t-individual-switch"
                          data-testid="add-righttab-flag-toggle-button"
                          disabled="disabled"
                          @change="handleRightTabFlag"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div v-if="servicesData.individualInfo.rightTabFlag">
                    <div class="t-services-detail__row">
                      <div class="t-services-detail__col-features-1">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text">右タブ名</a-text>
                        </m-form-input>
                      </div>
                      <div class="t-services-detail__col-features-2">
                        <m-form-input>
                          <a-apply-text-field
                            id="t-services-detail-tab1-title"
                            v-model="servicesData.individualInfo.tabs[1].title"
                            data-testid="add-services-title-input"
                            type="text"
                            maxlength="10"
                            placeholder="右タブ名称"
                            disabled="disabled"
                          />
                        </m-form-input>
                      </div>
                      <div class="t-services-detail__col-features-3">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text"></a-text>
                        </m-form-input>
                      </div>
                    </div>
                    <div v-for="(item, index) in servicesData.individualInfo.tabs[1].flow" :key="index">
                      <div class="t-services-detail__row t-services-detail__fields">
                        <div class="t-services-detail__col-features-1">
                          <m-form-input>
                            <a-text class="t-individual-level2__a-text">右タブ流れ{{ index + 1 }}</a-text>
                          </m-form-input>
                        </div>
                        <div class="t-services-detail__col-features-2">
                          <m-form-input>
                            <a-upload-field
                              v-model="item.imageFile.file"
                              name="imageFile"
                              class="t-services-detail-uploading-file"
                              label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                              accept="image/png, image/jpg, image/jpeg"
                              disabled="true"
                              @change="handleUploadFileChangeImg(item.imageFile)"
                              @showErrorModal="showErrorModal"
                            />
                          </m-form-input>
                        </div>
                        <div class="t-services-detail__col-features-3">
                          <!--  子flow削除ボタン-->
                          <a-button
                            v-if="index === servicesData.individualInfo.tabs[1].flow.length - 1 && index > 0"
                            id="deleteBtn"
                            class="t-services-delete-flow"
                            data-testid="t-services-delete-button"
                            variant="round"
                            size="small"
                            label="削除"
                            color="grey"
                            icon="trash"
                            icon-color="black"
                            :inline="true"
                            disabled="disabled"
                            @click="handleDeleteFlow(1, index)"
                          />

                          <m-form-input v-if="index === 0">
                            <a-button
                              id="t-services-detail-tab1-flow-button"
                              data-testid="services-add-features-button"
                              class="t-individual-add-button"
                              variant="icon"
                              color="primary"
                              icon="plus"
                              icon-color="white"
                              disabled="disabled"
                              @click="handleAddFlow(1)"
                            />
                            <span class="t-individual-add-button-red-text">右タブ流れ追加ボタン</span>
                          </m-form-input>
                          <div class="t-services-detail__fields">
                            <m-form-input>
                              <a-apply-text-field
                                v-model="item.description"
                                data-testid="add-services-tab1-flow{{ index }}-description-input"
                                type="textarea"
                                rows="3"
                                maxlength="40"
                                placeholder="流れ説明"
                                disabled="disabled"
                              />
                            </m-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- お問い合わせ -->
                  <div class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">お問い合わせ</a-text>
                      </m-form-input>
                    </div>
                  </div>

                  <div v-if="!servicesData.individualInfo.inquiryFlag" class="t-services-detail__row">
                    <div class="t-services-detail__col-features-1">
                      <m-form-input>
                        <a-button
                          id="t-services-detail-support-button"
                          data-testid="services-add-support-button"
                          class="t-individual-add-button"
                          variant="icon"
                          color="primary"
                          icon="plus"
                          icon-color="white"
                          disabled="disabled"
                          @click="handleInquiryAdd"
                        />
                        <span class="t-individual-add-button-red-text">大カテゴリ追加ボタン</span>
                      </m-form-input>
                    </div>
                  </div>
                  <div v-if="servicesData.individualInfo.inquiryFlag" class="t-services-detail__inquiry">
                    <div class="t-services-add__fields">
                      <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                        <a-text id="t-services-add__fields__a-text-top">●</a-text>
                        <a-text id="t-services-add__fields__support__a-text">大カテゴリ1</a-text>
                        <a-button
                          id="deleteBtn"
                          class="t-support-delete-category"
                          data-testid="t-support-delete-button"
                          variant="round"
                          size="small"
                          label="削除"
                          color="grey"
                          icon="trash"
                          icon-color="black"
                          :inline="true"
                          disabled="disabled"
                          @click="handleInquiryDelete"
                        />
                      </div>
                    </div>
                    <div class="t-services-add__fields">
                      <div class="t-services-add__row">
                        <a-text id="t-services-add-support__a-text">大カテゴリ名1</a-text>　
                        <m-form-input id="t-services-add__pushtype">
                          <a-apply-text-field
                            :id="'mainCategoryName' + index"
                            v-model="servicesData.individualInfo.inquiry.mainCategoryName"
                            class="t"
                            :data-testid="'add-services-mainCategoryName-input' + index"
                            type="text"
                            maxlength="50"
                            :placeholder="`大カテゴリ名1入力`"
                            disabled="disabled"
                          />
                        </m-form-input>
                      </div>
                    </div>
                    <div
                      v-for="(subCategory, subIndex) in servicesData.individualInfo.inquiry.subCategories"
                      :key="subIndex"
                    >
                      <div class="t-services-add__fields">
                        <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                          <a-text id="t-services-add__fields__a-text-top">●</a-text>
                          <a-text id="t-services-add__fields__support__a-text">小カテゴリ{{ subIndex + 1 }}</a-text>
                          <a-button
                            v-if="subIndex === 0"
                            id="t-services-add-support-button"
                            data-testid="services-detail-support-button"
                            class="t-supprt-subcategory-add"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            disabled="disabled"
                            @click="handleAddSubForInquiry"
                          />
                          <span v-if="subIndex === 0" class="t-add-button-method-red-text">小カテゴリ追加ボタン</span>
                          <!--  子カテゴリ削除ボタン-->
                          <a-button
                            v-if="subIndex > 0"
                            id="deleteBtn"
                            class="t-support-delete-category"
                            data-testid="t-support-delete-button"
                            variant="round"
                            size="small"
                            label="削除"
                            color="grey"
                            icon="trash"
                            icon-color="black"
                            :inline="true"
                            disabled="disabled"
                            @click="handleDeleteSubForInquiry(subIndex)"
                          />
                        </div>
                      </div>
                      <div class="t-services-add__fields">
                        <div class="t-services-add__row">
                          <a-text id="t-services-add-support__a-text">小カテゴリ名{{ subIndex + 1 }}</a-text
                          >　
                          <m-form-input id="t-services-detail__pushtype">
                            <a-apply-text-field
                              id="subCategoriesName"
                              v-model="subCategory.name"
                              class="t-services-add__pushtype"
                              data-testid="add-services-subCategoryName-input"
                              type="text"
                              maxlength="50"
                              :placeholder="`小カテゴリ名${subIndex + 1}入力`"
                              disabled="disabled"
                            />
                          </m-form-input>
                        </div>
                        <div class="t-services-add__row">
                          <a-text id="t-services-add-support__a-text">お問い合わせ種類</a-text>　
                          <m-form-input :id="'t-support-radio' + index + subIndex">
                            <a-radio
                              v-model="subCategory.contactType"
                              class="t-support-link"
                              :disabled="true"
                              :data-testid="'t-support-link' + subIndex"
                              value="link"
                              label="外部リンク"
                              @change="setDefaultContactMethodLinkForInquiry(subIndex)"
                            />
                            <a-radio
                              v-model="subCategory.contactType"
                              class="t-services-button"
                              :disabled="true"
                              :data-testid="'t-support-button' + subIndex"
                              value="button"
                              label="ボタン"
                              @change="setDefaultContactMethodForInquiry(subIndex)"
                            />
                          </m-form-input>
                        </div>
                        <div v-if="subCategory.contactType === 'button'" class="t-services-add__row">
                          <a-text id="t-services-add-support__a-text"
                            >補足説明&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a-text
                          >　
                          <m-form-input id="t-services-detail__pushtype">
                            <a-apply-text-field
                              id="description"
                              v-model="subCategory.description"
                              class="t-services-add__pushtype"
                              data-testid="add-services-description-input"
                              type="text"
                              maxlength="50"
                              placeholder="フリーテキスト（様々なパターンありそうなため）"
                              disabled="disabled"
                            />
                          </m-form-input>
                        </div>
                        <div class="t-services-add__row">
                          <m-form-input v-if="subCategory.contactType === 'link'">
                            <a-apply-text-field
                              id="t-support-url"
                              v-model="subCategory.contactInfo[0].target"
                              class="m-form-input__content"
                              disabled="disabled"
                              :data-testid="'add-support-url-input-for-inquiry' + subIndex"
                              type="text"
                              maxlength="500"
                              placeholder="URLを入力"
                            />
                          </m-form-input>
                        </div>
                        <div v-if="subCategory.contactType === 'button'">
                          <div v-for="(method, methodIndex) in subCategory.contactInfo" :key="methodIndex">
                            <div class="t-services-add__row">
                              <a-text id="t-services-add-method__a-text">お問い合わせ方法{{ methodIndex + 1 }} </a-text
                              >　
                              <a-button
                                v-if="methodIndex === 0"
                                id="t-services-detail-method-button"
                                data-testid="services-add-method-button-for-inquiry"
                                variant="icon"
                                color="primary"
                                icon="plus"
                                icon-color="white"
                                disabled="disabled"
                                @click="handleAddMethodForInquiry(subCategory)"
                              />
                              <span v-if="methodIndex === 0" class="t-add-button-method-red-text"
                                >問合せ追加ボタン</span
                              >
                              <!--  削除ボタン-->
                              <a-button
                                v-if="methodIndex > 0"
                                id="deleteBtn"
                                data-testid="t-support-delete-button-for-inquery"
                                variant="round"
                                size="small"
                                label="削除"
                                color="grey"
                                icon="trash"
                                icon-color="black"
                                :inline="true"
                                disabled="disabled"
                                @click="handleDeleteMethodForInquiry(subIndex, methodIndex)"
                              />
                            </div>
                            <div class="t-services-add__row t-support-contact">
                              <a-radio
                                id="t-support-button-mail"
                                v-model="method.method"
                                class="t-support-button-mail"
                                disabled="disabled"
                                :name="'methodContactForInquiry' + subIndex + methodIndex"
                                :data-testid="'t-support-button-for-inquery' + subIndex"
                                value="mail"
                                label="メールでお問い合わせ"
                              />
                              <a-radio
                                id="t-support-button-phone"
                                v-model="method.method"
                                class="t-support-button-phone"
                                disabled="disabled"
                                :name="'methodContactForInquiry' + subIndex + methodIndex"
                                :data-testid="'t-support-button-phone-for-inquery' + subIndex"
                                value="phone"
                                label="電話でお問い合わせ"
                              />
                              <a-radio
                                id="t-support-button-form"
                                v-model="method.method"
                                class="t-support-button-form"
                                disabled="disabled"
                                :name="'methodContactForInquery' + subIndex + methodIndex"
                                :data-testid="'t-support-button-phone-for-inquery' + subIndex"
                                value="form"
                                label="フォームでお問い合わせ"
                              />
                              <a-radio
                                id="t-support-button-shop"
                                v-model="method.method"
                                class="t-support-button-shop"
                                disabled="disabled"
                                :name="'methodContactForInquery' + subIndex + methodIndex"
                                :data-testid="'t-support-button-shop-for-inquery' + subIndex"
                                value="shop"
                                label="ドコモショップでお問い合わせ"
                              />
                            </div>
                            <div class="t-services-add__row">
                              <m-form-input>
                                <a-apply-text-field
                                  id="t-support-url"
                                  v-model="method.target"
                                  class="t-add-method"
                                  disabled="disabled"
                                  :data-testid="'add-target-input-for-inquery' + subIndex + methodIndex"
                                  type="text"
                                  maxlength="500"
                                  placeholder="メールアドレスor電話番号orURLを入力"
                                />
                              </m-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-action-card
            :id="idName[3]"
            title="サポートページ
"
            header-border
            no-header-margin
            icon="calendar-setting"
          >
            <template #action>
              <a-button
                id="t-services-add-support-button"
                data-testid="services-add-support-button"
                class="t-services-add-support-heading-item"
                variant="icon"
                color="primary"
                icon="plus"
                icon-color="white"
              />
              <span class="t-add-button-support-red-text">大カテゴリ追加ボタン</span>
            </template>
            <!-- サポート情報がある確認 -->
            <div v-if="servicesData.supportInfo.length > 0">
              <div
                v-for="(support, index) in servicesData.supportInfo"
                id="t-services-add-homeBubbleFlag__form-input"
                :key="index"
              >
                <div class="t-services-add__fields">
                  <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                    <a-text id="t-services-add__fields__a-text-top">●</a-text>
                    <a-text id="t-services-add__fields__support__a-text">大カテゴリ{{ index + 1 }}</a-text>
                    <a-button
                      id="deleteBtn"
                      class="t-support-delete-category"
                      data-testid="t-support-delete-button"
                      variant="round"
                      size="small"
                      label="削除"
                      color="grey"
                      icon="trash"
                      icon-color="black"
                      :inline="true"
                    />
                  </div>
                </div>
                <div class="t-services-add__fields">
                  <div class="t-services-add__row">
                    <a-text id="t-services-add-support__a-text">大カテゴリ名{{ index + 1 }}</a-text
                    >　
                    <m-form-input id="t-services-add__pushtype">
                      <a-apply-text-field
                        :id="'mainCategoryName' + index"
                        v-model="servicesData.supportInfo[index].mainCategoryName"
                        class="t"
                        :data-testid="'add-services-mainCategoryName-input' + index"
                        type="text"
                        maxlength="50"
                        :disabled="true"
                        :placeholder="`大カテゴリ名${index + 1}入力`"
                      />
                    </m-form-input>
                  </div>
                </div>
                <div v-for="(subCategory, subIndex) in support.subCategories" :key="subIndex">
                  <div class="t-services-add__fields">
                    <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                      <a-text id="t-services-add__fields__a-text-top">●</a-text>
                      <a-text id="t-services-add__fields__support__a-text">小カテゴリ{{ subIndex + 1 }}</a-text>
                      <a-button
                        v-if="subIndex === 0"
                        id="t-services-add-support-button"
                        data-testid="services-add-support-button"
                        class="t-supprt-subcategory-add"
                        variant="icon"
                        color="primary"
                        icon="plus"
                        icon-color="white"
                      />
                      <span v-if="subIndex === 0" class="t-add-button-method-red-text">小カテゴリ追加ボタン</span>
                      <!--  子カテゴリ削除ボタン-->
                      <a-button
                        v-if="subIndex > 0"
                        id="deleteBtn"
                        class="t-support-delete-category"
                        data-testid="t-support-delete-button"
                        variant="round"
                        size="small"
                        label="削除"
                        color="grey"
                        icon="trash"
                        icon-color="black"
                        :inline="true"
                      />
                    </div>
                  </div>
                  <div class="t-services-add__fields">
                    <div class="t-services-add__row">
                      <a-text id="t-services-add-support__a-text">小カテゴリ名{{ subIndex + 1 }}</a-text
                      >　
                      <m-form-input id="t-services-add__pushtype">
                        <a-apply-text-field
                          id="subCategoriesName"
                          v-model="subCategory.name"
                          :disabled="true"
                          class="t-services-add__pushtype"
                          data-testid="add-services-subCategoryName-input"
                          type="text"
                          maxlength="50"
                          :placeholder="`小カテゴリ名${subIndex + 1}入力`"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__row">
                      <a-text id="t-services-add-support__a-text">お問い合わせ種類</a-text>　
                      <m-form-input :id="'t-support-radio' + index + subIndex">
                        <a-radio
                          :id="'t-support-link' + index + subIndex"
                          v-model="subCategory.contactType"
                          class="t-support-link"
                          :disabled="true"
                          :name="'contactSelectLink' + index + subIndex"
                          :data-testid="'t-support-link' + subIndex"
                          value="link"
                          label="外部リンク"
                        />
                        <a-radio
                          :id="'t-support-button' + index + subIndex"
                          v-model="subCategory.contactType"
                          class="t-services-button"
                          :disabled="true"
                          :name="'contactSelectButton' + index + subIndex"
                          :data-testid="'t-support-button' + subIndex"
                          value="button"
                          label="ボタン"
                        />
                      </m-form-input>
                    </div>
                    <div v-if="subCategory.contactType === 'button'" class="t-services-add__row">
                      <a-text id="t-services-add-support__a-text"
                        >補足説明&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a-text
                      >　
                      <m-form-input id="t-services-add__pushtype">
                        <a-apply-text-field
                          id="description"
                          v-model="subCategory.description"
                          class="t-services-add__pushtype"
                          data-testid="add-services-description-input"
                          type="text"
                          maxlength="50"
                          :disabled="true"
                          placeholder="フリーテキスト（様々なパターンありそうなため）"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__row">
                      <m-form-input v-if="subCategory.contactType === 'link'">
                        <a-apply-text-field
                          id="t-support-url"
                          v-model="subCategory.contactInfo[0].target"
                          class="m-form-input__content"
                          :disabled="true"
                          :data-testid="'add-support-url-input' + subIndex"
                          type="text"
                          maxlength="500"
                          placeholder="URLを入力"
                        />
                      </m-form-input>
                    </div>
                    <div v-if="subCategory.contactType === 'button'">
                      <div v-for="(method, methodIndex) in subCategory.contactInfo" :key="methodIndex">
                        <div class="t-services-add__row">
                          <a-text id="t-services-add-method__a-text">問合せ方法{{ methodIndex + 1 }} </a-text>　
                          <a-button
                            v-if="methodIndex === 0"
                            id="t-services-add-method-button"
                            data-testid="services-add-method-button"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                          />
                          <span v-if="methodIndex === 0" class="t-add-button-method-red-text">問合せ追加ボタン</span>
                          <!--  子カテゴリ削除ボタン-->
                          <a-button
                            v-if="methodIndex > 0"
                            id="deleteBtn"
                            data-testid="t-support-delete-button"
                            variant="round"
                            size="small"
                            label="削除"
                            color="grey"
                            icon="trash"
                            icon-color="black"
                            :inline="true"
                          />
                        </div>
                        <div class="t-services-add__row t-support-contact">
                          <a-radio
                            id="t-support-button-mail"
                            v-model="method.method"
                            class="t-support-button-mail"
                            :disabled="true"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button' + subIndex"
                            value="mail"
                            label="メールでお問い合わせ"
                          />
                          <a-radio
                            id="t-support-button-phone"
                            v-model="method.method"
                            class="t-support-button-phone"
                            :disabled="true"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button-phone' + subIndex"
                            value="phone"
                            label="電話でお問い合わせ"
                          />
                          <a-radio
                            id="t-support-button-form"
                            v-model="method.method"
                            class="t-support-button-form"
                            :disabled="true"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button-phone' + subIndex"
                            value="form"
                            label="フォームでお問い合わせ"
                          />
                          <a-radio
                            id="t-support-button-shop"
                            v-model="method.method"
                            class="t-support-button-shop"
                            :disabled="true"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button-shop' + subIndex"
                            value="shop"
                            label="ドコモショップでお問い合わせ"
                          />
                        </div>
                        <div class="t-services-add__row">
                          <m-form-input>
                            <a-apply-text-field
                              id="t-support-url"
                              v-model="method.target"
                              class="t-add-method"
                              :disabled="true"
                              :data-testid="'add-target-input' + index + subIndex + methodIndex"
                              type="text"
                              maxlength="500"
                              placeholder="メールアドレスor電話番号orURLを入力"
                            />
                          </m-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </m-action-card>
          <m-section>
            <m-action-card :id="idName[4]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-services-detail__fields">
                <div id="t-services-detail-cast-start-date-day__row" class="t-services-detail__row">
                  <a-text id="t-services-detail-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-services-detail-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="servicesData.castStartDate"
                      test-id-text="detail-services-publish-start"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="true"
                    />
                  </m-form-input>
                </div>
                <div class="t-services-detail__row">
                  <a-text id="t-services-detail-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-services-detail-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="servicesData.castEndDate"
                      test-id-text="detail-services-publish-end"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="true"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[5]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-services-detail__fields__pushtype">
                <m-form-input id="t-services-detail__fields__pushtype">
                  <a-select-field
                    id="t-services-detail__fields__pushtype__a-select-field"
                    v-model="servicesData.checkStatus"
                    data-testid="detail-services-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
              <!-- 情報を表示するために m-data-table を追加します 承認フロー -->
              <m-data-table
                :empty-text="dataTableEmp"
                :fit="true"
                :data="servicesData.checkHistories"
                id-text="services-check-histories-info-display"
              >
                <a-data-table-column
                  prop="publishedDocumentId"
                  :min-width="120"
                  :label="'公開ドキュメントID：<br>' + docId"
                  align="center"
                >
                  <template #default="scope">
                    <a-text size="medium" :center="true">公開{{ scope.index + 1 }}回目</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="draft" :min-width="100" label="下書き" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.draft.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.draft.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="checked" :min-width="100" label="チェック済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.checked.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.checked.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="approved" :min-width="100" label="承認済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.approved.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.approved.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
              </m-data-table>
            </m-action-card>
          </m-section>
        </div>
        <div v-if="checkIsManager()" id="t-services-detail-buttons">
          <div v-show="!isPublished" class="t-services-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-services-detail-detail-saveBtn"
                data-testid="detail-services-edit-button"
                variant="block"
                color="primary"
                :disabled="isEnded || disableButton"
                @click="handleNavigateEdit"
                >編集</a-button
              >
            </m-form-action-buttons>
          </div>
          <div v-show="isPublished" class="t-services-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-services-detail-edit-return-unpublished-btn"
                data-testid="detail-services-edit-return-unpublished-button"
                variant="block"
                color="primary"
                :disabled="disableButton"
                @click.stop="handleEditReturnUnpublished"
                >未公開に戻す</a-button
              >
            </m-form-action-buttons>
          </div>
          <div v-show="isPublished" class="t-services-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-services-detail-edit-keep-published-btn"
                data-testid="detail-services-edit-keep-published-button"
                variant="block"
                color="primary"
                :disabled="disabledButton || disableButton"
                @click.stop="handleEditKeepPublished"
                >公開のまま編集</a-button
              >
            </m-form-action-buttons>
          </div>
          <div class="t-services-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-services-detail-detail-deleteBtn"
                data-testid="detail-services-delete-button"
                variant="block"
                color="primary"
                :disabled="disableButton"
                @click.stop="handleConfirmDelete"
                >削除</a-button
              >
            </m-form-action-buttons>
          </div>
        </div>
      </form>
    </div>

    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="online-shop-bannersCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="t-services-remove-modal-close-button"
            data-testid="detail-services-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCloseModal"
          />
          <a-button
            id="t-services-delete-button"
            data-testid="detail-services-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleDelete"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';

export default {
  name: 't-services-detail',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASwitch,
    AUploadField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, customValidate, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      show: this.$route.query.show ?? '1',
      idName: [],
      disableButton: false,
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,
      isPublished: false, //公開中かどうかのフラグ
      docId: (() => {
        const id = this.$route.query.id;
        if (id.includes('_')) {
          const lastIndex = id.lastIndexOf('_');
          return id.slice(0, lastIndex);
        }
        return id;
      })(),

      // コンテンツデータ
      servicesData: {
        supportInfo: [
          {
            mainCategoryName: '', // カテゴリのメイン名
            subCategories: [
              {
                name: '', // 子カテゴリ名
                description: '', // 詳細
                contactType: 'link',
                contactInfo: [
                  {
                    method: '',
                    target: '',
                  },
                ],
              },
            ],
          },
        ],
        serviceName1: '', // サービス名1
        serviceName2: '', // サービス名2
        serviceOverview: '', // サービス概要
        position: 0, // 位置表示
        imageURL: '', // 画像の公開URL
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        homeBubbleFlag: false, // ホーム画面NEW吹き出し
        homeBubbleComment: '', // ホーム画面吹き出し
        webviewFlag: false, // Webビュー
        bubbleFlag: false, // 吹き出しフラグ
        bubbleComment: '', // 吹き出しコメント
        castStartDate: '', // 公開開始日
        castEndDate: '', // 公開終了日
        draftFlag: false, // 下書き
        checkStatus: '', // チェックステータス
        contentsURL: '', // 遷移先URL入力
        individualFlag: false,
        individualFlagStr: 'noneed', // 概要ページフラグ
        individualInfo: {
          imageURL: '', // サービスイメージ画像
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          description: '', // サービス概要
          priceDescription: '', // 料金説明
          appWebviewFlag: false, // 申し込みページのwebviewフラグ
          appURL: '', // 申込ページのURL
          appLoginFlag: false, // 申込ページのログイン要求
          appCollaborationFlag: false, // アプリ申し込みページ連携
          customerWebviewFlag: false, // ご利用中の遷移先のwebviewフラグ
          customerURL: '', // ご利用中の方向けURL
          customerAccessFlag: false, // 直接アクセス設定
          // サービスの特徴
          features: [
            {
              imageURL: '',
              imageFile: {
                file: null,
                filename: null,
                binary: null,
                size: null,
              },
              description: '',
            },
          ],
          // ご利用までの流れ
          rightTabFlag: false, // 右設置タブ有無
          tabs: [
            {
              title: '',
              flow: [
                {
                  imageURL: '',
                  imageFile: {
                    file: null,
                    filename: null,
                    binary: null,
                    size: null,
                  },
                  description: '',
                },
              ],
            },
          ],
          // お問い合わせ
          inquiryFlag: false, // お問い合わせ有無
          inquiry: {
            mainCategoryName: '', // カテゴリのメイン名
            subCategories: [
              {
                name: '', // 子カテゴリ名
                contactType: 'link',
                description: '', // 詳細
                contactInfo: [
                  {
                    method: 'link',
                    target: '',
                  },
                ],
              },
            ],
          },
        },
        checkHistories: [
          {
            draft: {
              userName: '',
              updateDate: '',
            },
            checked: {
              userName: '',
              updateDate: '',
            },
            approved: {
              userName: '',
              updateDate: '',
            },
          },
        ],
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
      isEnded: false,
      nowDate: '',
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-services-detail');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    this.handleGetServicesInfo(() => {
      const userRole = this.$store.state.user.authInfo;
      const checkStatus = this.servicesData.checkStatus;
      if (userRole === 'writer' && checkStatus !== 'draft') {
        this.disableButton = true;
      }
    });
    for (let i = 1; i <= 5; i++) {
      this.idName[i] = 'h1-services-detail-' + i;
    }
  },
  mounted() {},
  methods: {
    checkIsManager() {
      return this.$store.state.user.authInfo === 'manager';
    },
    handleGetServicesInfo(callback) {
      const id = this.$route.query.id || this.id;
      this.nowDate = utils.currentDateTime();
      if (id) {
        this.postRequest('/services/get', { id })
          .then(e => {
            if (e.status === 'success' && e.data) {
              const data = e.data;
              this.servicesData.serviceName1 = data?.serviceName1 || '';
              this.servicesData.serviceName2 = data?.serviceName2 || '';
              this.servicesData.bubbleComment = data?.bubbleComment || '';
              this.servicesData.serviceOverview = data?.serviceOverview || '';
              this.servicesData.imageURL = data?.imageURL || '';
              (this.servicesData.imageFile = {
                file: e.data.imageURL ? e.data.imageURL : null,
              }),
                (this.servicesData.homeBubbleFlag = data?.homeBubbleFlag || false);
              this.servicesData.homeBubbleComment = data?.homeBubbleComment || '';
              this.servicesData.castStartDate = data?.castStartDate || '';
              this.servicesData.castEndDate = data?.castEndDate || '';
              this.servicesData.draftFlag = data?.draftFlag || false;
              this.servicesData.bubbleFlag = data?.bubbleFlag || false;
              this.servicesData.webviewFlag = data?.webviewFlag || false;
              this.servicesData.contentsURL = decodeURI(data?.contentsURL) || '';
              this.servicesData.checkStatus = data?.checkStatus || '';
              this.servicesData.checkHistories = data?.checkHistories || [];
              // 個別ページ情報
              this.servicesData.individualFlag = data?.individualFlag || false;
              this.servicesData.individualFlagStr = data?.individualFlag ? 'need' : 'noneed';
              if (this.servicesData.individualFlag) {
                this.servicesData.individualInfo.imageURL = data?.individualInfo.imageURL || '';
                this.servicesData.individualInfo.imageFile = {
                  file: data?.individualInfo.imageURL !== '' ? data?.individualInfo.imageURL : null,
                };
                this.servicesData.individualInfo.description = data?.individualInfo.description || '';
                this.servicesData.individualInfo.priceDescription = data?.individualInfo.priceDescription || '';
                this.servicesData.individualInfo.appWebviewFlag = data?.individualInfo.appWebviewFlag || false;
                this.servicesData.individualInfo.appURL = decodeURI(data?.individualInfo.appURL) || '';
                this.servicesData.individualInfo.appLoginFlag = data?.individualInfo.appLoginFlag || false;
                this.servicesData.individualInfo.appCollaborationFlag =
                  data?.individualInfo.appCollaborationFlag || false;
                this.servicesData.individualInfo.customerWebviewFlag =
                  data?.individualInfo.customerWebviewFlag || false;
                this.servicesData.individualInfo.customerURL = decodeURI(data?.individualInfo.customerURL) || '';
                this.servicesData.individualInfo.customerAccessFlag = data?.individualInfo.customerAccessFlag || false;
                // 個別ページ特徴
                this.servicesData.individualInfo.features =
                  data?.individualInfo.features.map(feature => ({
                    imageURL: feature.imageURL || '',
                    imageFile: {
                      file: feature.imageURL !== '' ? feature.imageURL : null,
                    },
                    description: feature.description || '',
                  })) || [];
                // 個別ページ流れ
                this.servicesData.individualInfo.tabs =
                  data?.individualInfo.tabs.map(tab => ({
                    title: tab.title || '',
                    flow:
                      tab.flow.map(f => ({
                        imageURL: f.imageURL || '',
                        imageFile: {
                          file: f.imageURL !== '' ? f.imageURL : null,
                        },
                        description: f.description || '',
                      })) || [],
                  })) || [];
                this.servicesData.individualInfo.rightTabFlag = this.servicesData.individualInfo.tabs.length > 1;
                // 個別ページ問い合わせ
                this.servicesData.individualInfo.inquiry.mainCategoryName =
                  data?.individualInfo.inquiry.mainCategoryName || '';
                this.servicesData.individualInfo.inquiry.subCategories =
                  data?.individualInfo.inquiry.subCategories.map(subCategory => ({
                    name: subCategory.name || '',
                    description: subCategory.description || '',
                    contactType: subCategory.contactType || 'link',
                    contactInfo:
                      subCategory.contactInfo.map(contact => ({
                        method: subCategory.contactType === 'link' ? 'link' : contact.method || '',
                        target: decodeURI(contact.target) || '',
                      })) || [],
                  })) || [];
                this.servicesData.individualInfo.inquiryFlag =
                  this.servicesData.individualInfo.inquiry.mainCategoryName !== '' ||
                  this.servicesData.individualInfo.inquiry.subCategories.some(
                    subCategory =>
                      subCategory.name !== '' || subCategory.contactInfo.some(contact => contact.target !== '')
                  );
              }
              //サポート情報
              this.servicesData.supportInfo =
                data?.supportInfo.map(support => ({
                  mainCategoryName: support.mainCategoryName || '',
                  subCategories: support.subCategories.map(subCategory => ({
                    name: subCategory.name || '',
                    description: subCategory.description || '',
                    contactType: subCategory.contactType || 'link',
                    contactInfo: subCategory.contactInfo.map(contact => ({
                      method: subCategory.contactType === 'link' ? 'link' : contact.method || '',
                      target: decodeURI(contact.target) || '',
                    })),
                  })),
                })) || [];
              // 下書きとチェック済み中ではなく、公開終了日時を過ぎている場合、isEndedフラグをtrueにする
              if (this.servicesData.checkStatus === 'approved') {
                if (this.servicesData.castEndDate && this.servicesData.castEndDate < this.nowDate) {
                  this.isEnded = true;
                }
              }
              const nowDate = utils.currentDateTime();
              // 公開中の投稿か確認
              if (
                data &&
                data.checkStatus === 'approved' &&
                data.castStartDate &&
                data.castEndDate &&
                data.castStartDate <= nowDate &&
                nowDate <= data.castEndDate
              ) {
                this.isPublished = true;
              }
            } else {
              this.showErrorModal(messageContent.requireTry('サービス取得'));
            }
            if (typeof callback === 'function') {
              callback();
            }
          })
          .catch(error => {
            console.error(error);
            this.showErrorModal(messageContent.requireTry('サービス取得'));
          });
      }
    },
    async handleEditReturnUnpublished() {
      const data = {
        id: this.$route.query.id,
      };
      await this.postRequest('/services/edit-return-unpublished', data).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Services' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('サービス取得'));
          }
        }
      });
    },
    async handleEditKeepPublished() {
      const data = {
        id: this.$route.query.id,
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/services/edit-keep-published', data).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Services' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('サービス取得'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleNavigateEdit() {
      const id = this.$route.query.id;
      if (id) {
        this.$router.push({ name: 'ServicesEdit', query: { id: id, source: 'detail', show: this.show } });
      }
    },
    handleConfirmDelete() {
      this.resultModalVisible = true;
      this.resultModalTitle = 'Info';
      this.resultModalContent = messageContent.comfirmDelete(this.servicesData.serviceName1);
    },
    handleDelete() {
      this.handleCloseModal();
      const data = {
        execUserID: this.$store.state.user.userID,
        documentID: this.$route.query.id,
      };
      this.postRequest('/services/delete', data).then(e => {
        this.$router.push({ name: 'Services', query: { show: this.show } });
      });
    },
    handleCancelClick() {
      this.$router.push({ name: 'Services', query: { show: this.show } });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
