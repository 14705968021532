<template>
  <p :id="this.textId" :class="componentClassName">
    <slot>{{ text }}</slot>
  </p>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-text',
  props: {
    text: {
      type: String,
      default: null,
    },
    class: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|orange|red)/);
      },
    },
    center: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|light|thin|regular|medium|bold)/);
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return value.match(/(small|medium)/);
      },
    },
    textId: {
      type: String,
      default: null,
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-text',
        this.color ? `color-${this.color}` : '',
        this.center ? 'center' : '',
        this.weight ? `weight-${this.weight}` : '',
        this.size
      );
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
