<template>
  <t-default-layout ref="defaultLayout" active-nav-id="users">
    <div id="t-users-component" :class="componentClassName">
      <a-page-heading>ユーザー管理</a-page-heading>
      <m-section>
        <m-action-card :title="`スーパーユーザー （${adminUserList.length}）`" icon="user-setting">
          <template #action>
            <a-button
              v-if="canAddUser && !isWriterUser"
              id="addUserAdminBtn"
              data-testid="admin-user-add-button"
              :inline="true"
              variant="fill"
              color="primary"
              size="small"
              icon="plus"
              label="ユーザー追加"
              @click="handleUserAdd('superuser')"
            />
          </template>

          <m-data-table
            :empty-text="dataTableEmptyText"
            :fit="true"
            :data="adminUserList"
            id-text="admin-user-info-display"
          >
            <a-data-table-column prop="userID" :min-width="120" label="メールアドレス" align="left" />
            <a-data-table-column prop="userName" :min-width="100" label="名前" align="left">
              <template #default="scope">
                {{ scope.row.userName }}
              </template>
            </a-data-table-column>
            <a-data-table-column prop="departmentInfo" :min-width="100" label="部署" align="left" />
            <a-data-table-column prop="docomoID" :min-width="100" label="dアカウントid" align="left" />
            <a-data-table-column type="action" :width="160" align="center" class="a-data-table-column--action">
              <template #default="scope">
                <a-button
                  v-if="canEditUser && !isWriterUser"
                  :id="'editBtn' + scope.index"
                  :data-testid="'admin-user-edit-button-' + scope.index"
                  variant="round"
                  size="small"
                  label="編集"
                  color="grey"
                  icon="edit"
                  icon-color="black"
                  :inline="true"
                  @click="handleUserEdit('superuser', scope.row)"
                />
                <a-button
                  v-if="canDeleteUser && !isWriterUser"
                  :id="'deleteBtn' + scope.index"
                  :data-testid="'admin-user-delete-button-' + scope.index"
                  variant="round"
                  size="small"
                  label="削除"
                  color="grey"
                  icon="trash"
                  icon-color="black"
                  :inline="true"
                  @click="handleRowUserRemove('superuser', scope.row)"
                />
              </template>
            </a-data-table-column>
          </m-data-table>
        </m-action-card>
      </m-section>

      <m-section>
        <m-action-card :id="idName[2]" :title="`運用者権限ユーザー （${operatorUserList.length}）`" icon="user-setting">
          <template #action>
            <a-button
              v-if="canAddUser && !isWriterUser"
              id="addUserOperatorBtn"
              data-testid="operator-user-add-button"
              :inline="true"
              variant="fill"
              color="primary"
              size="small"
              icon="plus"
              label="ユーザー追加"
              @click="handleUserAdd('operater')"
            />
          </template>

          <m-data-table
            :empty-text="dataTableEmptyText"
            :fit="true"
            :data="operatorUserList"
            id-text="operator-user-info-display"
          >
            <a-data-table-column prop="userID" :min-width="120" label="メールアドレス" align="left" />
            <a-data-table-column prop="userName" :min-width="100" label="名前" align="left">
              <template #default="scope">
                {{ scope.row.userName }}
              </template>
            </a-data-table-column>
            <a-data-table-column prop="departmentInfo" :min-width="100" label="部署" align="left" />
            <a-data-table-column prop="docomoID" :min-width="100" label="dアカウントid" align="left" />
            <a-data-table-column type="action" :width="160" align="center" class="a-data-table-column--action">
              <template #default="scope">
                <a-button
                  v-if="!isWriterUser"
                  :id="scope.row.clientName + '-editBtn'"
                  :data-testid="'operator-user-edit-button-' + scope.index"
                  variant="round"
                  size="small"
                  label="編集"
                  color="grey"
                  icon="edit"
                  icon-color="black"
                  :inline="true"
                  @click="handleUserEdit('operator', scope.row)"
                />
                <a-button
                  v-if="canDeleteUser && !isWriterUser"
                  :id="scope.row.clientName + '-deleteBtn'"
                  :data-testid="'operator-user-delete-button-' + scope.index"
                  variant="round"
                  size="small"
                  label="削除"
                  color="grey"
                  icon="trash"
                  icon-color="black"
                  :inline="true"
                  @click="handleRowUserRemove('operator', scope.row)"
                />
              </template>
            </a-data-table-column>
          </m-data-table>
        </m-action-card>
      </m-section>
      <!-- 作業者権限ユーザーの追加する -->
      <m-section>
        <m-action-card :id="idName[3]" :title="`作業者権限ユーザー （${writerList.length}）`" icon="user-setting">
          <template #action>
            <a-button
              v-if="canAddUser && !isWriterUser"
              id="addUserWriterBtn"
              data-testid="writer-user-add-button"
              :inline="true"
              variant="fill"
              color="primary"
              size="small"
              icon="plus"
              label="ユーザー追加"
              @click="handleUserAdd('writer')"
            />
          </template>

          <m-data-table
            :empty-text="dataTableEmptyText"
            :fit="true"
            :data="writerList"
            id-text="writer-user-info-display"
          >
            <a-data-table-column prop="userID" :min-width="120" label="メールアドレス" align="left" />
            <a-data-table-column prop="userName" :min-width="100" label="名前" align="left">
              <template #default="scope">
                {{ scope.row.userName }}
              </template>
            </a-data-table-column>
            <a-data-table-column prop="departmentInfo" :min-width="100" label="部署" align="left" />
            <a-data-table-column prop="docomoID" :min-width="100" label="dアカウントid" align="left" />
            <a-data-table-column type="action" :width="160" align="center" class="a-data-table-column--action">
              <template #default="scope">
                <a-button
                  v-if="!isWriterUser"
                  :id="scope.row.clientName + '-editBtn'"
                  :data-testid="'writer-user-edit-button-' + scope.index"
                  variant="round"
                  size="small"
                  label="編集"
                  color="grey"
                  icon="edit"
                  icon-color="black"
                  :inline="true"
                  @click="handleUserEdit('writer', scope.row)"
                />
                <a-button
                  v-if="canDeleteUser && !isWriterUser"
                  :id="scope.row.clientName + '-deleteBtn'"
                  :data-testid="'writer-user-delete-button-' + scope.index"
                  variant="round"
                  size="small"
                  label="削除"
                  color="grey"
                  icon="trash"
                  icon-color="black"
                  :inline="true"
                  @click="handleRowUserRemove('writer', scope.row)"
                />
              </template>
            </a-data-table-column>
          </m-data-table>
        </m-action-card>
      </m-section>
    </div>
    <teleport to="body">
      <o-modal
        id="settingUserList_removeModal"
        v-model:visible="removeUserDialogVisible"
        data-testid="user-delete-confirm-modal"
        class="o-modal-remove-users-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        @close="handleCancelRemoveUser"
      >
        <template #title> ユーザー削除 </template>
        <a-text :center="true">
          <!-- eslint-disable -->
          <span data-testid="user-delete-confirm-message-spans" v-html="removeUserConfirmationMessageText()" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="removeUserDialogCancelBtn"
            data-testid="user-delete-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCancelRemoveUser"
          />
          <a-button
            id="removeUserDialogOKBtn"
            data-testid="user-delete-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleConfirmRemoveUser"
          />
        </template>
      </o-modal>
    </teleport>

    <!-- Confirm, Error Modal -->
    <teleport to="body">
      <o-modal
        id="settingUserList_removeNumberModal"
        v-model:visible="resultModalVisible"
        class="o-modal-remove-phone-number-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span data-testid="user-delete-result-content-spans" v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="resultModalOKBtn"
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import { PERMISSIONS_FRONTEND } from '@/libs/constants';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';

export default {
  name: 't-users',
  components: {
    TDefaultLayout,
    APageHeading,
  },
  mixins: [customRequest, customValidate, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  emits: [],
  data() {
    return {
      //USER
      userToRemove: {
        userID: '',
        data: null,
      },
      userList: [],
      writerList: [], //  "作業者権限ユーザー"
      adminUserList: [],
      operatorUserList: [],
      writerAuth: ['作業者権限ユーザー', 'writer'],
      operatorAuth: ['運用者権限ユーザー', 'operator'],
      adminAuth: ['スーパーユーザー', 'manager', 'supervisor'],
      removeUserDialogVisible: false,
      dataTableEmptyText: '', // 登録がありません
      resultModalVisible: false,
      resultModalTitle: '',
      resultModalContent: '',
      authInfo: this.$store.state.user.authInfo,
      isUserDataReady: false,
      idName: [],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-users');
      return `${baseClassName} ${this.class}`.trim();
    },
    isWriterUser() {
      return this.authInfo === 'writer';
    },
    canAddUser() {
      if (this.authInfo === 'operator') {
        return false;
      } else {
        return PERMISSIONS_FRONTEND['userAdd'][this.authInfo];
      }
    },
    canEditUser() {
      return this.canAddUser;
    },
    canDeleteUser() {
      return this.canAddUser;
    },
  },
  watch: {},
  async created() {
    await this.handlerFetchUserData();
    for (let i = 1; i <= 5; i++) {
      this.idName[i] = 'h4-idmgmt' + i;
    }
  },
  async mounted() {},
  methods: {
    handlerFetchUserData: async function () {
      this.isUserDataReady = false;
      await this.postRequest('/users/get-list')
        .then(e => {
          this.userList = e.allUsers;
          for (const id in this.userList) {
            this.userList[id]['id'] = id;
          }
          this.suspendedCheckallModal = this.suspendedCheckall;
          this.writerList = this.filterUserList(this.userList, 'authInfo', this.writerAuth);
          this.operatorUserList = this.filterUserList(this.userList, 'authInfo', this.operatorAuth);
          this.adminUserList = this.filterUserList(this.userList, 'authInfo', this.adminAuth);
          for (let i = 0; i < this.adminUserList.length; i++) {
            this.adminUserList[i].userName = this.adminUserList[i].userName.replace('_', ' ');
          }
          this.isUserDataReady = true;
        })
        .catch(error => {
          console.error(error);
          this.showErrorModal(messageContent.requireTry('検索'));
        });
    },
    filterUserList(obj, filter, filterValue) {
      const tempObj = Object.keys(obj).reduce(function (currentObj, key) {
        if (filterValue.includes(obj[key][filter])) {
          currentObj[key] = obj[key];
        }
        return currentObj;
      }, {});
      const self = this;
      //convert object to array;
      const tempArray = [];
      Object.keys(tempObj).map(function (key) {
        const obj = Object.assign({}, tempObj[key]);
        obj.userID = key;
        tempArray.push(obj);
      });
      return tempArray;
    },
    handleUserAdd(authInfo) {
      this.$router.push({
        path: '/users/add',
        query: { authInfo: authInfo },
      });
    },
    handleRowUserRemove(authInfo, data) {
      this.userToRemove.authInfo = authInfo;
      this.userToRemove.data = data;
      this.removeUserDialogVisible = true;
    },
    handleCancelRemoveUser() {
      this.resetUserToRemove();
    },
    resetUserToRemove() {
      this.removeUserDialogVisible = false;
      this.userToRemove.authInfo = '';
      this.userToRemove.data = null;
    },
    removeUserConfirmationMessageText() {
      if (this.userToRemove.data) {
        return messageContent.notifyUserDelete(this.userToRemove.data.userID);
      } else {
        return '';
      }
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
    handleUserEdit(group, row) {
      this.$router.push({
        path: '/users/edit',
        query: { userID: row.userID },
      });
    },
    handleConfirmRemoveUser() {
      this.handleUserRemove(this.userToRemove.authInfo, this.userToRemove.data);
      this.resetUserToRemove();
    },
    handleUserRemove(authInfo, data) {
      const self = this;
      const deleteUserIDList = [];
      deleteUserIDList.push({ userID: data.userID });
      const formDataTmp = {
        deleteList: deleteUserIDList,
      };
      this.postRequest('/users/delete', formDataTmp)
        .then(e => {
          if (e.status === 'success') {
            self.showInfoModal(messageContent.userDeleted());
            self.handlerFetchUserData();
          } else {
            self.showErrorModal(messageContent.requireTry('ユーザー削除'));
          }
        })
        .catch(error => {
          console.error(error);
          self.showErrorModal(messageContent.requireTry('ユーザー削除'));
        });
    },
  },
};
</script>
