import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { commonCastDateValidations } from '@/common/commonValidations.js';

const commonCreateUpdateValidations = (checkStatus, transitionType) => {
  return {
    announcementData: {
      checkStatus: {
        accepted: helpers.withMessage(messageContent.requireSelect('チェックステータス'), value =>
          ['draft', 'checked', 'approved'].includes(value)
        ),
      },
      pushFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('プッシュ通知'),
          value => value === true || value === false
        ),
      },
      importantFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('優先設定'),
          value => value === true || value === false
        ),
      },
      title: {
        required: helpers.withMessage(messageContent.requireInput('内容サマリのタイトル'), required),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('内容サマリのタイトル', 16),
          maxLength(16)
        ),
      },
      text: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('内容サマリのテキスト'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('内容サマリのテキスト', 35),
          maxLength(35)
        ),
      },
      imageFile: {
        binary: {
          requiredIf: helpers.withMessage(
            messageContent.requireUpload('内容サマリの画像'),
            requiredIf((value, vm) => {
              if (checkStatus === 'draft') return false;
              return !value && !vm.file;
            })
          ),
        },
      },
      detailInfo: {
        title: {
          requiredIf: helpers.withMessage(
            messageContent.requireInput('詳細ページ内容のタイトル'),
            requiredIf(() => checkStatus !== 'draft')
          ),
          maxlength: helpers.withMessage(
            messageContent.requireUnderMaximumNumberOfCharacters('詳細ページ内容のタイトル', 50),
            maxLength(50)
          ),
        },
        text: {
          requiredIf: helpers.withMessage(
            messageContent.requireInput('詳細ページ内容のテキスト'),
            requiredIf(() => checkStatus !== 'draft')
          ),
          maxlength: helpers.withMessage(
            messageContent.requireUnderMaximumNumberOfCharacters('詳細ページ内容のテキスト', 800),
            maxLength(800)
          ),
        },
        contentsURL: {
          maxlength: helpers.withMessage(
            messageContent.requireUnderMaximumNumberOfCharacters('URL', 500),
            maxLength(500)
          ),
        },
        webviewFlag: {
          accepted: helpers.withMessage(
            messageContent.requireSelect('Webビュー'),
            value => value === true || value === false
          ),
        },
        dAccountLoginFlag: {
          accepted: helpers.withMessage(
            messageContent.requireSelect('ビジネスｄアカウントログイン'),
            value => value === true || value === false
          ),
        },
        imageFile: {
          binary: {
            requiredIf: helpers.withMessage(
              messageContent.requireUpload('詳細ページ内容の画像'),
              requiredIf((value, vm) => {
                if (checkStatus === 'draft') return false;
                return !value && !vm.file;
              })
            ),
          },
        },
        transitionDoc: {
          requiredIf: helpers.withMessage(
            messageContent.requireSelect('遷移先ドキュメント'),
            requiredIf(() => ['checked', 'approved'].includes(checkStatus) && ['service'].includes(transitionType))
          ),
        },
        transitionType: {
          accepted: helpers.withMessage(messageContent.requireSelect('タップ時の遷移先'), value =>
            ['url', 'service'].includes(value)
          ),
        },
      },
    },
  };
};

export const announcementValidation = {
  create: (checkStatus, transitionType) => {
    const obj = commonCreateUpdateValidations(checkStatus, transitionType);

    obj.announcementData.castStartDate = commonCastDateValidations(checkStatus).create.castStartDate;
    obj.announcementData.castEndDate = commonCastDateValidations(checkStatus).create.castEndDate;

    return obj;
  },

  update: (checkStatus, transitionType) => {
    const obj = commonCreateUpdateValidations(checkStatus, transitionType);

    obj.announcementData.castStartDate = commonCastDateValidations(checkStatus).update.castStartDate;
    obj.announcementData.castEndDate = commonCastDateValidations(checkStatus).update.castEndDate;
    obj.isPublished = commonCastDateValidations(checkStatus).update.isPublished('announcementData');
    obj.beforeCastEndDate = commonCastDateValidations(checkStatus).update.beforeCastEndDate('announcementData');

    return obj;
  },
};
