<template>
  <div :class="componentClassName">
    <slot>{{ label }}</slot>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'm-application-summary',
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|primary)/);
      },
    },
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-application-summary', this.variant);
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
