<template>
  <div :class="componentClassName">
    <template v-for="c in 4" :key="c">
      <div class="m-numpad__row">
        <template v-for="r in 3" :key="r">
          <div :class="getButtonClassName(c, r)">
            <a-button
              :ref="'numpad-' + getKeyValue(getKey(c, r))"
              :state="getKey(c, r).state"
              variant="numpad"
              :label="getKeyValue(getKey(c, r))"
              @click="handleClick(getKeyValue(getKey(c, r)))"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';

export default {
  name: 'm-numpad',
  components: {
    AButton,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    enableKeyboard: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    const keys = [];
    for (let num = 1; num <= 9; num += 1) {
      keys.push({
        name: `numpad-${num}`,
        type: 'number',
        value: num.toString(),
        state: null,
      });
    }
    keys.push({
      name: `numpad-*`,
      type: 'star',
      value: '*',
      state: null,
    });
    keys.push({
      name: `numpad-0`,
      type: 'number',
      value: '0',
      state: null,
    });
    keys.push({
      name: `numpad-#`,
      type: 'sharp',
      value: '#',
      state: null,
    });

    return {
      keys,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-numpad');
      return `${baseClassName} ${this.class}`.trim();
    },
    numKeys() {
      return this.keys;
    },
    numKeyTexts() {
      return this.numKeys.map(x => x.value);
    },
  },
  beforeMount() {
    if (this.enableKeyboard) {
      // register global keyup event
      document.addEventListener('keydown', this.handleKeyboard);
    }
  },
  beforeUnmount() {
    if (this.enableKeyboard) {
      // unregister keyup
      document.removeEventListener('keydown', this.handleKeyboard);
    }
  },
  methods: {
    getKey(col, row) {
      return this.numKeys[(col - 1) * 3 + (row - 1)];
    },
    getKeyValue(key) {
      return key.value;
    },
    getKeyType(key) {
      if (key.type !== 'number') {
        return 'sign';
      }
      return key.type;
    },
    getButtonClassName(col, row) {
      const key = this.getKey(col, row);
      const type = this.getKeyType(key);
      const modifiers = [type];
      if (type === 'sign') {
        modifiers.push(key.type);
      }
      return mapModifiers('m-numpad__button', modifiers);
    },
    handleClick(value) {
      this.$emit('click', value);
    },
    handleKeyboard(e) {
      const vm = this;
      const key = e.key;
      const keys = vm.numKeyTexts;
      if (keys.indexOf(key) !== -1) {
        this.$emit('click', key);
        vm.keys.forEach(keyButton => {
          if (keyButton.name === `numpad-${key}`) {
            keyButton.state = 'active';
          }
        });
        setTimeout(() => {
          vm.keys.forEach(keyButton => {
            if (keyButton.name === `numpad-${key}`) {
              keyButton.state = null;
            }
          });
        }, 100);
      }
    },
  },
};
</script>
