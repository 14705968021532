<template>
  <t-default-layout active-nav-id="announcements">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="Announcements" :show="show" />
      <form
        ref="form"
        class="t-announcements-detail__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-announcements-detail__fields">
          <m-section>
            <m-action-card :id="idName[0]" title="お知らせコンテンツ登録" header-border no-header-margin icon="clock">
              <div class="t-announcements-detail__fields__pushtype">
                <m-form-input id="t-announcements-detail-info-importantFlag__form-input">
                  <a-text id="t-announcements-detail__fields__a-text-top_1">●</a-text>
                  <a-text id="t-announcements-detail-info-importantFlag__a-text">重要なお知らせ</a-text>　　
                  <a-switch
                    id="t-announcements-detail-importantFlag"
                    v-model="announcementData.importantFlag"
                    data-testid="detail-announcement-important-display"
                    :disabled="true"
                  />
                </m-form-input>
                <m-form-input id="t-announcements-detail__fields__pushtype_1">
                  <a-text id="t-announcements-detail__fields__a-text-top_2">●</a-text>
                  <a-text id="t-announcements-detail__fields__pushtype__a-text">通知ステータス</a-text>
                  <a-select-field
                    id="t-announcements-detail__fields__pushtype__a-select-field_1"
                    v-model="pushStatus"
                    :options="pushStatusOptions"
                    disabled="true"
                  />
                  <a-text id="t-announcements-detail__fields__pushtype__pushDoneCount"
                    >プッシュ通知総回数: {{ announcementData.pushDoneCount }}回</a-text
                  >
                </m-form-input>
              </div>
              <div v-if="pushStatus !== 'none'" class="t-announcements-detail__fields__pushcategory">
                <m-form-input id="t-announcements-detail-info-pushcategory__form-input">
                  <a-text id="t-announcements-detail__fields__pushcategory__a-text-top">●</a-text>
                  <a-text id="t-announcements-detail__fields__pushcategory__a-text">プッシュ通知カテゴリ</a-text>
                  <div id="t-announcements-detail__fields__pushcategory__a-apply-text-field">
                    <a-apply-text-field
                      id="t-announcements-detail__fields__pushcategory__a-apply-text-field-id"
                      v-model="announcementData.pushCategory"
                      data-testid="detail-announcement-pushcategory"
                      type="text"
                      maxlength="10"
                      disabled="true"
                    />
                  </div>
                </m-form-input>
              </div>
              <div id="t-announcements-detail__fields__csv-history" class="t-announcements-detail__fields__csv-history">
                <a-text id="t-announcements-detail__fields__a-text-top_3">●</a-text>
                <a-text id="t-announcements-detail__fields__csv-history__a-text">CSVアップロード履歴</a-text>
                <o-csv-history :id="id" type="announcement" :csvlist="filteredSegmentCsvList" />
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card
              :id="idName[1]"
              test-id-text="detail-announcement"
              title="内容サマリ"
              :document-id="id"
              header-border
              no-header-margin
              icon="clock"
            >
              <div class="t-announcements-detail__fields">
                <div id="t-announcements-detail-uploading-file" class="t-announcements-detail__row">
                  <div class="t-announcements-detail__col-1">
                    <div class="t-announcements-detail__fields">
                      <m-form-input>
                        <a-upload-field
                          id="t-announcements-detail-upload-image"
                          v-model="announcementData.imageFile.file"
                          test-id-text="detail-announcement-summary-image"
                          name="imageFile"
                          class="t-announcements-detail-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-announcements-detail__col-2">
                    <div class="t-announcements-detail__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="announcementData.title"
                          data-testid="detail-announcement-summary-title-display"
                          type="text"
                          maxlength="16"
                          placeholder="タイトル入力"
                          :disabled="true"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-apply-text-field
                          v-model="announcementData.text"
                          data-testid="detail-announcement-summary-content-display"
                          class="t-announcements-detail__content-input"
                          type="textarea"
                          rows="7"
                          maxlength="35"
                          placeholder="テキスト入力"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[2]" title="詳細ページ内容" header-border no-header-margin icon="clock-remove">
              <div class="t-announcements-detail__fields">
                <div id="t-announcements-detail-info-uploading-file" class="t-announcements-detail__row">
                  <div class="t-announcements-detail__col-1">
                    <div class="t-announcements-detail__fields">
                      <m-form-input>
                        <a-upload-field
                          id="t-announcements-detail-info-upload-image"
                          v-model="announcementData.detailInfo.imageFile.file"
                          test-id-text="detail-announcement-detail-image"
                          class="t-announcements-detail-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-announcements-detail__col-2">
                    <div class="t-announcements-detail__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="t-announcements-detail-info-title"
                          v-model="announcementData.detailInfo.title"
                          data-testid="detail-announcement-detail-title-display"
                          type="text"
                          maxlength="50"
                          placeholder="タイトル入力"
                          :disabled="true"
                        />
                      </m-form-input>
                      <m-rich-text-editor
                        :html-content="announcementData.detailInfo.htmlContent"
                        read-only="true"
                        max-length="800"
                      />
                    </div>
                  </div>
                </div>
                <o-transition-type-service
                  v-if="updatedDataFlag"
                  v-model:transitionType="announcementData.detailInfo.transitionType"
                  v-model:transitionDoc="announcementData.detailInfo.transitionDoc"
                  v-model:contentsUrl="announcementData.detailInfo.contentsURL"
                  disabled="true"
                />
                <div v-if="announcementData.detailInfo.transitionType === 'url'" class="t-announcements-edit__fields">
                  <m-form-input id="t-announcements-detail-info-webviewFlag__form-input">
                    <a-text id="t-announcements-detail-info-webviewFlag__a-text">Webビュー</a-text>　　
                    <a-switch
                      id="t-announcements-detail-webviewFlag"
                      v-model="announcementData.detailInfo.webviewFlag"
                      data-testid="add-announcement-detail-webview-toggle-button"
                      :disabled="true"
                    />
                    <a-text id="t-announcements-detail-info-webview-note__a-text"
                      >※Webビュー設定ONの場合、URLにはダイナミックリンクを登録しないでください。</a-text
                    >
                  </m-form-input>
                </div>
                <div v-if="announcementData.detailInfo.transitionType === 'url'" class="t-announcements-add__fields">
                  <m-form-input id="t-announcements-detail-info-dAccountLoginFlag__form-input">
                    <a-text id="t-announcements-detail-info-dAccountLoginFlag__a-text"
                      >ビジネスｄアカウントログイン</a-text
                    >
                    <a-switch
                      id="t-announcements-detail-dAccountLoginFlag"
                      v-model="announcementData.detailInfo.dAccountLoginFlag"
                      data-testid="add-announcements-detail-webview-toggle-button"
                      :disabled="true"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-announcements-detail__fields">
                <div id="t-announcements-detail-cast-start-date-day__row" class="t-announcements-detail__row">
                  <a-text id="t-announcements-detail-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-announcements-detail-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="announcementData.castStartDate"
                      test-id-text="detail-announcement-publish-start"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="true"
                    />
                  </m-form-input>
                </div>
                <div class="t-announcements-detail__row">
                  <a-text id="t-announcements-detail-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-announcements-detail-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="announcementData.castEndDate"
                      test-id-text="detail-announcement-publish-end"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="true"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-announcements-detail__fields__pushtype">
                <m-form-input id="t-announcements-detail__fields__pushtype_2">
                  <a-select-field
                    id="t-announcements-detail__fields__pushtype__a-select-field_2"
                    v-model="announcementData.checkStatus"
                    data-testid="detail-announcement-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
              <!-- 情報を表示するために m-data-table を追加します 承認フロー -->
              <m-data-table
                :empty-text="dataTableEmp"
                :fit="true"
                :data="announcementData.checkHistories"
                id-text="announcement-check-histories-info-display"
              >
                <a-data-table-column
                  prop="publishedDocumentId"
                  :min-width="120"
                  :label="'公開ドキュメントID：<br>' + docId"
                  align="center"
                >
                  <template #default="scope">
                    <a-text size="medium" :center="true">公開{{ scope.index + 1 }}回目</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="draft" :min-width="100" label="下書き" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.draft.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.draft.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="checked" :min-width="100" label="チェック済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.checked.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.checked.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="approved" :min-width="100" label="承認済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.approved.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.approved.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
              </m-data-table>
            </m-action-card>
          </m-section>
        </div>
        <div id="t-announcements-test-buttons">
          <o-test-push-selected v-if="isManager" :id="id" type="announcement" :csvlist="filteredTestSegmentCsvList" />
          <o-test-push v-else :id="id" type="announcement" />
        </div>
        <div id="t-announcements-detail-buttons">
          <div v-show="!isPublished" class="t-announcements-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-announcements-detail-detail-saveBtn"
                data-testid="detail-announcement-edit-button"
                variant="block"
                color="primary"
                :disabled="isEnded || disableButton"
                @click="handleNavigateEdit"
                >編集</a-button
              >
            </m-form-action-buttons>
          </div>
          <div v-show="isPublished" class="t-announcements-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-announcements-detail-edit-return-unpublished-btn"
                data-testid="detail-announcement-edit-return-unpublished-button"
                variant="block"
                color="primary"
                :disabled="disableButton"
                @click.stop="handleEditReturnUnpublished"
                >未公開に戻す</a-button
              >
            </m-form-action-buttons>
          </div>
          <div v-show="isPublished" class="t-announcements-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-announcements-detail-edit-keep-published-btn"
                data-testid="detail-announcement-edit-keep-published-button"
                variant="block"
                color="primary"
                :disabled="disabledButton || disableButton"
                @click.stop="handleEditKeepPublished"
                >公開のまま編集</a-button
              >
            </m-form-action-buttons>
          </div>
          <div class="t-announcements-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-announcements-detail-detail-deleteBtn"
                data-testid="detail-announcement-delete-button"
                variant="block"
                color="primary"
                :disabled="disableButton"
                @click.stop="handleConfirmDelete"
                >削除</a-button
              >
            </m-form-action-buttons>
          </div>
        </div>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="announcementsCreate_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="t-announcements-remove-modal-close-button"
            data-testid="detail-announcement-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCloseModal"
          />
          <a-button
            id="t-announcements-delete-button"
            data-testid="detail-announcement-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleDelete"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import OTransitionTypeService from '@/components/organisms/transition-type-service';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import dayjs from 'dayjs';
import OCsvHistory from '@/components/organisms/csv-history';
import MRichTextEditor from '@/components/molecules/rich-text-editor';

export default {
  name: 't-announcements-detail',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASwitch,
    AUploadField,
    ACalendarField,
    AApplyTextField,
    OCsvHistory,
    MRichTextEditor,
    OTransitionTypeService,
  },
  mixins: [customRequest, customValidate, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      show: this.$route.query.show ?? '1',
      isManager: this.$store.state.user.authInfo === 'manager',
      idName: [],
      disableButton: false,
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,
      isPublished: false, //公開中かどうかのフラグ
      docId: (() => {
        const id = this.$route.query.id;
        if (id.includes('_')) {
          const lastIndex = id.lastIndexOf('_');
          return id.slice(0, lastIndex);
        }
        return id;
      })(),

      //お知らせデータ
      announcementData: {
        pushFlag: false, //push 通知フラグ
        text: '', //お知らせ内容
        title: '', //お知らせタイトル
        imageURL: '', //
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        importantFlag: false, //重要なお知らせフラグ
        castStartDate: '', //公開開始日
        castEndDate: '', //公開終了日
        draftFlag: false, //下書き
        checkStatus: '', //チェックステータス
        pushDoneCount: 0, //プッシュ通知総回数
        detailInfo: {
          //お知らせ詳細
          text: '',
          title: '',
          htmlContent: '',
          contentsURL: '',
          webviewFlag: false, //Webビューフラグ
          contentsFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          imageURL: '',
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          transitionType: 'url', //遷移先種別
          transitionDoc: '', //遷移先ドキュメントID
          dAccountLoginFlag: false, //ビジネスｄアカウントログイン
        },
        pushType: 'ALL',
        pushTargetListFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        checkHistories: [
          {
            draft: {
              userName: '',
              updateDate: '',
            },
            checked: {
              userName: '',
              updateDate: '',
            },
            approved: {
              userName: '',
              updateDate: '',
            },
          },
        ],
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
      // CSVデータ
      pushTargetCsvList: [],

      isEnded: false,
      nowDate: '',
      updatedDataFlag: false,
      pushStatus: 'all',
      pushStatusOptions: [
        { value: 'all', label: '全体プッシュ通知' },
        { value: 'limited', label: '特定プッシュ通知' },
        { value: 'none', label: 'プッシュ通知なし' },
      ],
      pushCategory: '',
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-announcements-detail');
      return `${baseClassName} ${this.class}`.trim();
    },
    filteredTestSegmentCsvList() {
      // テスト特定配信用のCSV
      return this.pushTargetCsvList.filter(csv => csv.testPushFlag);
    },
    filteredSegmentCsvList() {
      // 特定配信のCSV
      return this.pushTargetCsvList.filter(csv => !csv.testPushFlag);
    },
  },
  created() {
    this.handleGetAnnouncementInfo(() => {
      const userRole = this.$store.state.user.authInfo;
      const checkStatus = this.announcementData.checkStatus;
      if (userRole === 'writer' && checkStatus !== 'draft') {
        this.disableButton = true;
      }
    });
    for (let i = 1; i <= 4; i++) {
      this.idName[i] = 'h1-announcements-detail-' + i;
    }
  },
  mounted() {},
  methods: {
    handleGetAnnouncementInfo(callback) {
      const id = this.id;
      this.nowDate = utils.currentDateTime();
      if (id) {
        this.postRequest('/announcements/get', { id })
          .then(e => {
            if (e.status === 'success' && e.data && e.data.data) {
              const data = e.data.data;
              this.announcementData.pushFlag = data?.pushFlag || false;
              this.announcementData.title = data?.title || '';
              this.announcementData.text = data?.text || '';
              this.announcementData.imageURL = data?.imageURL || '';
              if (data?.pushDoneCount) {
                this.announcementData.pushDoneCount = data?.pushDoneCount;
              }
              (this.announcementData.imageFile = {
                file: data.imageURL ? data.imageURL : null,
              }),
                (this.announcementData.importantFlag = data?.importantFlag || false);
              this.announcementData.castStartDate = data?.castStartDate || '';
              this.announcementData.castEndDate = data?.castEndDate || '';
              this.announcementData.draftFlag = data?.draftFlag || false;
              this.announcementData.checkStatus = data?.checkStatus || '';
              this.announcementData.detailInfo.title = data?.detailInfo?.title || '';
              this.announcementData.detailInfo.text = data?.detailInfo?.text || '';
              if (data.detailInfo?.htmlContent) {
                this.announcementData.detailInfo.htmlContent = data?.detailInfo?.htmlContent;
              } else {
                // Note: 改行をpタグに統一してhtml化
                this.announcementData.detailInfo.htmlContent =
                  '<p>' + this.announcementData.detailInfo.text.replace(/\n/g, '</p><p>') + '</p>';
              }
              this.announcementData.detailInfo.contentsURL = decodeURI(data?.detailInfo?.contentsURL) || '';
              this.announcementData.detailInfo.webviewFlag = data?.detailInfo?.webviewFlag || false;
              this.announcementData.detailInfo.imageURL = data?.detailInfo?.imageURL || '';
              this.announcementData.detailInfo.transitionType = data?.detailInfo?.transitionType || 'url';
              this.announcementData.detailInfo.transitionDoc = data?.detailInfo?.transitionDoc || '';
              this.announcementData.detailInfo.dAccountLoginFlag = data?.detailInfo?.dAccountLoginFlag || false;
              (this.announcementData.detailInfo.imageFile = {
                file: data.detailInfo?.imageURL ? data.detailInfo.imageURL : null,
              }),
                (this.dataLoaded = true);
              // 下書きとチェク済み中ではなく、公開終了日時を過ぎている場合、isEndedフラグをtrueにする
              if (this.announcementData.checkStatus === 'approved') {
                if (this.announcementData.castEndDate && this.announcementData.castEndDate < this.nowDate) {
                  this.isEnded = true;
                }
              }
              this.announcementData.pushType = data?.pushType || 'ALL';
              this.announcementData.checkHistories = data?.checkHistories || [];
              // 通知ステータス設定
              if (this.announcementData.pushFlag === false) this.pushStatus = 'none';
              else if (this.announcementData.pushType === 'ALL') this.pushStatus = 'all';
              else this.pushStatus = 'limited';
              this.announcementData.pushCategory = data?.pushCategory || '';

              const nowDate = utils.currentDateTime();
              // 公開中の投稿か確認
              if (
                data &&
                data.checkStatus === 'approved' &&
                data.castStartDate &&
                data.castEndDate &&
                data.castStartDate <= nowDate &&
                nowDate <= data.castEndDate
              ) {
                this.isPublished = true;
              }

              // CSVアップロード履歴
              if (e.data.pushTargetCsv) {
                this.pushTargetCsvList = Object.entries(e.data.pushTargetCsv).map(([key, value]) => {
                  value['id'] = key;
                  return value;
                });
                this.pushTargetCsvList.sort((a, b) => dayjs(b.updateDate).valueOf() - dayjs(a.updateDate).valueOf());
              }
            } else {
              this.showErrorModal(messageContent.requireTry('お知らせ取得'));
            }
            if (typeof callback === 'function') {
              callback();
            }
            this.updatedDataFlag = true;
          })
          .catch(error => {
            console.error(error);
            this.showErrorModal(messageContent.requireTry('お知らせ取得'));
          });
      }
    },
    async handleEditReturnUnpublished() {
      const data = {
        id: this.$route.query.id,
      };
      await this.postRequest('/announcements/edit-return-unpublished', data).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Announcements' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('お知らせ更新'));
          }
        }
      });
    },
    async handleEditKeepPublished() {
      const data = {
        id: this.$route.query.id,
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/announcements/edit-keep-published', data).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Announcements' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('お知らせ更新'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleNavigateEdit() {
      const id = this.$route.query.id;
      if (id) {
        this.$router.push({
          name: 'AnnouncementsEdit',
          query: { id: id, source: 'detail', show: this.show },
        });
      }
    },
    handleConfirmDelete() {
      this.resultModalVisible = true;
      this.resultModalTitle = 'Info';
      this.resultModalContent = messageContent.comfirmDelete(this.announcementData.title);
    },
    handleDelete() {
      this.handleCloseModal();
      const data = {
        execUserID: this.$store.state.user.userID,
        documentID: this.$route.query.id,
      };
      this.postRequest('/announcements/delete', data).then(e => {
        this.$router.push({ name: 'Announcements', query: { show: this.show } });
      });
    },
    handleCancelClick() {
      this.$router.push({ name: 'Announcements', query: { show: this.show } });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
