<template>
  <t-default-entry
    v-show="finishCheckAuthen"
    sub-heading="Login"
    heading="ログイン"
    :navigation-items="navigationItems"
    :class="componentClassName"
  >
    <loading :active="isLoading" :is-full-page="true" :height="100" :width="100"></loading>
    <div v-if="error" class="t-login__alert">
      <a-alert color="error" size="small" class="t-login__alert">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span data-testid="login-error-message-span" v-html="errorMessage"></span>
      </a-alert>
    </div>
    <form class="t-login__form">
      <div class="t-login__group">
        <m-form-input class="t-login__input" label="ユーザーID">
          <a-text-field
            id="operatorId"
            v-model="userInfo.userID"
            data-testid="login-user-id-input"
            type="email"
            name=""
            placeholder="dummy@gmail.com"
            autocomplete="email"
          />
        </m-form-input>
        <m-form-input class="t-login__input" label="パスワード">
          <a-text-field
            id="password"
            v-model="userInfo.password"
            data-testid="login-user-password-input"
            type="password"
            hidden
            name=""
            placeholder="********"
            autocomplete="current-password"
          />
        </m-form-input>
        <!-- <a-checkbox v-model="formData.remember" label="ログイン状態を保持する" name="login-checkbox" value="1" /> -->
      </div>
      <m-button-container class="t-login__buttons">
        <a-button id="login" data-testid="login-button" variant="block" color="primary" @click="processLogin()"
          >ログイン</a-button
        >
      </m-button-container>
    </form>
    <teleport to="body">
      <o-modal
        id="login_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal-remove-phone-number-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button id="modal-ok" variant="block" color="primary" label="OK" size="small" @click="handleCloseModal" />
        </template>
      </o-modal>
    </teleport>
  </t-default-entry>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AAlert from '@/components/atoms/alert';
import AButton from '@/components/atoms/button';
import ATextField from '@/components/atoms/text-field';
import MButtonContainer from '@/components/molecules/button-container';
import MFormInput from '@/components/molecules/form-input';
import TDefaultEntry from '@/components/templates/default-entry';
import { cloneDeep } from 'lodash';
import { showMsgBoxMixin } from '@/common/showMsgBox.js';
import { mapActions } from 'vuex';
import { customValidate } from '@/common/customValidate.js';
import { messageContent } from '@/common/messageContent.js';
import { customRequest } from '@/common/customRequest.js';
import Loading from 'vue-loading-overlay';
import { useVuelidate } from '@vuelidate/core';
import { loginValidation } from '@/validations/login.js';

export default {
  name: 't-login',
  components: {
    AAlert,
    AButton,
    ATextField,
    MButtonContainer,
    MFormInput,
    TDefaultEntry,
    Loading,
  },
  mixins: [showMsgBoxMixin, customValidate, customRequest],
  props: {
    class: {
      type: String,
      default: '',
    },
    navigationItems: {
      type: Array,
      default: new Array(0),
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data: function () {
    return {
      userInfo: {
        userID: '',
        password: '',
        remember: true,
      },
      loginErrorMsg: '',
      error: false,
      errorMessage: '',
      finishCheckAuthen: true,
      csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      dAccountUrl: null,
      validFlag: true,
      isLoading: false,

      // modal
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
    };
  },

  validations() {
    return loginValidation.login;
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-login', this.error ? 'error' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created: function () {
    this.sessionCheck();
    if (this.$route.params.errorMessage) {
      this.error = true;
      this.errorMessage = this.$route.params.errorMessage;
    }
  },
  methods: {
    ...mapActions(['updateLoginInfo']),
    async sessionCheck(doingLogin = false) {
      await this.postRequest('/login/sessioncheck')
        .then(res => {
          if (res.status === 'csrfExpired') {
            // refresh csrf token
            this.csrfToken = res.newCsrfToken;
            document.querySelector('meta[name="csrf-token"]').setAttribute('content', res.newCsrfToken);
            this.finishCheckAuthen = true;
            return;
          }
          if (doingLogin) return;
          const params = new URLSearchParams();
          if (res.session) {
            if (res.tempPwdFlag) {
              this.postRequest('/login/logout').then(data => {
                if (data.status === 'success') {
                  window.location.href = '/login';
                } else {
                  this.showErrorModal(messageContent.requireTry('ログアウト'));
                }
              });
            } else if (res.firstVisitFlag && res.authInfo === 'supervisor') {
              // ウォークスルーページはマスタ管理者のみアクセス可能
              this.$router.push({ name: 'WTInviteUser' });
            } else {
              this.$router.push({ name: 'NewsAndSeminars' });
            }
          } else {
            this.finishCheckAuthen = true;
          }
        })
        .catch(err => {
          console.error(err);
          this.finishCheckAuthen = true;
          this.setError(messageContent.requireTry('検索'));
        });
    },
    processLogin: async function () {
      this.errorMessage = '';
      this.error = false;

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.v$.$errors.map(item => (this.errorMessage += item.$message + '<br>'));
        this.error = true;
      }

      //check email format
      // else if (!this.addressCheck(this.userInfo.userID)) {
      //   this.errorMessage = messageContent.loginEmailInputError;
      //   this.error = true;
      // }
      //特殊文字をチェック
      // else if (this.hasSpecialCharacterInString(this.userInfo.password)) {
      //   this.errorMessage = messageContent.hasSpecialCharacter;
      //   this.error = true;
      // }
      else {
        this.isLoading = true;
        // ログインする前にCSRFトークンの有効期限を確認する
        await this.sessionCheck(true);

        const formDataTmp = {
          userID: this.userInfo.userID,
          password: this.userInfo.password,
          _csrf: this.csrfToken,
        };
        const formData = JSON.stringify(formDataTmp);
        await fetch('/login', {
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            if (response.status === 403) {
              return {
                session: false,
                status: 'csrfExpired',
                msg: messageContent.loginCsrfExpiredError,
              };
            } else if (!response.ok && response.status !== 429) {
              throw new Error('/login response was not ok.');
            } else {
              return response.json();
            }
          })
          .then(e => {
            if (e.session || e.status === 'success') {
              // Update new csrf token due to new session is generated(old one be expired).
              document.querySelector('meta[name="csrf-token"]').setAttribute('content', e.csrfToken);
              this.updateLoginInfo(e.data);
              //this.$router.push({ name: 'Users' });
              window.location.href = '/news-and-seminars';
            } else if (e.status === 'twoFactorAuthenticating') {
              //仮パスワードの場合
              this.$router.push({
                name: 'LoginPasscodeInput',
                params: { userID: this.userInfo.userID },
              });
            } else if (e.status === 'resetPassword') {
              //仮パスワードの場合
              this.$router.push({ path: '/resettemppassword', params: { userID: this.userInfo.userID || '' } });
            } else if (e.status === 'loginFailed') {
              this.isLoading = false;
              this.errorMessage = e.msg;
              this.error = true;
            } else if (e.status === 'rateLimit') {
              this.isLoading = false;
              this.errorMessage = e.msg;
              this.error = true;
            } else if (e.status === 'csrfExpired') {
              this.isLoading = false;
              this.errorMessage = e.msg ? e.msg : messageContent.loginOtherError;
              this.error = true;
            } else {
              this.isLoading = false;
              this.errorMessage = messageContent.loginOtherError;
              this.error = true;
            }
          })
          /* eslint-disable no-unused-vars*/
          .catch(error => {
            console.error(error);
            this.isLoading = false;
            this.errorMessage = messageContent.loginOtherError;
            this.error = true;
          });
      }
    },
    setCookie(key, value, expires = null) {
      if (!expires) {
        const d = new Date();
        // Cookie expires after 3 days
        d.setTime(d.getTime() + 3 * 24 * 60 * 60 * 1000);
        expires = 'expires=' + d.toUTCString();
      }
      document.cookie = `${key}=${value};expires=${expires}`;
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
