<template>
  <div :class="componentClassName">
    <section class="t-entry__col">
      <div class="t-entry__form-wrapper">
        <a-heading class="t-entry__heading" tag="h1" variant="center" :heading="heading" :subheading="subHeading" />
        <a-section-divider />

        <div class="t-entry__body">
          <slot></slot>
        </div>
      </div>

      <o-global-footer class="t-entry__footer"> </o-global-footer>
    </section>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AHeading from '@/components/atoms/heading';
import ASectionDivider from '@/components/atoms/section-divider';
import OGlobalFooter from '@/components/organisms/global-footer';

export default {
  name: 't-default-entry',
  components: {
    AHeading,
    ASectionDivider,
    OGlobalFooter,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: null,
    },
    subHeading: {
      type: String,
      default: null,
    },
    navigationItems: {
      type: Array,
      default: new Array(0),
    },
    activeNavId: {
      type: String,
      default: '',
    },
    termsSize: {
      type: String,
      default: '1038KB',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-entry');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
};
</script>
