<template>
  <label :id="id + '-label'" :class="componentClassName" @click="labelClickAction">
    <input
      :id="id + '-input'"
      ref="input"
      v-model="checkboxModel"
      :data-testid="dataTestid"
      class="a-checkbox__input"
      type="checkbox"
      :name="name"
      :value="value"
      v-bind="inputAttrs"
      @click="handleClick"
      @change="handleChange"
    />
    <div className="a-checkbox__field">
      <span :id="id + '-input-span'" class="a-checkbox__icon">
        <a-icon icon="check" color="white" />
      </span>
      <p class="a-checkbox__content">
        <slot>{{ label }}</slot>
      </p>
    </div>
  </label>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'a-checkbox',
  components: {
    AIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Boolean, Array],
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    },
    dataTestid: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'change', 'update:modelValue'],
  data() {
    return {
      checkboxModel: false,
      isChecked: this.checked || false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-checkbox',
        this.disabled ? 'disabled' : '',
        this.isChecked ? 'checked' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { checked, placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    checked(val, oldValue) {
      this.isChecked = val;
      if (this.modelValue === null) {
        this.checkboxModel = val;
      }
    },
    modelValue(val, oldValue) {
      this.checkboxModel = val;
    },
    checkboxModel(val, oldValue) {
      if (!Array.isArray(this.modelValue)) {
        this.isChecked = this.checkboxModel;
      }
    },
  },
  mounted() {
    if (this.modelValue !== null) {
      this.checkboxModel = this.modelValue;
    } else if (this.checked !== null) {
      this.checkboxModel = this.checked;
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
    handleChange(event) {
      // check model is array or not
      this.$emit('update:modelValue', this.checkboxModel);
      this.$emit('change', event);
    },
    labelClickAction(event) {
      if (this.stopPropagation) {
        event.stopPropagation();
      }
    },
  },
};
</script>
