<template>
  <table :class="componentClassName">
    <slot>
      <tr v-for="(item, index) in data" :key="index" class="m-data-list__row">
        <td :class="valueColumnClassName(item, 'label')">{{ item.label }}</td>
        <td :class="valueColumnClassName(item, 'value')">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div :id="`${id}-${item.label}`" class="m-data-list__value" v-html="item.content" />
        </td>
      </tr>
    </slot>
  </table>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'm-data-list',
  props: {
    class: {
      type: String,
      default: '',
    },
    verticalAlign: {
      type: String,
      default: 'middle',
      validator(value) {
        return value.match(/(top|middle|bottom)/);
      },
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: null,
    },
    variant: {
      type: String,
      default: null,
      validation(value) {
        return value.match(/(long)/) || value === null;
      },
    },
    id: {
      type: String,
      default: '',
    },
  },
  emits: ['click:action'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('m-data-list', this.variant, this.alignRight ? 'align-right' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    valueColumnClassName(item, type) {
      return mapModifiers(
        'm-data-list__col',
        type,
        item.verticalAlign ? `vertical-align-${item.verticalAlign}` : '',
        item.paragraph ? 'paragraph' : '',
        item.action ? 'action' : ''
      );
    },
    handleActionClick(index) {
      this.$emit('click:action', index);
    },
  },
};
</script>
