<template>
  <div class="a-split-button">
    <button :id="activeButton.type" type="button" :disabled="disabled" :class="componentClassName" @click="handleClick">
      <slot>{{ activeButton.label }}</slot>
    </button>
    <details :open="isOpenButtonOption && 'open'">
      <summary class="a-split-button__dropdown-open-button" @click="handleOpen">▼</summary>
      <ul class="a-split-button__dropdown-menu">
        <li v-for="detail in details" :key="detail.type">
          <button class="a-split-button__button" @click="e => handleChange(e, detail)">
            <span class="a-split-button__button__label">
              {{ detail.label }}
            </span>
            <br /><span class="a-split-button__button__description">{{ detail.description }}</span>
          </button>
        </li>
      </ul>
    </details>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-split-button',
  props: {
    disabled: {
      type: [String, Boolean],
      default: null,
    },
    class: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/null|focus|hover|active/);
      },
    },
    change: {
      type: Function,
      required: false,
      default: () => {},
    },
    click: {
      type: Function,
      required: false,
      default: () => {},
    },
    /**
     * @type {{id: string; label: string, description: string}[]}
     */
    details: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeButton: this.details[0],
      isOpenButtonOption: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-split-button',
        'block',
        'color-primary-red',
        this.disabled ? 'disabled' : null,
        'medium',
        this.state
      );
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    handleClick() {
      this.click(this.activeButton.type);
    },
    handleChange(e, detail) {
      e.stopImmediatePropagation();
      this.isOpenButtonOption = false;
      this.activeButton = detail;
      this.change(detail.type);
    },
    handleOpen(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      this.isOpenButtonOption = !this.isOpenButtonOption;
    },
  },
};
</script>
