<script lang="jsx">
/* eslint-disable react/react-in-jsx-scope */
import { mapModifiers } from '@/libs/component';
import ACheckbox from '@/components/atoms/checkbox';
import { h } from 'vue';

export default {
  name: 'a-data-table-body',
  components: {
    ACheckbox,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: new Array(0),
    },
    data: {
      type: Array,
      default: new Array(0),
    },
    table: {
      type: Object,
      required: true,
    },
    isHistoryListTable: {
      type: Boolean,
      default: false,
    },
    idText: {
      type: String,
      default: '',
    },
  },
  emits: ['rowClick'],
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-data-table-body');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    getCellClass(rowIndex, columnIndex, row, column) {
      const classes = [column.id, `a-data-table-column--${column.type}`, column.align, column.nowrap, column.class];

      const cellClassName = this.table.cellClassName;
      if (typeof cellClassName === 'string') {
        classes.push(cellClassName);
      } else if (typeof cellClassName === 'function') {
        classes.push(
          cellClassName.call(null, {
            rowIndex,
            columnIndex,
            row,
            column,
          })
        );
      }

      return classes.join(' ');
    },
    getRowClass(isGreyBg, isOrangeBg) {
      const classes = [];
      if (isGreyBg) classes.push('m-data-table--row-grey');
      if (isOrangeBg) classes.push('m-data-table--row-orange');
      return classes;
    },
    rowClick(row, e) {
      if (this.isHistoryListTable) {
        this.$emit('rowClick', { row: row });
      }
    },
  },
  render() {
    const self = this;
    return (
      <table className={this.componentClassName}>
        <colgroup>
          {this.columns.map(column => {
            return <col name={column.id} key={column.id} />;
          })}
        </colgroup>
        <tbody>
          {this.data.map((row, index) => [
            <tr
              id={'table-row-' + index}
              data-testid={this.idText + '-' + index}
              className={this.getRowClass(row.isGreyBg, row.isOrangeBg)}
              key={index}
              onClick={e => this.rowClick(row, e)}
            >
              {this.columns.map((column, cellIndex) => {
                return (
                  <td
                    className={this.getCellClass(index, cellIndex, row, column)}
                    key={cellIndex}
                    id={'row-' + index + '-col-' + cellIndex}
                    data-testid={this.idText + '-' + index + '-col-' + cellIndex}
                  >
                    {column.renderCell.call(this, {
                      row,
                      column,
                      index,
                      isGreyBg: row.isGreyBg,
                      isOrangeBg: row.isOrangeBg,
                    })}
                  </td>
                );
              })}
            </tr>,
          ])}
        </tbody>
      </table>
    );
  },
};
</script>
