<template>
  <img
    v-if="variant === 'horizontal'"
    class="a-brand-logo"
    src="@/images/bdaccount_certification_icon.png"
    alt="ビジネスdアプリ運用者カスコン"
    width="100"
    style="display: block; margin: auto"
  />
  <img
    v-else
    class="a-brand-logo"
    src="@/images/favicon.svg"
    alt="ビジネスdアプリ運用者カスコン"
    width="100"
    style="display: block; margin: auto"
  />
</template>

<script>
import { mapModifiers } from '@/libs/component';
export default {
  name: 'a-brand-logo',
  props: {
    variant: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|horizontal)/);
      },
    },
  },
};
</script>
