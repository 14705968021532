<template>
  <div :class="componentClassName">
    <div v-for="(pane, index) in panes" :key="index" :class="getTabClassName(index)" @click="onTabClick(index)">
      {{ pane.label }}
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';

export default {
  name: 'a-tab-nav',
  props: {
    class: {
      type: String,
      default: '',
    },
    panes: {
      type: Array,
      default: new Array(0),
    },
    currentName: {
      type: String,
      default: '',
    },
    onTabClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    componentClassName() {
      const tabCountClassName = `tab-len-${this.panes.length}`;
      // get active tab
      let activeTabPositionClassName = '';
      let activeIndex = -1;
      this.panes.forEach((pane, index) => {
        if (pane.name === this.currentName) {
          activeIndex = index;
        }
      });
      if (activeIndex !== -1) {
        activeTabPositionClassName = `active-${activeIndex + 1}`;
      }
      const baseClassName = mapModifiers('a-tab-nav', tabCountClassName, activeTabPositionClassName);
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    getTabClassName(index) {
      const active = this.panes[index].name === this.currentName;
      const tabClassName = mapModifiers('a-tab-nav__tab', active ? 'active' : '');
      return tabClassName;
    },
  },
};
</script>
