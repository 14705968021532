import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/index.scss';
import Component from './index.js';

const app = createApp(App);
Component.install(app);
app.use(store).use(router);
app.mount('#app');
