<template>
  <t-default-layout active-nav-id="announcements">
    <div :class="componentClassName">
      <a-back-page :id="id" :text="backPageName()" :page="backPage()" :show="show" />
      <form
        ref="form"
        class="t-announcements-edit__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-announcements-edit__fields">
          <m-section>
            <m-action-card :id="idName[0]" title="お知らせコンテンツ登録" header-border no-header-margin icon="clock">
              <div class="t-announcements-edit__fields__pushtype">
                <m-form-input id="t-announcements-edit-info-importantFlag__form-input">
                  <a-text id="t-announcements-edit__fields__a-text-top_1">●</a-text>
                  <a-text id="t-announcements-edit-info-importantFlag__a-text">重要なお知らせ</a-text>　　
                  <a-switch
                    id="t-announcements-edit-importantFlag"
                    v-model="announcementData.importantFlag"
                    data-testid="edit-announcement-important-toggle"
                    @change="checkDefaultCategory"
                  />
                </m-form-input>
                <m-form-input id="t-announcements-edit__fields__pushtype_1">
                  <a-text id="t-announcements-edit__fields__a-text-top_2">●</a-text>
                  <a-text id="t-announcements-edit__fields__pushtype__a-text">通知ステータス</a-text>
                  <a-select-field
                    id="t-announcements-edit__fields__pushtype__a-select-field_1"
                    v-model="pushStatus"
                    :options="pushStatusOptions"
                  />
                  <a-text id="t-announcements-edit__fields__pushtype__pushDoneCount"
                    >プッシュ通知総回数: {{ announcementData.pushDoneCount }}回</a-text
                  >
                </m-form-input>
              </div>
              <div v-if="pushStatus !== 'none'" class="t-announcements-edit__fields__pushcategory">
                <m-form-input id="t-announcements-edit-info-pushcategory__form-input">
                  <a-text id="t-announcements-edit__fields__pushcategory__a-text-top">●</a-text>
                  <a-text id="t-announcements-edit__fields__pushcategory__a-text">プッシュ通知カテゴリ</a-text>
                  <div id="t-announcements-edit__fields__pushcategory__a-apply-text-field">
                    <a-apply-text-field
                      id="t-announcements-edit__fields__pushcategory__a-apply-text-field-id"
                      v-model="announcementData.pushCategory"
                      data-testid="edit-announcement-pushcategory"
                      type="text"
                      maxlength="10"
                      placeholder="テキストを入力"
                    />
                  </div>
                </m-form-input>
              </div>
              <div
                v-if="pushStatus === 'limited'"
                id="t-announcements-edit__fields__upload-file"
                class="t-announcements-edit__fields__upload-file"
              >
                <a-text id="t-announcements-edit__fields__a-text-top_3">●</a-text>
                <a-text id="t-announcements-edit__fields__upload-file__a-text">CSVアップロード</a-text>
                <o-csv-uploader
                  v-model:pushTargetListFile="announcementData.pushTargetListFile"
                  v-model:pushTargetListFileType="announcementData.pushTargetListFileType"
                  :error-function="showErrorModal"
                />
              </div>
              <div id="t-announcements-edit__fields__csv-history" class="t-announcements-edit__fields__csv-history">
                <a-text id="t-announcements-edit__fields__a-text-top_4">●</a-text>
                <a-text id="t-announcements-edit__fields__csv-history__a-text">CSVアップロード履歴</a-text>
                <o-csv-history :id="id" type="announcement" :csvlist="pushTargetCsvList" />
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card
              :id="idName[1]"
              test-id-text="edit-announcement"
              title="内容サマリ"
              :document-id="id"
              header-border
              no-header-margin
              icon="clock"
            >
              <div class="t-announcements-edit__fields">
                <div id="t-announcements-edit-uploading-file" class="t-announcements-edit__row">
                  <div class="t-announcements-edit__col-1">
                    <div class="t-announcements-edit__fields">
                      <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                      <!-- <m-form-input>
                        <a-upload-field
                          id="t-announcements-edit-upload-image"
                          v-model="announcementData.imageFile.file"
                          name="imageFile"
                          class="t-announcements-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                          @change="handleUploadFileChangeImg(announcementData.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input> -->
                      <m-form-input>
                        <a-upload-field
                          id="t-announcements-edit-upload-image"
                          v-model="announcementData.imageFile.file"
                          test-id-text="edit-announcement-summary-image"
                          name="imageFile"
                          class="t-announcements-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChangeImg('imageFile', announcementData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-announcements-edit__col-2">
                    <div class="t-announcements-edit__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="announcementData.title"
                          data-testid="edit-announcement-summary-title-input"
                          type="text"
                          maxlength="16"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-apply-text-field
                          v-model="announcementData.text"
                          data-testid="edit-announcement-summary-content-input"
                          class="t-announcements-edit__content-input"
                          type="textarea"
                          rows="2"
                          maxlength="35"
                          placeholder="テキスト入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[2]" title="詳細ページ内容" header-border no-header-margin icon="clock-remove">
              <div class="t-announcements-edit__fields">
                <div id="t-announcements-edit-info-uploading-file" class="t-announcements-edit__row">
                  <div class="t-announcements-edit__col-1">
                    <div class="t-announcements-edit__fields">
                      <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                      <!-- <m-form-input>
                        <a-upload-field
                          id="t-announcements-edit-info-upload-image"
                          v-model="announcementData.detailInfo.imageFile.file"
                          class="t-announcements-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                          @change="handleUploadFileChangeImg(announcementData.detailInfo.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input> -->
                      <m-form-input>
                        <a-upload-field
                          id="t-announcements-edit-info-upload-image"
                          v-model="announcementData.detailInfo.imageFile.file"
                          test-id-text="edit-announcement-detail-image"
                          class="t-announcements-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="
                            handleUploadFileChangeImg('detailImageURL', announcementData.detailInfo.imageFile.file)
                          "
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-announcements-edit__col-2">
                    <div class="t-announcements-edit__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="t-announcements-edit-info-title"
                          v-model="announcementData.detailInfo.title"
                          data-testid="edit-announcement-detail-title-input"
                          type="text"
                          maxlength="50"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                      <m-rich-text-editor
                        :html-content="announcementData.detailInfo.htmlContent"
                        max-length="800"
                        @getHtmlContent="announcementData.detailInfo.htmlContent = $event"
                        @getText="announcementData.detailInfo.text = $event"
                      />
                    </div>
                  </div>
                </div>
                <o-transition-type-service
                  v-if="updatedDataFlag"
                  v-model:transitionType="announcementData.detailInfo.transitionType"
                  v-model:transitionDoc="announcementData.detailInfo.transitionDoc"
                  v-model:contentsUrl="announcementData.detailInfo.contentsURL"
                />
                <div v-if="announcementData.detailInfo.transitionType === 'url'" class="t-announcements-edit__fields">
                  <m-form-input id="t-announcements-edit-info-webviewFlag__form-input">
                    <a-text id="t-announcements-edit-info-webviewFlag__a-text">Webビュー</a-text>　　
                    <a-switch
                      id="t-announcements-edit-webviewFlag"
                      v-model="announcementData.detailInfo.webviewFlag"
                      data-testid="add-announcement-detail-webview-toggle-button"
                    />
                    <a-text id="t-announcements-edit-info-webview-note__a-text"
                      >※Webビュー設定ONの場合、URLにはダイナミックリンクを登録しないでください。</a-text
                    >
                  </m-form-input>
                </div>
                <div v-if="announcementData.detailInfo.transitionType === 'url'" class="t-announcements-add__fields">
                  <m-form-input id="t-announcements-edit-info-dAccountLoginFlag__form-input">
                    <a-text id="t-announcements-edit-info-dAccountLoginFlag__a-text"
                      >ビジネスｄアカウントログイン</a-text
                    >
                    <a-switch
                      id="t-announcements-add-dAccountLoginFlag"
                      v-model="announcementData.detailInfo.dAccountLoginFlag"
                      data-testid="add-announcements-detail-webview-toggle-button"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-announcements-edit__fields">
                <div id="t-announcements-edit-cast-start-date-day__row" class="t-announcements-edit__row">
                  <a-text id="t-announcements-edit-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-announcements-edit-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="announcementData.castStartDate"
                      test-id-text="edit-announcement-publish-start"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="hasParentDoc"
                    />
                  </m-form-input>
                </div>
                <div class="t-announcements-edit__row">
                  <a-text id="t-announcements-edit-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-announcements-edit-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="announcementData.castEndDate"
                      test-id-text="edit-announcement-publish-end"
                      :min-date="minDate"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-announcements-edit__fields__pushtype">
                <m-form-input id="t-announcements-edit__fields__pushtype_2">
                  <a-select-field
                    id="t-announcements-edit__fields__pushtype__a-select-field_2"
                    v-model="announcementData.checkStatus"
                    data-testid="edit-announcement-check-status-select"
                    :options="checkStatusOptions"
                    :v-options="visibleCheckStatusOptions"
                  />
                </m-form-input>
              </div>
              <m-data-table
                :empty-text="dataTableEmp"
                :fit="true"
                :data="announcementData.checkHistories"
                id-text="announcement-check-histories-info-display"
              >
                <a-data-table-column
                  prop="publishedDocumentId"
                  :min-width="120"
                  :label="'公開ドキュメントID：<br>' + docId"
                  align="center"
                >
                  <template #default="scope">
                    <a-text size="medium" :center="true">公開{{ scope.index + 1 }}回目</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="draft" :min-width="100" label="下書き" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.draft.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.draft.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="checked" :min-width="100" label="チェック済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.checked.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.checked.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="approved" :min-width="100" label="承認済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.approved.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.approved.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
              </m-data-table>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="edit-announcement-save-button"
            variant="block"
            color="primary"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>

    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="announcementsCreate_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import MFormInput from '@/components/molecules/form-input';
import ASelectField from '@/components/atoms/select-field';
import OModal from '@/components/organisms/modal';
import OCsvUploader from '@/components/organisms/csv-uploader/index.vue';
import OCsvHistory from '@/components/organisms/csv-history/index.vue';
import MDataTable from '@/components/molecules/data-table';
import ADataTableColumn from '@/components/atoms/data-table-column';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import OTransitionTypeService from '@/components/organisms/transition-type-service';
import { announcementValidation } from '@/validations/announcement.js';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import MRichTextEditor from '@/components/molecules/rich-text-editor';
import sanitizeHtml from 'sanitize-html';

export default {
  name: 't-announcements-edit',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    OCsvUploader,
    OCsvHistory,
    MDataTable,
    ADataTableColumn,
    MFormInput,
    ASelectField,
    OModal,
    MFormActionButtons,
    ASwitch,
    AUploadField,
    ACalendarField,
    AApplyTextField,
    MRichTextEditor,
    OTransitionTypeService,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      id: this.$route.query.id,
      source: this.$route.query.source,
      show: this.$route.query.show ?? '1',
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      hasParentDoc: false,
      docId: (() => {
        const id = this.$route.query.id;
        if (id.includes('_')) {
          const lastIndex = id.lastIndexOf('_');
          return id.slice(0, lastIndex);
        }
        return id;
      })(),

      //お知らせデータ
      announcementData: {
        pushFlag: false, //push 通知フラグ
        text: '', //お知らせ内容
        title: '', //お知らせタイトル
        imageURL: '', //
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        importantFlag: false, //重要なお知らせフラグ
        castStartDate: '', //公開開始日
        castEndDate: '', //公開終了日
        deleteDate: '', //削除予定日
        draftFlag: false, //下書き
        checkStatus: 'draft', //チェックステータス
        pushDoneCount: 0, //プッシュ通知総回数
        detailInfo: {
          //お知らせ詳細
          text: '',
          title: '',
          htmlContent: '',
          contentsURL: '',
          webviewFlag: false, //Webビューフラグ
          contentsFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          imageURL: '',
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          transitionType: 'url', //遷移先種別
          transitionDoc: '', //遷移先ドキュメントID
          dAccountLoginFlag: false, //ビジネスｄアカウントログイン
        },
        pushType: 'ALL',
        pushTargetListFileType: 'accountid',
        pushTargetListFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        checkHistories: [
          {
            draft: {
              userName: '',
              updateDate: '',
            },
            checked: {
              userName: '',
              updateDate: '',
            },
            approved: {
              userName: '',
              updateDate: '',
            },
          },
        ],
        pushCategory: '',
      },

      // CSVデータ
      pushTargetCsvList: [],

      isPublished: false, //公開中かどうかのフラグ
      beforeCastEndDate: '', //変更前の公開終了日時
      pushProcessStatus: 'completed', //completedの場合、通知済み。pendingの場合、通知実施対象になる。processingの場合、通知処理中
      currentCheckStatus: 'draft', //DBに現在のCheckStatus
      pushStatus: 'all',
      pushStatusOptions: [
        { value: 'all', label: '全体プッシュ通知' },
        { value: 'limited', label: '特定プッシュ通知' },
        { value: 'none', label: 'プッシュ通知なし' },
      ],
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
      visibleCheckStatusOptions: [],
      updatedDataFlag: false,
    };
  },

  validations() {
    return announcementValidation.update(
      this.announcementData.checkStatus,
      this.announcementData.detailInfo.transitionType
    );
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-announcements-edit');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 1; i <= 3; i++) {
      this.idName[i] = 'h1-announcements-edit-' + i;
    }
    this.handleGetAnnouncementInfo();
  },
  mounted() {},
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.announcementData);
      // URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.detailInfo.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.detailInfo.contentsURL = encodeURI(payload.detailInfo.contentsURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');
      payload.text = payload.text.replace(/=/g, '%%eq%%');
      payload.detailInfo.title = payload.detailInfo.title.replace(/=/g, '%%eq%%');
      payload.detailInfo.text = payload.detailInfo.text.replace(/=/g, '%%eq%%');

      // Note: htmlコンテンツは不要なタグの削除
      payload.detailInfo.htmlContent = sanitizeHtml(payload.detailInfo.htmlContent, {
        allowedTags: ['p', 'u', 'strong', 'span'],
        allowedAttributes: {
          '*': ['style'],
        },
        allowedStyles: {
          '*': {
            // Match HEX and RGB
            color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
          },
        },
      });
      // Note: 詳細記事はHTMLタグやstyle定義のエスケープ処理を実施
      payload.detailInfo.htmlContent = payload.detailInfo.htmlContent
        .replace(/</g, '%%lt%%')
        .replace(/>/g, '%%gt%%')
        .replace(/=/g, '%%eq%%')
        .replace(/"/g, '%%q%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }
      const id = this.$route.query.id;
      if (id) {
        if (this.announcementData.castEndDate) {
          const castEndDate = new Date(this.announcementData.castEndDate);
          const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
          this.deleteDate = this.convertDateToString(deleteDate);
        }

        // 特定プッシュ通知以外はファイルクリア
        if (this.pushStatus !== 'limited') {
          this.announcementData.pushTargetListFile.filename = null;
          this.announcementData.pushTargetListFile.binary = null;
          this.announcementData.pushTargetListFile.size = null;
        }

        // 通知ステータスの状態を反映
        switch (this.pushStatus) {
          case 'all': {
            this.announcementData.pushFlag = true;
            this.announcementData.pushType = 'ALL';
            break;
          }
          case 'limited': {
            this.announcementData.pushFlag = true;
            this.announcementData.pushType = 'Limited';
            break;
          }
          case 'none': {
            this.announcementData.pushFlag = false;
            this.announcementData.pushType = 'ALL';
            break;
          }
          default: {
            this.announcementData.pushFlag = false;
            this.announcementData.pushType = 'ALL';
          }
        }
        // pushFlagをONの場合、通知実施対象の投稿にする
        if (this.announcementData.pushFlag) {
          this.pushProcessStatus = 'pending';
        }

        const formDataTmp = {
          id: id,
          text: payload.text ?? '',
          title: payload.title ?? '',
          imageFile: this.announcementData.imageFile.binary ?? '',
          pushFlag: this.announcementData.pushFlag ?? false,
          importantFlag: this.announcementData.importantFlag ?? false,
          castStartDate: this.announcementData.castStartDate ?? '',
          castEndDate: this.announcementData.castEndDate ?? '',
          deleteDate: this.deleteDate ?? '',
          draftFlag: this.announcementData.draftFlag ?? false,
          checkStatus: this.announcementData.checkStatus ?? 'draft',
          userList: '',
          imageURL: this.announcementData.imageURL ?? '',
          detailInfo: {
            text: payload.detailInfo.text ?? '',
            title: payload.detailInfo.title ?? '',
            htmlContent: payload.detailInfo.htmlContent ?? '',
            imageFile: this.announcementData.detailInfo.imageFile.binary ?? '',
            imageURL: this.announcementData.detailInfo.imageURL ?? '',
            contentsURL: encodeURI(this.announcementData.detailInfo.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
            webviewFlag: this.announcementData.detailInfo.webviewFlag ?? false,
            transitionType: this.announcementData.detailInfo.transitionType ?? 'url',
            transitionDoc: this.announcementData.detailInfo.transitionDoc ?? '',
            dAccountLoginFlag: this.announcementData.detailInfo.dAccountLoginFlag ?? false,
          },
          pushType: this.announcementData.pushType ?? 'ALL',
          pushTargetListFile: this.announcementData.pushTargetListFile.binary ?? '',
          pushTargetListFileSize: this.announcementData.pushTargetListFile.size ?? 0,
          pushTargetListFileName: this.announcementData.pushTargetListFile.filename ?? '',
          pushTargetListFileType: this.announcementData.pushTargetListFileType ?? 'accountid',
          checkHistories: this.announcementData.checkHistories ?? [],
          pushCategory: this.announcementData.pushCategory ?? '',
          pushProcessStatus: this.pushProcessStatus ?? 'pending',
          retryCount: 0,
        };
        await this.postRequest('/announcements/edit', formDataTmp).then(res => {
          if (res.status === 'success') {
            this.$router.push({ name: 'Announcements', query: { show: this.show } });
          } else {
            if (res.message) {
              this.showErrorModal(res.message);
            } else {
              this.showErrorModal(messageContent.requireTry('お知らせ更新'));
            }
          }
        });
      } else {
        this.showErrorModal(messageContent.requireTry('お知らせ更新'));
      }
    },
    handleUploadFileChangeImg(type, file) {
      const vm = this;
      const reader = new FileReader();
      const data = type === 'imageFile' ? vm.announcementData.imageFile : vm.announcementData.detailInfo.imageFile;
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          data.binary = content;
        },
        false
      );

      if (file[0]) {
        reader.readAsDataURL(file[0]);
        data.filename = file[0].name;
        data.size = file[0].size;
        vm.agree = true;
      } else {
        // Clear file content
        data.file = null;
        data.filename = null;
        data.binary = null;
        data.size = null;
        vm.agree = false;
        type === 'imageFile' ? (vm.announcementData.imageURL = '') : (vm.announcementData.detailInfo.imageURL = '');
      }
    },
    handleGetAnnouncementInfo() {
      const nowDate = utils.currentDateTime();
      const id = this.$route.query.id;
      if (id) {
        this.postRequest('/announcements/get', { id })
          .then(e => {
            if (e.status === 'success' && e.data && e.data.data) {
              const data = e.data.data;
              const authInfo = this.$store.state.user.authInfo;
              if ((authInfo === 'writer' && data.checkStatus === 'draft') || authInfo !== 'writer') {
                this.announcementData.pushFlag = data?.pushFlag || false;
                this.announcementData.title = data?.title || '';
                this.announcementData.text = data?.text || '';
                this.announcementData.imageURL = data?.imageURL || '';
                (this.announcementData.imageFile = {
                  file: data.imageURL ? data.imageURL : null,
                }),
                  (this.announcementData.importantFlag = data?.importantFlag || false);
                this.announcementData.castStartDate = data?.castStartDate || '';
                this.announcementData.castEndDate = data?.castEndDate || '';
                this.announcementData.detailInfo.title = data?.detailInfo?.title || '';
                this.announcementData.detailInfo.text = data?.detailInfo?.text || '';
                this.announcementData.detailInfo.transitionType = data?.detailInfo?.transitionType || 'url';
                this.announcementData.detailInfo.transitionDoc = data?.detailInfo?.transitionDoc || '';
                this.announcementData.detailInfo.dAccountLoginFlag = data?.detailInfo?.dAccountLoginFlag || false;
                if (data.detailInfo?.htmlContent) {
                  this.announcementData.detailInfo.htmlContent = data?.detailInfo?.htmlContent;
                } else {
                  // Note: 改行をpタグに統一してhtml化
                  this.announcementData.detailInfo.htmlContent =
                    '<p>' + this.announcementData.detailInfo.text.replace(/\n/g, '</p><p>') + '</p>';
                }
                this.announcementData.detailInfo.contentsURL = decodeURI(data?.detailInfo?.contentsURL) || '';
                this.announcementData.detailInfo.webviewFlag = data?.detailInfo?.webviewFlag || false;
                this.announcementData.detailInfo.imageURL = data?.detailInfo?.imageURL || '';
                if (data?.pushDoneCount) {
                  this.announcementData.pushDoneCount = data?.pushDoneCount;
                }
                (this.announcementData.detailInfo.imageFile = {
                  file: data.detailInfo?.imageURL ? data.detailInfo.imageURL : null,
                }),
                  (this.dataLoaded = true);
                // 変更前の公開終了日時を保存
                this.beforeCastEndDate = data?.castEndDate || '';
                // 公開中の投稿か確認
                if (
                  data &&
                  data.checkStatus === 'approved' &&
                  data.castStartDate &&
                  data.castEndDate &&
                  data.castStartDate <= nowDate &&
                  nowDate <= data.castEndDate
                ) {
                  this.isPublished = true;
                  this.minDate = '';
                }

                if (this.isPublished) {
                  this.$router.push({ name: 'Announcements' });
                  return;
                }

                this.hasParentDoc = data?.parentDocId ? true : false;

                // DBに現在のcheckStatusを保存
                this.currentCheckStatus = data?.checkStatus;

                this.announcementData.pushType = data?.pushType || 'ALL';

                // 通知ステータス設定
                if (this.announcementData.pushFlag === false) this.pushStatus = 'none';
                else if (this.announcementData.pushType === 'ALL') this.pushStatus = 'all';
                else this.pushStatus = 'limited';

                this.announcementData.pushCategory = data?.pushCategory || '';

                // CSVアップロード履歴
                if (e.data.pushTargetCsv) {
                  this.pushTargetCsvList = Object.entries(e.data.pushTargetCsv).map(([key, value]) => {
                    value['id'] = key;
                    return value;
                  });
                  // テスト特定配信用のCSVを反映させない
                  this.pushTargetCsvList = this.pushTargetCsvList.filter(csv => !csv.testPushFlag);
                  this.pushTargetCsvList.sort((a, b) => dayjs(b.updateDate).valueOf() - dayjs(a.updateDate).valueOf());
                }

                // 現在のcheckStatusの値しだいでvisibleCheckStatusOptionsを変更する
                this.visibleCheckStatusOptions = this.selectVisibleCheckStatusOptions(data?.checkStatus || '');
                // DBから取得したcheckHistoriesが空配列のときは更新せず、初期値のcampaignData.checkHistoriesを用いる
                if (data?.checkHistories && data?.checkHistories.length > 0) {
                  this.announcementData.checkHistories = data?.checkHistories;
                }
              } else {
                this.$router.push({ name: 'PermissionDenied' });
                return;
              }
            } else {
              this.showErrorModal(messageContent.requireTry('お知らせ取得'));
            }
            this.updatedDataFlag = true;
          })
          .catch(error => {
            console.error(error);
            this.showErrorModal(messageContent.requireTry('お知らせ取得'));
          });
      }
    },
    handleCancelClick() {
      const id = this.id;
      const source = this.source;
      if (id && source === 'detail') {
        return this.$router.push({ name: 'AnnouncementsDetail', query: { id: id, show: this.show } });
      } else {
        return this.$router.push({ name: 'Announcements', query: { show: this.show } });
      }
    },
    selectVisibleCheckStatusOptions(checkStatus) {
      if (checkStatus === 'draft') {
        return [
          { value: 'draft', label: '下書き' },
          { value: 'checked', label: 'チェック済み' },
        ];
      } else if (checkStatus === 'checked') {
        return [
          { value: 'draft', label: '下書き' },
          { value: 'approved', label: '承認済み' },
        ];
      } else if (checkStatus === 'approved') {
        return [{ value: 'draft', label: '下書き' }];
      }
      // イレギュラーは下書き相当
      return [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
      ];
    },
    backPageName() {
      const source = this.source;
      if (source === 'detail') {
        return '詳細画面';
      } else {
        return '一覧画面';
      }
    },
    backPage() {
      const source = this.source;
      if (source === 'detail') {
        return 'AnnouncementsDetail';
      } else {
        return 'Announcements';
      }
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
    checkDefaultCategory() {
      if (this.announcementData.importantFlag && this.announcementData.pushCategory === 'サービス') {
        this.announcementData.pushCategory = '重要';
      } else if (!this.announcementData.importantFlag && this.announcementData.pushCategory === '重要') {
        this.announcementData.pushCategory = 'サービス';
      }
    },
  },
};
</script>
