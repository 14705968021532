<template>
  <t-default-layout active-nav-id="users-edit">
    <div :class="componentClassName">
      <a-page-heading>ユーザー管理</a-page-heading>
      <m-section>
        <m-action-card v-if="dataLoaded" title="ユーザー情報編集" icon="user" :header-border="true" :narrow-body="true">
          <form ref="form" class="t-users-edit__form" @submit.prevent="handleFormSubmit">
            <div v-if="userInfo" class="t-users-edit__fields">
              <m-form-input label="ユーザーID">
                <a-text-field
                  id="userIDInput"
                  v-model="userData.userID"
                  data-testid="edit-user-id-display"
                  type="text"
                  :disabled="true"
                />
              </m-form-input>
              <m-form-input label="登録名">
                <a-text-field
                  id="userNameInput"
                  v-model="userData.userName"
                  data-testid="edit-user-name-input"
                  type="text"
                />
              </m-form-input>
              <m-form-input label="部署名（任意）">
                <a-text-field
                  id="departmentInfoInput"
                  v-model="userData.departmentInfo"
                  data-testid="edit-user-department-input"
                  type="text"
                />
              </m-form-input>
              <m-form-input label="システム権限">
                <a-select-field
                  id="authInfoSelect"
                  v-model="userData.authInfo"
                  data-testid="edit-user-auth-select"
                  :options="permissionOptions"
                  :disabled="!canEditUser"
                />
              </m-form-input>
              <a-caption-list v-if="canPasswordReset">
                <li>
                  パスワード再設定メールをユーザーに送信する場合は
                  <a-button
                    id="resetPasswordLink"
                    data-testid="password-reset-link"
                    variant="text"
                    color="primary"
                    :href="'#'"
                    @click="handlePasswordReset"
                    >こちら</a-button
                  >
                </li>
              </a-caption-list>
              <m-form-input label="ビジネスdアカウントのid（任意）">
                <a-text-field
                  id="docomoIDInput"
                  v-model="userData.docomoID"
                  data-testid="edit-user-docomoid-input"
                  type="text"
                />
              </m-form-input>
            </div>
            <m-form-action-buttons>
              <a-button
                v-if="userInfo"
                id="saveBtn"
                data-testid="edit-user-save-button"
                variant="block"
                color="primary"
                @click="handleUserEdit"
                >保存</a-button
              >
              <a-button
                id="cancelBtn"
                data-testid="edit-user-cancel-button"
                variant="outlined"
                color="primary"
                @click="handleCancelClick"
                >キャンセル</a-button
              >
            </m-form-action-buttons>
          </form>
        </m-action-card>
      </m-section>
    </div>
    <teleport to="body">
      <o-modal
        id="userEdit_resultModal"
        :visible="resultModalVisible"
        class="o-modal-remove-phone-number-confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span data-testid="user-edit-result-content-spans" v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            v-if="resultModalMode === 'normal'"
            id="resultModalOKBtn"
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
          <a-button
            v-if="resultModalMode === 'checkUserSettings'"
            id="saveCheckModalOKBtn"
            data-testid="save-check-modal-confirm-button"
            variant="block"
            color="primary"
            label="保存"
            size="small"
            @click="handleUserEdit"
          />
          <a-button
            v-if="resultModalMode === 'checkUserSettings'"
            id="saveCheckModalCancelBtn"
            data-testid="save-check-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="キャンセル"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import AButton from '@/components/atoms/button';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import ACaptionList from '@/components/atoms/caption-list';
import MFormInput from '@/components/molecules/form-input';
import ATextField from '@/components/atoms/text-field';
import ASelectField from '@/components/atoms/select-field';
import { cloneDeep } from 'lodash';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { userValidation } from '@/validations/user.js';

export default {
  name: 't-users-edit',
  components: {
    TDefaultLayout,
    APageHeading,
    MSection,
    MActionCard,
    AButton,
    MFormActionButtons,
    MFormInput,
    ATextField,
    ASelectField,
    ACaptionList,
  },
  mixins: [customRequest, customValidate, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      dataLoaded: false,
      userData: {
        userID: '',
        userName: '',
        departmentInfo: '',
        authInfo: '',
        docomoID: '',
      },
      userInfo: {},
      authInfo: this.$store.state.user.authInfo,
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      resultModalMode: '',
      permissionOptions: [
        { value: 'operator', label: 'オペレーター' },
        { value: 'manager', label: '管理者' },
        { value: 'writer', label: '作業者権限ユーザー' },
      ],
      canResetPassword: false,
      isTrialUser: false,
    };
  },

  validations() {
    return userValidation.update;
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-users-edit');
      return `${baseClassName} ${this.class}`.trim();
    },
    canEditUser() {
      if (this.authInfo === 'operator') return false;
      return true;
    },
    canPasswordReset() {
      if (this.authInfo === 'operator') return false;
      return true;
    },
  },
  created() {
    // 作業者として編集ユーザー画面に移動すると４０３ページに遷移する
    if (this.authInfo === 'writer') {
      this.$router.push({ name: 'PermissionDenied' });
      return;
    }
  },
  mounted() {
    this.permissionOptions = [
      { value: 'operator', label: '運用者権限ユーザー' },
      { value: 'manager', label: 'スーパーユーザー' },
      { value: 'writer', label: '作業者権限ユーザー' },
    ];
    const userID = this.$route.query.userID;
    if (userID) {
      this.postRequest('/users/get', { userID: userID })
        .then(e => {
          if (e.status === 'success') {
            this.userData = e.user;
            this.userData.userName = this.userData.userName.replace('_', ' ');
            this.userData.userID = userID;
            if (this.userData.authInfo === '運用者権限ユーザー') {
              this.userData.authInfo = 'operator';
            } else if (this.userData.authInfo === 'スーパーユーザー') {
              this.userData.authInfo = 'manager';
            } else if (this.userData.authInfo === '作業者権限ユーザー') {
              this.userData.authInfo = 'writer';
            }
            if (this.userData.authInfo === 'supervisor') {
              this.permissionOptions = [{ value: 'supervisor', label: 'スーパーユーザー' }];
            }
            Object.assign(this.userInfo, this.userData);

            this.dataLoaded = true;
          }
        })
        .catch(error => {
          console.error(error);
          this.showErrorModal(messageContent.requireTry('検索'));
        });
    }
  },
  methods: {
    ...mapActions(['updateLoginInfo']),
    handleFormSubmit(event) {
      event.preventDefault();
      this.handleUserEdit(cloneDeep(this.userData));
    },
    async handleUserEdit() {
      // 保存前にモーダルを非表示
      this.handleCloseModal();

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      if (this.hasSpecialCharacterInObject(this.userData)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      const sendData = {
        userName: this.userData.userName,
        departmentInfo: this.userData.departmentInfo,
        authInfo: this.userData.authInfo,
        docomoID: this.userData.docomoID,
      };

      const formDataTmp = {
        userID: this.userData.userID,
        userData: sendData,
      };
      this.postRequest('/users/update', formDataTmp)
        .then(e => {
          if (e.status) {
            if (e.status === 'validationError') return this.showErrorModal(e.msg);
            if (e.status === 'failed') return this.showErrorModal(e.message);
            this.showInfoModal('設定を変更しました。');
            if (this.$store.state.user.userID === this.userData.userID) {
              const loginInfo = {};
              Object.assign(loginInfo, this.userData);
              // Merge processed user data with user data in the store
              // Make sure updated data also update, and un-updated fields be kept in the store
              const storeUserData = this.$store.state.user;
              for (const key in storeUserData) {
                if (typeof loginInfo[key] === 'undefined' || loginInfo[key] === null) {
                  loginInfo[key] = storeUserData[key];
                }
              }
              this.updateLoginInfo(loginInfo);
            }
            return '';
          } else {
            return this.showErrorModal(messageContent.userInfoUpdateFailed());
          }
        })
        .catch(error => {
          console.error(error);
          this.showErrorModal(messageContent.userInfoUpdateFailed());
        });
    },
    handleCancelClick() {
      this.$router.push('/users');
    },
    handlePasswordReset() {
      const formDataTmp = {
        userID: this.userData.userID,
      };
      this.postRequest('/users/reset-password', formDataTmp)
        .then(e => {
          if (e.status === 'success') {
            this.showInfoModal(messageContent.passwordEmailed);
          } else {
            this.showErrorModal(messageContent.canNotResetPassword);
          }
        })
        .catch(error => {
          console.error(error);
          this.showErrorModal(messageContent.canNotResetPassword);
        });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalMode = '';
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalMode = 'normal';
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalMode = 'normal';
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
