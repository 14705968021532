import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { commonCastDateValidations } from '@/common/commonValidations.js';

const commonCreateUpdateValidations = (checkStatus, transitionType) => {
  return {
    newsAndSeminarData: {
      checkStatus: {
        accepted: helpers.withMessage(messageContent.requireSelect('チェックステータス'), value =>
          ['draft', 'checked', 'approved'].includes(value)
        ),
      },
      importantFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('優先設定'),
          value => value === true || value === false
        ),
      },
      type: {
        accepted: helpers.withMessage('タイプはニュースまたはセミナーを選択してください。', value =>
          ['news', 'seminar'].includes(value)
        ),
      },
      title: {
        required: helpers.withMessage(messageContent.requireInput('タイトル'), required),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('タイトル', 30),
          maxLength(30)
        ),
      },
      transitionType: {
        accepted: helpers.withMessage(messageContent.requireSelect('タップ時の遷移先'), value =>
          ['url', 'campaign', 'info'].includes(value)
        ),
      },
      contentsURL: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('URL'),
          requiredIf(() => checkStatus !== 'draft' && transitionType === 'url')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('URL', 500),
          maxLength(500)
        ),
      },
      imageFile: {
        binary: {
          requiredIf: helpers.withMessage(
            messageContent.requireUpload('画像'),
            requiredIf((value, vm) => {
              if (checkStatus === 'draft') return false;
              return !value && !vm.file;
            })
          ),
        },
      },
      transitionDoc: {
        requiredIf: helpers.withMessage(
          messageContent.requireSelect('遷移先ドキュメント'),
          requiredIf(
            () => ['checked', 'approved'].includes(checkStatus) && ['campaign', 'info'].includes(transitionType)
          )
        ),
      },
    },
  };
};

export const newsAndSeminarValidation = {
  create: (checkStatus, transitionType) => {
    const obj = commonCreateUpdateValidations(checkStatus, transitionType);

    obj.newsAndSeminarData.castStartDate = commonCastDateValidations(checkStatus).create.castStartDate;
    obj.newsAndSeminarData.castEndDate = commonCastDateValidations(checkStatus).create.castEndDate;

    return obj;
  },

  update: (checkStatus, transitionType) => {
    const obj = commonCreateUpdateValidations(checkStatus, transitionType);

    obj.newsAndSeminarData.castStartDate = commonCastDateValidations(checkStatus).update.castStartDate;
    obj.newsAndSeminarData.castEndDate = commonCastDateValidations(checkStatus).update.castEndDate;
    obj.isPublished = commonCastDateValidations(checkStatus).update.isPublished('newsAndSeminarData');
    obj.beforeCastEndDate = commonCastDateValidations(checkStatus).update.beforeCastEndDate('newsAndSeminarData');

    return obj;
  },
};
