<template>
  <label :class="componentClassName">
    <input
      v-model="currentValue"
      type="radio"
      class="m-user-invitation-radio__input"
      :name="name"
      :value="value"
      :checked="checked"
      v-bind="inputAttrs"
      @click="handleClick"
      @change="handleChange"
    />
    <div class="m-user-invitation-radio__body">
      <div class="m-user-invitation-radio__label-container">
        <div class="m-user-invitation-radio__indicator"></div>
        <div class="m-user-invitation-radio__label">招待ユーザー情報入力</div>
      </div>
      <div v-if="isChecked" class="m-user-invitation-radio__field">
        <m-user-invitation-list
          ref="list"
          v-model="currentUserList"
          :add-limit="addLimit"
          @change="handleUserListChange"
          @reach-limit="reachLimit"
        />
      </div>
    </div>
  </label>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import MUserInvitationList from '@/components/molecules/user-invitation-list';
import { cloneDeep } from 'lodash';

export default {
  name: 'm-user-invitation-radio',
  components: {
    MUserInvitationList,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: null,
    },
    userList: {
      type: Array,
      default: new Array(0),
    },
    addLimit: {
      type: Number,
      default: 0,
    },
  },
  emits: ['click', 'change', 'update:modelValue', 'update:userList', 'reachLimit'],
  data() {
    return {
      isChecked: this.checked || false,
      currentValue: '',
      currentUserList: [],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-user-invitation-radio',
        this.disabled ? 'disabled' : '',
        this.isChecked ? 'checked' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { checked, placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    modelValue(val, oldValue) {
      this.setCurrentValue(val);
    },
    isChecked(val, oldValue) {
      this.initItem();
    },
    userList: {
      handler(val, oldValue) {
        this.setCurrentUserList(val);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.checked) {
      this.isChecked = this.checked;
      this.initItem();
    }
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
    if (this.userList) {
      this.setCurrentUserList(this.userList);
    }
  },
  methods: {
    handleClick(event) {
      const value = event.target.value;
      this.setCurrentValue(value);
      this.$emit('click', event);
    },
    handleChange(event) {
      this.setCurrentValue(event.target.value);
      this.isChecked = event.target.checked;
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', event);
    },
    setCurrentValue(val) {
      this.currentValue = val;
      this.isChecked = this.value === this.currentValue;
    },
    setCurrentUserList(items) {
      this.currentUserList.splice(0);
      if (Array.isArray(items)) {
        items.forEach(item => {
          this.currentUserList.push(item);
        });
      }
    },
    initItem() {
      const vm = this;
      if (this.isChecked) {
        if (vm.currentUserList.length === 0) {
          // wait for UI update
          vm.$nextTick(() => {
            vm.$refs.list.handleAddItem();
          });
        }
      }
    },
    handleUserListChange(data) {
      this.setCurrentUserList(data);
      this.emitEvents();
    },
    reachLimit(val) {
      this.$emit('reachLimit', val);
    },
    emitEvents() {
      this.$emit('update:userList', cloneDeep(this.currentUserList));
      this.$emit('change', cloneDeep(this.currentUserList));
    },
  },
};
</script>
