<template>
  <div :class="componentClassName">
    <a class="a-back-page__clickable">
      <a-button id="backBtn" data-testid="go-back-page-button" icon="back" color="primary" @click="handleBackClick"
        >{{ text }}へ戻る</a-button
      >
    </a>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';

export default {
  name: 'a-back-page',
  components: {
    AButton,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    page: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    show: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-back-page');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  methods: {
    handleBackClick() {
      if (this.id) {
        if (this.show) {
          return this.$router.push({ name: this.page, query: { id: this.id, show: this.show } });
        }
        return this.$router.push({ name: this.page, query: { id: this.id } });
      }
      if (this.show) {
        return this.$router.push({ name: this.page, query: { show: this.show } });
      }
      return this.$router.push({ name: this.page });
    },
  },
};
</script>
