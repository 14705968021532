<template>
  <t-default-layout active-nav-id="app-review-settings">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="AppReviewSettings" />
      <form
        ref="form"
        class="t-app-review-settings-add__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-app-review-settings-add__fields">
          <m-section>
            <m-action-card :id="idName[1]" title="詳細設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-app-review-settings-add__fields">
                <div id="t-app-review-settings-add-show-start-date-day__row" class="t-app-review-settings-add__row">
                  <a-text id="t-app-review-settings-add-show-start-date__a-text">表示開始日時</a-text>　
                  <m-form-input id="t-app-review-settings-add-show-start-date-day__form-input">
                    <a-calendar-field
                      id="show-start-date-day"
                      v-model="data.showStartDate"
                      test-id-text="app-review-setting-show-start"
                      :min-date="minDate"
                      mode="dateTime"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="app-review-setting-save-button"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            >追加</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="appReviewSettingsCreate_resultModal"
        :visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span id="appReviewSettingsCreate_resultModal_body" v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { appReviewSettingValidation } from '@/validations/appReviewSetting.js';
import { cloneDeep } from 'lodash';
export default {
  name: 't-app-review-settings-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ACalendarField,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,

      // アプリレビュー設定データ
      data: {
        showStartDate: '', // 表示開始日時
      },
    };
  },
  validations() {
    return appReviewSettingValidation;
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-app-review-settings-add');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    const authInfo = this.$store.state.user.authInfo;
    // 「マネージャー」でない場合は「PermissionDenied」ページにリダイレクトする。
    if (authInfo !== 'manager') {
      this.$router.push({ name: 'PermissionDenied' });
      return;
    }
    for (let i = 1; i <= 3; i++) {
      this.idName[i] = 'h1-app-review-settings-add-' + i;
    }
  },
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.data);

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      const formDataTmp = {
        showStartDate: this.data.showStartDate ?? '',
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/app-review-settings/add', formDataTmp).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'AppReviewSettings' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('アプリレビュー設定作成'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleCancelClick() {
      this.$router.push({ name: 'AppReviewSettings' });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
