<template>
  <label
    :id="`${id}-label`"
    :class="componentClassName"
    @drop="handleDrop"
    @dragenter="handleDragging"
    @dragover="handleDragging"
    @dragleave="handleDragLeave"
  >
    <div class="a-upload-field__dropzone"></div>
    <input
      :id="`${id}-input`"
      ref="inputRef"
      :data-testid="testIdText + '-drop'"
      v-bind="inputAttrs"
      type="file"
      class="a-upload-field__input"
      @change="handleInputChange"
    />
    <figure class="a-upload-field__body">
      <div class="a-upload-field__preview">
        <img v-if="imageUrl" :src="imageUrl" class="img_upload" :data-testid="testIdText + '-img'" />
        <div v-else class="a-upload-field__preview-text">
          <a-text v-if="fileInfo" color="orange">
            {{ fileInfo.name }}<br />
            {{ fileSizeText }}
          </a-text>
        </div>
        <button
          v-show="!disabled"
          :id="`${id}-button`"
          :data-testid="testIdText + '-clear'"
          class="a-upload-field__clear-button"
          v-bind="inputAttrs"
          @click="handleClearButtonClick"
        >
          <a-icon icon="close" />
        </button>
      </div>
      <div v-show="!disabled" class="a-upload-field__placeholder">
        <div class="a-upload-field__label">
          <a-text color="orange">{{ label }}</a-text>
        </div>
        <div class="a-upload-field__button">
          <a-button
            id="uploadFieldBtn"
            :data-testid="testIdText + '-select'"
            variant="fill"
            color="primary"
            size="small"
            icon="upload"
            icon-color="white"
            label="アップロード"
          />
        </div>
      </div>
    </figure>
    <a-text v-if="fileInfo" color="orange">
      {{ fileSizeText }}
    </a-text>
  </label>
</template>

<script>
import numeral from 'numeral';
import { mapModifiers } from '@/libs/component';
import { Limit_UPLOAD_FILE_SIZE } from '@/libs/constants';
import AButton from '@/components/atoms/button';
import AIcon from '@/components/atoms/icon';
import AText from '@/components/atoms/text';
import { messageContent } from '@/common/messageContent.js';

export default {
  name: 'a-upload-field',
  components: {
    AButton,
    AIcon,
    AText,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Array, String], // String -> existing URL file for display
      default: null,
    },
    testIdText: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
  },
  emits: ['change', 'update:modelValue', 'showErrorModal'],
  data() {
    return {
      imageUrl: '',
      fileInfo: null,
      isDragging: false,
      currentValue: [],
      closeButton: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'a-upload-field',
        this.hasImage ? 'has-image' : '',
        this.isDragging ? 'dragging' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
    hasImage() {
      return this.imageUrl || this.fileInfo;
    },
    fileSizeText() {
      const size = this.unitChangeForByte(this.fileInfo.size);
      return `${size}`;
    },
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.setCurrentValue(val);
      } else {
        this.handleClearButtonClick();
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const inputRef = this.$refs.inputRef;
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0 && inputRef) {
        // Validate if the dropped file type is accepted

        // Only take first file in the case of dropping multiple files
        const dt = new DataTransfer();
        dt.items.add(e.dataTransfer.files[0]);
        const inputType = dt.files[0].type.toLowerCase();
        const fileName = dt.files[0].name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
        const inputTypeParts = inputType.split('/');
        const acceptedTypes = this.inputAttrs.accept.split(',').map(type => {
          return type.trim();
          //          return type.trim().toLowerCase();
        });
        if (acceptedTypes && acceptedTypes.length > 0) {
          // Check each accept type
          for (let i = 0; i < acceptedTypes.length; i++) {
            const acceptedTypeParts = acceptedTypes[i].split('/');
            if (
              acceptedTypes[i] === inputType ||
              acceptedTypes[i] === fileExtension ||
              (acceptedTypeParts.length > 0 &&
                acceptedTypeParts[1] === '*' &&
                acceptedTypeParts[0] === inputTypeParts[0])
            ) {
              // Dropped file type accepted
              inputRef.files = dt.files;
              inputRef.dispatchEvent(new Event('change', { bubbles: true }));
              return;
            }
            // Dropped file type does not match current accept type
          }
        }
      }
      // Dropped input type rejected
    },
    handleDragging(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = true;
    },
    handleDragLeave(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
    },
    handleClearButtonClick(e) {
      this.closeButton = true;
      if (e) e.preventDefault();
      const inputRef = this.$refs.inputRef;
      if (inputRef) {
        inputRef.value = '';
        inputRef.dispatchEvent(new Event('change', { bubbles: true }));
      }
    },
    handleInputChange(e) {
      // ファイル選択ダイアログでキャンセルが押されたとき、何もしないで処理を抜ける
      if (!this.closeButton && e.target.files.length === 0) {
        return;
      }
      this.closeButton = false;

      this.setCurrentValue(e.target.files || []);
      if (e.target.files && e.target.files.length > 0) {
        let set_url;
        const file = e.target.files[0];
        this.fileInfo = file;
        const isReachedFileSizeLimit = this.isReachedFileSizeLimit(file.size);
        if (isReachedFileSizeLimit) {
          this.$emit(
            'showErrorModal',
            messageContent.reachFileSizeLimit(this.fileInfo.name, this.unitChangeForByte(file.size))
          );
          this.handleClearButtonClick(e);
          return;
        }
        // check image file
        if (file.type.indexOf('image/') !== -1) {
          const fileReader = new FileReader();
          fileReader.onload = function () {
            const imageUrl = 'data:image/png;base64,' + btoa(this.result);
            const img_elements = document.getElementsByClassName('img_upload');

            for (let i = 0; i < img_elements.length; i++) {
              if (img_elements[i].src === set_url) {
                img_elements[i].src = imageUrl;
              }
            }
          };
          fileReader.readAsBinaryString(file);
          this.imageUrl = URL.createObjectURL(file);
          set_url = this.imageUrl;
        } else {
          this.imageUrl = '';
        }
      } else {
        this.imageUrl = '';
        this.fileInfo = null;
      }
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', this.currentValue);
    },
    setCurrentValue(val) {
      if (typeof val !== 'string') {
        this.currentValue.splice(0);
        if (val) {
          val.forEach(item => {
            this.currentValue.push(item);
          });
        }
      } else {
        // use for display
        this.imageUrl = val;
      }
    },
    unitChangeForByte(size) {
      const { target, unit } = this.getTarget(size);
      const newSize = target !== null ? Math.floor((size / target) * Math.pow(10, 2)) / Math.pow(10, 2) : size;
      return newSize + unit;
    },
    getTarget(size) {
      const kb = 1024;
      const mb = Math.pow(kb, 2);
      const gb = Math.pow(kb, 3);
      const tb = Math.pow(kb, 4);
      const returnData = (target, unit) => ({ target, unit });
      if (size >= tb) return returnData(tb, 'TB');
      if (size >= gb) return returnData(gb, 'GB');
      if (size >= mb) return returnData(mb, 'MB');
      if (size >= kb) return returnData(kb, 'KB');
      return returnData(null, 'byte');
    },
    // アップロードのファイルサイズが10MB以上のファイルかチェック
    isReachedFileSizeLimit(fileSize) {
      const { target, unit } = this.getTarget(fileSize);
      const newSize = target !== null ? Math.floor((fileSize / target) * Math.pow(10, 2)) / Math.pow(10, 2) : fileSize;
      if (unit === ('GB' || 'TB') || (unit === 'MB' && newSize > Limit_UPLOAD_FILE_SIZE)) {
        return true;
      }
      return false;
    },
  },
};
</script>
