<template>
  <t-default-layout active-nav-id="services">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="Services" />
      <form ref="form" class="t-services-add__form" enctype="multipart/form-data" @submit.prevent="handleFormSubmit">
        <div class="t-services-add__fields">
          <m-section>
            <m-action-card :id="idName[1]" title="詳細設定" header-border no-header-margin icon="clock">
              <div class="t-services-add__fields">
                <div class="t-services-add__row">
                  <div class="t-services-add__form-col-1">
                    <div class="t-services-add__fields">
                      <m-form-input id="t-services-add-homeBubbleFlag__form-input">
                        <a-text id="t-services-add-homeBubbleFlag__a-text">ホーム画面吹き出し</a-text>
                        <a-switch
                          id="t-services-add-homeBubbleFlag"
                          v-model="servicesData.homeBubbleFlag"
                          data-testid="add-services-priority-toggle-button"
                          @change="handleHomeBubbleFlag(servicesData.homeBubbleFlag)"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-services-add__form-col-2">
                    <div class="t-services-add__bubble">
                      <a-apply-text-field
                        id="t-services-add-homeBubbleComment"
                        v-model="servicesData.homeBubbleComment"
                        data-testid="add-services-homeBubble-input"
                        type="text"
                        maxlength="6"
                        placeholder="ホーム画面の吹き出し入力"
                        :disabled="!servicesData.homeBubbleFlag"
                      />
                    </div>
                  </div>
                </div>
                <div id="t-services-add-uploading-file" class="t-services-add__row">
                  <div class="t-services-add__col-1">
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-upload-field
                          id="upload-image"
                          v-model="servicesData.imageFile.file"
                          test-id-text="add-services-image"
                          name="imageFile"
                          class="t-services-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange('imageFile', servicesData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                      <m-form-input id="t-services-add-info-bubbleFlag__form-input">
                        <a-text id="t-services-add-info-bubbleFlag__a-text">吹き出し</a-text>　　
                        <a-switch
                          id="t-services-add-bubbleFlag"
                          v-model="servicesData.bubbleFlag"
                          data-testid="add-bubbleFlag-detail-bubbleFlag-toggle-button"
                        />
                      </m-form-input>
                      <m-form-input id="t-services-add-info-serviceOverview__form-input">
                        <a-text id="t-services-add-info-serviceOverview__a-text">サービス概要</a-text>
                      </m-form-input>
                      <m-form-input id="t-services-add-info-contentsURL__form-input">
                        <a-text id="t-services-add-info-contentsURL__a-text">Webビュー</a-text>　　
                        <a-switch
                          id="t-services-add-webviewFlag"
                          v-model="servicesData.webviewFlag"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-services-add__col-2">
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="serviceName1"
                          v-model="servicesData.serviceName1"
                          data-testid="add-services-serviceName1-input"
                          type="text"
                          maxlength="10"
                          placeholder="サービス名1入力"
                        />
                      </m-form-input>
                    </div>
                    <m-form-input>
                      <a-apply-text-field
                        id="serviceName2"
                        v-model="servicesData.serviceName2"
                        data-testid="add-services-serviceName2-input"
                        type="text"
                        maxlength="10"
                        placeholder="サービス名2入力"
                      />
                    </m-form-input>
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="bubbleComment"
                          v-model="servicesData.bubbleComment"
                          data-testid="add-services-bubbleComment-input"
                          type="text"
                          maxlength="10"
                          placeholder="吹き出しコメント入力"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="serviceOverview"
                          v-model="servicesData.serviceOverview"
                          data-testid="add-services-serviceOverview-input"
                          type="text"
                          maxlength="30"
                          placeholder="サービス概要入力"
                        />
                      </m-form-input>
                    </div>
                    <m-form-input>
                      <a-apply-text-field
                        id="t-services-add__contents-url"
                        v-model="servicesData.contentsURL"
                        class="t-services-add__contents-url"
                        :disabled="disabled"
                        data-testid="t-services-add-url-input"
                        type="text"
                        maxlength="500"
                        placeholder="遷移先URL入力"
                      />
                    </m-form-input>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card
              :id="idName[2]"
              title="サービス概要ページ"
              header-border
              no-header-margin
              icon="calendar-setting"
            >
              <div class="t-services-add__fields">
                <div class="t-services-add__row">
                  <a-text id="t-services-add-individual__a-text">概要ページ</a-text>　
                  <m-form-input :id="'t-individual-radio'">
                    <a-radio
                      :id="'t-individual-true'"
                      v-model="servicesData.individualFlag"
                      value="true"
                      label="要"
                      @change="handleIndividualFlag(true)"
                    />
                    <a-radio
                      :id="'t-individual-false'"
                      v-model="servicesData.individualFlag"
                      value="false"
                      label="不要"
                      checked="checked"
                      @change="handleIndividualFlag(false)"
                    />
                  </m-form-input>
                </div>
                <div v-if="servicesData.individualFlag">
                  <!-- サービスイメージ画像 -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">サービスイメージ画像</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-upload-field
                          v-model="servicesData.individualInfo.imageFile.file"
                          test-id-text="add-services-image"
                          name="imageFile"
                          class="t-services-add-uploading-file"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChangeImg(servicesData.individualInfo.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- サービス概要 -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">サービス概要</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          id="description"
                          v-model="servicesData.individualInfo.description"
                          data-testid="add-services-description-input"
                          class="t-banners-add__content-input"
                          type="textarea"
                          rows="4"
                          maxlength="90"
                          placeholder="サービス説明"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- 料金説明 -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">料金説明</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          id="priceDescription"
                          v-model="servicesData.individualInfo.priceDescription"
                          data-testid="add-services-priceDescription-input"
                          type="textarea"
                          rows="4"
                          maxlength="90"
                          placeholder="料金説明"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- 申込ページURL -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">申込ページURL</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-2">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">Webビュー</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.appWebviewFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ログイン要求</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.appLoginFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          @change="handleLoginCollaboration(servicesData.individualInfo.appLoginFlag)"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          v-model="servicesData.individualInfo.appURL"
                          data-testid="add-services-serviceOverview-input"
                          type="text"
                          maxlength="500"
                          placeholder="遷移先URL"
                        />
                      </m-form-input>
                      <div v-if="servicesData.individualInfo.appLoginFlag">
                        <m-form-input>
                          <a-text class="t-individual-level1__a-text">アプリ申し込みページ連携</a-text>　　
                          <a-switch
                            v-model="servicesData.individualInfo.appCollaborationFlag"
                            class="t-individual-switch"
                            data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                          />
                        </m-form-input>
                      </div>
                    </div>
                  </div>
                  <!-- 利用者向けURL -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ご利用中の方向けURL</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-2">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">Webビュー</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.customerWebviewFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">直接アクセス設定</a-text>　　
                        <a-switch
                          v-model="servicesData.individualInfo.customerAccessFlag"
                          class="t-individual-switch"
                          data-testid="add-webviewFlag-detail-bubbleFlag-toggle-button"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-apply-text-field
                          v-model="servicesData.individualInfo.customerURL"
                          data-testid="add-services-serviceOverview-input"
                          type="text"
                          maxlength="500"
                          placeholder="遷移先URL"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <!-- サービスの特徴 -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">サービスの特徴</a-text>
                      </m-form-input>
                    </div>
                  </div>
                  <div
                    v-for="(feature, index) in servicesData.individualInfo.features"
                    :key="index"
                    class="t-services-add__fields"
                  >
                    <div class="t-services-add__row">
                      <div class="t-services-add__col-features-1">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text">特徴{{ index + 1 }}</a-text>
                        </m-form-input>
                      </div>
                      <div class="t-services-add__col-features-2">
                        <m-form-input>
                          <a-upload-field
                            v-model="feature.imageFile.file"
                            name="imageFile"
                            class="t-services-add-uploading-file"
                            label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="handleUploadFileChangeImg(feature.imageFile)"
                            @showErrorModal="showErrorModal"
                          />
                        </m-form-input>
                      </div>
                      <div class="t-services-add__col-features-3">
                        <!--  子カテゴリ削除ボタン-->
                        <a-button
                          v-if="index === servicesData.individualInfo.features.length - 1 && index > 0"
                          id="deleteBtn"
                          class="t-services-delete-feature"
                          variant="round"
                          size="small"
                          label="削除"
                          color="grey"
                          icon="trash"
                          icon-color="black"
                          :inline="true"
                          @click="handleDeleteFeature(index)"
                        />
                        <m-form-input v-if="index === 0">
                          <a-button
                            data-testid="services-add-features-button"
                            class="t-individual-add-button"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            @click="handleAddFeature"
                          />
                          <span class="t-individual-add-button-red-text">特徴追加ボタン</span>
                        </m-form-input>
                        <div class="t-services-add__fields">
                          <m-form-input>
                            <a-apply-text-field
                              id="t-service-add-feature-description{{ index }}"
                              v-model="feature.description"
                              data-testid="add-services-feature{{ index }}-input"
                              type="textarea"
                              rows="3"
                              maxlength="40"
                              placeholder="特徴説明"
                            />
                          </m-form-input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ご利用までの流れ -->
                  <!-- 左設置タブ -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ご利用までの流れ<br />左設置タブ</a-text>
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level2__a-text">左タブ名</a-text>
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-2">
                      <m-form-input>
                        <a-apply-text-field
                          id="t-services-add-tab1-title"
                          v-model="servicesData.individualInfo.tabs[0].title"
                          data-testid="add-services--title-input"
                          type="text"
                          maxlength="10"
                          placeholder="左タブ名称"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-text class="t-individual-level2__a-text"></a-text>
                      </m-form-input>
                    </div>
                  </div>
                  <div v-for="(item, index) in servicesData.individualInfo.tabs[0].flow" :key="index">
                    <div class="t-services-add__row t-services-add__fields">
                      <div class="t-services-add__col-features-1">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text">左タブ流れ{{ index + 1 }}</a-text>
                        </m-form-input>
                      </div>
                      <div class="t-services-add__col-features-2">
                        <m-form-input>
                          <a-upload-field
                            v-model="item.imageFile.file"
                            test-id-text="add-services-image"
                            name="imageFile"
                            class="t-services-add-uploading-file"
                            label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="handleUploadFileChangeImg(item.imageFile)"
                            @showErrorModal="showErrorModal"
                          />
                        </m-form-input>
                      </div>
                      <div class="t-services-add__col-features-3">
                        <!--  子カテゴリ削除ボタン-->
                        <a-button
                          v-if="index === servicesData.individualInfo.tabs[0].flow.length - 1 && index > 0"
                          id="deleteBtn"
                          class="t-services-delete-flow"
                          data-testid="t-support-delete-button"
                          variant="round"
                          size="small"
                          label="削除"
                          color="grey"
                          icon="trash"
                          icon-color="black"
                          :inline="true"
                          @click="handleDeleteFlow(0, index)"
                        />
                        <m-form-input v-if="index === 0">
                          <a-button
                            id="t-services-add-tab1-flow-button"
                            data-testid="services-add-features-button"
                            class="t-individual-add-button"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            @click="handleAddFlow(0)"
                          />
                          <span class="t-individual-add-button-red-text">左タブ流れ追加ボタン</span>
                        </m-form-input>
                        <div class="t-services-add__fields">
                          <m-form-input>
                            <a-apply-text-field
                              id="t-service-add-tab1-flow{{ index }}-description"
                              v-model="item.description"
                              data-testid="add-services-tab1-flow{{ index }}-description-input"
                              type="textarea"
                              rows="3"
                              maxlength="40"
                              placeholder="流れ説明"
                            />
                          </m-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 右タブ設置 -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">ご利用までの流れ<br />右設置タブ</a-text>
                      </m-form-input>
                    </div>

                    <div class="t-services-add__col-features-3">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">右タブ設置</a-text>　　
                        <a-switch
                          id="t-services-add-rightTabFlag"
                          v-model="servicesData.individualInfo.rightTabFlag"
                          class="t-individual-switch"
                          data-testid="add-righttab-flag-toggle-button"
                          @change="handleRightTabFlag"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div v-if="servicesData.individualInfo.rightTabFlag">
                    <div class="t-services-add__row">
                      <div class="t-services-add__col-features-1">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text">右タブ名</a-text>
                        </m-form-input>
                      </div>
                      <div class="t-services-add__col-features-2">
                        <m-form-input>
                          <a-apply-text-field
                            id="t-services-add-tab1-title"
                            v-model="servicesData.individualInfo.tabs[1].title"
                            data-testid="add-services-title-input"
                            type="text"
                            maxlength="10"
                            placeholder="右タブ名称"
                          />
                        </m-form-input>
                      </div>
                      <div class="t-services-add__col-features-3">
                        <m-form-input>
                          <a-text class="t-individual-level2__a-text"></a-text>
                        </m-form-input>
                      </div>
                    </div>
                    <div v-for="(item, index) in servicesData.individualInfo.tabs[1].flow" :key="index">
                      <div class="t-services-add__row t-services-add__fields">
                        <div class="t-services-add__col-features-1">
                          <m-form-input>
                            <a-text class="t-individual-level2__a-text">右タブ流れ{{ index + 1 }}</a-text>
                          </m-form-input>
                        </div>
                        <div class="t-services-add__col-features-2">
                          <m-form-input>
                            <a-upload-field
                              v-model="item.imageFile.file"
                              name="imageFile"
                              class="t-services-add-uploading-file"
                              label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                              accept="image/png, image/jpg, image/jpeg"
                              @change="handleUploadFileChangeImg(item.imageFile)"
                              @showErrorModal="showErrorModal"
                            />
                          </m-form-input>
                        </div>
                        <div class="t-services-add__col-features-3">
                          <!--  子flow削除ボタン-->
                          <a-button
                            v-if="index === servicesData.individualInfo.tabs[1].flow.length - 1 && index > 0"
                            id="deleteBtn"
                            class="t-services-delete-flow"
                            data-testid="t-services-delete-button"
                            variant="round"
                            size="small"
                            label="削除"
                            color="grey"
                            icon="trash"
                            icon-color="black"
                            :inline="true"
                            @click="handleDeleteFlow(1, index)"
                          />

                          <m-form-input v-if="index === 0">
                            <a-button
                              id="t-services-add-tab1-flow-button"
                              data-testid="services-add-features-button"
                              class="t-individual-add-button"
                              variant="icon"
                              color="primary"
                              icon="plus"
                              icon-color="white"
                              @click="handleAddFlow(1)"
                            />
                            <span class="t-individual-add-button-red-text">右タブ流れ追加ボタン</span>
                          </m-form-input>
                          <div class="t-services-add__fields">
                            <m-form-input>
                              <a-apply-text-field
                                v-model="item.description"
                                data-testid="add-services-tab1-flow{{ index }}-description-input"
                                type="textarea"
                                rows="3"
                                maxlength="40"
                                placeholder="流れ説明"
                              />
                            </m-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- お問い合わせ -->
                  <div class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-text class="t-individual-level1__a-text">お問い合わせ</a-text>
                      </m-form-input>
                    </div>
                  </div>

                  <div v-if="!servicesData.individualInfo.inquiryFlag" class="t-services-add__row">
                    <div class="t-services-add__col-features-1">
                      <m-form-input>
                        <a-button
                          id="t-services-add-support-button"
                          data-testid="services-add-support-button"
                          class="t-individual-add-button"
                          variant="icon"
                          color="primary"
                          icon="plus"
                          icon-color="white"
                          @click="handleInquiryAdd"
                        />
                        <span class="t-individual-add-button-red-text">大カテゴリ追加ボタン</span>
                      </m-form-input>
                    </div>
                  </div>
                  <div v-if="servicesData.individualInfo.inquiryFlag" class="t-services-add__inquiry">
                    <div class="t-services-add__fields">
                      <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                        <a-text id="t-services-add__fields__a-text-top">●</a-text>
                        <a-text id="t-services-add__fields__support__a-text">大カテゴリ1</a-text>
                        <a-button
                          id="deleteBtn"
                          class="t-support-delete-category"
                          data-testid="t-support-delete-button"
                          variant="round"
                          size="small"
                          label="削除"
                          color="grey"
                          icon="trash"
                          icon-color="black"
                          :inline="true"
                          @click="handleInquiryDelete"
                        />
                      </div>
                    </div>
                    <div class="t-services-add__fields">
                      <div class="t-services-add__row">
                        <a-text id="t-services-add-support__a-text">大カテゴリ名1</a-text>　
                        <m-form-input id="t-services-add__pushtype">
                          <a-apply-text-field
                            :id="'mainCategoryName' + index"
                            v-model="servicesData.individualInfo.inquiry.mainCategoryName"
                            class="t"
                            :data-testid="'add-services-mainCategoryName-input' + index"
                            type="text"
                            maxlength="50"
                            :placeholder="`大カテゴリ名1入力`"
                          />
                        </m-form-input>
                      </div>
                    </div>
                    <div
                      v-for="(subCategory, subIndex) in servicesData.individualInfo.inquiry.subCategories"
                      :key="subIndex"
                    >
                      <div class="t-services-add__fields">
                        <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                          <a-text id="t-services-add__fields__a-text-top">●</a-text>
                          <a-text id="t-services-add__fields__support__a-text">小カテゴリ{{ subIndex + 1 }}</a-text>
                          <a-button
                            v-if="subIndex === 0"
                            id="t-services-add-support-button"
                            data-testid="services-add-support-button"
                            class="t-supprt-subcategory-add"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            @click="handleAddSubForInquiry"
                          />
                          <span v-if="subIndex === 0" class="t-add-button-method-red-text">小カテゴリ追加ボタン</span>
                          <!--  子カテゴリ削除ボタン-->
                          <a-button
                            v-if="subIndex > 0"
                            id="deleteBtn"
                            class="t-support-delete-category"
                            data-testid="t-support-delete-button"
                            variant="round"
                            size="small"
                            label="削除"
                            color="grey"
                            icon="trash"
                            icon-color="black"
                            :inline="true"
                            @click="handleDeleteSubForInquiry(subIndex)"
                          />
                        </div>
                      </div>
                      <div class="t-services-add__fields">
                        <div class="t-services-add__row">
                          <a-text id="t-services-add-support__a-text">小カテゴリ名{{ subIndex + 1 }}</a-text
                          >　
                          <m-form-input id="t-services-add__pushtype">
                            <a-apply-text-field
                              id="subCategoriesName"
                              v-model="subCategory.name"
                              class="t-services-add__pushtype"
                              data-testid="add-services-subCategoryName-input"
                              type="text"
                              maxlength="50"
                              :placeholder="`小カテゴリ名${subIndex + 1}入力`"
                            />
                          </m-form-input>
                        </div>
                        <div class="t-services-add__row">
                          <a-text id="t-services-add-support__a-text">お問い合わせ種類</a-text>　
                          <m-form-input :id="'t-support-radio' + index + subIndex">
                            <a-radio
                              v-model="subCategory.contactType"
                              class="t-support-link"
                              :disabled="disabled"
                              :data-testid="'t-support-link' + subIndex"
                              value="link"
                              label="外部リンク"
                              @change="setDefaultContactMethodLinkForInquiry(subIndex)"
                            />
                            <a-radio
                              v-model="subCategory.contactType"
                              class="t-services-button"
                              :disabled="disabled"
                              :data-testid="'t-support-button' + subIndex"
                              value="button"
                              label="ボタン"
                              @change="setDefaultContactMethodForInquiry(subIndex)"
                            />
                          </m-form-input>
                        </div>
                        <div v-if="subCategory.contactType === 'button'" class="t-services-add__row">
                          <a-text id="t-services-add-support__a-text"
                            >補足説明&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a-text
                          >　
                          <m-form-input id="t-services-add__pushtype">
                            <a-apply-text-field
                              id="description"
                              v-model="subCategory.description"
                              class="t-services-add__pushtype"
                              data-testid="add-services-description-input"
                              type="text"
                              maxlength="50"
                              placeholder="フリーテキスト（様々なパターンありそうなため）"
                            />
                          </m-form-input>
                        </div>
                        <div class="t-services-add__row">
                          <m-form-input v-if="subCategory.contactType === 'link'">
                            <a-apply-text-field
                              id="t-support-url"
                              v-model="subCategory.contactInfo[0].target"
                              class="m-form-input__content"
                              :disabled="disabled"
                              :data-testid="'add-support-url-input-for-inquiry' + subIndex"
                              type="text"
                              maxlength="500"
                              placeholder="URLを入力"
                            />
                          </m-form-input>
                        </div>
                        <div v-if="subCategory.contactType === 'button'">
                          <div v-for="(method, methodIndex) in subCategory.contactInfo" :key="methodIndex">
                            <div class="t-services-add__row">
                              <a-text id="t-services-add-method__a-text">お問い合わせ方法{{ methodIndex + 1 }} </a-text
                              >　
                              <a-button
                                v-if="methodIndex === 0"
                                id="t-services-add-method-button"
                                data-testid="services-add-method-button-for-inquiry"
                                variant="icon"
                                color="primary"
                                icon="plus"
                                icon-color="white"
                                @click="handleAddMethodForInquiry(subCategory)"
                              />
                              <span v-if="methodIndex === 0" class="t-add-button-method-red-text"
                                >問合せ追加ボタン</span
                              >
                              <!--  削除ボタン-->
                              <a-button
                                v-if="methodIndex > 0"
                                id="deleteBtn"
                                data-testid="t-support-delete-button-for-inquery"
                                variant="round"
                                size="small"
                                label="削除"
                                color="grey"
                                icon="trash"
                                icon-color="black"
                                :inline="true"
                                @click="handleDeleteMethodForInquiry(subIndex, methodIndex)"
                              />
                            </div>
                            <div class="t-services-add__row t-support-contact">
                              <a-radio
                                id="t-support-button-mail"
                                v-model="method.method"
                                class="t-support-button-mail"
                                :disabled="disabled"
                                :name="'methodContactForInquiry' + subIndex + methodIndex"
                                :data-testid="'t-support-button-for-inquery' + subIndex"
                                value="mail"
                                label="メールでお問い合わせ"
                              />
                              <a-radio
                                id="t-support-button-phone"
                                v-model="method.method"
                                class="t-support-button-phone"
                                :disabled="disabled"
                                :name="'methodContacForInquiry' + subIndex + methodIndex"
                                :data-testid="'t-support-button-phone-for-inquery' + subIndex"
                                value="phone"
                                label="電話でお問い合わせ"
                              />
                              <a-radio
                                id="t-support-button-form"
                                v-model="method.method"
                                class="t-support-button-form"
                                :disabled="disabled"
                                :name="'methodContactForInquery' + subIndex + methodIndex"
                                :data-testid="'t-support-button-phone-for-inquery' + subIndex"
                                value="form"
                                label="フォームでお問い合わせ"
                              />
                              <a-radio
                                id="t-support-button-shop"
                                v-model="method.method"
                                class="t-support-button-shop"
                                :disabled="disabled"
                                :name="'methodContactForInquery' + subIndex + methodIndex"
                                :data-testid="'t-support-button-shop-for-inquery' + subIndex"
                                value="shop"
                                label="ドコモショップでお問い合わせ"
                              />
                            </div>
                            <div class="t-services-add__row">
                              <m-form-input>
                                <a-apply-text-field
                                  id="t-support-url"
                                  v-model="method.target"
                                  class="t-add-method"
                                  :disabled="disabled"
                                  :data-testid="'add-target-input-for-inquery' + subIndex + methodIndex"
                                  type="text"
                                  maxlength="500"
                                  placeholder="メールアドレスor電話番号orURLを入力"
                                />
                              </m-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-action-card :id="idName[3]" title="サポートページ" header-border no-header-margin icon="calendar-setting">
            <template #action>
              <a-button
                id="t-services-add-support-button"
                data-testid="services-add-support-button"
                class="t-services-add-support-heading-item"
                variant="icon"
                color="primary"
                icon="plus"
                icon-color="white"
                @click="handleSupportAdd"
              />
              <span class="t-add-button-support-red-text">大カテゴリ追加ボタン</span>
            </template>
            <!-- サポート情報-->
            <div v-if="showFields">
              <div
                v-for="(support, index) in servicesData.supportInfo"
                id="t-services-add-homeBubbleFlag__form-input"
                :key="index"
              >
                <div class="t-services-add__fields">
                  <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                    <a-text id="t-services-add__fields__a-text-top">●</a-text>
                    <a-text id="t-services-add__fields__support__a-text">大カテゴリ{{ index + 1 }}</a-text>
                    <a-button
                      id="deleteBtn"
                      class="t-support-delete-category"
                      data-testid="t-support-delete-button"
                      variant="round"
                      size="small"
                      label="削除"
                      color="grey"
                      icon="trash"
                      icon-color="black"
                      :inline="true"
                      @click="handleDeleteSupport(index)"
                    />
                  </div>
                </div>
                <div class="t-services-add__fields">
                  <div class="t-services-add__row">
                    <a-text id="t-services-add-support__a-text">大カテゴリ名{{ index + 1 }}</a-text
                    >　
                    <m-form-input id="t-services-add__pushtype">
                      <a-apply-text-field
                        :id="'mainCategoryName' + index"
                        v-model="servicesData.supportInfo[index].mainCategoryName"
                        class="t"
                        :data-testid="'add-services-mainCategoryName-input' + index"
                        type="text"
                        maxlength="50"
                        :placeholder="`大カテゴリ名${index + 1}入力`"
                      />
                    </m-form-input>
                  </div>
                </div>
                <div v-for="(subCategory, subIndex) in support.subCategories" :key="subIndex">
                  <div class="t-services-add__fields">
                    <div id="t-services-add__fields__support" class="t-services-add__fields__support">
                      <a-text id="t-services-add__fields__a-text-top">●</a-text>
                      <a-text id="t-services-add__fields__support__a-text">小カテゴリ{{ subIndex + 1 }}</a-text>
                      <a-button
                        v-if="subIndex === 0"
                        id="t-services-add-support-button"
                        data-testid="services-add-support-button"
                        class="t-supprt-subcategory-add"
                        variant="icon"
                        color="primary"
                        icon="plus"
                        icon-color="white"
                        @click="handleAddSubcategory(index)"
                      />
                      <span v-if="subIndex === 0" class="t-add-button-method-red-text">小カテゴリ追加ボタン</span>
                      <!--  子カテゴリ削除ボタン-->
                      <a-button
                        v-if="subIndex > 0"
                        id="deleteBtn"
                        class="t-support-delete-category"
                        data-testid="t-support-delete-button"
                        variant="round"
                        size="small"
                        label="削除"
                        color="grey"
                        icon="trash"
                        icon-color="black"
                        :inline="true"
                        @click="handleDeleteSub(index, subIndex)"
                      />
                    </div>
                  </div>
                  <div class="t-services-add__fields">
                    <div class="t-services-add__row">
                      <a-text id="t-services-add-support__a-text">小カテゴリ名{{ subIndex + 1 }}</a-text
                      >　
                      <m-form-input id="t-services-add__pushtype">
                        <a-apply-text-field
                          id="subCategoriesName"
                          v-model="subCategory.name"
                          class="t-services-add__pushtype"
                          data-testid="add-services-subCategoryName-input"
                          type="text"
                          maxlength="50"
                          :placeholder="`小カテゴリ名${subIndex + 1}入力`"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__row">
                      <a-text id="t-services-add-support__a-text">お問い合わせ種類</a-text>　
                      <m-form-input :id="'t-support-radio' + index + subIndex">
                        <a-radio
                          :id="'t-support-link' + index + subIndex"
                          v-model="subCategory.contactType"
                          class="t-support-link"
                          :disabled="disabled"
                          :name="'contactSelectLink' + index + subIndex"
                          :data-testid="'t-support-link' + subIndex"
                          value="link"
                          label="外部リンク"
                          @change="setDefaultContactMethodLink(index, subIndex)"
                        />
                        <a-radio
                          :id="'t-support-button' + index + subIndex"
                          v-model="subCategory.contactType"
                          class="t-services-button"
                          :disabled="disabled"
                          :name="'contactSelectButton' + index + subIndex"
                          :data-testid="'t-support-button' + subIndex"
                          value="button"
                          label="ボタン"
                          @change="setDefaultContactMethod(index, subIndex)"
                        />
                      </m-form-input>
                    </div>
                    <div v-if="subCategory.contactType === 'button'" class="t-services-add__row">
                      <a-text id="t-services-add-support__a-text"
                        >補足説明&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a-text
                      >　
                      <m-form-input id="t-services-add__pushtype">
                        <a-apply-text-field
                          id="description"
                          v-model="subCategory.description"
                          class="t-services-add__pushtype"
                          data-testid="add-services-description-input"
                          type="text"
                          maxlength="50"
                          placeholder="フリーテキスト（様々なパターンありそうなため）"
                        />
                      </m-form-input>
                    </div>
                    <div class="t-services-add__row">
                      <m-form-input v-if="subCategory.contactType === 'link'">
                        <a-apply-text-field
                          id="t-support-url"
                          v-model="subCategory.contactInfo[0].target"
                          class="m-form-input__content"
                          :disabled="disabled"
                          :data-testid="'add-support-url-input' + subIndex"
                          type="text"
                          maxlength="500"
                          placeholder="URLを入力"
                        />
                      </m-form-input>
                    </div>
                    <div v-if="subCategory.contactType === 'button'">
                      <div v-for="(method, methodIndex) in subCategory.contactInfo" :key="methodIndex">
                        <div class="t-services-add__row">
                          <a-text id="t-services-add-method__a-text">お問い合わせ方法{{ methodIndex + 1 }} </a-text>　
                          <a-button
                            v-if="methodIndex === 0"
                            id="t-services-add-method-button"
                            data-testid="services-add-method-button"
                            variant="icon"
                            color="primary"
                            icon="plus"
                            icon-color="white"
                            @click="handleAddMethod(subCategory)"
                          />
                          <span v-if="methodIndex === 0" class="t-add-button-method-red-text">問合せ追加ボタン</span>
                          <!--  削除ボタン-->
                          <a-button
                            v-if="methodIndex > 0"
                            id="deleteBtn"
                            data-testid="t-support-delete-button"
                            variant="round"
                            size="small"
                            label="削除"
                            color="grey"
                            icon="trash"
                            icon-color="black"
                            :inline="true"
                            @click="handleDeleteMethod(index, subIndex, methodIndex)"
                          />
                        </div>
                        <div class="t-services-add__row t-support-contact">
                          <a-radio
                            id="t-support-button-mail"
                            v-model="method.method"
                            class="t-support-button-mail"
                            :disabled="disabled"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button' + subIndex"
                            value="mail"
                            label="メールでお問い合わせ"
                          />
                          <a-radio
                            id="t-support-button-phone"
                            v-model="method.method"
                            class="t-support-button-phone"
                            :disabled="disabled"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button-phone' + subIndex"
                            value="phone"
                            label="電話でお問い合わせ"
                          />
                          <a-radio
                            id="t-support-button-form"
                            v-model="method.method"
                            class="t-support-button-form"
                            :disabled="disabled"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button-phone' + subIndex"
                            value="form"
                            label="フォームでお問い合わせ"
                          />
                          <a-radio
                            id="t-support-button-shop"
                            v-model="method.method"
                            class="t-support-button-shop"
                            :disabled="disabled"
                            :name="'methodContact' + index + subIndex + methodIndex"
                            :data-testid="'t-support-button-shop' + subIndex"
                            value="shop"
                            label="ドコモショップでお問い合わせ"
                          />
                        </div>
                        <div class="t-services-add__row">
                          <m-form-input>
                            <a-apply-text-field
                              id="t-support-url"
                              v-model="method.target"
                              class="t-add-method"
                              :disabled="disabled"
                              :data-testid="'add-target-input' + index + subIndex + methodIndex"
                              type="text"
                              maxlength="500"
                              placeholder="メールアドレスor電話番号orURLを入力"
                            />
                          </m-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </m-action-card>
          <m-section>
            <m-action-card :id="idName[4]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-services-add__fields">
                <div id="t-services-add-cast-start-date-day__row" class="t-services-add__row">
                  <a-text id="t-services-add-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-services-add-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="servicesData.castStartDate"
                      test-id-text="add-services-publish-start"
                      :min-date="minDate"
                      date-placeholder="公開日"
                      time-placeholder="00:00"
                      mode="dateTime"
                    />
                  </m-form-input>
                </div>
                <div class="t-services-add__row">
                  <a-text id="t-services-add-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-services-add-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="servicesData.castEndDate"
                      test-id-text="add-services-publish-end"
                      :min-date="minDate"
                      date-placeholder="公開終了日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[5]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-services-add__fields__pushtype">
                <m-form-input id="t-services-add__fields__pushtype">
                  <a-select-field
                    id="t-services-add__fields__pushtype__a-select-field"
                    v-model="servicesData.checkStatus"
                    data-testid="add-services-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="add-services-save-button"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="serviceCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { cloneDeep } from 'lodash';

export default {
  name: 't-services-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASwitch,
    AUploadField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      showFields: false,
      showFeaturesFields: false,
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,
      // コンテンツデータ
      servicesData: {
        supportInfo: [
          {
            mainCategoryName: '', // カテゴリのメイン名
            subCategories: [
              {
                name: '', // 子カテゴリ名
                description: '', // 詳細
                contactType: 'link',
                contactInfo: [
                  {
                    method: 'link',
                    target: '',
                  },
                ],
              },
            ],
          },
        ],
        serviceName1: '', // サービス名1
        serviceName2: '', // サービス名2
        serviceOverview: '', // サービス概要
        imageURL: '', // 画像の公開URL
        position: 0, // 位置表示
        homeBubbleFlag: false, // ホーム画面NEW吹き出し
        homeBubbleComment: '', // ホーム画面吹き出し
        webviewFlag: false, // Webビュー
        bubbleFlag: false, // 吹き出しフラグ
        bubbleComment: '', // 吹き出しコメント
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        castStartDate: '', // 公開開始日
        castEndDate: '', // 公開終了日
        deleteDate: '', // 削除予定日
        draftFlag: true, // 下書き
        checkStatus: 'draft', // 作成時のチェックステータスは下書き
        contentsURL: '', // 遷移先URL
        individualFlag: false, // 概要ページフラグ
        individualInfo: {
          imageURL: '', // サービスイメージ画像
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          description: '', // サービス概要
          priceDescription: '', // 料金説明
          appWebviewFlag: false, // 申し込みページのwebviewフラグ
          appURL: '', // 申込ページのURL
          appLoginFlag: false, // 申込ページのログイン要求
          appCollaborationFlag: false, // アプリ申し込みページ連携
          customerWebviewFlag: false, // ご利用中の遷移先のwebviewフラグ
          customerURL: '', // ご利用中の方向けURL
          customerAccessFlag: false, // 直接アクセス設定
          // サービスの特徴
          features: [
            {
              imageURL: '',
              imageFile: {
                file: null,
                filename: null,
                binary: null,
                size: null,
              },
              description: '',
            },
          ],
          // ご利用までの流れ
          rightTabFlag: false, // 右設置タブ有無
          tabs: [
            {
              title: '',
              flow: [
                {
                  imageURL: '',
                  imageFile: {
                    file: null,
                    filename: null,
                    binary: null,
                    size: null,
                  },
                  description: '',
                },
              ],
            },
          ],
          // お問い合わせ
          inquiryFlag: false, // お問い合わせ有無
          inquiry: {
            mainCategoryName: '', // カテゴリのメイン名
            subCategories: [
              {
                name: '', // 子カテゴリ名
                contactType: 'link',
                description: '', // 詳細
                contactInfo: [
                  {
                    method: 'link',
                    target: '',
                  },
                ],
              },
            ],
          },
        },
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-services-add');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    const authInfo = this.$store.state.user.authInfo;
    if (authInfo !== 'manager') {
      this.$router.push({ name: 'PermissionDenied' });
      return;
    }
    for (let i = 1; i <= 5; i++) {
      this.idName[i] = 'h1-services-add-' + i;
    }
  },
  methods: {
    handleLoginCollaboration(flag) {
      if (flag === false) {
        this.servicesData.individualInfo.appCollaborationFlag = false;
      }
    },
    handleHomeBubbleFlag(flag) {
      if (flag === false) {
        this.servicesData.homeBubbleComment = '';
      }
    },
    handleIndividualFlag(flag) {
      this.servicesData.individualFlag = flag;
    },
    setDefaultContactMethodForInquiry(subIndex) {
      if (this.servicesData.individualInfo.inquiry.subCategories[subIndex].contactType === 'button') {
        this.servicesData.individualInfo.inquiry.subCategories[subIndex].contactInfo = [{ method: 'mail', target: '' }];
      }
    },
    setDefaultContactMethodLinkForInquiry(subIndex) {
      if (this.servicesData.individualInfo.inquiry.subCategories[subIndex].contactType === 'link') {
        this.servicesData.individualInfo.inquiry.subCategories[subIndex].contactInfo = [{ method: 'link', target: '' }];
      }
    },
    setDefaultContactMethod(index, subIndex) {
      if (this.servicesData.supportInfo[index].subCategories[subIndex].contactType === 'button') {
        this.servicesData.supportInfo[index].subCategories[subIndex].contactInfo = [{ method: 'mail', target: '' }];
      }
    },
    setDefaultContactMethodLink(index, subIndex) {
      if (this.servicesData.supportInfo[index].subCategories[subIndex].contactType === 'link') {
        this.servicesData.supportInfo[index].subCategories[subIndex].contactInfo = [{ method: 'link', target: '' }];
      }
    },
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      const payload = cloneDeep(this.servicesData);
      //  URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      //  targetに特殊文字　<>'"　を入力するチェックする
      for (const info of payload.supportInfo) {
        for (const subCategory of info.subCategories) {
          for (const contact of subCategory.contactInfo) {
            if (messageContent.checkUrlSpecialCharacter(contact.target)) {
              this.showErrorModal(messageContent.urlSpecialCharater);
              return;
            }
          }
        }
      }
      //  appURL,customerURLに特殊文字　<>'"　を入力するチェックする
      if (
        messageContent.checkUrlSpecialCharacter(payload.individualInfo.appURL) ||
        messageContent.checkUrlSpecialCharacter(payload.individualInfo.customerURL)
      ) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      for (const subCategory of payload.individualInfo.inquiry.subCategories) {
        for (const contact of subCategory.contactInfo) {
          if (messageContent.checkUrlSpecialCharacter(contact.target)) {
            this.showErrorModal(messageContent.urlSpecialCharater);
            return;
          }
        }
      }

      payload.contentsURL = encodeURI(payload.contentsURL);
      payload.individualInfo.appURL = encodeURI(payload.individualInfo.appURL);
      payload.individualInfo.customerURL = encodeURI(payload.individualInfo.customerURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.homeBubbleComment = payload.homeBubbleComment.replace(/=/g, '%%eq%%');
      payload.serviceName1 = payload.serviceName1.replace(/=/g, '%%eq%%');
      payload.serviceName2 = payload.serviceName2.replace(/=/g, '%%eq%%');
      payload.individualInfo.description = payload.individualInfo.description.replace(/=/g, '%%eq%%');
      payload.individualInfo.priceDescription = payload.individualInfo.priceDescription.replace(/=/g, '%%eq%%');
      for (const info of payload.individualInfo.features) {
        info.description = info.description.replace(/=/g, '%%eq%%');
      }
      for (const info of payload.individualInfo.tabs) {
        for (const tab of info.flow) {
          tab.description = tab.description.replace(/=/g, '%%eq%%');
        }
      }
      for (const info of payload.individualInfo.inquiry.subCategories) {
        info.description = info.description.replace(/=/g, '%%eq%%');
      }

      for (const info of payload.supportInfo) {
        for (const subCategory of info.subCategories) {
          for (const contact of subCategory.contactInfo) {
            contact.target = encodeURI(contact.target);
          }
        }
      }
      for (const contact of payload.individualInfo.inquiry.subCategories) {
        contact.target = encodeURI(contact.target);
      }

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      if (this.servicesData.castEndDate) {
        const castEndDate = new Date(this.servicesData.castEndDate);
        const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
        this.deleteDate = this.convertDateToString(deleteDate);
      }
      const formDataTmp = {
        serviceName1: payload.serviceName1 ?? '',
        serviceName2: payload.serviceName2 ?? '',
        serviceOverview: this.servicesData.serviceOverview ?? '',
        position: this.servicesData.position ?? 0,
        imageFile: this.servicesData.imageFile.binary ?? '',
        homeBubbleFlag: this.servicesData.homeBubbleFlag ?? false,
        homeBubbleComment: this.servicesData.homeBubbleComment ?? '',
        bubbleFlag: this.servicesData.bubbleFlag ?? false,
        bubbleComment: this.servicesData.bubbleComment ?? '',
        castStartDate: this.servicesData.castStartDate ?? '',
        castEndDate: this.servicesData.castEndDate ?? '',
        deleteDate: this.deleteDate ?? '',
        draftFlag: this.servicesData.draftFlag ?? false,
        webviewFlag: this.servicesData.webviewFlag ?? false,
        checkStatus: this.servicesData.checkStatus ?? '',
        imageURL: this.servicesData.imageURL ?? '',
        contentsURL: encodeURI(this.servicesData.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
        supportInfo: this.servicesData.supportInfo.map(support => ({
          mainCategoryName: support.mainCategoryName ?? '',
          subCategories: support.subCategories.map(subCategory => ({
            name: subCategory.name ?? '',
            description: subCategory.description ?? '',
            contactType: subCategory.contactType ?? '',
            contactInfo: subCategory.contactInfo.map(contact => ({
              method: contact.method ?? '',
              target: encodeURI(contact.target.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
            })),
          })),
        })),
        individualFlag: this.servicesData.individualFlag ?? false,
        individualInfo: {
          imageURL: this.servicesData.individualInfo.imageURL ?? '',
          imageFile: this.servicesData.individualInfo.imageFile.binary ?? '',
          description: payload.individualInfo.description ?? '',
          priceDescription: payload.individualInfo.priceDescription ?? '',
          appWebviewFlag: this.servicesData.individualInfo.appWebviewFlag ?? false,
          appURL: encodeURI(this.servicesData.individualInfo.appURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
          appLoginFlag: this.servicesData.individualInfo.appLoginFlag ?? false,
          appCollaborationFlag: this.servicesData.individualInfo.appCollaborationFlag ?? false,
          customerWebviewFlag: this.servicesData.individualInfo.customerWebviewFlag ?? '',
          customerURL: encodeURI(this.servicesData.individualInfo.customerURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
          customerAccessFlag: this.servicesData.individualInfo.customerAccessFlag ?? false,
          features: this.servicesData.individualInfo.features.map(feature => ({
            imageURL: feature.imageURL ?? '',
            imageFile: feature.imageFile.binary ?? '',
            description: feature.description ?? '',
          })),
          tabs: this.servicesData.individualInfo.tabs.map(tab => ({
            title: tab.title ?? '',
            flow: tab.flow.map(flow => ({
              imageURL: flow.imageURL ?? '',
              imageFile: flow.imageFile.binary ?? '',
              description: flow.description ?? '',
            })),
          })),
          inquiry: {
            mainCategoryName: this.servicesData.individualInfo.inquiry.mainCategoryName ?? '',
            subCategories: this.servicesData.individualInfo.inquiry.subCategories.map(subCategory => ({
              name: subCategory.name ?? '',
              description: subCategory.description ?? '',
              contactType: subCategory.contactType ?? '',
              contactInfo: subCategory.contactInfo.map(contact => ({
                method: contact.method ?? '',
                target: encodeURI(contact.target.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
              })),
            })),
          },
        },
      };
      if (this.showFields === false) {
        formDataTmp.supportInfo = [];
      }
      if (this.servicesData.individualFlag === false) {
        (formDataTmp.individualInfo.imageURL = ''),
          (formDataTmp.individualInfo.imageFile = ''),
          (formDataTmp.individualInfo.description = ''),
          (formDataTmp.individualInfo.priceDescription = ''),
          (formDataTmp.individualInfo.appWebviewFlag = false),
          (formDataTmp.individualInfo.appURL = ''),
          (formDataTmp.individualInfo.appLoginFlag = false),
          (formDataTmp.individualInfo.appCollaborationFlag = false),
          (formDataTmp.individualInfo.customerWebviewFlag = ''),
          (formDataTmp.individualInfo.customerURL = ''),
          (formDataTmp.individualInfo.customerAccessFlag = false),
          (formDataTmp.individualInfo.features = []),
          (formDataTmp.individualInfo.tabs = []),
          (formDataTmp.individualInfo.inquiry = {
            mainCategoryName: '',
            subCategories: [],
          });
      }
      if (this.servicesData.individualInfo.appLoginFlag === false) {
        formDataTmp.individualInfo.appCollaborationFlag = false;
      }
      this.disabledButton = 'disabled';
      await this.postRequest('/services/add', formDataTmp).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Services' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('サービス作成'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleUploadFileChangeImg(fileObj) {
      const vm = this;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          fileObj.binary = content;
        },
        false
      );
      if (fileObj.file[0]) {
        reader.readAsDataURL(fileObj.file[0]);
        fileObj.filename = fileObj.file[0].name;
        fileObj.size = fileObj.file[0].size;
        if (fileObj.filename !== null) {
          vm.agree = true;
        }
      } else {
        // clear file content
        fileObj.file = null;
        fileObj.filename = null;
        fileObj.binary = null;
        fileObj.size = null;
        vm.agree = false;
      }
    },
    handleUploadFileChange(type, file) {
      const vm = this;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          vm.servicesData[type].binary = content;
        },
        false
      );

      if (file[0]) {
        reader.readAsDataURL(file[0]);
        vm.servicesData[type].filename = file[0].name;
        vm.servicesData[type].size = file[0].size;

        if (type === 'imageFile' && vm.servicesData[type].filename !== null) {
          vm.agree = true;
        }
      } else {
        // clear file content
        vm.servicesData[type].file = null;
        vm.servicesData[type].filename = null;
        vm.servicesData[type].binary = null;
        vm.servicesData[type].size = null;

        if (type === 'imageFile' && vm.servicesData[type].filename === null) {
          vm.agree = false;
        }
      }
    },
    // お問い合わせを追加する
    handleInquiryAdd() {
      // inquiryFlag が true に設定されている場合は、新しいページを追加しないでください
      if (!this.servicesData.individualInfo.inquiryFlag) {
        this.servicesData.individualInfo.inquiryFlag = true;
      } else {
        this.showErrorModal('大カテゴリには3つ以上のアイテムを追加できません。');
      }
    },
    // お問い合わせを削除する
    handleInquiryDelete() {
      // データの初期化
      this.servicesData.individualInfo.inquiry.mainCategoryName = '';
      this.servicesData.individualInfo.inquiry.subCategories = [
        {
          name: '', // 子カテゴリ名
          contactType: 'link',
          description: '', // 詳細
          contactInfo: [
            {
              method: 'link',
              target: '',
            },
          ],
        },
      ];
      // 表示フラグ変更
      this.servicesData.individualInfo.inquiryFlag = false;
    },
    // サポートを追加する
    handleSupportAdd() {
      const maxSupportPageAdd = 3;
      // showFields が true に設定されている場合は、新しいページを追加しないでください
      if (!this.showFields) {
        this.showFields = true;
      } else if (this.servicesData.supportInfo.length < maxSupportPageAdd) {
        // showFields が true に設定されている場合は、 配列に新しいページを追加します。
        this.servicesData.supportInfo.push({
          mainCategoryName: '',
          subCategories: [
            {
              name: '',
              description: '',
              contactType: 'link',
              contactInfo: [
                {
                  target: '',
                  method: 'link',
                },
              ],
            },
          ],
        });
      } else {
        this.showErrorModal('大カテゴリには3つ以上のアイテムを追加できません。');
      }
    },
    // サポート削除する
    handleDeleteSupport(index) {
      // インデックスに対応する情報入力ページを削除します
      this.servicesData.supportInfo.splice(index, 1);

      // 残りの情報入力ページのインデックスを更新します。
      this.servicesData.supportInfo.forEach((support, i) => {
        // 子情報入力ページのインデックスを更新する
        support.subCategories.forEach(subCategory => {
          subCategory.index = i;
        });
      });
    },
    //　subカテゴリの追加する
    handleAddSubcategory(index) {
      const support = this.servicesData.supportInfo[index];
      if (support.subCategories.length < 5) {
        support.subCategories.push({
          name: '',
          description: '',
          contactType: 'link',
          contactInfo: [
            {
              method: 'mail',
              target: '',
            },
          ],
        });
      } else {
        this.showErrorModal('小カテゴリには6つ以上のアイテムを追加できません。');
      }
    },
    // subカテゴリを削除する
    handleDeleteSub(mainIndex, subIndex) {
      if (subIndex === 0) return;
      const support = this.servicesData.supportInfo[mainIndex];
      support.subCategories.splice(subIndex, 1);
    },
    // subメソードを削除する
    handleDeleteMethod(index, subCategoryIndex, methodIndex) {
      if (methodIndex === 0) return;
      const subCategory = this.servicesData.supportInfo[index].subCategories[subCategoryIndex];
      subCategory.contactInfo.splice(methodIndex, 1);
    },
    // subメソードを追加する
    handleAddMethod(subCategory) {
      if (subCategory.contactInfo.length < 2) {
        subCategory.contactInfo.push({
          method: 'mail',
          target: '',
        });
      } else {
        this.showErrorModal('お問い合わせ方法には3つ以上のアイテムを追加できません。');
      }
    },
    handleChange() {},
    handleCancelClick() {
      this.$router.push({ name: 'Services' });
    },
    // featureを追加する
    handleAddFeature() {
      if (this.servicesData.individualInfo.features.length < 5) {
        this.servicesData.individualInfo.features.push({
          imageURL: '',
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          description: '',
        });
      } else {
        this.showErrorModal('サービスの特徴には6つ以上のアイテムを追加できません。');
      }
    },
    // featureを削除する
    handleDeleteFeature(index) {
      if (index === 0) return;
      this.servicesData.individualInfo.features.splice(index, 1);
    },

    // flowを追加する
    handleAddFlow(tabIndex) {
      if (this.servicesData.individualInfo.tabs[tabIndex].flow.length < 10) {
        this.servicesData.individualInfo.tabs[tabIndex].flow.push({
          imageURL: '',
          imageFile: {
            file: null,
            filename: null,
            binary: null,
            size: null,
          },
          description: '',
        });
      } else {
        this.showErrorModal('ご利用の流れには11以上のアイテムを追加できません。');
      }
    },
    // flowを削除する
    handleDeleteFlow(tabIndex, index) {
      if (index === 0) return;
      this.servicesData.individualInfo.tabs[tabIndex].flow.splice(index, 1);
    },
    // 右設置タブの有無を制御する
    handleRightTabFlag() {
      if (this.servicesData.individualInfo.rightTabFlag) {
        this.servicesData.individualInfo.tabs[1] = {
          title: '',
          flow: [
            {
              imageURL: '',
              imageFile: {
                file: null,
                filename: null,
                binary: null,
                size: null,
              },
              description: '',
            },
          ],
        };
      } else {
        this.servicesData.individualInfo.tabs.splice(1, 1);
      }
    },
    //　[個別ページお問い合わせ]subカテゴリ追加
    handleAddSubForInquiry() {
      const inquiry = this.servicesData.individualInfo.inquiry;
      if (inquiry.subCategories.length < 5) {
        inquiry.subCategories.push({
          name: '',
          description: '',
          contactType: 'link',
          contactInfo: [
            {
              method: 'mail',
              target: '',
            },
          ],
        });
      } else {
        this.showErrorModal('小カテゴリには6つ以上のアイテムを追加できません。');
      }
    },
    // [個別ページお問い合わせ]subカテゴリ削除
    handleDeleteSubForInquiry(subIndex) {
      if (subIndex === 0) return;
      const inquiry = this.servicesData.individualInfo.inquiry;
      inquiry.subCategories.splice(subIndex, 1);
    },
    // [個別ページお問い合わせ]subメソッド追加
    handleAddMethodForInquiry(subCategory) {
      if (subCategory.contactInfo.length < 2) {
        subCategory.contactInfo.push({
          method: 'mail',
          target: '',
        });
      } else {
        this.showErrorModal('お問い合わせ方法には3つ以上のアイテムを追加できません。');
      }
    },
    // [個別ページお問い合わせ]subメソッド削除
    handleDeleteMethodForInquiry(subCategoryIndex, methodIndex) {
      if (methodIndex === 0) return;
      const subCategory = this.servicesData.individualInfo.inquiry.subCategories[subCategoryIndex];
      subCategory.contactInfo.splice(methodIndex, 1);
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
