<template>
  <div>
    <router-view :current-user-info="currentUserInfo"></router-view>
  </div>
</template>

<script>
export default {
  name: 't-index',
  data: function () {
    return {};
  },
  computed: {
    currentUserInfo() {
      return {
        userName: this.$store.state.user.userName ? this.$store.state.user.userName : '',
        auth_info: this.$store.state.user.authInfo ? this.$store.state.user.authInfo : '',
        departmentInfo: this.$store.state.user.departmentInfo ? this.$store.state.user.departmentInfo : '',
        edit_profile_url: '#1',
        logout_url: '#2',
      };
    },
  },
};
</script>
