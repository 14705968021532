import { helpers, required, sameAs } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';

const commonPasswordCheck = target => {
  return {
    passwordCheck: helpers.withMessage(messageContent.passwordCheck(target), value => {
      if (!value) return true;
      return messageContent.passwordPatternCheck(value);
    }),
  };
};

export const loginValidation = {
  login: {
    userInfo: {
      userID: {
        required: helpers.withMessage(messageContent.requireInput('ユーザーID'), required),
      },
      password: {
        required: helpers.withMessage(messageContent.requireInput('パスワード'), required),
      },
    },
  },

  loginInvitedUserSetPassword: {
    formData: {
      userID: {
        required: helpers.withMessage(messageContent.requireInput('ユーザーID'), required),
      },
      old_password: {
        required: helpers.withMessage(messageContent.requireInput('旧パスワード'), required),
        passwordCheck: commonPasswordCheck('旧パスワード').passwordCheck,
      },
      password: {
        required: helpers.withMessage(messageContent.requireInput('新しいパスワード'), required),
        passwordCheck: commonPasswordCheck('新しいパスワード').passwordCheck,
      },
      passwordConfirm: {
        required: helpers.withMessage(messageContent.requireInput('新しいパスワード(確認)'), required),
        passwordCheck: commonPasswordCheck('新しいパスワード(確認)').passwordCheck,
        sameAsPassword: helpers.withMessage(messageContent.passwordNotSame, (value, vm) => {
          if (!value) return true;
          return value === vm.password;
        }),
      },
    },
  },
};
