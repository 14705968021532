<template>
  <label :class="componentClassName">
    <input
      v-model="currentValue"
      type="radio"
      class="m-user-invitation-upload-radio__input"
      :name="name"
      :value="value"
      :checked="checked"
      v-bind="inputAttrs"
      @click="handleClick"
      @change="handleChange"
    />
    <div class="m-user-invitation-upload-radio__body">
      <div class="m-user-invitation-upload-radio__label-container">
        <div class="m-user-invitation-upload-radio__indicator"></div>
        <div class="m-user-invitation-upload-radio__label">ファイルで一括登録</div>
      </div>
      <div v-if="isChecked" class="m-user-invitation-upload-radio__field">
        <div class="m-user-invitation-upload-radio__upload">
          <m-form-input>
            <a-upload-field
              v-model="currentUploadFile.file"
              accept="text/csv"
              label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
              @change="handleFileUploadChange(currentUploadFile)"
            />
          </m-form-input>
        </div>
        <div v-if="templateUrl" class="m-user-invitation-upload-radio__button">
          <a-button variant="text" color="primary" :href="templateUrl" target="_blank">
            テンプレートをダウンロード <a-icon icon="download" color="orange" />
          </a-button>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import MFormInput from '@/components/molecules/form-input';
import AUploadField from '@/components/atoms/upload-field';
import AButton from '@/components/atoms/button';
import AIcon from '@/components/atoms/icon';
import { cloneDeep } from 'lodash';

export default {
  name: 'm-user-invitation-upload-radio',
  components: {
    MFormInput,
    AUploadField,
    AButton,
    AIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: null,
    },
    uploadFile: {
      type: [Array, Object],
      default: null,
    },
    templateUrl: {
      type: String,
      default: '',
      required: true,
    },
  },
  emits: ['click', 'change', 'update:modelValue', 'update:uploadFile'],
  data() {
    return {
      isChecked: this.checked || false,
      currentValue: '',
      currentUploadFile: {
        file: '',
        binary: null,
      },
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-user-invitation-upload-radio',
        this.disabled ? 'disabled' : '',
        this.isChecked ? 'checked' : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    disabled() {
      return this.$attrs.disabled;
    },
    inputAttrs() {
      const { checked, placeholder, ...inputAttrs } = this.$attrs;
      return inputAttrs;
    },
  },
  watch: {
    modelValue(val, oldValue) {
      this.setCurrentValue(val);
    },
    uploadFile(val, oldValue) {
      this.setCurrentUploadFile(val);
    },
  },
  mounted() {
    if (this.checked) {
      this.isChecked = this.checked;
    }
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
    if (this.userList) {
      this.setCurrentUserList(this.userList);
    }
  },
  methods: {
    handleClick(event) {
      const value = event.target.value;
      this.setCurrentValue(value);
      this.$emit('click', event);
    },
    handleChange(event) {
      this.setCurrentValue(event.target.value);
      this.isChecked = event.target.checked;
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', event);
    },
    setCurrentValue(val) {
      this.currentValue = val;
      this.isChecked = this.value === this.currentValue;
    },
    setCurrentUploadFile(file) {
      this.currentUploadFile = file;
    },
    handleFileUploadChange(filePair) {
      const vm = this;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          vm.currentUploadFile.binary = content;
        },
        false
      );

      if (filePair.file[0] && filePair.file[0] instanceof Blob) {
        reader.readAsDataURL(filePair.file[0]);
      } else {
        // clear file content
        vm.currentUploadFile.file = null;
        vm.currentUploadFile.binary = null;
      }

      this.setCurrentUploadFile(filePair);
      this.emitEvents();
    },
    emitEvents() {
      this.$emit('update:uploadFile', cloneDeep(this.currentUploadFile));
      this.$emit('change', cloneDeep(this.currentUploadFile));
    },
  },
};
</script>
