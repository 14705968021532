<template>
  <ul class="a-caption-list">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'a-caption-list',
  props: {
    list: {
      type: Array,
      default: null,
    },
  },
};
</script>
