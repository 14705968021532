<template>
  <t-default-layout active-nav-id="banners">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="Banners" />
      <form ref="form" class="t-banners-add__form" enctype="multipart/form-data" @submit.prevent="handleFormSubmit">
        <div class="t-banners-add__fields">
          <m-section>
            <m-action-card :id="idName[1]" title="詳細設定" header-border no-header-margin icon="clock-remove">
              <div class="t-banners-add__fields">
                <m-form-input id="t-banners-add-info-importantFlag__form-input">
                  <a-text id="t-banners-add-info-importantFlag__a-text">優先</a-text>　　
                  <a-switch
                    id="t-banners-add-importantFlag"
                    v-model="bannerData.importantFlag"
                    data-testid="add-banner-priority-toggle-button"
                  />
                </m-form-input>
                <o-transition-type-selector
                  v-model:transitionType="bannerData.transitionType"
                  v-model:transitionDoc="bannerData.transitionDoc"
                  v-model:contentsUrl="bannerData.contentsURL"
                />
                <m-form-input
                  v-if="bannerData.transitionType === 'url'"
                  id="t-banners-add-info-dAccountLoginFlag__form-input"
                >
                  <a-text id="t-banners-add-info-dAccountLoginFlag__a-text">ビジネスｄアカウントログイン</a-text>　　
                  <a-switch id="t-banners-add-dAccountLoginFlag" v-model="bannerData.dAccountLoginFlag" />
                </m-form-input>
                <m-form-input
                  v-if="bannerData.transitionType === 'url'"
                  id="t-banners-add-info-webviewFlag__form-input"
                >
                  <a-text id="t-banners-add-info-webviewFlag__a-text">Webビュー</a-text>　　
                  <a-switch id="t-banners-add-webviewFlag" v-model="bannerData.webviewFlag" />
                </m-form-input>
                <m-form-input
                  v-if="bannerData.transitionType === 'url'"
                  id="t-banners-add-info-reloclubFlag__form-input"
                >
                  <a-text id="t-banners-add-info-reloclubFlag__a-text">リロクラブ連携</a-text>　　
                  <a-switch id="t-banners-add-reloclubFlag" v-model="bannerData.reloclubFlag" />
                </m-form-input>
                <div id="t-banners-add-info-showPopupFlag__form-input" class="t-banners-add__fields">
                  <m-form-input>
                    <a-text id="t-banners-add-info-showPopupFlag__a-text">ポップアップ表示</a-text>　　
                    <a-switch id="t-banners-add-showPopupFlag" v-model="bannerData.showPopupFlag" />
                  </m-form-input>
                  <div
                    v-if="bannerData.showPopupFlag"
                    id="t-banners-add__fields__upload-file"
                    class="t-banners-add__fields__upload-file"
                  >
                    <a-text id="t-banners-add__fields__upload-file__a-text_1"
                      >ポップアップ用画像（340*222px推奨）</a-text
                    >
                    <div class="t-banners-add__fields__upload-file__m-form-input">
                      <m-form-input>
                        <a-upload-field
                          id="t-banners-add__fields__upload-file__a-upload-field"
                          v-model="bannerData.popupImageFile.file"
                          test-id-text="add-popup-banner-image"
                          name="popupImageFile"
                          class="t-banners-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange(bannerData.popupImageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
                <div id="t-banners-add-info-uploading-file" class="t-banners-add__row">
                  <div class="t-banners-add__col-1">
                    <div class="t-banners-add__fields">
                      <a-text v-if="bannerData.showPopupFlag" id="t-banners-add__fields__upload-file__a-text_2"
                        >バナー用画像（340*89px推奨）</a-text
                      >
                      <m-form-input>
                        <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                        <!-- <a-upload-field
                            id="t-banners-add-info-upload-image"
                            v-model="bannerData.detailInfo.imageFile.file"
                            class="t-banners-add-uploading-file__form-upload"
                            label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                            accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                            @change="handleUploadFileChange('detailImageFile', bannerData.detailInfo.imageFile.file)"
                            @showErrorModal="showErrorModal"
                          /> -->
                        <a-upload-field
                          id="t-banners-add-info-upload-image"
                          v-model="bannerData.imageFile.file"
                          test-id-text="add-banner-image"
                          name="imageFile"
                          class="t-banners-add-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange(bannerData.imageFile)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-banners-add__col-2">
                    <div class="t-banners-add__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="t-banners-add-info-title"
                          v-model="bannerData.title"
                          data-testid="add-banner-title-input"
                          type="text"
                          maxlength="17"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-apply-text-field
                          id="t-banners-add-info-text"
                          v-model="bannerData.text"
                          data-testid="add-banner-content-input"
                          class="t-banners-add__content-input"
                          type="textarea"
                          rows="7"
                          maxlength="42"
                          placeholder="テキスト入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[2]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-banners-add__fields">
                <div id="t-banners-add-cast-start-date-day__row" class="t-banners-add__row">
                  <a-text id="t-banners-add-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-banners-add-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="bannerData.castStartDate"
                      test-id-text="add-banner-publish-start"
                      :min-date="minDate"
                      mode="dateTime"
                    />
                  </m-form-input>
                </div>
                <div class="t-banners-add__row">
                  <a-text id="t-banners-add-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-banners-add-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="bannerData.castEndDate"
                      test-id-text="add-banner-publish-end"
                      :min-date="minDate"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-banners-add__fields__pushtype">
                <m-form-input id="t-banners-add__fields__pushtype">
                  <a-select-field
                    id="t-banners-add__fields__pushtype__a-select-field"
                    v-model="bannerData.checkStatus"
                    data-testid="add-banner-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button
            id="saveBtn"
            data-testid="add-banner-save-button"
            variant="block"
            color="primary"
            :disabled="disabledButton"
            submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>
    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="bannersCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import OTransitionTypeSelector from '@/components/organisms/transition-type-selector';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { bannerValidation } from '@/validations/banner.js';
import { cloneDeep } from 'lodash';
export default {
  name: 't-banners-add',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    AUploadField,
    ACalendarField,
    AApplyTextField,
    OTransitionTypeSelector,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,

      //バナーデータ
      bannerData: {
        text: '', //バナー内容
        title: '', //バナータイトル
        imageURL: '', //
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        importantFlag: false, //優先フラグ
        dAccountLoginFlag: false, //ビジネスｄアカウントログインフラグ
        webviewFlag: false, //Webビューフラグ
        reloclubFlag: false, //リロクラブ連携フラグ
        showPopupFlag: false, //ポップアップ表示フラグ
        castStartDate: '', //公開開始日
        castEndDate: '', //公開終了日
        deleteDate: '', //削除予定日
        draftFlag: true, //下書き
        checkStatus: 'draft', //作成時のチェックステータスは下書き
        contentsURL: '', //遷移先URL
        transitionType: 'url', //遷移先種別
        transitionDoc: '', //遷移先ドキュメントID
        popupImageURL: '',
        popupImageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
    };
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-banners-add');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 1; i <= 3; i++) {
      this.idName[i] = 'h1-banners-add-' + i;
    }
  },
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }

      // リロクラブ連携チェック
      if (this.bannerData.reloclubFlag && (!this.bannerData.dAccountLoginFlag || !this.bannerData.webviewFlag)) {
        this.showErrorModal(
          'リロクラブ連携ONにはビジネスｄアカウントログインおよびWebビュー設定をONにする必要があります。'
        );
        return;
      }

      const payload = cloneDeep(this.bannerData);
      // URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.contentsURL = encodeURI(payload.contentsURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');
      payload.text = payload.text.replace(/=/g, '%%eq%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      if (this.bannerData.castEndDate) {
        const castEndDate = new Date(this.bannerData.castEndDate);
        const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
        this.deleteDate = this.convertDateToString(deleteDate);
      }
      const formDataTmp = {
        text: payload.text ?? '',
        title: payload.title ?? '',
        imageFile: this.bannerData.imageFile.binary ?? '',
        importantFlag: this.bannerData.importantFlag ?? false,
        dAccountLoginFlag: this.bannerData.dAccountLoginFlag ?? false,
        webviewFlag: this.bannerData.webviewFlag ?? false,
        reloclubFlag: this.bannerData.reloclubFlag ?? false,
        showPopupFlag: this.bannerData.showPopupFlag ?? false,
        popupImageFile: this.bannerData.popupImageFile.binary ?? '',
        popupImageURL: this.bannerData.popupImageURL ?? '',
        castStartDate: this.bannerData.castStartDate ?? '',
        castEndDate: this.bannerData.castEndDate ?? '',
        deleteDate: this.deleteDate ?? '',
        draftFlag: this.bannerData.draftFlag ?? false,
        checkStatus: this.bannerData.checkStatus ?? '',
        imageURL: this.bannerData.imageURL ?? '',
        contentsURL: encodeURI(this.bannerData.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
        transitionType: this.bannerData.transitionType ?? 'url',
        transitionDoc: this.bannerData.transitionDoc ?? '',
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/banners/add', formDataTmp).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'Banners' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('バナー作成'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleUploadFileChange(fileObj) {
      const vm = this;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          fileObj.binary = content;
        },
        false
      );
      if (fileObj.file[0]) {
        reader.readAsDataURL(fileObj.file[0]);
        fileObj.filename = fileObj.file[0].name;
        fileObj.size = fileObj.file[0].size;
        if (fileObj.filename !== null) {
          vm.agree = true;
        }
      } else {
        // clear file content
        fileObj.file = null;
        fileObj.filename = null;
        fileObj.binary = null;
        fileObj.size = null;
        vm.agree = false;
      }
    },
    handleChange() {},
    handleCancelClick() {
      this.$router.push({ name: 'Banners' });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
