<template>
  <t-default-layout ref="defaultLayout" active-nav-id="campaigns">
    <div id="t-campaigns-component" :class="componentClassName">
      <div class="t-campaigns-heading-line">
        <a-page-heading class="t-campaigns-heading-item">記事・セミナー（一覧）</a-page-heading>
        <a-button
          id="t-campaigns-add-button"
          data-testid="campaign-add-button"
          class="t-campaigns-heading-item"
          variant="icon"
          color="primary"
          icon="plus"
          icon-color="white"
          @click="handleCampaignAdd"
        />
      </div>
      <m-section>
        <div class="t-campaigns-tabgroup">
          <ul class="t-campaigns-tabnav">
            <li
              data-testid="unpublish-campaigns-display-button"
              :class="{ selected: show == '1' }"
              @click="select('1')"
            >
              未公開
            </li>
            <li data-testid="open-campaigns-display-button" :class="{ selected: show == '2' }" @click="select('2')">
              公開中
            </li>
            <li data-testid="end-campaigns-display-button" :class="{ selected: show == '3' }" @click="select('3')">
              公開終了
            </li>
          </ul>
          <m-action-card title="" icon="">
            <m-data-table
              ref="dataTable"
              id-text="campaign-info-display"
              :empty-text="dataTableEmptyText"
              :fit="true"
              :data="displayedTableData()"
              :border="true"
              @rowClick="handleRowClick($event.row.documentID)"
            >
              <a-data-table-column prop="id" label="タイトル" align="center">
                <template #default="scope">
                  <div v-if="scope.row.imageURL">
                    <img :src="scope.row.imageURL" alt="Logo" class="t-campaigns-record-logo" width="70" height="70" />
                  </div>
                  <div v-else>
                    <a-icon class="t-campaigns-record-logo" size="70px" icon="dummy-picture" color="white"></a-icon>
                  </div>
                  <div class="t-campaigns-record-text-column">
                    <a-text
                      :data-testid="'campaign-title-display-' + scope.index"
                      class="t-campaigns-record-title"
                      size="medium"
                      weight="bold"
                      >{{ scope.row.title ? scope.row.title : '　　' }}</a-text
                    >
                    <a-text
                      :data-testid="'campaign-draft-author-update-display-' + scope.index"
                      class="t-campaigns-record-info"
                    >
                      <span>
                        {{ scope.row.checkStatus ? checkStatus2ViewStatus(scope.row.checkStatus) + '：' : '' }}
                        {{
                          scope.row.checkHistories && scope.row.checkHistories.length > 0
                            ? scope.row.checkHistories[scope.row.checkHistories.length - 1][
                                assignIrregularCheckStatus(scope.row.checkStatus)
                              ].userName + '　　'
                            : ''
                        }}
                        更新日：{{ scope.row.updateDate }}
                      </span>
                    </a-text>
                    <a-text
                      v-show="scope.row.childrenDocIdsStr || scope.row.parentDocId"
                      class="t-campaigns-record-info"
                      weight="bold"
                    >
                      <span style="font-weight: bold">
                        {{ scope.row.childrenDocIdsStr ? `編集先：${scope.row.childrenDocIdsStr}` : '' }}
                        {{ scope.row.parentDocId ? `編集元：${scope.row.parentDocId}` : '' }}
                      </span>
                    </a-text>
                  </div>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="115" prop="documentID" label="ドキュメントID" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.documentID }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="115" prop="timestamp" label="公開日" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{
                    scope.row.castStartDate ? scope.row.castStartDate : '-'
                  }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="115" prop="timestamp" label="公開終了日" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.castEndDate ? scope.row.castEndDate : '-' }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="80" prop="progressInfo" label="PICKUP" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{ scope.row.importantFlag ? '◯' : '-' }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="90" prop="repeatCount" label="プッシュ通知" align="center">
                <template #default="scope">
                  <a-text size="small" :center="true">{{
                    dbStatus2ViewStatus(scope.row.pushFlag, scope.row.pushType)
                  }}</a-text>
                </template>
              </a-data-table-column>
              <a-data-table-column :width="75">
                <template #default="scope">
                  <button
                    class="t-campaigns-three-dot-leader"
                    :data-testid="'campaign-edit-delete-show-' + scope.index"
                    type="button"
                    @click.stop="showModal($event, scope.row)"
                  >
                    <span class="t-campaigns-dot"></span>
                  </button>
                </template>
              </a-data-table-column>
            </m-data-table>
          </m-action-card>
        </div>
      </m-section>
    </div>
    <m-modal
      v-show="modalVisible"
      :documentid="selectedDocumentID"
      :is-ended="checkStatus !== 'approved' ? false : isEnded"
      :is-published="checkStatus !== 'approved' ? false : isPublished"
      @handle-confirm-delete="handleConfirmCampaignDelete(selectedDocumentID, selectedTitle)"
      @handle-edit="handleCampaignEdit(selectedDocumentID)"
      @handle-edit-return-unpublished="handleEditReturnUnpublished(selectedDocumentID)"
      @handle-edit-keep-published="handleEditKeepPublished(selectedDocumentID)"
      @handle-modal-close="handleModalClose()"
    ></m-modal>
    <teleport to="body">
      <o-modal
        id="t-campaigns-remove-modal"
        :visible="confirmModalVisible"
        class="t-campaigns-remove-modal"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ modalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="modalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="t-campaigns-remove-modal-close-button"
            data-testid="campaign-delete-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleModalClose"
          />
          <a-button
            id="t-campaigns-delete-button"
            data-testid="campaign-delete-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleCampaignDelete(selectedDocumentID)"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>
<script>
import { mapModifiers } from '@/libs/component';
import TDefaultLayout from '@/components/templates/default-layout';
import APageHeading from '@/components/atoms/page-heading';
import MModal from '@/components/molecules/modal';
import OModal from '@/components/organisms/modal';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import AIcon from '@/components/atoms/icon';
import { utils } from '@/common/utils.js';
export default {
  name: 't-campaigns',
  components: {
    TDefaultLayout,
    APageHeading,
    MModal,
    OModal,
    AIcon,
  },
  mixins: [customRequest, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  emits: [],
  data() {
    return {
      documentData: '',
      tableData: [],
      tableDataUnpublished: [],
      tableDataPublished: [],
      tableDataEnded: [],
      show: '',
      execUserID: this.$store.state.user.userID,
      modalVisible: false,
      confirmModalVisible: false,
      modalTitle: '',
      modalContent: '',
      selectedDocumentID: '',
      selectedCategory: '',
      selectedTitle: '',
      nowDate: '',
      draftFlag: false,
      isEnded: false,
      isPublished: false,
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-campaigns');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  watch: {},
  created() {
    this.getCampaignList();
    this.show = this.$route.query.show ?? '1';
  },
  async mounted() {},
  methods: {
    select: function (num) {
      this.show = num;
    },
    getCampaignList: function () {
      const data = {
        execUserID: this.execUserID,
      };
      this.postRequest('/campaigns/get-list', data).then(e => {
        for (const k in e.data) {
          const fileInfo = {
            documentID: k,
            title: e.data[k].title,
            castStartDate: e.data[k].castStartDate,
            castEndDate: e.data[k].castEndDate,
            importantFlag: e.data[k].importantFlag,
            pushFlag: e.data[k].pushFlag,
            draftFlag: e.data[k].draftFlag,
            createUserName: e.data[k].createUserName,
            updateDate: e.data[k].updateDate,
            imageURL: e.data[k].imageURL,
            pushType: e.data[k].pushType,
            checkStatus: e.data[k].checkStatus,
            checkHistories: e.data[k].checkHistories,
          };
          const castStartDate = e.data[k].castStartDate;
          const castEndDate = e.data[k].castEndDate;
          this.nowDate = utils.currentDateTime();
          if (castStartDate <= this.nowDate && this.nowDate <= castEndDate && e.data[k].checkStatus === 'approved') {
            fileInfo.childrenDocIds = e.data[k].childrenDocIds ? Object.values(e.data[k].childrenDocIds) : [];
            fileInfo.parentDocId = e.data[k].parentDocId ? e.data[k].parentDocId : '';
            if (fileInfo.childrenDocIds.length > 0) {
              let str = fileInfo.childrenDocIds[0];
              for (let i = 1; i < fileInfo.childrenDocIds.length; i++) {
                str += `, ${fileInfo.childrenDocIds[i]}`;
              }
              fileInfo.childrenDocIdsStr = str;
            }
            // 下書きとチェック済み中ではなく、公開期間内の場合
            this.tableDataPublished.push(fileInfo);
          } else if (castEndDate < this.nowDate && e.data[k].checkStatus === 'approved') {
            // 公開期限後の場合
            this.tableDataEnded.push(fileInfo);
          } else {
            fileInfo.parentDocId = e.data[k].parentDocId ? e.data[k].parentDocId : '';
            // 下書き中、または公開期限前の場合
            this.tableDataUnpublished.push(fileInfo);
          }
        }

        this.tableDataUnpublished = utils.tableDataUnpublishedSort(this.tableDataUnpublished);
        this.tableDataEnded.sort(utils.latestCastStartDateLatestUpdateDateSortOrderRule);
        this.tableDataPublished = utils.tableDataPublishedSort(this.tableDataPublished, 5);
        // 30件を超えてコンテンツは背景色を白地ではなくグレーで表示する
        let count = 0;
        for (const item of this.tableDataPublished) {
          count++;
          if (count > 30) item.isGreyBg = true;
          if (item.childrenDocIds.length > 0) {
            item.isOrangeBg = true;
          }
        }
        for (const item of this.tableDataUnpublished) {
          if (item.parentDocId) {
            item.isOrangeBg = true;
          }
        }
      });
    },
    displayedTableData() {
      if (this.show === '1') {
        this.tableData = this.tableDataUnpublished;
      } else if (this.show === '2') {
        this.tableData = this.tableDataPublished;
      } else if (this.show === '3') {
        this.tableData = this.tableDataEnded;
      }
      return this.tableData;
    },
    handleCampaignAdd() {
      this.$router.push({
        path: '/campaigns/add',
        query: { execUserID: this.execUserID },
      });
    },
    showModal(e, row) {
      const authInfo = this.$store.state.user.authInfo;
      const shouldShowModal = this.shouldShowModal(authInfo, row);

      if (shouldShowModal) {
        this.selectedDocumentID = row.documentID;
        this.selectedTitle = row.title;
        this.isEnded = row.castEndDate < this.nowDate;
        this.isPublished = !row.parentDocId && row.castStartDate <= this.nowDate && this.nowDate <= row.castEndDate;
        this.checkStatus = row.checkStatus;

        const elem = document.getElementById('m-modal-content');
        const topPosition = e.pageY - window.pageYOffset;

        if (window.innerHeight > (topPosition - window.pageYOffset) * 2) {
          elem.style.top = topPosition + 'px';
        } else {
          elem.style.top = topPosition - elem.clientHeight + 'px';
        }

        this.modalVisible = true;
      }
    },

    shouldShowModal(authInfo, row) {
      return (authInfo === 'writer' && row.checkStatus === 'draft') || authInfo !== 'writer';
    },
    handleConfirmCampaignDelete(documentid, title) {
      this.modalVisible = false;
      this.confirmModalVisible = true;
      this.modalTitle = 'Info';
      this.selectedDocumentID = documentid;
      this.selectedTitle = title;
      this.modalContent = messageContent.comfirmDelete(title);
    },
    handleCampaignDelete(documentid) {
      this.handleModalClose();
      const data = {
        execUserID: this.execUserID,
        documentID: documentid,
      };
      this.postRequest('/campaigns/delete', data).then(e => {
        this.$router.push({ name: 'Campaigns', query: { show: this.show } }).then(() => {
          this.$router.go();
        });
      });
    },
    handleCampaignEdit(documentid) {
      this.$router.push({
        path: '/campaigns/edit',
        query: { id: documentid, source: 'list', show: this.show },
      });
    },
    handleEditReturnUnpublished(documentid) {
      const data = {
        id: documentid,
      };
      this.postRequest('/campaigns/edit-return-unpublished', data).then(e => {
        this.$router.push({ name: 'Campaigns' }).then(() => {
          this.$router.go();
        });
      });
    },
    handleEditKeepPublished(documentid) {
      const data = {
        id: documentid,
      };
      this.postRequest('/campaigns/edit-keep-published', data).then(e => {
        this.$router.push({ name: 'Campaigns' }).then(() => {
          this.$router.go();
        });
      });
    },
    handleRowClick(documentid) {
      this.$router.push({
        path: '/campaigns/detail',
        query: { id: documentid, show: this.show },
      });
    },
    handleModalClose() {
      this.modalVisible = false;
      this.confirmModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
      this.selectedDocumentID = '';
      this.selectedTitle = '';
    },
    dbStatus2ViewStatus(pushFlag, pushType) {
      if (pushFlag === false) return '-';
      else if (pushType === 'Limited') return 'Limited';
      else return 'ALL';
    },
    checkStatus2ViewStatus(checkStatus) {
      const statusMap = {
        approved: '承認済み',
        checked: 'チェック済み',
        draft: '下書き',
      };
      return statusMap[checkStatus] || '';
    },
    assignIrregularCheckStatus(checkStatus) {
      const validStatuses = ['draft', 'checked', 'approved'];
      return validStatuses.includes(checkStatus) ? checkStatus : 'draft';
    },
  },
};
</script>
