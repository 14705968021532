import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { messageContent } from '@/common/messageContent.js';
import { commonCastDateValidations } from '@/common/commonValidations.js';

const commonCreateUpdateValidations = (checkStatus, transitionType) => {
  return {
    bannerData: {
      checkStatus: {
        accepted: helpers.withMessage(messageContent.requireSelect('チェックステータス'), value =>
          ['draft', 'checked', 'approved'].includes(value)
        ),
      },
      importantFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('優先設定'),
          value => value === true || value === false
        ),
      },
      dAccountLoginFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('ビジネスｄアカウントログイン'),
          value => value === true || value === false
        ),
      },
      webviewFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('Webビュー'),
          value => value === true || value === false
        ),
      },
      showPopupFlag: {
        accepted: helpers.withMessage(
          messageContent.requireSelect('ポップアップ表示'),
          value => value === true || value === false
        ),
      },
      title: {
        required: helpers.withMessage(messageContent.requireInput('タイトル'), required),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('タイトル', 17),
          maxLength(17)
        ),
      },
      text: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('テキスト'),
          requiredIf(() => checkStatus !== 'draft')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('テキスト', 42),
          maxLength(42)
        ),
      },
      transitionType: {
        accepted: helpers.withMessage(messageContent.requireSelect('タップ時の遷移先'), value =>
          ['url', 'campaign', 'info'].includes(value)
        ),
      },
      contentsURL: {
        requiredIf: helpers.withMessage(
          messageContent.requireInput('URL'),
          requiredIf(() => checkStatus !== 'draft' && transitionType === 'url')
        ),
        maxlength: helpers.withMessage(
          messageContent.requireUnderMaximumNumberOfCharacters('URL', 500),
          maxLength(500)
        ),
        couponUrl: helpers.withMessage(
          'クーポンサイトを登録する場合、ビジネスdアカウントログインとWebビューをONにしてください。',
          (value, vm) => {
            if (!value || vm.checkStatus === 'draft') return true;
            if (
              value ===
                'https://couponbank.relo.jp/docomobusiness/search?serial_no=6AVWGWWRHKTVLWAGPKXDDN6YYDNAHHP2BQHGLX6FMKRKAB3JCPFP59RA8YWRL4FQ&utm_source=dapp&utm_medium=dapp&utm_id=dapp' &&
              (!vm.dAccountLoginFlag || !vm.webviewFlag)
            ) {
              return false;
            }
            return true;
          }
        ),
      },
      imageFile: {
        binary: {
          requiredIf: helpers.withMessage(
            messageContent.requireUpload('画像'),
            requiredIf((value, vm) => {
              if (checkStatus === 'draft') return false;
              return !value && !vm.file;
            })
          ),
        },
      },
      transitionDoc: {
        requiredIf: helpers.withMessage(
          messageContent.requireSelect('遷移先ドキュメント'),
          requiredIf(
            () => ['checked', 'approved'].includes(checkStatus) && ['campaign', 'info'].includes(transitionType)
          )
        ),
      },
    },
  };
};

export const bannerValidation = {
  create: (checkStatus, transitionType) => {
    const obj = commonCreateUpdateValidations(checkStatus, transitionType);

    obj.bannerData.castStartDate = commonCastDateValidations(checkStatus).create.castStartDate;
    obj.bannerData.castEndDate = commonCastDateValidations(checkStatus).create.castEndDate;

    return obj;
  },

  update: (checkStatus, transitionType) => {
    const obj = commonCreateUpdateValidations(checkStatus, transitionType);

    obj.bannerData.castStartDate = commonCastDateValidations(checkStatus).update.castStartDate;
    obj.bannerData.castEndDate = commonCastDateValidations(checkStatus).update.castEndDate;
    obj.isPublished = commonCastDateValidations(checkStatus).update.isPublished('bannerData');
    obj.beforeCastEndDate = commonCastDateValidations(checkStatus).update.beforeCastEndDate('bannerData');

    return obj;
  },
};
