import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      authInfo: '',
      userName: '',
      userID: '',
      departmentInfo: '',
    },
    logedIn: false,
  },
  mutations: {
    loginUser(state, user) {
      user && user.userName ? user.userName.replace('_', ' ') : '';
      state.user = user;
    },
    loginStatus(state, status) {
      state.logedIn = status;
    },
  },
  actions: {
    updateLoginInfo({ commit }, user) {
      commit('loginUser', user);
    },
    updateLoggedInStatus({ commit }, status) {
      commit('loginStatus', status);
    },
  },
  modules: {},
});
