<template>
  <div :class="componentClassName">
    <a-text-field
      v-model="currentMonth"
      class="a-expiry-date-field__input-month"
      type="tel"
      placeholder="MM"
      maxlength="2"
      @change="handleMonthChange"
    />
    <span class="a-expiry-date__divider">/</span>
    <a-text-field
      v-model="currentYear"
      class="a-expiry-date-field__input-year"
      type="tel"
      placeholder="YY"
      maxlength="2"
      @change="handleYearChange"
    />
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ATextField from '@/components/atoms/text-field';

export default {
  name: 'a-expiry-date-field',
  components: {
    ATextField,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      currentMonth: '',
      currentYear: '',
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-expiry-date-field');
      return `${baseClassName} ${this.class}`.trim();
    },
    currentValue() {
      return `${this.getNumText(this.currentMonth)}/${this.getNumText(this.currentYear)}`;
    },
  },
  watch: {
    modelValue(val, oldVal) {
      this.setCurrentValue(val);
    },
  },
  mounted() {
    if (this.value) {
      this.setCurrentValue(this.value);
    }
    if (this.modelValue) {
      this.setCurrentValue(this.modelValue);
    }
  },
  methods: {
    handleMonthChange(e) {
      this.currentMonth = this.getNumText(e.target.value);
      this.handleChange();
    },
    handleYearChange(e) {
      this.currentYear = this.getNumText(e.target.value);
      this.handleChange();
    },
    handleChange() {
      this.$emit('update:modelValue', this.currentValue);
      this.$emit('change', this.currentValue);
    },
    setCurrentValue(value) {
      const [month, year] = value.split('/');
      this.currentMonth = this.getNumText(month);
      this.currentYear = this.getNumText(year);
    },
    getNumText(val) {
      const num = Number(val) || 0;
      if (num <= 9) {
        return `0${num}`;
      }
      return num.toString();
    },
  },
};
</script>
