<template>
  <t-default-layout active-nav-id="online-shop-service">
    <div :class="componentClassName">
      <a-back-page text="一覧画面" page="OnlineShopService" :show="show" />
      <form
        ref="form"
        class="t-online-shop-service-detail__form"
        enctype="multipart/form-data"
        @submit.prevent="handleFormSubmit"
      >
        <div class="t-online-shop-service-detail__fields">
          <m-section>
            <m-action-card
              :id="idName[1]"
              test-id-text="detail-online-shop-service"
              title="詳細設定"
              :document-id="id"
              header-border
              no-header-margin
              icon="clock"
            >
              <div class="t-online-shop-service-detail__fields">
                <div id="t-online-shop-service-detail-uploading-file" class="t-online-shop-service-detail__row">
                  <div class="t-online-shop-service-detail__col-1">
                    <div class="t-online-shop-service-detail__fields">
                      <m-form-input>
                        <a-upload-field
                          id="t-online-shop-service-detail-upload-image"
                          v-model="onlineShopServiceData.imageFile.file"
                          test-id-text="detail-online-shop-service-image"
                          name="imageFile"
                          class="t-online-shop-service-detail-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          :disabled="true"
                          @change="handleUploadFileChange('imageFile', onlineShopServiceData.imageFile.file)"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-online-shop-service-detail__col-2">
                    <div class="t-online-shop-service-detail__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="onlineShopServiceData.title"
                          data-testid="detail-online-shop-service-title-display"
                          type="text"
                          maxlength="50"
                          placeholder="タイトル入力"
                          :disabled="true"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
                <div class="t-online-shop-service-add__row">
                  <a-text id="t-online-shop-service-add-importantFlag__a-text"> 外部Webサイト</a-text>
                  <m-form-input>
                    <a-apply-text-field
                      id="t-online-shop-service-add__contents-url"
                      v-model="onlineShopServiceData.contentsURL"
                      class="t-online-shop-service-add__contents-url"
                      :disabled="true"
                      data-testid="t-online-shop-service-add-url-input"
                      type="text"
                      maxlength="500"
                      placeholder="URL入力"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-online-shop-service-detail__fields">
                <div
                  id="t-online-shop-service-detail-cast-start-date-day__row"
                  class="t-online-shop-service-detail__row"
                >
                  <a-text id="t-online-shop-service-detail-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-online-shop-service-detail-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="onlineShopServiceData.castStartDate"
                      test-id-text="detail-online-shop-service-publish-start"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="true"
                    />
                  </m-form-input>
                </div>
                <div class="t-online-shop-service-detail__row">
                  <a-text id="t-online-shop-service-detail-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-online-shop-service-detail-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="onlineShopServiceData.castEndDate"
                      test-id-text="detail-online-shop-service-publish-end"
                      :min-date="minDate"
                      mode="dateTime"
                      :disabled="true"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-online-shop-service-detail__fields__pushtype">
                <m-form-input id="t-online-shop-service-detail__fields__pushtype">
                  <a-select-field
                    id="t-online-shop-service-detail__fields__pushtype__a-select-field"
                    v-model="onlineShopServiceData.checkStatus"
                    data-testid="detail-online-shop-service-check-status-select"
                    :options="checkStatusOptions"
                    disabled="true"
                  />
                </m-form-input>
              </div>
              <!-- 情報を表示するために m-data-table を追加します 承認フロー -->
              <m-data-table
                :empty-text="dataTableEmp"
                :fit="true"
                :data="onlineShopServiceData.checkHistories"
                id-text="online-shop-service-check-histories-info-display"
              >
                <a-data-table-column
                  prop="publishedDocumentId"
                  :min-width="120"
                  :label="'公開ドキュメントID：<br>' + docId"
                  align="center"
                >
                  <template #default="scope">
                    <a-text size="medium" :center="true">公開{{ scope.index + 1 }}回目</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="draft" :min-width="100" label="下書き" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.draft.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.draft.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="checked" :min-width="100" label="チェック済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.checked.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.checked.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="approved" :min-width="100" label="承認済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.approved.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.approved.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
              </m-data-table>
            </m-action-card>
          </m-section>
        </div>
        <div id="t-online-shop-service-detail-buttons">
          <div v-show="!isPublished" class="t-online-shop-service-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-online-shop-service-detail-detail-saveBtn"
                data-testid="detail-online-shop-service-edit-button"
                variant="block"
                color="primary"
                :disabled="isEnded || disableButton"
                @click="handleNavigateEdit"
                >編集</a-button
              >
            </m-form-action-buttons>
          </div>
          <div v-show="isPublished" class="t-online-shop-service-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-online-shop-service-detail-edit-return-unpublished-btn"
                data-testid="detail-online-shop-service-edit-return-unpublished-button"
                variant="block"
                color="primary"
                :disabled="disableButton"
                @click.stop="handleEditReturnUnpublished"
                >未公開に戻す</a-button
              >
            </m-form-action-buttons>
          </div>
          <div v-show="isPublished" class="t-online-shop-service-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-online-shop-service-detail-edit-keep-published-btn"
                data-testid="detail-online-shop-service-edit-keep-published-button"
                variant="block"
                color="primary"
                :disabled="disabledButton || disableButton"
                @click.stop="handleEditKeepPublished"
                >公開のまま編集</a-button
              >
            </m-form-action-buttons>
          </div>
          <div class="t-online-shop-service-detail-buttons__button">
            <m-form-action-buttons>
              <a-button
                id="t-online-shop-service-detail-detail-deleteBtn"
                data-testid="detail-online-shop-service-delete-button"
                variant="block"
                color="primary"
                :disabled="disableButton"
                @click.stop="handleConfirmDelete"
                >削除</a-button
              >
            </m-form-action-buttons>
          </div>
        </div>
      </form>
    </div>

    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="online-shop-bannersCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            id="t-online-shop-service-remove-modal-close-button"
            data-testid="detail-online-shop-service-modal-cancel-button"
            variant="outlined"
            color="primary"
            label="いいえ"
            size="small"
            @click="handleCloseModal"
          />
          <a-button
            id="t-online-shop-service-delete-button"
            data-testid="detail-online-shop-service-modal-confirm-button"
            variant="block"
            color="primary"
            label="はい"
            size="small"
            @click="handleDelete"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';

export default {
  name: 't-online-shop-service-detail',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    AUploadField,
    ACalendarField,
    AApplyTextField,
  },
  mixins: [customRequest, customValidate, messageContent],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      show: this.$route.query.show ?? '1',
      idName: [],
      disableButton: false,
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      disabledButton: null,
      isPublished: false, //公開中かどうかのフラグ
      docId: (() => {
        const id = this.$route.query.id;
        if (id.includes('_')) {
          const lastIndex = id.lastIndexOf('_');
          return id.slice(0, lastIndex);
        }
        return id;
      })(),

      // コンテンツデータ
      onlineShopServiceData: {
        title: '', // タイトル
        imageURL: '', // 画像の公開URL
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        position: 0, // 位置
        castStartDate: '', // 公開開始日
        castEndDate: '', // 公開終了日
        draftFlag: false, // 下書き
        checkStatus: '', // チェックステータス
        contentsURL: '', // URL
        checkHistories: [
          {
            draft: {
              userName: '',
              updateDate: '',
            },
            checked: {
              userName: '',
              updateDate: '',
            },
            approved: {
              userName: '',
              updateDate: '',
            },
          },
        ],
      },
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
      isEnded: false,
      nowDate: '',
    };
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-online-shop-service-detail');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    this.handleGetOnlineShopServiceInfo(() => {
      const userRole = this.$store.state.user.authInfo;
      const checkStatus = this.onlineShopServiceData.checkStatus;
      if (userRole === 'writer' && checkStatus !== 'draft') {
        this.disableButton = true;
      }
    });
    for (let i = 1; i <= 4; i++) {
      this.idName[i] = 'h1-online-shop-service-detail-' + i;
    }
  },
  mounted() {},
  methods: {
    handleGetOnlineShopServiceInfo(callback) {
      const id = this.$route.query.id || this.id;
      this.nowDate = utils.currentDateTime();
      if (id) {
        this.postRequest('/online-shop-service/get', { id })
          .then(e => {
            if (e.status === 'success' && e.data) {
              const data = e.data;
              this.onlineShopServiceData.title = data?.title || '';
              this.onlineShopServiceData.imageURL = data?.imageURL || '';
              (this.onlineShopServiceData.imageFile = {
                file: e.data.imageURL ? e.data.imageURL : null,
              }),
                (this.onlineShopServiceData.castStartDate = data?.castStartDate || '');
              this.onlineShopServiceData.castEndDate = data?.castEndDate || '';
              this.onlineShopServiceData.draftFlag = data?.draftFlag || false;
              this.onlineShopServiceData.contentsURL = decodeURI(data?.contentsURL) || '';
              this.onlineShopServiceData.checkStatus = data?.checkStatus || '';
              this.onlineShopServiceData.checkHistories = data?.checkHistories || [];
              // 下書きとチェク済み中ではなく、公開終了日時を過ぎている場合、isEndedフラグをtrueにする
              if (this.onlineShopServiceData.checkStatus === 'approved') {
                if (this.onlineShopServiceData.castEndDate && this.onlineShopServiceData.castEndDate < this.nowDate) {
                  this.isEnded = true;
                }
              }
              const nowDate = utils.currentDateTime();
              // 公開中の投稿か確認
              if (
                data &&
                data.checkStatus === 'approved' &&
                data.castStartDate &&
                data.castEndDate &&
                data.castStartDate <= nowDate &&
                nowDate <= data.castEndDate
              ) {
                this.isPublished = true;
              }
            } else {
              this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス）取得'));
            }
            if (typeof callback === 'function') {
              callback();
            }
          })
          .catch(error => {
            console.error(error);
            this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス）取得'));
          });
      }
    },
    async handleEditReturnUnpublished() {
      const data = {
        id: this.$route.query.id,
      };
      await this.postRequest('/online-shop-service/edit-return-unpublished', data).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'OnlineShopService' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス）更新'));
          }
        }
      });
    },
    async handleEditKeepPublished() {
      const data = {
        id: this.$route.query.id,
      };

      this.disabledButton = 'disabled';

      await this.postRequest('/online-shop-service/edit-keep-published', data).then(res => {
        if (res.status === 'success') {
          this.$router.push({ name: 'OnlineShopService' });
        } else {
          if (res.message) {
            this.showErrorModal(res.message);
          } else {
            this.showErrorModal(messageContent.requireTry('ショップ（人気のサービス）更新'));
          }
        }
        this.disabledButton = null;
      });
    },
    handleNavigateEdit() {
      const id = this.$route.query.id;
      if (id) {
        this.$router.push({ name: 'OnlineShopServiceEdit', query: { id: id, source: 'detail', show: this.show } });
      }
    },
    handleConfirmDelete() {
      this.resultModalVisible = true;
      this.resultModalTitle = 'Info';
      this.resultModalContent = messageContent.comfirmDelete(this.onlineShopServiceData.title);
    },
    handleDelete() {
      this.handleCloseModal();
      const data = {
        execUserID: this.$store.state.user.userID,
        documentID: this.$route.query.id,
      };
      this.postRequest('/online-shop-service/delete', data).then(e => {
        this.$router.push({ name: 'OnlineShopService', query: { show: this.show } });
      });
    },
    handleCancelClick() {
      this.$router.push({ name: 'OnlineShopService', query: { show: this.show } });
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
