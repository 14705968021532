<template>
  <div :class="componentClassName">
    <label v-if="label" :for="labelFor" class="m-form-input__label">
      {{ label }}
      <span v-if="type" class="m-form-input__type">{{ type }}</span>
      <span v-if="type2" class="m-form-input__type2" :class="iconClass">{{ type2 }}</span>
    </label>
    <div :class="contentClassName">
      <slot></slot>
    </div>
    <div v-if="$slots.note" class="m-form-input__note">
      <a-caption-list>
        <slot name="note"></slot>
      </a-caption-list>
    </div>
  </div>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import ACaptionList from '@/components/atoms/caption-list';

export default {
  name: 'm-form-input',
  components: {
    ACaptionList,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|center|left|right)/);
      },
    },
    contentLayout: {
      type: String,
      default: null,
      validator(value) {
        return value.match(/(null|horizontal|vertical)/);
      },
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    type2: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    /**
     * column number in the grid. Maximum 12.
     */
    col: {
      type: Number,
      default: null,
      validator(value) {
        if (value) {
          const num = Number(value);
          return num > 0 && num <= 12;
        }
        return true;
      },
    },
    /**
     * Unique identifier name for this form input inside parent form
     */
    prop: {
      type: String,
      default: null,
    },
    /**
     * Identifier of the label for inside input. Can be `name` or `id` of the input
     */
    for: {
      type: String,
      default: null,
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers(
        'm-form-input',
        this.align ? `align-${this.align}` : '',
        this.error ? 'invalid' : '',
        this.col ? `col-${this.col}` : ''
      );
      return `${baseClassName} ${this.class}`.trim();
    },
    labelFor() {
      return this.for || this.prop;
    },
    contentClassName() {
      const baseClassName = 'm-form-input__content';
      return this.contentLayout === 'vertical' ? baseClassName + '-vertical' : baseClassName;
    },
  },
};
</script>
