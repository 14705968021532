<template>
  <figure :class="componentClassName">
    <img v-if="imageUrl" :src="imageUrl" />
    <a-icon v-else icon="user-outline" :color="iconColor" />
  </figure>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AIcon from '@/components/atoms/icon';

export default {
  name: 'a-avatar',
  components: {
    AIcon,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('a-avatar', this.active ? 'active' : '', this.imageUrl ? 'with-image' : '');
      return `${baseClassName} ${this.class}`.trim();
    },
    iconColor() {
      return this.active ? 'orange' : 'grey';
    },
  },
};
</script>
