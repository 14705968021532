<template>
  <t-default-layout active-nav-id="banners">
    <div :class="componentClassName">
      <a-back-page :id="id" :text="backPageName()" :page="backPage()" :show="show" />
      <form ref="form" class="t-banners-edit__form" enctype="multipart/form-data" @submit.prevent="handleFormSubmit">
        <div class="t-banners-edit__fields">
          <m-section>
            <m-action-card
              :id="idName[1]"
              test-id-text="edit-banner"
              title="詳細設定"
              :document-id="id"
              header-border
              no-header-margin
              icon="clock"
            >
              <div class="t-banners-edit__fields">
                <div class="t-banners-edit__row">
                  <m-form-input id="t-banners-edit-importantFlag__form-input">
                    <a-text id="t-banners-edit-importantFlag__a-text">優先</a-text>　　
                    <a-switch
                      id="t-banners-edit-importantFlag"
                      v-model="bannerData.importantFlag"
                      data-testid="edit-banner-priority-toggle"
                    />
                  </m-form-input>
                </div>
                <o-transition-type-selector
                  v-if="updatedDataFlag"
                  v-model:transitionType="bannerData.transitionType"
                  v-model:transitionDoc="bannerData.transitionDoc"
                  v-model:contentsUrl="bannerData.contentsURL"
                />
                <div v-if="bannerData.transitionType === 'url'" class="t-banners-edit__row">
                  <m-form-input id="t-banners-edit-info-dAccountLoginFlag__form-input">
                    <a-text id="t-banners-edit-info-dAccountLoginFlag__a-text">ビジネスｄアカウントログイン</a-text>　　
                    <a-switch id="t-banners-edit-dAccountLoginFlag" v-model="bannerData.dAccountLoginFlag" />
                  </m-form-input>
                </div>
                <div v-if="bannerData.transitionType === 'url'" class="t-banners-edit__row">
                  <m-form-input id="t-banners-edit-info-webviewFlag__form-input">
                    <a-text id="t-banners-edit-info-webviewFlag__a-text">Webビュー</a-text>　　
                    <a-switch id="t-banners-edit-webviewFlag" v-model="bannerData.webviewFlag" />
                  </m-form-input>
                </div>
                <div v-if="bannerData.transitionType === 'url'" class="t-banners-edit__row">
                  <m-form-input id="t-banners-edit-info-reloclubFlag__form-input">
                    <a-text id="t-banners-edit-info-reloclubFlag__a-text">リロクラブ連携</a-text>　　
                    <a-switch id="t-banners-edit-reloclubFlag" v-model="bannerData.reloclubFlag" />
                  </m-form-input>
                </div>
                <div id="t-banners-add-info-showPopupFlag__form-input" class="t-banners-edit__fields">
                  <m-form-input>
                    <a-text id="t-banners-edit-info-showPopupFlag__a-text">ポップアップ表示</a-text>　　
                    <a-switch id="t-banners-edit-showPopupFlag" v-model="bannerData.showPopupFlag" />
                  </m-form-input>
                  <div
                    v-if="bannerData.showPopupFlag"
                    id="t-banners-edit__fields__upload-file"
                    class="t-banners-edit__fields__upload-file"
                  >
                    <a-text id="t-banners-edit__fields__upload-file__a-text_1"
                      >ポップアップ用画像（340*222px推奨）</a-text
                    >
                    <div class="t-banners-edit__fields__upload-file__m-form-input">
                      <m-form-input>
                        <a-upload-field
                          id="t-banners-edit__upload-image_1"
                          v-model="bannerData.popupImageFile.file"
                          test-id-text="edit-banner-image"
                          name="popupImageFile"
                          class="t-banners-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange('popupImageFile', bannerData.popupImageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
                <div id="t-banners-edit-uploading-file" class="t-banners-edit__row">
                  <div class="t-banners-edit__col-1">
                    <div class="t-banners-edit__fields">
                      <!-- .jfifと.svg対応はチケット#6297で対応予定 -->
                      <!-- <m-form-input>
                        <a-upload-field
                          id="t-banners-edit__upload-image_3"
                          v-model="bannerData.imageFile.file"
                          name="imageFile"
                          class="t-banners-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, .jfif, image/jpg, image/jpeg, .svg"
                          @change="handleUploadFileChange('imageFile', bannerData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input> -->
                      <a-text v-if="bannerData.showPopupFlag" id="t-banners-edit__fields__upload-file__a-text_2"
                        >バナー用画像（340*89px推奨）</a-text
                      >
                      <m-form-input>
                        <a-upload-field
                          id="t-banners-edit__upload-image_2"
                          v-model="bannerData.imageFile.file"
                          test-id-text="edit-banner-image"
                          name="imageFile"
                          class="t-banners-edit-uploading-file__form-upload"
                          label="ファイルをドラッグ＆ドロップするかパソコンからファイルを貼り付けしてください。"
                          accept="image/png, image/jpg, image/jpeg"
                          @change="handleUploadFileChange('imageFile', bannerData.imageFile.file)"
                          @showErrorModal="showErrorModal"
                        />
                      </m-form-input>
                    </div>
                  </div>
                  <div class="t-banners-edit__col-2">
                    <div class="t-banners-edit__fields">
                      <m-form-input>
                        <a-apply-text-field
                          id="title"
                          v-model="bannerData.title"
                          data-testid="edit-banner-title-input"
                          type="text"
                          maxlength="17"
                          placeholder="タイトル入力"
                        />
                      </m-form-input>
                      <m-form-input>
                        <a-apply-text-field
                          v-model="bannerData.text"
                          data-testid="edit-banner-content-input"
                          class="t-banners-detail__content-input"
                          type="textarea"
                          rows="7"
                          maxlength="42"
                          placeholder="テキスト入力"
                        />
                      </m-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[3]" title="公開設定" header-border no-header-margin icon="calendar-setting">
              <div class="t-banners-edit__fields">
                <div id="t-banners-edit-cast-start-date-day__row" class="t-banners-edit__row">
                  <a-text id="t-banners-edit-cast-start-date__a-text">公開日</a-text>　
                  <m-form-input id="t-banners-edit-cast-start-date-day__form-input">
                    <a-calendar-field
                      id="cast-start-date-day"
                      v-model="bannerData.castStartDate"
                      test-id-text="edit-banner-publish-start"
                      :min-date="minDate"
                      date-placeholder="公開日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      :disabled="hasParentDoc"
                    />
                  </m-form-input>
                </div>
                <div class="t-banners-edit__row">
                  <a-text id="t-banners-edit-cast-end-date__a-text">公開終了日</a-text>　
                  <m-form-input id="t-banners-edit-cast-end-date-day__form-input">
                    <a-calendar-field
                      id="cast-end-date-day"
                      v-model="bannerData.castEndDate"
                      test-id-text="edit-banner-publish-end"
                      :min-date="minDate"
                      date-placeholder="公開終了日"
                      time-placeholder="00:00"
                      mode="dateTime"
                      @change="handleChange"
                    />
                  </m-form-input>
                </div>
              </div>
            </m-action-card>
          </m-section>
          <m-section>
            <m-action-card :id="idName[4]" title="承認フロー" header-border no-header-margin icon="user-setting">
              <div class="t-banners-edit__fields__pushtype">
                <m-form-input id="t-banners-edit__fields__pushtype">
                  <a-select-field
                    id="t-banners-edit__fields__pushtype__a-select-field"
                    v-model="bannerData.checkStatus"
                    data-testid="edit-banner-check-status-select"
                    :options="checkStatusOptions"
                    :v-options="visibleCheckStatusOptions"
                  />
                </m-form-input>
              </div>
              <m-data-table
                :empty-text="dataTableEmp"
                :fit="true"
                :data="bannerData.checkHistories"
                id-text="banner-check-histories-info-display"
              >
                <a-data-table-column
                  prop="publishedDocumentId"
                  :min-width="120"
                  :label="'公開ドキュメントID：<br>' + docId"
                  align="center"
                >
                  <template #default="scope">
                    <a-text size="medium" :center="true">公開{{ scope.index + 1 }}回目</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="draft" :min-width="100" label="下書き" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.draft.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.draft.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="checked" :min-width="100" label="チェック済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.checked.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.checked.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
                <a-data-table-column prop="approved" :min-width="100" label="承認済み" align="center">
                  <template #default="scope">
                    <a-text size="medium" :center="true">{{ scope.row.approved.userName }}</a-text>
                    <a-text size="small" :center="true">{{ scope.row.approved.updateDate }}</a-text>
                  </template>
                </a-data-table-column>
              </m-data-table>
            </m-action-card>
          </m-section>
        </div>
        <m-form-action-buttons>
          <a-button id="saveBtn" data-testid="edit-banner-save-button" variant="block" color="primary" submit="submit"
            >保存</a-button
          >
        </m-form-action-buttons>
      </form>
    </div>

    <!-- Info, Erro Modal -->
    <teleport to="body">
      <o-modal
        id="bannersCreate_resultModal"
        v-model:visible="resultModalVisible"
        class="o-modal--confirmation"
        footer-variant="confirmation"
        width="442px"
        :footer-shadow="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #title>{{ resultModalTitle }}</template>
        <a-text>
          <!-- eslint-disable -->
          <span v-html="resultModalContent" />
          <!-- eslint-enable -->
        </a-text>
        <template #footer>
          <a-button
            data-testid="error-info-modal-confirm-button"
            variant="block"
            color="primary"
            label="OK"
            size="small"
            @click="handleCloseModal"
          />
        </template>
      </o-modal>
    </teleport>
  </t-default-layout>
</template>

<script>
import { mapModifiers } from '@/libs/component';
import AButton from '@/components/atoms/button';
import ASwitch from '@/components/atoms/switch';
import AUploadField from '@/components/atoms/upload-field';
import AText from '@/components/atoms/text';
import ABackPage from '@/components/atoms/back-page';
import AApplyTextField from '@/components/atoms/apply-text-field';
import MSection from '@/components/molecules/section';
import MActionCard from '@/components/molecules/action-card';
import MFormActionButtons from '@/components/molecules/form-action-buttons';
import OTransitionTypeSelector from '@/components/organisms/transition-type-selector';
import TDefaultLayout from '@/components/templates/default-layout';
import { customRequest } from '@/common/customRequest.js';
import { messageContent } from '@/common/messageContent.js';
import { customValidate } from '@/common/customValidate.js';
import ACalendarField from '@/components/atoms/calendar-field';
import { utils } from '@/common/utils.js';
import { useVuelidate } from '@vuelidate/core';
import { bannerValidation } from '@/validations/banner.js';
import { cloneDeep } from 'lodash';

export default {
  name: 't-banners-edit',
  components: {
    TDefaultLayout,
    MSection,
    MActionCard,
    AText,
    AButton,
    ABackPage,
    MFormActionButtons,
    ASwitch,
    AUploadField,
    ACalendarField,
    AApplyTextField,
    OTransitionTypeSelector,
  },
  mixins: [customRequest, customValidate, messageContent, utils],
  props: {
    class: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      id: this.$route.query.id,
      source: this.$route.query.source,
      show: this.$route.query.show ?? '1',
      idName: [],
      minDate: utils.currentDateTime(),
      resultModalTitle: '',
      resultModalContent: '',
      resultModalVisible: false,
      hasParentDoc: false,
      docId: (() => {
        const id = this.$route.query.id;
        if (id.includes('_')) {
          const lastIndex = id.lastIndexOf('_');
          return id.slice(0, lastIndex);
        }
        return id;
      })(),

      //バナーデータ
      bannerData: {
        title: '', //バナータイトル
        text: '', //バナーテキスト
        imageURL: '', //
        imageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        importantFlag: false, //優先フラグ
        dAccountLoginFlag: false, //ビジネスｄアカウントログインフラグ
        webviewFlag: false, //Webビューフラグ
        reloclubFlag: false, //リロクラブ連携フラグ
        showPopupFlag: false, //ポップアップ表示フラグ
        castStartDate: '', //公開開始日
        castEndDate: '', //公開終了日
        deleteDate: '', //削除予定日
        draftFlag: false, //下書き
        checkStatus: 'draft', //チェックステータス
        contentsURL: '',
        popupImageURL: '',
        popupImageFile: {
          file: null,
          filename: null,
          binary: null,
          size: null,
        },
        checkHistories: [
          {
            draft: {
              userName: '',
              updateDate: '',
            },
            checked: {
              userName: '',
              updateDate: '',
            },
            approved: {
              userName: '',
              updateDate: '',
            },
          },
        ],
        transitionType: 'url', //遷移先種別
        transitionDoc: '', //遷移先ドキュメントID
      },
      isPublished: false, //公開中かどうかのフラグ
      beforeCastEndDate: '', //変更前の公開終了日時
      currentCheckStatus: 'draft', //DBに現在のcheckStatus
      checkStatusOptions: [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
        { value: 'approved', label: '承認済み' },
      ],
      visibleCheckStatusOptions: [],
      updatedDataFlag: false,
    };
  },

  validations() {
    return bannerValidation.update(this.bannerData.checkStatus, this.bannerData.transitionType);
  },

  computed: {
    componentClassName() {
      const baseClassName = mapModifiers('t-banners-edit');
      return `${baseClassName} ${this.class}`.trim();
    },
  },
  created() {
    for (let i = 1; i <= 4; i++) {
      this.idName[i] = 'h1-banners-edit-' + i;
    }
    this.handleGetBannerInfo();
  },
  mounted() {},
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        let errMsg = '';
        this.v$.$errors.map(item => (errMsg += item.$message + '<br>'));
        this.showErrorModal(errMsg);
        return;
      }
      // リロクラブ連携チェック
      if (this.bannerData.reloclubFlag && (!this.bannerData.dAccountLoginFlag || !this.bannerData.webviewFlag)) {
        this.showErrorModal(
          'リロクラブ連携ONにはビジネスｄアカウントログインおよびWebビュー設定をONにする必要があります。'
        );
        return;
      }

      const payload = cloneDeep(this.bannerData);
      //  URLに特殊文字　<>'"　を入力するチェックする
      if (messageContent.checkUrlSpecialCharacter(payload.contentsURL)) {
        this.showErrorModal(messageContent.urlSpecialCharater);
        return;
      }
      payload.contentsURL = encodeURI(payload.contentsURL);
      // Note: 「=」はCloudArmorポリシーに抵触するケースがあるためエスケープを行う
      payload.title = payload.title.replace(/=/g, '%%eq%%');
      payload.text = payload.text.replace(/=/g, '%%eq%%');

      if (!messageContent.checkHasSpecialCharacterInObj(payload)) {
        this.showErrorModal(messageContent.hasSpecialCharacter);
        return;
      }

      const id = this.id;
      if (id) {
        if (this.bannerData.castEndDate) {
          const castEndDate = new Date(this.bannerData.castEndDate);
          const deleteDate = new Date(castEndDate.setFullYear(castEndDate.getFullYear() + 1));
          this.deleteDate = this.convertDateToString(deleteDate);
        }
        const formDataTmp = {
          id: id ?? '',
          title: payload.title ?? '',
          text: payload.text ?? '',
          imageFile: this.bannerData.imageFile.binary ?? '',
          importantFlag: this.bannerData.importantFlag ?? false,
          dAccountLoginFlag: this.bannerData.dAccountLoginFlag ?? false,
          webviewFlag: this.bannerData.webviewFlag ?? false,
          reloclubFlag: this.bannerData.reloclubFlag ?? false,
          showPopupFlag: this.bannerData.showPopupFlag ?? false,
          castStartDate: this.bannerData.castStartDate ?? '',
          castEndDate: this.bannerData.castEndDate ?? '',
          deleteDate: this.deleteDate ?? '',
          draftFlag: this.bannerData.draftFlag ?? false,
          checkStatus: this.bannerData.checkStatus ?? 'draft',
          imageURL: this.bannerData.imageURL ?? '',
          popupImageURL: this.bannerData.popupImageURL ?? '',
          popupImageFile: this.bannerData.popupImageFile.binary ?? '',
          contentsURL: encodeURI(this.bannerData.contentsURL.replace(/[\s\uFEFF\xA0]/g, '')) ?? '',
          checkHistories: this.bannerData.checkHistories ?? [],
          transitionType: this.bannerData.transitionType ?? 'url',
          transitionDoc: this.bannerData.transitionDoc ?? '',
        };
        await this.postRequest('/banners/edit', formDataTmp).then(res => {
          if (res.status === 'success') {
            this.$router.push({ name: 'Banners', query: { show: this.show } });
          } else {
            if (res.message) {
              this.showErrorModal(res.message);
            } else {
              this.showErrorModal(messageContent.requireTry('バナー更新'));
            }
          }
        });
      } else {
        this.showErrorModal(messageContent.requireTry('バナー更新'));
      }
    },
    handleUploadFileChange(type, file) {
      const vm = this;
      const reader = new FileReader();
      const data = type === 'imageFile' ? vm.bannerData.imageFile : vm.bannerData.popupImageFile;
      reader.addEventListener(
        'load',
        function () {
          const [info, content] = reader.result.split(',');
          data.binary = content;
        },
        false
      );

      if (file[0]) {
        reader.readAsDataURL(file[0]);
        data.filename = file[0].name;
        data.size = file[0].size;
        vm.agree = true;
      } else {
        // Clear file content
        data.file = null;
        data.filename = null;
        data.binary = null;
        data.size = null;
        vm.agree = false;
        type === 'imageFile' ? (vm.bannerData.imageURL = '') : (vm.bannerData.popupImageURL = '');
      }
    },
    handleGetBannerInfo() {
      const nowDate = utils.currentDateTime();
      const id = this.$route.query.id;
      if (id) {
        this.postRequest('/banners/get', { id })
          .then(e => {
            if (e.status === 'success' && e.data) {
              const data = e.data;
              const authInfo = this.$store.state.user.authInfo;
              if ((authInfo === 'writer' && data.checkStatus === 'draft') || authInfo !== 'writer') {
                this.bannerData.title = data?.title || '';
                this.bannerData.text = data?.text || '';
                this.bannerData.imageURL = data?.imageURL || '';
                (this.bannerData.imageFile = {
                  file: e.data.imageURL ? e.data.imageURL : null,
                }),
                  (this.bannerData.importantFlag = data?.importantFlag || false);
                this.bannerData.dAccountLoginFlag = data?.dAccountLoginFlag || false;
                this.bannerData.webviewFlag = data?.webviewFlag || false;
                this.bannerData.reloclubFlag = data?.reloclubFlag || false;
                this.bannerData.showPopupFlag = data?.showPopupFlag || false;
                this.bannerData.castStartDate = data?.castStartDate || '';
                this.bannerData.castEndDate = data?.castEndDate || '';
                this.bannerData.contentsURL = decodeURI(data?.contentsURL) || '';
                this.bannerData.type = data?.type || '';
                this.bannerData.transitionType = data?.transitionType || 'url';
                this.bannerData.transitionDoc = data?.transitionDoc || '';
                this.bannerData.popupImageURL = data?.popupImageURL || '';
                this.bannerData.popupImageFile = {
                  file: e.data.popupImageURL ? e.data.popupImageURL : null,
                };
                // 変更前の公開終了日時を保存
                this.beforeCastEndDate = data?.castEndDate || '';
                // 公開中の投稿か確認
                if (
                  data &&
                  data.checkStatus === 'approved' &&
                  data.castStartDate &&
                  data.castEndDate &&
                  data.castStartDate <= nowDate &&
                  nowDate <= data.castEndDate
                ) {
                  this.isPublished = true;
                  this.minDate = '';
                }

                if (this.isPublished) {
                  this.$router.push({ name: 'Banners' });
                  return;
                }

                this.hasParentDoc = data?.parentDocId ? true : false;

                // DBに現在のcheckStatusを保存
                this.currentCheckStatus = data?.checkStatus;
                // 現在のcheckStatusの値しだいでvisibleCheckStatusOptionsを変更する
                this.visibleCheckStatusOptions = this.selectVisibleCheckStatusOptions(data?.checkStatus || '');
                // DBから取得したcheckHistoriesが空配列のときは更新せず、初期値のcampaignData.checkHistoriesを用いる
                if (data?.checkHistories && data?.checkHistories.length > 0) {
                  this.bannerData.checkHistories = data?.checkHistories;
                }
              } else {
                this.$router.push({ name: 'PermissionDenied' });
                return;
              }
            } else {
              this.showErrorModal(messageContent.requireTry('バナー取得'));
            }
            this.updatedDataFlag = true;
          })
          .catch(error => {
            console.error(error);
            this.showErrorModal(messageContent.requireTry('バナー取得'));
          });
      }
    },
    handleCancelClick() {
      const id = this.id;
      const source = this.source;
      if (id && source === 'detail') {
        return this.$router.push({ name: 'BannersDetail', query: { id: id, show: this.show } });
      } else {
        return this.$router.push({ name: 'Banners', query: { show: this.show } });
      }
    },
    selectVisibleCheckStatusOptions(checkStatus) {
      if (checkStatus === 'draft') {
        return [
          { value: 'draft', label: '下書き' },
          { value: 'checked', label: 'チェック済み' },
        ];
      } else if (checkStatus === 'checked') {
        return [
          { value: 'draft', label: '下書き' },
          { value: 'approved', label: '承認済み' },
        ];
      } else if (checkStatus === 'approved') {
        return [{ value: 'draft', label: '下書き' }];
      }
      // イレギュラーは下書き相当
      return [
        { value: 'draft', label: '下書き' },
        { value: 'checked', label: 'チェック済み' },
      ];
    },
    backPageName() {
      const source = this.source;
      if (source === 'detail') {
        return '詳細画面';
      } else {
        return '一覧画面';
      }
    },
    backPage() {
      const source = this.source;
      if (source === 'detail') {
        return 'BannersDetail';
      } else {
        return 'Banners';
      }
    },
    handleCloseModal() {
      this.resultModalVisible = false;
      this.resultModalTitle = '';
      this.resultModalContent = '';
    },
    showErrorModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.errorModalTitle;
      this.resultModalContent = message;
    },
    showInfoModal(message) {
      this.resultModalVisible = true;
      this.resultModalTitle = messageContent.infoModalTitle;
      this.resultModalContent = message;
    },
  },
};
</script>
